import React, { FunctionComponent } from 'react';
import Table from 'react-bootstrap/Table';
import styles from './Table.scss';

interface Props {
  totalHits: number;
  noRowsFoundText?: string;
  renderRowCount?: (rowsNr: number) => string;
}

const UnpagedTable: FunctionComponent<Props> = ({
  children,
  totalHits,
  noRowsFoundText,
  renderRowCount
}) => (
  <div>
    <Table className={styles.table}>
      <caption>
        {renderRowCount
          ? renderRowCount(totalHits)
          : `Viser ${totalHits} rader`}
      </caption>
      {children}
    </Table>
    {!totalHits && (
      <p style={{ textAlign: 'center' }}>
        {noRowsFoundText && 'Ingen data ble funnet'}
      </p>
    )}
  </div>
);

export { UnpagedTable };
