/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InternalUserResponse
 */
export interface InternalUserResponse {
    /**
     * 
     * @type {string}
     * @memberof InternalUserResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserResponse
     */
    internalUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserResponse
     */
    employeeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserResponse
     */
    employmentCategory?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserResponse
     */
    initials?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserResponse
     */
    sectionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserResponse
     */
    workLocation?: string | null;
}

export function InternalUserResponseFromJSON(json: any): InternalUserResponse {
    return InternalUserResponseFromJSONTyped(json, false);
}

export function InternalUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'internalUserId': !exists(json, 'internalUserId') ? undefined : json['internalUserId'],
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'employmentCategory': !exists(json, 'employmentCategory') ? undefined : json['employmentCategory'],
        'initials': !exists(json, 'initials') ? undefined : json['initials'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'sectionCode': !exists(json, 'sectionCode') ? undefined : json['sectionCode'],
        'workLocation': !exists(json, 'workLocation') ? undefined : json['workLocation'],
    };
}

export function InternalUserResponseToJSON(value?: InternalUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'internalUserId': value.internalUserId,
        'employeeId': value.employeeId,
        'employmentCategory': value.employmentCategory,
        'initials': value.initials,
        'name': value.name,
        'sectionCode': value.sectionCode,
        'workLocation': value.workLocation,
    };
}


