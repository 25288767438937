import { AccessRequestResponse } from 'api/adgang';
import { accessRequestsClient } from 'api/AdgangClients';
import { useFetch } from 'common.ui';
import { DependencyList } from 'react';

export function useAccessRequest(
  accessRequestId?: number,
  dependencies: DependencyList = [] as DependencyList
): [AccessRequestResponse | undefined] {
  const [fetchedAccessRequest] = useFetch<AccessRequestResponse | undefined>(
    () => fetchAccessRequest(),
    undefined,
    false,
    [accessRequestId, ...dependencies]
  );

  const fetchAccessRequest = async (): Promise<AccessRequestResponse | undefined> => {
    if (!accessRequestId) return undefined;
    const result = await accessRequestsClient.getAccessRequestById({
      id: +accessRequestId
    });
    return result;
  };

  return [fetchedAccessRequest];
}
