/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

export function usePaging(
  fetchMethod: (pageNumber: number) => Promise<void>,
  initialPage: number = 1,
  pageSize: number = 20
): [
    number,
    number,
    number,
    () => void,
    () => void,
    React.Dispatch<React.SetStateAction<number>>
  ] {
  const calculateStartIndex = (page: number) =>
    page > 0 ? (page - 1) * pageSize : 0;

  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [startIndex, setStartIndex] = useState<number>(
    calculateStartIndex(initialPage)
  );

  useEffect(() => {
    fetchMethod(currentPage).then(() => {
      setStartIndex(calculateStartIndex(currentPage));
    });
  }, [currentPage]);

  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPreviousPage = () =>
    setCurrentPage(currentPage > 0 ? currentPage - 1 : 0);

  return [
    startIndex,
    currentPage,
    pageSize,
    onNextPage,
    onPreviousPage,
    setCurrentPage
  ];
}
