import React, { useState } from 'react';
import IconArrowRight from '../../assets/icons/arrow-right-icon.svg';
import IconArrowLeft from '../../assets/icons/arrow-left-icon.svg';
import styles from './Sidenav.scss';
import { SidebarItem, Sidebar } from './Sidebar';

type IProp = {
  routes: SidebarItem[];
};

export function Sidenav({ routes }: IProp) {
  const [leftSidenavOpen, setLeftSidenavOpen] = useState(false);
  const toggleLeftSidenav = () => setLeftSidenavOpen(!leftSidenavOpen);

  return (
    <div
      className={`${styles.sidenav} ${
        leftSidenavOpen ? styles['menu-open'] : styles['menu-close']
      }`}
    >
      <div
        role='button'
        tabIndex={0}
        className={styles.caret}
        aria-label='Toggle menu'
        onClick={toggleLeftSidenav}
        onKeyPress={toggleLeftSidenav}
      >
        {leftSidenavOpen ? (
          <IconArrowLeft className={styles.caretLeft} />
        ) : (
          <IconArrowRight className={styles.caretLeft} />
        )}
      </div>
      <Sidebar
        routes={routes}
        isOpened={leftSidenavOpen}
        onLinkClick={() => setLeftSidenavOpen(false)}
      />
    </div>
  );
}
