import { UserAuthenticationConstraint } from 'api/adgang/models';
import { SelectOption } from 'common.ui';

export function useUserAuthenticationConstraints(
  isRootConfiguration: boolean,
  allowsNull: boolean
): [SelectOption[]] {
  let types: SelectOption[] = [];

  if (isRootConfiguration) {
    // used only in Applications
    types = [
      ...types,
      {
        id: UserAuthenticationConstraint.RequiresChildConfiguration,
        text: 'Brukerautentisering er definert på rolle/tilgang'
      }
    ];
  }

  if (allowsNull) {
    // used in Roles & Permissions
    types = [
      ...types,
      {
        id: '',
        text: ''
      }
    ];
  }

  types = [
    ...types,
    {
      id: UserAuthenticationConstraint.Anonymous,
      text: 'Anonym bruker'
    },
    {
      id: UserAuthenticationConstraint.None, // Ukjent bruker
      text: 'Azure pålogget bruker'
    },
    {
      id: UserAuthenticationConstraint.IdPortenVerified, // Kjent bruker
      text: 'Azure pålogget bruker (med kjent identitet i ID-porten)'
    },
    {
      id: UserAuthenticationConstraint.IdPortenAlways, // ID-porten alltid
      text: 'ID-porten pålogget bruker'
    }
  ];
  return [types];
}
