import React, { useEffect } from 'react';
import { PagedTable, LoadingSpinner, useFetch, useSortedPaging, ThSortable } from 'common.ui';
import { adgroupsClient } from 'api/AdgangClients';
import { Link } from 'react-router-dom';
import {
  AdGroupDetailResponse,
  AdGroupDetailResponsePagedResult,
  AdGroupDetailSearchOrderBy,
  ApiAdGroupsGetRequest,
  PermissionResponse,
  RoleResponse,
  SortOrder
} from 'api/adgang';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import RoutePaths from 'RoutePaths';
import { GlobalApplicationName } from 'helpers/application/consts';

interface IAdGroupsTable {
  textFilter: string;
}

function AdGroupsTable({ textFilter }: IAdGroupsTable) {
  useEffect(() => setCurrentPage(1), [textFilter]);

  const [groupsPaged, isLoading, , fetchGroups] = useFetch<AdGroupDetailResponsePagedResult>(
    () => fetchPagedGroups(),
    {},
    false,
    [textFilter]
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    setCurrentPage,
    setSorting
  ] = useSortedPaging<AdGroupDetailSearchOrderBy>(fetchGroups, {
    field: AdGroupDetailSearchOrderBy.Name,
    direction: 'asc'
  });

  const fetchPagedGroups = async () => {
    const request = {
      filter: textFilter,
      pageSize,
      pageNumber: currentPage,
      sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
      orderField: sorting.field
    } as ApiAdGroupsGetRequest;
    return adgroupsClient.apiAdGroupsGet(request);
  };

  const handleSort = (e: SortEvent<AdGroupDetailSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <PagedTable
          startIndex={startIndex}
          totalHits={groupsPaged.pageInfo?.totalCount ?? 0}
          pageSize={pageSize}
          onNextClick={onNextPage}
          onPreviousClick={onPreviousPage}
        >
          <thead>
            <tr>
              <ThSortable
                title='Navn'
                field={AdGroupDetailSearchOrderBy.Name}
                currentSort={sorting}
                onSort={handleSort}
              />
              <ThSortable
                title='Tilknyttede roller'
                field={AdGroupDetailSearchOrderBy.Roles}
                currentSort={sorting}
                onSort={handleSort}
              />
              <ThSortable
                title='Tilknyttede tilganger'
                field={AdGroupDetailSearchOrderBy.Permissions}
                currentSort={sorting}
                onSort={handleSort}
              />
            </tr>
          </thead>

          <tbody>
            {groupsPaged.data?.map((a: AdGroupDetailResponse) => (
              <tr key={a.adGroupId ?? ''}>
                <td>
                  <Link to={`${RoutePaths.adgroups}/${a.adGroupId}`}>{a.name}</Link>
                </td>
                <td>
                  {a.roles
                    ?.sort(
                      (r1: RoleResponse, r2: RoleResponse) =>
                        (r1.applicationName || '').localeCompare(r2.applicationName || '') ||
                        (r1.name || '').localeCompare(r2.name || '')
                    )
                    .map((r) => `${r.name} (${r.applicationName || GlobalApplicationName})`)
                    .join(', ')}
                </td>
                <td>
                  {a.permissions
                    ?.sort(
                      (p1: PermissionResponse, p2: PermissionResponse) =>
                        (p1.applicationName || '').localeCompare(p2.applicationName || '') ||
                        (p1.name || '').localeCompare(p2.name || '')
                    )
                    .map(
                      (p: PermissionResponse) => `${p.name} (${p.applicationName || GlobalApplicationName})`
                    )
                    .join(', ')}
                </td>
              </tr>
            ))}
          </tbody>
        </PagedTable>
      )}
    </>
  );
}

export default AdGroupsTable;
