import { ApplicationStatus } from 'api/adgang/models';
import { SelectOption } from 'common.ui';

export function useApplicationStatuses(): [SelectOption[]] {
  const statuses = [
    {
      id: ApplicationStatus.Active as string,
      text: 'Aktiv'
    },
    {
      id: ApplicationStatus.Inactive as string,
      text: 'Inaktiv'
    }
  ];
  return [statuses];
}
