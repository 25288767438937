/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdgangAccessControl,
    AdgangAccessControlFromJSON,
    AdgangAccessControlFromJSONTyped,
    AdgangAccessControlToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoleConsumptionResponse
 */
export interface RoleConsumptionResponse {
    /**
     * 
     * @type {Array<AdgangAccessControl>}
     * @memberof RoleConsumptionResponse
     */
    accessControls?: Array<AdgangAccessControl> | null;
    /**
     * 
     * @type {number}
     * @memberof RoleConsumptionResponse
     */
    roleId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RoleConsumptionResponse
     */
    isConsumedByOtherEntitities?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RoleConsumptionResponse
     */
    numUserRoles?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleConsumptionResponse
     */
    numAdGroupRoles?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleConsumptionResponse
     */
    numSectionRoles?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleConsumptionResponse
     */
    numActiveAccessRequests?: number;
}

export function RoleConsumptionResponseFromJSON(json: any): RoleConsumptionResponse {
    return RoleConsumptionResponseFromJSONTyped(json, false);
}

export function RoleConsumptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleConsumptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessControls': !exists(json, 'accessControls') ? undefined : (json['accessControls'] === null ? null : (json['accessControls'] as Array<any>).map(AdgangAccessControlFromJSON)),
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'isConsumedByOtherEntitities': !exists(json, 'isConsumedByOtherEntitities') ? undefined : json['isConsumedByOtherEntitities'],
        'numUserRoles': !exists(json, 'numUserRoles') ? undefined : json['numUserRoles'],
        'numAdGroupRoles': !exists(json, 'numAdGroupRoles') ? undefined : json['numAdGroupRoles'],
        'numSectionRoles': !exists(json, 'numSectionRoles') ? undefined : json['numSectionRoles'],
        'numActiveAccessRequests': !exists(json, 'numActiveAccessRequests') ? undefined : json['numActiveAccessRequests'],
    };
}

export function RoleConsumptionResponseToJSON(value?: RoleConsumptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessControls': value.accessControls === undefined ? undefined : (value.accessControls === null ? null : (value.accessControls as Array<any>).map(AdgangAccessControlToJSON)),
        'roleId': value.roleId,
        'isConsumedByOtherEntitities': value.isConsumedByOtherEntitities,
        'numUserRoles': value.numUserRoles,
        'numAdGroupRoles': value.numAdGroupRoles,
        'numSectionRoles': value.numSectionRoles,
        'numActiveAccessRequests': value.numActiveAccessRequests,
    };
}


