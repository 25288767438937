/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalUserSearchOrderBy,
    InternalUserSearchOrderByFromJSON,
    InternalUserSearchOrderByFromJSONTyped,
    InternalUserSearchOrderByToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderFromJSONTyped,
    SortOrderToJSON,
} from './';

/**
 * 
 * @export
 * @interface InternalUserSearchOrderByPaginationQuery
 */
export interface InternalUserSearchOrderByPaginationQuery {
    /**
     * 
     * @type {number}
     * @memberof InternalUserSearchOrderByPaginationQuery
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof InternalUserSearchOrderByPaginationQuery
     */
    pageSize?: number;
    /**
     * 
     * @type {SortOrder}
     * @memberof InternalUserSearchOrderByPaginationQuery
     */
    sortOrder?: SortOrder;
    /**
     * 
     * @type {InternalUserSearchOrderBy}
     * @memberof InternalUserSearchOrderByPaginationQuery
     */
    orderField?: InternalUserSearchOrderBy;
}

export function InternalUserSearchOrderByPaginationQueryFromJSON(json: any): InternalUserSearchOrderByPaginationQuery {
    return InternalUserSearchOrderByPaginationQueryFromJSONTyped(json, false);
}

export function InternalUserSearchOrderByPaginationQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalUserSearchOrderByPaginationQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : SortOrderFromJSON(json['sortOrder']),
        'orderField': !exists(json, 'orderField') ? undefined : InternalUserSearchOrderByFromJSON(json['orderField']),
    };
}

export function InternalUserSearchOrderByPaginationQueryToJSON(value?: InternalUserSearchOrderByPaginationQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'sortOrder': SortOrderToJSON(value.sortOrder),
        'orderField': InternalUserSearchOrderByToJSON(value.orderField),
    };
}


