/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationResponsiblePersonSearchOrderByPaginationQuery,
    ApplicationResponsiblePersonSearchOrderByPaginationQueryFromJSON,
    ApplicationResponsiblePersonSearchOrderByPaginationQueryFromJSONTyped,
    ApplicationResponsiblePersonSearchOrderByPaginationQueryToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationResponsiblePersonSearchRequest
 */
export interface ApplicationResponsiblePersonSearchRequest {
    /**
     * 
     * @type {ApplicationResponsiblePersonSearchOrderByPaginationQuery}
     * @memberof ApplicationResponsiblePersonSearchRequest
     */
    paginationQuery?: ApplicationResponsiblePersonSearchOrderByPaginationQuery;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationResponsiblePersonSearchRequest
     */
    applicationIds?: Array<string> | null;
}

export function ApplicationResponsiblePersonSearchRequestFromJSON(json: any): ApplicationResponsiblePersonSearchRequest {
    return ApplicationResponsiblePersonSearchRequestFromJSONTyped(json, false);
}

export function ApplicationResponsiblePersonSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponsiblePersonSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationQuery': !exists(json, 'paginationQuery') ? undefined : ApplicationResponsiblePersonSearchOrderByPaginationQueryFromJSON(json['paginationQuery']),
        'applicationIds': !exists(json, 'applicationIds') ? undefined : json['applicationIds'],
    };
}

export function ApplicationResponsiblePersonSearchRequestToJSON(value?: ApplicationResponsiblePersonSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paginationQuery': ApplicationResponsiblePersonSearchOrderByPaginationQueryToJSON(value.paginationQuery),
        'applicationIds': value.applicationIds,
    };
}


