/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalUserResponse,
    InternalUserResponseFromJSON,
    InternalUserResponseFromJSONTyped,
    InternalUserResponseToJSON,
    PageModel,
    PageModelFromJSON,
    PageModelFromJSONTyped,
    PageModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface InternalUserResponsePagedResult
 */
export interface InternalUserResponsePagedResult {
    /**
     * 
     * @type {PageModel}
     * @memberof InternalUserResponsePagedResult
     */
    pageInfo?: PageModel;
    /**
     * 
     * @type {Array<InternalUserResponse>}
     * @memberof InternalUserResponsePagedResult
     */
    data?: Array<InternalUserResponse> | null;
}

export function InternalUserResponsePagedResultFromJSON(json: any): InternalUserResponsePagedResult {
    return InternalUserResponsePagedResultFromJSONTyped(json, false);
}

export function InternalUserResponsePagedResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalUserResponsePagedResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageInfo': !exists(json, 'pageInfo') ? undefined : PageModelFromJSON(json['pageInfo']),
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(InternalUserResponseFromJSON)),
    };
}

export function InternalUserResponsePagedResultToJSON(value?: InternalUserResponsePagedResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageInfo': PageModelToJSON(value.pageInfo),
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(InternalUserResponseToJSON)),
    };
}


