/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionResponse
 */
export interface SectionResponse {
    /**
     * 
     * @type {string}
     * @memberof SectionResponse
     */
    sectionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SectionResponse
     */
    sectionName?: string | null;
}

export function SectionResponseFromJSON(json: any): SectionResponse {
    return SectionResponseFromJSONTyped(json, false);
}

export function SectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sectionCode': !exists(json, 'sectionCode') ? undefined : json['sectionCode'],
        'sectionName': !exists(json, 'sectionName') ? undefined : json['sectionName'],
    };
}

export function SectionResponseToJSON(value?: SectionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sectionCode': value.sectionCode,
        'sectionName': value.sectionName,
    };
}


