/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessRequestResponse,
    AccessRequestResponseFromJSON,
    AccessRequestResponseToJSON,
    AccessRequestResponsePagedResult,
    AccessRequestResponsePagedResultFromJSON,
    AccessRequestResponsePagedResultToJSON,
    AccessRequestSearchRequest,
    AccessRequestSearchRequestFromJSON,
    AccessRequestSearchRequestToJSON,
    AccessRequestUpdateAccessRequest,
    AccessRequestUpdateAccessRequestFromJSON,
    AccessRequestUpdateAccessRequestToJSON,
    AccessRequestUpdateStatusNotificationPreviewResponse,
    AccessRequestUpdateStatusNotificationPreviewResponseFromJSON,
    AccessRequestUpdateStatusNotificationPreviewResponseToJSON,
    AccessRequestUpdateStatusRequest,
    AccessRequestUpdateStatusRequestFromJSON,
    AccessRequestUpdateStatusRequestToJSON,
    AccessRequestUpdateStatusResponse,
    AccessRequestUpdateStatusResponseFromJSON,
    AccessRequestUpdateStatusResponseToJSON,
    ApprovedAccessResponsePagedResult,
    ApprovedAccessResponsePagedResultFromJSON,
    ApprovedAccessResponsePagedResultToJSON,
    ApprovedAccessSearchRequest,
    ApprovedAccessSearchRequestFromJSON,
    ApprovedAccessSearchRequestToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
} from '../models';

export interface ApiAccessRequestsApprovedPostRequest {
    approvedAccessSearchRequest?: ApprovedAccessSearchRequest;
}

export interface ApiAccessRequestsIdAccessPutRequest {
    id: number;
    accessRequestUpdateAccessRequest?: AccessRequestUpdateAccessRequest;
}

export interface ApiAccessRequestsIdStatusPreviewPostRequest {
    id: number;
    accessRequestUpdateStatusRequest?: AccessRequestUpdateStatusRequest;
}

export interface ApiAccessRequestsIdStatusPutRequest {
    id: number;
    accessRequestUpdateStatusRequest?: AccessRequestUpdateStatusRequest;
}

export interface ApiAccessRequestsSearchesPostRequest {
    accessRequestSearchRequest?: AccessRequestSearchRequest;
}

export interface GetAccessRequestByIdRequest {
    id: number;
}

/**
 * 
 */
export class AccessRequestsApi extends runtime.BaseAPI {

    /**
     */
    async apiAccessRequestsApprovedPostRaw(requestParameters: ApiAccessRequestsApprovedPostRequest): Promise<runtime.ApiResponse<ApprovedAccessResponsePagedResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/access-requests/approved`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprovedAccessSearchRequestToJSON(requestParameters.approvedAccessSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprovedAccessResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsApprovedPost(requestParameters: ApiAccessRequestsApprovedPostRequest): Promise<ApprovedAccessResponsePagedResult> {
        const response = await this.apiAccessRequestsApprovedPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsIdAccessPutRaw(requestParameters: ApiAccessRequestsIdAccessPutRequest): Promise<runtime.ApiResponse<AccessRequestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAccessRequestsIdAccessPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/access-requests/{id}/access`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccessRequestUpdateAccessRequestToJSON(requestParameters.accessRequestUpdateAccessRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsIdAccessPut(requestParameters: ApiAccessRequestsIdAccessPutRequest): Promise<AccessRequestResponse> {
        const response = await this.apiAccessRequestsIdAccessPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsIdStatusPreviewPostRaw(requestParameters: ApiAccessRequestsIdStatusPreviewPostRequest): Promise<runtime.ApiResponse<AccessRequestUpdateStatusNotificationPreviewResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAccessRequestsIdStatusPreviewPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/access-requests/{id}/status/preview`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccessRequestUpdateStatusRequestToJSON(requestParameters.accessRequestUpdateStatusRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestUpdateStatusNotificationPreviewResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsIdStatusPreviewPost(requestParameters: ApiAccessRequestsIdStatusPreviewPostRequest): Promise<AccessRequestUpdateStatusNotificationPreviewResponse> {
        const response = await this.apiAccessRequestsIdStatusPreviewPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsIdStatusPutRaw(requestParameters: ApiAccessRequestsIdStatusPutRequest): Promise<runtime.ApiResponse<AccessRequestUpdateStatusResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAccessRequestsIdStatusPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/access-requests/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccessRequestUpdateStatusRequestToJSON(requestParameters.accessRequestUpdateStatusRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestUpdateStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsIdStatusPut(requestParameters: ApiAccessRequestsIdStatusPutRequest): Promise<AccessRequestUpdateStatusResponse> {
        const response = await this.apiAccessRequestsIdStatusPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsSearchesPostRaw(requestParameters: ApiAccessRequestsSearchesPostRequest): Promise<runtime.ApiResponse<AccessRequestResponsePagedResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/access-requests/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccessRequestSearchRequestToJSON(requestParameters.accessRequestSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsSearchesPost(requestParameters: ApiAccessRequestsSearchesPostRequest): Promise<AccessRequestResponsePagedResult> {
        const response = await this.apiAccessRequestsSearchesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAccessRequestByIdRaw(requestParameters: GetAccessRequestByIdRequest): Promise<runtime.ApiResponse<AccessRequestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAccessRequestById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/access-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async getAccessRequestById(requestParameters: GetAccessRequestByIdRequest): Promise<AccessRequestResponse> {
        const response = await this.getAccessRequestByIdRaw(requestParameters);
        return await response.value();
    }

}
