/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdGroupRolesUpdateRequest
 */
export interface AdGroupRolesUpdateRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AdGroupRolesUpdateRequest
     */
    roleIds?: Array<number> | null;
}

export function AdGroupRolesUpdateRequestFromJSON(json: any): AdGroupRolesUpdateRequest {
    return AdGroupRolesUpdateRequestFromJSONTyped(json, false);
}

export function AdGroupRolesUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdGroupRolesUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleIds': !exists(json, 'roleIds') ? undefined : json['roleIds'],
    };
}

export function AdGroupRolesUpdateRequestToJSON(value?: AdGroupRolesUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleIds': value.roleIds,
    };
}


