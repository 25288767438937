/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionPermissionsUpdateRequest
 */
export interface SectionPermissionsUpdateRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof SectionPermissionsUpdateRequest
     */
    permissionIds?: Array<number> | null;
}

export function SectionPermissionsUpdateRequestFromJSON(json: any): SectionPermissionsUpdateRequest {
    return SectionPermissionsUpdateRequestFromJSONTyped(json, false);
}

export function SectionPermissionsUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionPermissionsUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissionIds': !exists(json, 'permissionIds') ? undefined : json['permissionIds'],
    };
}

export function SectionPermissionsUpdateRequestToJSON(value?: SectionPermissionsUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissionIds': value.permissionIds,
    };
}


