/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationResponse,
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
    UserAccessContextOrigin,
    UserAccessContextOriginFromJSON,
    UserAccessContextOriginFromJSONTyped,
    UserAccessContextOriginToJSON,
    UserAccessOrigin,
    UserAccessOriginFromJSON,
    UserAccessOriginFromJSONTyped,
    UserAccessOriginToJSON,
    UserAccessType,
    UserAccessTypeFromJSON,
    UserAccessTypeFromJSONTyped,
    UserAccessTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserAccessResponse
 */
export interface UserAccessResponse {
    /**
     * 
     * @type {number}
     * @memberof UserAccessResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    applicationName?: string | null;
    /**
     * 
     * @type {Array<OrganizationResponse>}
     * @memberof UserAccessResponse
     */
    organizations?: Array<OrganizationResponse> | null;
    /**
     * 
     * @type {UserAccessType}
     * @memberof UserAccessResponse
     */
    type?: UserAccessType;
    /**
     * 
     * @type {UserAccessOrigin}
     * @memberof UserAccessResponse
     */
    origin?: UserAccessOrigin;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    originDetails?: string | null;
    /**
     * 
     * @type {UserAccessOrigin}
     * @memberof UserAccessResponse
     */
    parentOrigin?: UserAccessOrigin;
    /**
     * 
     * @type {UserAccessContextOrigin}
     * @memberof UserAccessResponse
     */
    contextOrigin?: UserAccessContextOrigin;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    parentOriginDetails?: string | null;
}

export function UserAccessResponseFromJSON(json: any): UserAccessResponse {
    return UserAccessResponseFromJSONTyped(json, false);
}

export function UserAccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'organizations': !exists(json, 'organizations') ? undefined : (json['organizations'] === null ? null : (json['organizations'] as Array<any>).map(OrganizationResponseFromJSON)),
        'type': !exists(json, 'type') ? undefined : UserAccessTypeFromJSON(json['type']),
        'origin': !exists(json, 'origin') ? undefined : UserAccessOriginFromJSON(json['origin']),
        'originDetails': !exists(json, 'originDetails') ? undefined : json['originDetails'],
        'parentOrigin': !exists(json, 'parentOrigin') ? undefined : UserAccessOriginFromJSON(json['parentOrigin']),
        'contextOrigin': !exists(json, 'contextOrigin') ? undefined : UserAccessContextOriginFromJSON(json['contextOrigin']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'parentOriginDetails': !exists(json, 'parentOriginDetails') ? undefined : json['parentOriginDetails'],
    };
}

export function UserAccessResponseToJSON(value?: UserAccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'shortName': value.shortName,
        'applicationName': value.applicationName,
        'organizations': value.organizations === undefined ? undefined : (value.organizations === null ? null : (value.organizations as Array<any>).map(OrganizationResponseToJSON)),
        'type': UserAccessTypeToJSON(value.type),
        'origin': UserAccessOriginToJSON(value.origin),
        'originDetails': value.originDetails,
        'parentOrigin': UserAccessOriginToJSON(value.parentOrigin),
        'contextOrigin': UserAccessContextOriginToJSON(value.contextOrigin),
        'userId': value.userId,
        'parentOriginDetails': value.parentOriginDetails,
    };
}


