/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SapUserDataResponse,
    SapUserDataResponseFromJSON,
    SapUserDataResponseFromJSONTyped,
    SapUserDataResponseToJSON,
    SectionHistoryResponse,
    SectionHistoryResponseFromJSON,
    SectionHistoryResponseFromJSONTyped,
    SectionHistoryResponseToJSON,
    UserAliasResponse,
    UserAliasResponseFromJSON,
    UserAliasResponseFromJSONTyped,
    UserAliasResponseToJSON,
    UserKrrInfoResponse,
    UserKrrInfoResponseFromJSON,
    UserKrrInfoResponseFromJSONTyped,
    UserKrrInfoResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InternalUserDetailResponse
 */
export interface InternalUserDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InternalUserDetailResponse
     */
    isIdPortenUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InternalUserDetailResponse
     */
    isIdPortenValidated?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InternalUserDetailResponse
     */
    lastLoggedInOn?: Date | null;
    /**
     * 
     * @type {Array<UserAliasResponse>}
     * @memberof InternalUserDetailResponse
     */
    userAliases?: Array<UserAliasResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserDetailResponse
     */
    employeeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserDetailResponse
     */
    givenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserDetailResponse
     */
    internalUserId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InternalUserDetailResponse
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InternalUserDetailResponse
     */
    mail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserDetailResponse
     */
    mailNickname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserDetailResponse
     */
    onPremisesUserPrincipalName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserDetailResponse
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserDetailResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserDetailResponse
     */
    userPrincipalName?: string | null;
    /**
     * 
     * @type {SapUserDataResponse}
     * @memberof InternalUserDetailResponse
     */
    sapUserData?: SapUserDataResponse;
    /**
     * 
     * @type {UserKrrInfoResponse}
     * @memberof InternalUserDetailResponse
     */
    userKrrInfo?: UserKrrInfoResponse;
    /**
     * 
     * @type {Array<SectionHistoryResponse>}
     * @memberof InternalUserDetailResponse
     */
    sectionHistory?: Array<SectionHistoryResponse> | null;
}

export function InternalUserDetailResponseFromJSON(json: any): InternalUserDetailResponse {
    return InternalUserDetailResponseFromJSONTyped(json, false);
}

export function InternalUserDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalUserDetailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isIdPortenUser': !exists(json, 'isIdPortenUser') ? undefined : json['isIdPortenUser'],
        'isIdPortenValidated': !exists(json, 'isIdPortenValidated') ? undefined : json['isIdPortenValidated'],
        'lastLoggedInOn': !exists(json, 'lastLoggedInOn') ? undefined : (json['lastLoggedInOn'] === null ? null : new Date(json['lastLoggedInOn'])),
        'userAliases': !exists(json, 'userAliases') ? undefined : (json['userAliases'] === null ? null : (json['userAliases'] as Array<any>).map(UserAliasResponseFromJSON)),
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'internalUserId': !exists(json, 'internalUserId') ? undefined : json['internalUserId'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'mail': !exists(json, 'mail') ? undefined : json['mail'],
        'mailNickname': !exists(json, 'mailNickname') ? undefined : json['mailNickname'],
        'onPremisesUserPrincipalName': !exists(json, 'onPremisesUserPrincipalName') ? undefined : json['onPremisesUserPrincipalName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userPrincipalName': !exists(json, 'userPrincipalName') ? undefined : json['userPrincipalName'],
        'sapUserData': !exists(json, 'sapUserData') ? undefined : SapUserDataResponseFromJSON(json['sapUserData']),
        'userKrrInfo': !exists(json, 'userKrrInfo') ? undefined : UserKrrInfoResponseFromJSON(json['userKrrInfo']),
        'sectionHistory': !exists(json, 'sectionHistory') ? undefined : (json['sectionHistory'] === null ? null : (json['sectionHistory'] as Array<any>).map(SectionHistoryResponseFromJSON)),
    };
}

export function InternalUserDetailResponseToJSON(value?: InternalUserDetailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isIdPortenUser': value.isIdPortenUser,
        'isIdPortenValidated': value.isIdPortenValidated,
        'lastLoggedInOn': value.lastLoggedInOn === undefined ? undefined : (value.lastLoggedInOn === null ? null : value.lastLoggedInOn.toISOString()),
        'userAliases': value.userAliases === undefined ? undefined : (value.userAliases === null ? null : (value.userAliases as Array<any>).map(UserAliasResponseToJSON)),
        'employeeId': value.employeeId,
        'givenName': value.givenName,
        'internalUserId': value.internalUserId,
        'isActive': value.isActive,
        'mail': value.mail,
        'mailNickname': value.mailNickname,
        'onPremisesUserPrincipalName': value.onPremisesUserPrincipalName,
        'surname': value.surname,
        'userId': value.userId,
        'userPrincipalName': value.userPrincipalName,
        'sapUserData': SapUserDataResponseToJSON(value.sapUserData),
        'userKrrInfo': UserKrrInfoResponseToJSON(value.userKrrInfo),
        'sectionHistory': value.sectionHistory === undefined ? undefined : (value.sectionHistory === null ? null : (value.sectionHistory as Array<any>).map(SectionHistoryResponseToJSON)),
    };
}


