/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TranslationSearchOrderByPaginationQuery,
    TranslationSearchOrderByPaginationQueryFromJSON,
    TranslationSearchOrderByPaginationQueryFromJSONTyped,
    TranslationSearchOrderByPaginationQueryToJSON,
} from './';

/**
 * 
 * @export
 * @interface TranslationsSearchRequest
 */
export interface TranslationsSearchRequest {
    /**
     * 
     * @type {TranslationSearchOrderByPaginationQuery}
     * @memberof TranslationsSearchRequest
     */
    paginationQuery?: TranslationSearchOrderByPaginationQuery;
}

export function TranslationsSearchRequestFromJSON(json: any): TranslationsSearchRequest {
    return TranslationsSearchRequestFromJSONTyped(json, false);
}

export function TranslationsSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranslationsSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationQuery': !exists(json, 'paginationQuery') ? undefined : TranslationSearchOrderByPaginationQueryFromJSON(json['paginationQuery']),
    };
}

export function TranslationsSearchRequestToJSON(value?: TranslationsSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paginationQuery': TranslationSearchOrderByPaginationQueryToJSON(value.paginationQuery),
    };
}


