/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationMigrationStatus {
    Available = 'Available',
    NotAvailable = 'NotAvailable'
}

export function ApplicationMigrationStatusFromJSON(json: any): ApplicationMigrationStatus {
    return ApplicationMigrationStatusFromJSONTyped(json, false);
}

export function ApplicationMigrationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationMigrationStatus {
    return json as ApplicationMigrationStatus;
}

export function ApplicationMigrationStatusToJSON(value?: ApplicationMigrationStatus | null): any {
    return value as any;
}

