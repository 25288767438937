import { RegisterError } from 'helpers/errorHandling/ErrorHandling';
import { Middleware, ResponseContext } from './adgang/runtime';

class ApiExceptionMiddleware implements Middleware {
  // eslint-disable-next-line class-methods-use-this
  async post?(context: ResponseContext): Promise<Response | void> {
    if (!context.response.ok) {
      if (context.response.status === 503) {
        RegisterError(`Systemet er i vedlikeholdsmodus. Vennligst prøv på nytt senere.`);
        return;
      }
      if (context.response.status !== 400)
        // Bad request is handled in the UI
        RegisterError(`Feil (${context.response.status}) i API-kall: ${context.url}`);
    }
  }
}

export default ApiExceptionMiddleware;
