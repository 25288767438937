import React from 'react';
import { Row, Col } from 'react-bootstrap';

export interface IUserProperty {
  label: string;
  value?: string | JSX.Element | null;
}

export function UserProperty({ label, value }: IUserProperty) {
  return (
    <Row>
      <Col lg='3'>{label}</Col>
      <Col lg='9'>
        <b>{value}</b>
      </Col>
    </Row>
  );
}
