/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationGroupType,
    OrganizationGroupTypeFromJSON,
    OrganizationGroupTypeFromJSONTyped,
    OrganizationGroupTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationGroupResponse
 */
export interface OrganizationGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationGroupResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationGroupResponse
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationGroupResponse
     */
    organizationCount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationGroupResponse
     */
    organizationGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationGroupResponse
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationGroupResponse
     */
    applicationId?: string | null;
    /**
     * 
     * @type {OrganizationGroupType}
     * @memberof OrganizationGroupResponse
     */
    organizationGroupType?: OrganizationGroupType;
}

export function OrganizationGroupResponseFromJSON(json: any): OrganizationGroupResponse {
    return OrganizationGroupResponseFromJSONTyped(json, false);
}

export function OrganizationGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationGroupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'organizationCount': !exists(json, 'organizationCount') ? undefined : json['organizationCount'],
        'organizationGroupId': !exists(json, 'organizationGroupId') ? undefined : json['organizationGroupId'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'organizationGroupType': !exists(json, 'organizationGroupType') ? undefined : OrganizationGroupTypeFromJSON(json['organizationGroupType']),
    };
}

export function OrganizationGroupResponseToJSON(value?: OrganizationGroupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'organizationCount': value.organizationCount,
        'organizationGroupId': value.organizationGroupId,
        'shortName': value.shortName,
        'applicationId': value.applicationId,
        'organizationGroupType': OrganizationGroupTypeToJSON(value.organizationGroupType),
    };
}


