/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalUserStatus,
    ExternalUserStatusFromJSON,
    ExternalUserStatusFromJSONTyped,
    ExternalUserStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalUserStatusUpdateResponse
 */
export interface ExternalUserStatusUpdateResponse {
    /**
     * 
     * @type {ExternalUserStatus}
     * @memberof ExternalUserStatusUpdateResponse
     */
    status?: ExternalUserStatus;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserStatusUpdateResponse
     */
    userId?: string | null;
}

export function ExternalUserStatusUpdateResponseFromJSON(json: any): ExternalUserStatusUpdateResponse {
    return ExternalUserStatusUpdateResponseFromJSONTyped(json, false);
}

export function ExternalUserStatusUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalUserStatusUpdateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : ExternalUserStatusFromJSON(json['status']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function ExternalUserStatusUpdateResponseToJSON(value?: ExternalUserStatusUpdateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ExternalUserStatusToJSON(value.status),
        'userId': value.userId,
    };
}


