/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    PermissionResponse,
    PermissionResponseFromJSON,
    PermissionResponseToJSON,
    ResourceFilter,
    ResourceFilterFromJSON,
    ResourceFilterToJSON,
    RoleConsumptionResponse,
    RoleConsumptionResponseFromJSON,
    RoleConsumptionResponseToJSON,
    RoleCreateRequest,
    RoleCreateRequestFromJSON,
    RoleCreateRequestToJSON,
    RoleResponse,
    RoleResponseFromJSON,
    RoleResponseToJSON,
    RoleResponsePagedResult,
    RoleResponsePagedResultFromJSON,
    RoleResponsePagedResultToJSON,
    RoleSearchOrderBy,
    RoleSearchOrderByFromJSON,
    RoleSearchOrderByToJSON,
    RoleSearchRequest,
    RoleSearchRequestFromJSON,
    RoleSearchRequestToJSON,
    RoleUpdateRequest,
    RoleUpdateRequestFromJSON,
    RoleUpdateRequestToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeToJSON,
} from '../models';

export interface ApiRolesCheckusageGetRequest {
    id?: number;
}

export interface ApiRolesGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: RoleSearchOrderBy;
    applicationId?: string;
    applicationIds?: Array<string>;
    isGlobal?: boolean;
    textFilter?: string;
    roleId?: number;
    permissionId?: number;
    userType?: UserType;
    canBeRequestedByUser?: boolean;
    userId?: string;
    resourceFilter?: ResourceFilter;
    requireUserOrganizationInMinSide?: boolean;
}

export interface ApiRolesIdDeleteRequest {
    id: number;
}

export interface ApiRolesIdPermissionsGetRequest {
    id: number;
}

export interface ApiRolesIdPutRequest {
    id: number;
    roleUpdateRequest?: RoleUpdateRequest;
}

export interface ApiRolesPostRequest {
    roleCreateRequest?: RoleCreateRequest;
}

export interface ApiRolesSearchesPostRequest {
    roleSearchRequest?: RoleSearchRequest;
}

export interface GetRolebyIdRequest {
    id: number;
}

/**
 * 
 */
export class RolesApi extends runtime.BaseAPI {

    /**
     */
    async apiRolesCheckusageGetRaw(requestParameters: ApiRolesCheckusageGetRequest): Promise<runtime.ApiResponse<RoleConsumptionResponse>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/roles/checkusage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleConsumptionResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiRolesCheckusageGet(requestParameters: ApiRolesCheckusageGetRequest): Promise<RoleConsumptionResponse> {
        const response = await this.apiRolesCheckusageGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiRolesGetRaw(requestParameters: ApiRolesGetRequest): Promise<runtime.ApiResponse<RoleResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        if (requestParameters.applicationId !== undefined) {
            queryParameters['applicationId'] = requestParameters.applicationId;
        }

        if (requestParameters.applicationIds) {
            queryParameters['applicationIds'] = requestParameters.applicationIds;
        }

        if (requestParameters.isGlobal !== undefined) {
            queryParameters['isGlobal'] = requestParameters.isGlobal;
        }

        if (requestParameters.textFilter !== undefined) {
            queryParameters['textFilter'] = requestParameters.textFilter;
        }

        if (requestParameters.roleId !== undefined) {
            queryParameters['roleId'] = requestParameters.roleId;
        }

        if (requestParameters.permissionId !== undefined) {
            queryParameters['permissionId'] = requestParameters.permissionId;
        }

        if (requestParameters.userType !== undefined) {
            queryParameters['userType'] = requestParameters.userType;
        }

        if (requestParameters.canBeRequestedByUser !== undefined) {
            queryParameters['canBeRequestedByUser'] = requestParameters.canBeRequestedByUser;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.resourceFilter !== undefined) {
            queryParameters['resourceFilter'] = requestParameters.resourceFilter;
        }

        if (requestParameters.requireUserOrganizationInMinSide !== undefined) {
            queryParameters['requireUserOrganizationInMinSide'] = requestParameters.requireUserOrganizationInMinSide;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiRolesGet(requestParameters: ApiRolesGetRequest): Promise<RoleResponsePagedResult> {
        const response = await this.apiRolesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiRolesIdDeleteRaw(requestParameters: ApiRolesIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRolesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRolesIdDelete(requestParameters: ApiRolesIdDeleteRequest): Promise<void> {
        await this.apiRolesIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiRolesIdPermissionsGetRaw(requestParameters: ApiRolesIdPermissionsGetRequest): Promise<runtime.ApiResponse<PermissionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRolesIdPermissionsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/roles/{id}/permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiRolesIdPermissionsGet(requestParameters: ApiRolesIdPermissionsGetRequest): Promise<PermissionResponse> {
        const response = await this.apiRolesIdPermissionsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiRolesIdPutRaw(requestParameters: ApiRolesIdPutRequest): Promise<runtime.ApiResponse<RoleResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRolesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleUpdateRequestToJSON(requestParameters.roleUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiRolesIdPut(requestParameters: ApiRolesIdPutRequest): Promise<RoleResponse> {
        const response = await this.apiRolesIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiRolesPostRaw(requestParameters: ApiRolesPostRequest): Promise<runtime.ApiResponse<RoleResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleCreateRequestToJSON(requestParameters.roleCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiRolesPost(requestParameters: ApiRolesPostRequest): Promise<RoleResponse> {
        const response = await this.apiRolesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiRolesSearchesPostRaw(requestParameters: ApiRolesSearchesPostRequest): Promise<runtime.ApiResponse<RoleResponsePagedResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/roles/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleSearchRequestToJSON(requestParameters.roleSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiRolesSearchesPost(requestParameters: ApiRolesSearchesPostRequest): Promise<RoleResponsePagedResult> {
        const response = await this.apiRolesSearchesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getRolebyIdRaw(requestParameters: GetRolebyIdRequest): Promise<runtime.ApiResponse<RoleResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRolebyId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async getRolebyId(requestParameters: GetRolebyIdRequest): Promise<RoleResponse> {
        const response = await this.getRolebyIdRaw(requestParameters);
        return await response.value();
    }

}
