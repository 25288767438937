// Replace with Lodash if more helpers are needed
// Ref: https://stackoverflow.com/questions/42136098/array-groupby-in-typescript

export const groupBy = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
) =>
  list.reduce((acc, currentItem) => {
    const group = getKey(currentItem);
    const previous = acc;
    if (!previous[group]) {
      previous[group] = [];
    }
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);
