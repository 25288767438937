/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PermissionSearchOrderBy,
    PermissionSearchOrderByFromJSON,
    PermissionSearchOrderByFromJSONTyped,
    PermissionSearchOrderByToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderFromJSONTyped,
    SortOrderToJSON,
} from './';

/**
 * 
 * @export
 * @interface PermissionSearchOrderByPaginationQuery
 */
export interface PermissionSearchOrderByPaginationQuery {
    /**
     * 
     * @type {number}
     * @memberof PermissionSearchOrderByPaginationQuery
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PermissionSearchOrderByPaginationQuery
     */
    pageSize?: number;
    /**
     * 
     * @type {SortOrder}
     * @memberof PermissionSearchOrderByPaginationQuery
     */
    sortOrder?: SortOrder;
    /**
     * 
     * @type {PermissionSearchOrderBy}
     * @memberof PermissionSearchOrderByPaginationQuery
     */
    orderField?: PermissionSearchOrderBy;
}

export function PermissionSearchOrderByPaginationQueryFromJSON(json: any): PermissionSearchOrderByPaginationQuery {
    return PermissionSearchOrderByPaginationQueryFromJSONTyped(json, false);
}

export function PermissionSearchOrderByPaginationQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionSearchOrderByPaginationQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : SortOrderFromJSON(json['sortOrder']),
        'orderField': !exists(json, 'orderField') ? undefined : PermissionSearchOrderByFromJSON(json['orderField']),
    };
}

export function PermissionSearchOrderByPaginationQueryToJSON(value?: PermissionSearchOrderByPaginationQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'sortOrder': SortOrderToJSON(value.sortOrder),
        'orderField': PermissionSearchOrderByToJSON(value.orderField),
    };
}


