import React, { useState } from 'react';
import { LoadingSpinner, Button, useBoundForm, SimpleModal, SelectOption } from 'common.ui';
import { useApplications, usePermissions, usePermission } from 'api/hooks';
import { ResourceFilter, UserType } from 'api/adgang';

type IProps = {
  header: string;
  show: boolean;
  userId?: string;
  userType: UserType;
  initialApplicationId?: string | null;
  initialPermissionId?: number;
  additionalJsx?: JSX.Element;
  okText?: string;
  resourceFilter?: ResourceFilter;
  onCancel: () => void;
  onPermissionSelected: (permission: SelectOption) => Promise<void>;
};

interface IPropsImpl {
  applications: SelectOption[];
}

type PermissionsForm = {
  applicationId: string;
  permissionId: string;
};
function PermissionsSelectorModalImpl({
  userId,
  userType,
  applications,
  initialApplicationId,
  initialPermissionId,
  onCancel,
  onPermissionSelected,
  okText,
  additionalJsx,
  resourceFilter
}: IProps & IPropsImpl) {
  const [values, setValues] = useState<PermissionsForm>({
    applicationId: initialApplicationId ?? 'global',
    permissionId: initialPermissionId ? initialPermissionId.toString() : ''
  });

  const [permissions] = usePermissions(userType, values.applicationId, userId, undefined, resourceFilter);

  const { form, FormContainer, Dropdown } = useBoundForm<PermissionsForm>({
    onSubmit: async (e) => {
      if (e.permissionId)
        onPermissionSelected({
          id: e.permissionId,
          text: permissions?.filter((r) => r.id === e.permissionId)[0].text ?? ''
        });
    },
    model: values
  });

  return (
    <FormContainer form={form}>
      <Dropdown
        form={form}
        onChange={(e) => setValues({ ...values, applicationId: e.target.value })}
        options={applications}
        label='Applikasjon'
        name='applicationId'
      />
      <Dropdown
        key={values.applicationId}
        form={form}
        onChange={(e) => setValues({ ...values, permissionId: e.target.value })}
        options={permissions || []}
        label='Tilgang'
        name='permissionId'
      />
      {additionalJsx}
      <Button type='submit' text={okText ?? 'Endre'} />
      <Button type='button' text='Avbryt' styleType='light' onClick={onCancel} />
    </FormContainer>
  );
}

function PermissionSelectorModal(props: IProps) {
  const { header, show, onCancel, initialPermissionId, initialApplicationId, userId, resourceFilter } = props;
  const [applications] = useApplications(userId, resourceFilter);
  const [, permission] = usePermission(initialPermissionId);

  return (
    <SimpleModal header={header} show={show} onCancel={onCancel}>
      {applications && (!initialPermissionId || (initialPermissionId && permission)) ? (
        <PermissionsSelectorModalImpl
          {...props}
          initialApplicationId={permission ? permission.applicationId : initialApplicationId}
          applications={applications}
        />
      ) : (
        <LoadingSpinner />
      )}
    </SimpleModal>
  );
}
export default PermissionSelectorModal;
