import { useState, useEffect } from 'react';
import { internalUsersClient } from 'api/AdgangClients';
import { SelectOption } from 'common.ui';

export function useEmploymentCategories(): [SelectOption[] | undefined] {
  const [employmentCategories, setEmploymentCategories] = useState<SelectOption[]>();

  useEffect(() => {
    const loadEmploymentCategories = async () => {
      const c = await internalUsersClient.apiInternalUsersEmploymentCategoriesGet();

      setEmploymentCategories(
        c
          .map((d) => {
            return {
              id: d,
              text: d
            } as SelectOption;
          })
          .sort((x, y) => x.id.localeCompare(y.id))
      );
    };

    loadEmploymentCategories();
  }, []);

  return [employmentCategories];
}
