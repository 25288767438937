import { SidebarItem } from 'common.ui';
import { RouteComponentProps } from 'react-router-dom';
import { AdgangAccessControl } from '../api/adgang/models';

export interface RouteItem {
  link: string;
  label?: string;
  hidden?: boolean;
  icon?: React.ReactNode;
  requiredPermission?: AdgangAccessControl;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  children?: RouteItem[];
}

export const toSidebarItem = (item: RouteItem): SidebarItem => {
  return {
    children: item.children?.map((c) => toSidebarItem(c)),
    component: item.component,
    link: item.link,
    icon: item.icon,
    label: item.label
  } as SidebarItem;
};

export function flattenRoutes(items: RouteItem[]): RouteItem[] {
  return items
    ? items.reduce(
        (result, item) => [
          ...result,
          {
            link: item.link,
            label: item.label,
            component: item.component,
            hidden: item.hidden,
            requiredPermission: item.requiredPermission
          },
          ...flattenRoutes(item.children ?? [])
        ],
        [] as RouteItem[]
      )
    : ([] as RouteItem[]);
}

export function validRoutes(items: RouteItem[], hasAccess: (item: RouteItem) => boolean): RouteItem[] {
  return items
    .filter((item) => !item.hidden && hasAccess(item))
    .map((item) => ({
      ...item,
      children: item.children && validRoutes(item.children, hasAccess)
    }));
}
