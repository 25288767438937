import React from 'react';
import { Link } from 'react-router-dom';
import {
  UserAccessOrigin,
  UserAccessType,
  UserAccessResponse,
  OrganizationResponse,
  AdgangAccessControl
} from 'api/adgang/models';
import { Button, UnpagedTable } from 'common.ui';
import { asAccessOriginText } from 'helpers/access/FormatOrigin';
import { Row, Col } from 'react-bootstrap';

import './UserAccessTable.scss';
import { groupBy } from 'helpers/arrayHelpers';
import { concat } from 'helpers/concat';
import RoutePaths from 'RoutePaths';
import { ReactComponent as IconDelete } from 'assets/icons/delete-icon.svg';
import { useAccessControlList } from 'hooks/access/useAccessControlList';
import { ReactComponent as CheckmarkGreen } from '../../assets/icons/checkmark-green.svg';

export interface IProps {
  access: UserAccessResponse[];
  showInheritedAccess: boolean;
  toggleShowInheritedAccess: VoidFunction;
  addPermission: VoidFunction;
  addRole: VoidFunction;
  idPortenUserId?: string;
  onDeleteAccess: (access: UserAccessResponseOrganization) => void;
}

export type UserAccessResponseOrganization = {
  Organization?: OrganizationResponse;
  UserAccess: UserAccessResponse;
};

export function UserAccessTable({
  access,
  showInheritedAccess,
  toggleShowInheritedAccess,
  addPermission,
  addRole,
  idPortenUserId,
  onDeleteAccess
}: IProps) {
  const activeAccess = access
    .filter((a) => showInheritedAccess || (!showInheritedAccess && !a.parentOrigin))
    .sort(
      (a1, a2) =>
        (a1.originDetails || '').localeCompare(a2.originDetails || '') ||
        (a1.name || '').localeCompare(a2.name || '')
    );

  const rows = groupBy(activeAccess, (a) =>
    concat('', a.origin, a.originDetails, a.type, (a.id || '').toString())
  );

  const numOrgs = Object.keys(rows)
    .map((key) => rows[key][0].organizations?.length ?? 0)
    .reduce((sum, current) => sum + current, 0);

  const [, hasAccess] = useAccessControlList();

  return (
    <>
      <Row>
        <Col sm='4'>
          <h2>Roller og tilganger</h2>
        </Col>
        <Col sm='8'>
          <div className='float-right'>
            {access.filter((a) => a.parentOrigin).length > 0 && (
              <Button
                text={`${showInheritedAccess ? 'Skjul' : 'Vis'}  arvede tilganger`}
                styleType='light'
                onClick={toggleShowInheritedAccess}
              />
            )}
            {hasAccess(AdgangAccessControl.AssignAccessRight) && (
              <Button text='Legg til tilgang' onClick={addPermission} />
            )}
            {hasAccess(AdgangAccessControl.AssignAccessRight) && (
              <Button text='Legg til rolle' onClick={addRole} />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <UnpagedTable totalHits={Object.keys(rows).length + numOrgs}>
            <thead>
              <tr>
                <th>Navn</th>
                <th>Type</th>
                <th>Beskrivelse</th>
                <th>Applikasjon</th>
                <th>Arvet fra</th>
                <th>Azure</th>
                <th>ID-porten</th>
                {hasAccess(AdgangAccessControl.RemoveAccessRight) && <th>Fjern</th>}
              </tr>
            </thead>
            <tbody>
              {Object.keys(rows).map((rowId) => {
                const orgs =
                  rows[rowId][0].organizations?.map(
                    (o) =>
                      ({
                        Organization: o,
                        UserAccess: rows[rowId][0]
                      } as UserAccessResponseOrganization)
                  ) ?? [];
                if (orgs.length === 0) {
                  orgs.push({
                    Organization: undefined,
                    UserAccess: rows[rowId][0]
                  } as UserAccessResponseOrganization);
                }

                return orgs.map((org) => {
                  const row = rows[rowId][0];
                  const details = rows[rowId];

                  return (
                    <tr
                      key={`${row.id}${row.type}${row.origin}${row.parentOrigin}${row.originDetails}${org.Organization?.organizationId}`}
                    >
                      <td>
                        <Link
                          to={
                            row.type === UserAccessType.Role
                              ? `${RoutePaths.roles}/${row.id}`
                              : `${RoutePaths.permissions}/${row.id}`
                          }
                        >
                          {row.name}{' '}
                          {typeof org.Organization !== 'undefined' ? `(${org?.Organization?.name})` : ''}
                        </Link>
                      </td>
                      <td>{row.type === UserAccessType.Role ? 'Rolle' : 'Tilgang'}</td>
                      <td>{row.description}</td>
                      <td>{row.applicationName}</td>
                      <td>
                        {asAccessOriginText(
                          row.origin,
                          row.originDetails,
                          row.parentOrigin,
                          row.parentOriginDetails
                        )}
                      </td>
                      <td>
                        {details.some((x) => x.userId !== idPortenUserId) && (
                          <CheckmarkGreen className='checkmark' />
                        )}
                      </td>
                      <td>
                        {details.some((x) => x.userId === idPortenUserId) && (
                          <CheckmarkGreen className='checkmark' />
                        )}
                      </td>
                      {hasAccess(AdgangAccessControl.RemoveAccessRight) && (
                        <td>
                          {!row.parentOrigin && row.origin === UserAccessOrigin.User && (
                            <IconDelete onClick={() => onDeleteAccess(org)} />
                          )}
                        </td>
                      )}
                    </tr>
                  );
                });
              })}
            </tbody>
          </UnpagedTable>
        </Col>
      </Row>
    </>
  );
}
