import React from 'react';
import IconCross from '../../../assets/icons/cross-icon.svg';

import styles from './FilterReset.scss';

interface IFilterResetProps {
  label: string;
  onRemove: () => void;
}
const FilterReset = ({ label, onRemove }: IFilterResetProps) => (
  <span className={styles.filterContainer}>
    <button type='button' className={styles.removeFilter} onClick={onRemove}>
      <IconCross />
      {label}
    </button>
  </span>
);

export { FilterReset };
