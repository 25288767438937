import Environment from '../Environment';

const B2cPolicies = {
  names: {
    externalUser: 'B2C_1A_signup_signin_external_user',
    internalUser: 'B2C_1A_signup_signin_internal_user'
  },
  authorities: {
    externalUser: {
      authority: Environment.MsalExternalUserAuthority
    },
    internalUser: {
      authority: Environment.MsalInternalUserAuthority
    }
  }
};

const B2cScopes = [Environment.MsalScope];

export { B2cPolicies, B2cScopes };
