/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum UserEventRequestSearchOrderBy {
  Who = 'Who',
  When = 'When'
}

export function UserEventRequestSearchOrderByFromJSON(json: any): UserEventRequestSearchOrderBy {
  return UserEventRequestSearchOrderByFromJSONTyped(json, false);
}

export function UserEventRequestSearchOrderByFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserEventRequestSearchOrderBy {
  return json as UserEventRequestSearchOrderBy;
}

export function UserEventRequestSearchOrderByToJSON(value?: UserEventRequestSearchOrderBy | null): any {
  return value as any;
}
