import React from 'react';
import { Button, PagedTable, LoadingSpinner, useFetch, ThSortable, useSortedPaging } from 'common.ui';
import { applicationsClient } from 'api/AdgangClients';
import {
  AdgangAccessControl,
  ApiApplicationsGetRequest,
  ApplicationResponsePagedResult,
  ApplicationResponsiblePersonResponse,
  ApplicationSearchOrderBy,
  ResourceFilter,
  SortOrder
} from 'api/adgang';
import Page from 'components/page/Page';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import RoutePaths from 'RoutePaths';
import { useAccessControlList } from 'hooks/access/useAccessControlList';

function Applications() {
  const history = useHistory();
  const [, hasAccess] = useAccessControlList();
  const [applicationsPaged, isLoading, , fetchApplications] = useFetch<ApplicationResponsePagedResult>(
    () => fetchPagedApplications(),
    {} as ApplicationResponsePagedResult,
    false
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    ,
    setSorting
  ] = useSortedPaging<ApplicationSearchOrderBy>(fetchApplications, {
    field: ApplicationSearchOrderBy.Name,
    direction: 'asc'
  });

  const fetchPagedApplications = async (): Promise<ApplicationResponsePagedResult> => {
    const request = {
      pageSize,
      pageNumber: currentPage,
      sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
      orderField: sorting.field,
      resourceFilter: ResourceFilter.Application
    } as ApiApplicationsGetRequest;

    return applicationsClient.apiApplicationsGet(request);
  };

  const handleSort = (e: SortEvent<ApplicationSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  return (
    <Page header='Applikasjoner'>
      <Container fluid>
        {hasAccess(AdgangAccessControl.ApplicationCreate) && (
          <Row>
            <Col sm={12}>
              <Button text='Legg til' onClick={() => history.push(RoutePaths.applicationsAdd)} />
            </Col>
          </Row>
        )}

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <PagedTable
            startIndex={startIndex}
            totalHits={applicationsPaged.pageInfo?.totalCount ?? 0}
            pageSize={pageSize}
            onNextClick={onNextPage}
            onPreviousClick={onPreviousPage}
          >
            <thead>
              <tr>
                <ThSortable
                  title='Applikasjonsnavn'
                  field={ApplicationSearchOrderBy.Name}
                  currentSort={sorting}
                  onSort={handleSort}
                />
                <ThSortable
                  title='Beskrivelse'
                  field={ApplicationSearchOrderBy.Description}
                  currentSort={sorting}
                  onSort={handleSort}
                />
                <th>Applikasjonsansvarlige</th>
              </tr>
            </thead>

            <tbody>
              {applicationsPaged.data?.map((u) => (
                <tr key={u.id ?? ''}>
                  <td>
                    <Link to={`${RoutePaths.applications}/${u.id}`}>{u.name}</Link>
                  </td>
                  <td>{u.description}</td>
                  <td>
                    {u.responsiblePersons &&
                      u.responsiblePersons
                        .map((p: ApplicationResponsiblePersonResponse) => `${p.firstName} ${p.surname}`)
                        .join(', ')}
                  </td>
                </tr>
              ))}
            </tbody>
          </PagedTable>
        )}
      </Container>
    </Page>
  );
}

export default Applications;
