const RoutePaths = {
  home: '/',

  internalusers: '/internalusers',
  internalusersDetail: '/internalusers/:id',
  internalusersDetailClaims: '/internalusers/:id/claims',

  externalusers: '/externalusers',
  externalusersDetail: '/externalusers/:id',
  externalusersDetailClaims: '/externalusers/:id/claims',

  applications: '/applications',
  applicationsAdd: '/applications/add',
  applicationsDetail: '/applications/:id',

  clearCookies: '/clear-cookies',

  roles: '/roles',
  rolesAdd: '/roles/add',
  rolesDetail: '/roles/:id',

  accessrequests: '/accessrequests',
  accessrequestsDetail: '/accessrequests/:id',

  approvedAccesses: '/user-access/approved',

  permissions: '/permissions',
  permissionsAdd: '/permissions/add',
  permissionsDetail: '/permissions/:id',

  organizationgroups: '/organizationgroups',
  organizationgroupsAdd: '/organizationgroups/add',
  organizationgroupsDetail: '/organizationgroups/:id',

  sectionsadgroups: '/sectionsadgroups',
  sections: '/sectionsadgroups/sections',
  sectionsDetail: '/sectionsadgroups/sections/:id',
  adgroups: '/sectionsadgroups/adgroups',
  adgroupsDetail: '/sectionsadgroups/adgroups/:id',

  uiTexts: '/uitexts',

  devTools: '/devtools'
};

export default RoutePaths;
