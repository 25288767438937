/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationFilterRequest,
    ApplicationFilterRequestFromJSON,
    ApplicationFilterRequestFromJSONTyped,
    ApplicationFilterRequestToJSON,
    InternalUserSearchOrderByPaginationQuery,
    InternalUserSearchOrderByPaginationQueryFromJSON,
    InternalUserSearchOrderByPaginationQueryFromJSONTyped,
    InternalUserSearchOrderByPaginationQueryToJSON,
    RoleFilterRequest,
    RoleFilterRequestFromJSON,
    RoleFilterRequestFromJSONTyped,
    RoleFilterRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface InternalUserSearchRequest
 */
export interface InternalUserSearchRequest {
    /**
     * 
     * @type {ApplicationFilterRequest}
     * @memberof InternalUserSearchRequest
     */
    applicationFilter?: ApplicationFilterRequest;
    /**
     * 
     * @type {string}
     * @memberof InternalUserSearchRequest
     */
    employeeNr?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InternalUserSearchRequest
     */
    employmentCategories?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserSearchRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserSearchRequest
     */
    initials?: string | null;
    /**
     * 
     * @type {InternalUserSearchOrderByPaginationQuery}
     * @memberof InternalUserSearchRequest
     */
    paginationQuery?: InternalUserSearchOrderByPaginationQuery;
    /**
     * 
     * @type {RoleFilterRequest}
     * @memberof InternalUserSearchRequest
     */
    roleFilter?: RoleFilterRequest;
    /**
     * 
     * @type {Array<string>}
     * @memberof InternalUserSearchRequest
     */
    sections?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserSearchRequest
     */
    surname?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InternalUserSearchRequest
     */
    workLocations?: Array<string> | null;
}

export function InternalUserSearchRequestFromJSON(json: any): InternalUserSearchRequest {
    return InternalUserSearchRequestFromJSONTyped(json, false);
}

export function InternalUserSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalUserSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationFilter': !exists(json, 'applicationFilter') ? undefined : ApplicationFilterRequestFromJSON(json['applicationFilter']),
        'employeeNr': !exists(json, 'employeeNr') ? undefined : json['employeeNr'],
        'employmentCategories': !exists(json, 'employmentCategories') ? undefined : json['employmentCategories'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'initials': !exists(json, 'initials') ? undefined : json['initials'],
        'paginationQuery': !exists(json, 'paginationQuery') ? undefined : InternalUserSearchOrderByPaginationQueryFromJSON(json['paginationQuery']),
        'roleFilter': !exists(json, 'roleFilter') ? undefined : RoleFilterRequestFromJSON(json['roleFilter']),
        'sections': !exists(json, 'sections') ? undefined : json['sections'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'workLocations': !exists(json, 'workLocations') ? undefined : json['workLocations'],
    };
}

export function InternalUserSearchRequestToJSON(value?: InternalUserSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationFilter': ApplicationFilterRequestToJSON(value.applicationFilter),
        'employeeNr': value.employeeNr,
        'employmentCategories': value.employmentCategories,
        'firstName': value.firstName,
        'initials': value.initials,
        'paginationQuery': InternalUserSearchOrderByPaginationQueryToJSON(value.paginationQuery),
        'roleFilter': RoleFilterRequestToJSON(value.roleFilter),
        'sections': value.sections,
        'surname': value.surname,
        'workLocations': value.workLocations,
    };
}


