import React from 'react';
import './App.scss';
import 'common.ui/dist/index.css';
import { MsalProvider } from 'auth/MsalContext';
import ReactNotification, { store } from 'react-notifications-component';
import { RegisterErrorCallback, RegisterError } from 'helpers/errorHandling/ErrorHandling';
import Routes from './Routing';

class App extends React.Component {
  componentDidMount() {
    RegisterErrorCallback((err) => {
      store.addNotification({
        message: err,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 10000
        }
      });
    });
    window.onerror = (err: any) => {
      RegisterError(err);
    };
  }

  render() {
    return (
      <MsalProvider>
        <ReactNotification />
        <Routes />
      </MsalProvider>
    );
  }
}

export default App;
