export const getFormInputClassNames = (staticClassName: string, props: any) => {
  let cN = staticClassName;

  if (props.invalid) {
    cN = `${cN} ${staticClassName}--invalid`;
  }

  if (props.disabled) {
    cN = `${cN} ${staticClassName}--disabled`;
  }

  if (props.className && props.className.length > 0) {
    cN = `${cN} ${props.className}`;
  }

  return cN;
};

export default getFormInputClassNames;
