import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  LoadingSpinner,
  useBoundForm,
  SelectOption,
  SimpleModal,
  useFetch,
  Th,
  UnpagedTable
} from 'common.ui';
import {
  GenericValidationError,
  OrganizationGroupCreateRequest,
  OrganizationGroupResponse,
  OrganizationGroupUpdateRequest,
  OrganizationGroupType,
  BrregSearchItem
} from 'api/adgang/models';
import {
  AdgangAccessControl,
  ApiLegalEntitiesSearchGetRequest,
  ApiExternalUserEmployerSearchGetRequest
} from 'api/adgang';
import { externalUserEmployerClient, legalEntitiesClient, organizationGroupsClient } from 'api/AdgangClients';
import { Container, Row, Col } from 'react-bootstrap';
import { useApplications } from 'api/hooks';
import RoutePaths from 'RoutePaths';
import { useHistory } from 'react-router-dom';
import { permanentOrganizationGroups } from 'helpers/organizationGroup/organizationGroupHelpers';
import { useOrganizations } from 'api/hooks/useOrganizations';
import { useAccessControlList } from 'hooks/access/useAccessControlList';
import OrganizationsTable from './OrganizationsTable';

type OrganizationGroupRequestValue = OrganizationGroupCreateRequest | OrganizationGroupUpdateRequest;

type Props<T extends OrganizationGroupRequestValue> = {
  initialValue?: OrganizationGroupResponse;
  id?: number;
  onSave: (model: T) => Promise<void>;
  onSaveLabel: string;
};

type CompanySearchForm = {
  company?: string | null;
  searchType: 'employer' | 'breg';
};

function OrganizationGroupForm<T extends OrganizationGroupRequestValue>({
  initialValue,
  onSave,
  onSaveLabel,
  id
}: Props<T>): React.ReactElement<Props<T>> {
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [isPermanentOrgGroup, setIsPermanentOrgGroup] = useState<boolean>(true);
  const [isDeleting, setIdDeleting] = useState<boolean>(false);
  const [showAddCompany, setShowAddCompany] = useState<boolean>(false);
  const [showAddMiscCompany, setShowAddMiscCompany] = useState<boolean>(false);
  const [miscCompanyName, setMiscCompanyName] = useState('');
  const [miscCompanyCode, setMiscCompanyCode] = useState('');
  const [addOrgApiErrors, setAddOrgApiErrors] = useState<GenericValidationError>();

  const [applications] = useApplications();
  const [organizations] = useOrganizations([id], [id]);
  const [searchType, setSearchType] = useState<'employer' | 'breg'>('employer');
  const [, hasAccess] = useAccessControlList();

  const [seq, setSeq] = useState(1);
  const [searchValues, setSearchValues] = useState<CompanySearchForm>({
    searchType: 'employer',
    company: ''
  });

  const history = useHistory();

  const [companies, isCompaniesLoading] = useFetch<BrregSearchItem[]>(
    async () => {
      if (!showAddCompany) {
        return [];
      }
      if (searchValues.searchType === 'breg') {
        return fetchCompanies();
      }
      if (searchValues.searchType === 'employer') {
        const result = await fetchEmployers();
        return result.map((e) => e as BrregSearchItem);
      }
      return [];
    },
    [] as BrregSearchItem[],
    false,
    [searchValues, showAddCompany]
  );

  const fetchCompanies = async () => {
    const request: ApiLegalEntitiesSearchGetRequest = {
      name: searchValues.company ?? undefined
    };
    if (!searchValues.company) {
      return [] as BrregSearchItem[];
    }
    return legalEntitiesClient.apiLegalEntitiesSearchGet(request);
  };

  const fetchEmployers = async () => {
    const request: ApiExternalUserEmployerSearchGetRequest = {
      name: searchValues.company ?? undefined,
      pageNumber: 1,
      pageSize: 2000
    };
    const result = await externalUserEmployerClient.apiExternalUserEmployerSearchGet(request);
    return result.data ?? [];
  };

  // @ts-ignore
  const [values, setValues] = useState<T>({
    ...initialValue,
    applicationId: initialValue?.applicationId ?? 'global'
  } as T);

  function runSearch(c: CompanySearchForm) {
    setAddOrgApiErrors(undefined);
    setSearchValues({
      searchType,
      company: c.company
    });
  }

  async function deleteOrganization(organizationId: number) {
    if (id) {
      setIdDeleting(true);
      await organizationGroupsClient.apiOrganizationGroupsOrganizationGroupIdOrganizationOrganizationIdDelete(
        {
          organizationId,
          organizationGroupId: id
        }
      );
      setSeq(seq + 1);
      setIdDeleting(false);
    }
  }

  async function addOrganization(o: BrregSearchItem) {
    if (id) {
      try {
        await organizationGroupsClient.apiOrganizationGroupsOrganizationGroupIdOrganizationPut({
          organizationGroupId: id,
          addOrganizationToOrganizationGroupRequest: {
            code: o.organisationNumber,
            name: o.organisationName
          }
        });
        setSeq(seq + 1);
        setShowAddCompany(false);
        setShowAddMiscCompany(false);
      } catch (e) {
        if (e instanceof Response) {
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            setAddOrgApiErrors(result);
          }
        }
      }
    }
  }

  function addMsicOrganization() {
    if (miscCompanyCode && miscCompanyName) {
      const c: BrregSearchItem = {
        organisationName: miscCompanyName,
        organisationNumber: miscCompanyCode
      };

      addOrganization(c);
    }
  }

  const OnFormSubmit = useCallback(
    async (model: T) => {
      setValues(model);
      try {
        await onSave({
          ...model,
          applicationId: model.applicationId === 'global' ? undefined : model.applicationId
        });
        setApiErrors(undefined);
      } catch (e) {
        if (e instanceof Response) {
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
      }
    },
    [onSave]
  );

  const showAddCompanyForm = () => {
    setAddOrgApiErrors(undefined);

    setSearchValues({
      searchType: 'employer',
      company: undefined
    });
    setShowAddCompany(true);
  };

  const showAddMiscCompanyForm = () => {
    setAddOrgApiErrors(undefined);
    setShowAddMiscCompany(true);
  };

  const { form: companyForm, RadioGroup } = useBoundForm<CompanySearchForm>({
    onSubmit: async (e) => {
      runSearch(e);
    },
    errors: addOrgApiErrors,
    model: searchValues
  });

  const { form, FormContainer, Input, DisplayErrors, Dropdown, TextArea } = useBoundForm<T>({
    onSubmit: async (e) => {
      await OnFormSubmit(e);
    },
    errors: apiErrors,
    model: values
  });

  useEffect(() => {
    const type = initialValue?.organizationGroupType;
    const ro = (type || false) && permanentOrganizationGroups.includes(type);
    setIsPermanentOrgGroup(ro);
  }, [initialValue, organizations]);

  const organizationGroupTypeOptions: SelectOption[] = [
    {
      id: OrganizationGroupType.DomesticCompany,
      text: 'Norsk organisasjonsenhet'
    },
    {
      id: OrganizationGroupType.ForeignCompany,
      text: 'Utenlandsk organisasjonsenhet'
    },
    {
      id: OrganizationGroupType.MiscCompany,
      text: 'Diverse organisasjonsenhet'
    }
  ];

  if (!applications) return <LoadingSpinner />;
  return (
    <>
      <SimpleModal
        header='Legg til organisasjonsenhet'
        size='xl'
        show={showAddMiscCompany}
        onCancel={() => setShowAddMiscCompany(false)}
      >
        <FormContainer form={companyForm}>
          <Row style={{ display: 'flex' }}>
            <Col sm={12} lg={6}>
              <Input
                form={companyForm}
                label='Org.nr'
                name='misc_company_nr'
                onChange={(val) => setMiscCompanyCode(val)}
              />
            </Col>
            <Col sm={12} lg={6}>
              <Input
                form={companyForm}
                label='Organisasjonsnavn'
                name='misc_company_name'
                onChange={(val) => setMiscCompanyName(val)}
              />
            </Col>
          </Row>
          <Button
            type='button'
            text='Legg til organisasjon'
            disabled={!miscCompanyCode || !miscCompanyName}
            onClick={() => addMsicOrganization()}
          />
        </FormContainer>
      </SimpleModal>

      <SimpleModal
        header='Legg til organisasjonsenhet'
        size='xl'
        show={showAddCompany}
        onCancel={() => setShowAddCompany(false)}
      >
        <FormContainer form={companyForm}>
          <DisplayErrors form={companyForm} />
          <Input
            form={companyForm}
            label='Søk organisasjonsenhet'
            name='company'
            onEnterPressed={(e) => {
              runSearch({
                ...searchValues,
                company: e
              });
            }}
          />
          <RadioGroup
            form={companyForm}
            name='searchType'
            label='Søk kilde'
            options={[
              { id: 'employer', text: 'Arbeidsgivere oppgitt av eksterne brukere' },
              { id: 'breg', text: 'Enhetsregisteret (komplette)' }
            ]}
            onChange={(e) => {
              setSearchType(e as 'breg' | 'employer');
            }}
          />
          <Button type='submit' text='Søk' />
        </FormContainer>

        {isCompaniesLoading && <LoadingSpinner />}
        {!isCompaniesLoading && (
          <>
            <UnpagedTable totalHits={companies.length}>
              <thead>
                <tr>
                  <Th title='Org.nr.' />
                  <Th title='Navn' />
                  <Th title='Bedriftstype' />
                  <Th title='Besøksadresse' />
                  <Th title='Postkode' />
                  <Th title='By' />
                  <Th title='' />
                </tr>
              </thead>
              <tbody>
                {companies.map((c) => (
                  <tr key={c.organisationNumber || ''}>
                    <td>{c.organisationNumber}</td>
                    <td>{c.organisationName}</td>
                    <td>{c.organisationCompanyType}</td>
                    <td>{c.address}</td>
                    <td>{c.postalCode}</td>
                    <td>{c.postalCity}</td>
                    <td style={{ minWidth: '90px' }}>
                      {/* eslint-disable-next-line */}
                      <a href='#' onClick={() => addOrganization(c)}>
                        Legg til
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </UnpagedTable>
          </>
        )}
      </SimpleModal>
      <Container fluid>
        <FormContainer form={form}>
          <Row>
            <Col sm={12} lg={6}>
              <DisplayErrors form={form} />
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={4}>
              <Input
                form={form}
                name='shortName'
                label='Kortnavn'
                placeholder='Kortnavn'
                readonly={isPermanentOrgGroup}
              />
            </Col>
            <Col sm={6} lg={4}>
              <Input
                form={form}
                name='name'
                label='Organisasjonsgruppe navn'
                placeholder='Organisasjonsgruppe navn'
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={4}>
              <Dropdown
                form={form}
                name='applicationId'
                label='Applikasjon'
                readonly={
                  isPermanentOrgGroup || !hasAccess(AdgangAccessControl.OrganizationGroupEditApplication)
                }
                options={applications}
              />
            </Col>
          </Row>

          {!isPermanentOrgGroup && (
            <Row>
              <Col sm={12} lg={8}>
                <Dropdown
                  form={form}
                  readonly={isPermanentOrgGroup || !!id}
                  name='organizationGroupType'
                  label='Organisasjonsgruppe type'
                  options={organizationGroupTypeOptions}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={12} lg={8}>
              <TextArea
                form={form}
                name='description'
                label='Utfyllende beskrivelse'
                placeholder='Utfyllende beskrivelse'
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12} lg={8}>
              <Button type='submit' text={onSaveLabel} />
              <Button
                type='reset'
                text='Avbryt'
                styleType='light'
                onClick={() => history.push(RoutePaths.organizationgroups)}
              />
            </Col>
          </Row>
          {id &&
            (initialValue?.organizationGroupType === OrganizationGroupType.DomesticCompany ||
              initialValue?.organizationGroupType === OrganizationGroupType.MiscCompany) && (
              <Row>
                <Col sm={12} lg={8}>
                  <Button
                    type='button'
                    text='Legg til organisasjon'
                    onClick={
                      initialValue?.organizationGroupType === OrganizationGroupType.DomesticCompany
                        ? showAddCompanyForm
                        : showAddMiscCompanyForm
                    }
                  />
                </Col>
              </Row>
            )}
        </FormContainer>
        {isDeleting && <LoadingSpinner />}
        {id && !isDeleting && (
          <OrganizationsTable key={seq} onDeleteOrganization={deleteOrganization} organizationGroupId={id} />
        )}
      </Container>
    </>
  );
}

export default OrganizationGroupForm;
