/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Language,
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
    TranslationGroupType,
    TranslationGroupTypeFromJSON,
    TranslationGroupTypeFromJSONTyped,
    TranslationGroupTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TranslationResponse
 */
export interface TranslationResponse {
    /**
     * 
     * @type {TranslationGroupType}
     * @memberof TranslationResponse
     */
    groupType?: TranslationGroupType;
    /**
     * 
     * @type {Language}
     * @memberof TranslationResponse
     */
    languageId?: Language;
    /**
     * 
     * @type {number}
     * @memberof TranslationResponse
     */
    groupKey?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationResponse
     */
    groupText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationResponse
     */
    text?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TranslationResponse
     */
    placeholders?: { [key: string]: string; } | null;
}

export function TranslationResponseFromJSON(json: any): TranslationResponse {
    return TranslationResponseFromJSONTyped(json, false);
}

export function TranslationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranslationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupType': !exists(json, 'groupType') ? undefined : TranslationGroupTypeFromJSON(json['groupType']),
        'languageId': !exists(json, 'languageId') ? undefined : LanguageFromJSON(json['languageId']),
        'groupKey': !exists(json, 'groupKey') ? undefined : json['groupKey'],
        'groupText': !exists(json, 'groupText') ? undefined : json['groupText'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'placeholders': !exists(json, 'placeholders') ? undefined : json['placeholders'],
    };
}

export function TranslationResponseToJSON(value?: TranslationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupType': TranslationGroupTypeToJSON(value.groupType),
        'languageId': LanguageToJSON(value.languageId),
        'groupKey': value.groupKey,
        'groupText': value.groupText,
        'text': value.text,
        'placeholders': value.placeholders,
    };
}


