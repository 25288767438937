/* eslint-disable class-methods-use-this */
import {
  AccountInfo,
  BrowserAuthError,
  BrowserAuthErrorMessage,
  Configuration,
  EndSessionRequest,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { AuthStateStorageService } from 'common.ui';
import MsalConfig from './MsalB2cConfig';
import { B2cPolicies, B2cScopes } from './B2cPoliciesAndScopes';

class AuthService {
  private msalConfig: Configuration;

  private MsalClient: PublicClientApplication;

  private openIdScopes: string[];

  constructor() {
    this.msalConfig = MsalConfig;
    this.openIdScopes = [...B2cScopes, 'openid', 'profile'];
    this.MsalClient = new PublicClientApplication(this.msalConfig);
  }

  async signInRedirect(authority: string): Promise<void> {
    this.MsalClient.loginRedirect({
      authority,
      scopes: this.openIdScopes
    } as RedirectRequest);
  }

  async handleRedirect(): Promise<void> {
    const authResult = await this.MsalClient.handleRedirectPromise();
    if (authResult?.idToken) AuthStateStorageService.setMsalError(undefined);
  }

  async signOut(): Promise<void> {
    const account = this.MsalClient.getAllAccounts()[0];
    await this.MsalClient.logout({
      account
    } as EndSessionRequest);
  }

  async getAccessToken(): Promise<string | undefined> {
    try {
      const account = this.getUser();
      const authResponse = await this.MsalClient.acquireTokenSilent({
        authority: this.mapHomeAccountIdToAuthority(account?.homeAccountId),
        account,
        scopes: B2cScopes
      } as SilentRequest);
      // eslint-disable-next-line no-console
      console.log('Refreshed access token claims:', authResponse.idTokenClaims);
      return authResponse.accessToken;
    } catch (
      err // silent acquisition failed
    ) {
      let promptUserForLogin = false;
      if (err instanceof InteractionRequiredAuthError) {
        promptUserForLogin = true;
      } else if (err instanceof BrowserAuthError) {
        if (err.errorCode === BrowserAuthErrorMessage.silentSSOInsufficientInfoError.code) {
          // eslint-disable-next-line no-console
          console.log(`SSO failed error desc:${BrowserAuthErrorMessage.silentSSOInsufficientInfoError.desc}`);

          promptUserForLogin = true;
        }
      } else {
        this.handleError(err);
      }

      if (promptUserForLogin) {
        const authority = this.mapHomeAccountIdToAuthority(this.getUser()?.homeAccountId);
        if (authority === undefined) throw new Error('Policy not found. Error redirecting user to login.');
        const account = this.getUser();

        AuthStateStorageService.setMsalError('Access token silent acquire failed.');

        this.MsalClient.acquireTokenRedirect({
          authority,
          scopes: B2cScopes,
          account
        } as RedirectRequest);
      }
    }

    return undefined;
  }

  mapHomeAccountIdToAuthority(homeAccountId: string | undefined): string | undefined {
    let authority: string | undefined;

    if (homeAccountId?.toLowerCase().indexOf(B2cPolicies.names.internalUser.toLowerCase()) !== -1) {
      authority = B2cPolicies.authorities.internalUser.authority;
    } else if (homeAccountId?.toLowerCase().indexOf(B2cPolicies.names.externalUser.toLowerCase()) !== -1) {
      authority = B2cPolicies.authorities.externalUser.authority;
    }

    return authority;
  }

  getUser(): AccountInfo | undefined {
    return this.MsalClient.getAllAccounts()[0];
  }

  isLoggedIn(): boolean {
    if (AuthStateStorageService.getMsalError()) return false;
    if (this.getUser()) return true;

    return false;
  }

  private handleError(error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  clearStorageValues() {
    AuthStateStorageService.clearMsalValues();
  }
}

const instance = new AuthService();
export { instance as AuthService };
