import React from 'react';
import { getFormInputClassNames } from '../sharedFormFunctions';
import Arrow from '../../../assets/icons/icon-arrow-right-darkblue.svg';
import styles1 from './_dropdown.scss';
import styles2 from '../../../styles/_utilities.scss';

const styles = (cN: string) => {
  const mapped = cN.split(' ').map((c) => styles1[c] || styles2[c]);
  return mapped.join(' ');
};

// export type SelectOptionKey = string | number | undefined;
export type SelectOptionValue =
  | string
  | number
  | boolean
  | null
  | object
  | undefined;

export type SelectOption<
  //   K extends SelectOptionKey = string,
  V extends SelectOptionValue = undefined
> = {
  id: string;
  text: string;
  value?: V;
};

interface IProps<V extends SelectOptionValue> {
  id: string;
  labelText: string;
  hideLabelText?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeSelection?: (key: string, tag?: V) => void;
  options: SelectOption<V>[];
  invalid?: boolean;
  invalidText?: string | null;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

const Dropdown = React.forwardRef(
  <V extends SelectOptionValue>(props: IProps<V>, ref: any) => {
    const {
      id,
      hideLabelText,
      labelText,
      invalid,
      invalidText,
      disabled,
      value,
      options,
      placeholder,
      onChange,
      onChangeSelection
    } = props;
    return (
      <label
        htmlFor={id}
        className={styles(getFormInputClassNames('c_dropdown', props))}
      >
        <span
          className={styles(
            `c_dropdown__label-text ${
              hideLabelText && styles2.u_visuallyhidden
            }`
          )}
        >
          {labelText}
        </span>
        {invalid && (
          <span className={styles('c_dropdown__invalid-text')}>
            {invalidText}
          </span>
        )}
        <div className={styles('c_dropdown-box-svg__inner')}>
          <select
            name={id}
            defaultValue={value}
            onChange={(e) => {
              const key = e.target.value;
              const option = options.find((x) => x.id === key);
              if (onChange) {
                onChange(e);
              }
              if (onChangeSelection) {
                onChangeSelection(key, option?.value);
              }
            }}
            disabled={disabled}
            ref={ref}
          >
            {typeof placeholder !== 'undefined' && (
              <option value=''>{placeholder}</option>
            )}
            {options.map((item) => (
              <option
                key={`dropDownSelectKey__${item.id}_${item.text}`}
                value={item.id}
              >
                {item.text}
              </option>
            ))}
          </select>
          <span className={styles('c_dropdown__arrow-wrapper')}>
            <Arrow />
          </span>
        </div>
      </label>
    );
  }
);

// Dropdown.propTypes = {
//   id: PropTypes.string.isRequired,
//   labelText: PropTypes.string.isRequired,
//   hideLabelText: PropTypes.bool,
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   onChange: PropTypes.func.isRequired,
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//       text: PropTypes.string.isRequired
//     })
//   ).isRequired,
//   invalid: PropTypes.bool,
//   invalidText: PropTypes.string,
//     className: PropTypes.string, // eslint-disable-line
//   placeholder: PropTypes.string,
//   disabled: PropTypes.bool
// };

// Dropdown.defaultProps = {
//   hideLabelText: false,
//   invalid: false,
//   invalidText: 'Invalid',
//   disabled: false
// };

export { Dropdown };
