/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Checkbox } from '../checkbox/Checkbox';
import ArrowIcon from '../../../assets/icons/icon-arrow-right-darkblue.svg';
import styles1 from './SelectWithCheckBoxAndSearch.scss';

const styles = (cN) => {
  const mapped = cN.split(' ').map((c) => styles1[c]);
  return mapped.join(' ');
};

const Arrow = () => (
  <span className={styles('c_select-with-checkbox__arrow-wrapper')}>
    <ArrowIcon />
  </span>
);

export class SelectWithCheckBoxAndSearch extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    this.setState({
      showOptions: this.props.isInitiallyOpen ?? false,
      selectedOptions: this.props.selectedOptions,
      currentOptions: this.props.options,
      textBoxHasFocus: this.props.isInitiallyOpen ?? false,
      changed: false
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentOptions: this.props.options
      });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keypress', this.handleEnterPressed);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keypress', this.handleEnterPressed);
  }

  handleTextBoxFocus() {
    this.setState({
      textBoxHasFocus: true
    });
  }

  handleTextBoxBlur() {
    this.setState({
      textBoxHasFocus: false
    });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const wrapperRef = this.props.wrapperRef?.current ?? this.wrapperRef;
    if (this.props.ignoreClicksOutside) {
      return;
    }
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      if (this.state.showOptions) {
        this.setState({
          showOptions: !this.state.showOptions,
          currentOptions: this.props.options
        });

        this.triggerOnChange();
      }
    }
  }

  handleEnterPressed(event) {
    if (event.charCode !== 13) {
      return;
    }

    // only one dropdown can be open in a page: it's closed when the user clicks outside
    if (this.state.showOptions) {
      this.triggerOnChange();
    }
  }

  triggerOnChange() {
    const textInput = document.getElementById(`txtListFilter_${this.props.id}`);
    textInput.value = '';
    let calledOnChange = false;
    if (this.state.changed) {
      this.setState({
        changed: false
      });
      this.props.onChange(this.state.selectedOptions);
      calledOnChange = true;
    }
    if (this.props.triggerOnChangeAlways && !calledOnChange) {
      this.props.onChange(this.state.selectedOptions);
    }
  }

  toggleOptions() {
    if (
      this.state.textBoxHasFocus === true &&
      this.state.showOptions === true
    ) {
      return;
    }

    this.setState({
      showOptions: !this.state.showOptions
    });

    if (this.state.showOptions) {
      this.setState({
        currentOptions: this.props.options
      });
      this.triggerOnChange();
    } else {
      this.setState({
        currentOptions: this.props.options,
        selectedOptions: this.props.selectedOptions,
        changed: false
      });
    }
  }

  updateSelectedOptions(event, option) {
    let options = this.state.selectedOptions;
    if (!options) {
      options = [];
    }
    if (this.canSelectOption()) {
      if (options.some((o) => o.value === option.value)) {
        options = options.filter((o) => o.value !== option.value);
      } else {
        options.push(option);
      }
    }

    this.setState(
      {
        selectedOptions: options,
        changed: true
      },
      () => {
        if (this.props.onSelectionChange) {
          this.props.onSelectionChange(
            this.state.selectedOptions.map((o) => o.value)
          );
        }
      }
    );
  }

  updateSelectedOptionsOld(event, option) {
    let options = this.state.selectedOptions;
    if (!options) {
      options = [];
    }
    if (this.canSelectOption()) {
      if (event.target.checked === true) {
        options.push(option);
      } else {
        options = options.filter((o) => o.value !== option.value);
      }
    } else if (
      !this.canSelectOption() &&
      this.props.maxChecked === this.state.selectedOptions.length
    ) {
      if (event.target.checked !== true) {
        options = options.filter((o) => o.value !== option.value);
      }
    } else {
      event.target.checked = false;
    }

    this.setState({
      selectedOptions: options,
      changed: true
    });
  }

  canSelectOption() {
    return true;
  }

  tokenizeString(str) {
    return str.split(/[\s,]+/);
  }

  compareOptions(searchTokenized, options) {
    const optionsTokenized = this.tokenizeString(options.toLowerCase());
    return searchTokenized.every((s) =>
      optionsTokenized.some((o) => o.search(s) !== -1)
    );
  }

  filterOptions(event) {
    const searchTokenized = this.tokenizeString(
      event.target.value.toLowerCase()
    );

    const filteredOptions = this.props.options.filter(
      (option) => this.compareOptions(searchTokenized, option.name)
      // option.name.toLowerCase().search(event.target.value.toLowerCase()) !==
      // -1
    );
    this.setState({
      currentOptions: filteredOptions
    });
  }

  resetSelectedOptions(event) {
    event.preventDefault();
    this.setState({
      selectedOptions: [],
      changed: true
    });
  }

  isChecked(option) {
    if (!this.state.selectedOptions) return false;
    const res = this.state.selectedOptions.findIndex(
      (x) => x.value === option.value
    );

    if (res !== -1) {
      return true;
    }

    return false;
  }

  get inputPlaceholder() {
    if (this.state.showOptions) {
      return 'Søk';
    }

    const selections = this.state.selectedOptions.filter(
      (so) =>
        this.state.currentOptions &&
        this.state.currentOptions.some((o) => o.value === so.value)
    ).length;

    if (!selections) {
      return 'Velg';
    }

    return `Velg (${selections} valgt)`;
  }

  render() {
    const { isMobile } = this.props;

    return (
      <div className={styles('c_filter-container')}>
        <span className={styles('filter-label')}>{this.props.label}</span>

        <div
          className={`${styles('c_select-with-checkbox')} ${
            this.props.class ?? ''
          }`}
          id={`select-container_${this.props.id}`}
          ref={this.setWrapperRef}
        >
          <div
            className={styles(
              `c_select-with-checkbox-select ${
                this.state.showOptions === true
                  ? 'c_select-with-checkbox-select--active'
                  : ''
              }`
            )}
            key='allYears'
            onClick={() => this.toggleOptions()}
          >
            <input
              id={`txtListFilter_${this.props.id}`}
              type='textbox'
              placeholder={this.inputPlaceholder}
              className={styles('c_select-with-checkbox-select__textbox')}
              onChange={(event) => this.filterOptions(event)}
              onFocus={() => this.handleTextBoxFocus()}
              onBlur={() => this.handleTextBoxBlur()}
            />

            {!this.state.showOptions && <Arrow />}
            {this.state.showOptions && <Arrow />}
          </div>
          {this.state.showOptions && (
            <div className={styles('relative')}>
              <div
                className={styles('c_select-with-checkbox-options-wrapper')}
                style={
                  this.props.doNotDisplayOptionsOnTop
                    ? { position: 'inherit' }
                    : {}
                }
              >
                <div className={styles('c_select-with-checkbox-options')}>
                  {this.state.currentOptions &&
                    this.state.currentOptions.map((option) => {
                      return (
                        <div
                          className={styles(
                            `c_select-with-checkbox-option ${
                              this.isChecked(option)
                                ? 'c_select-with-checkbox-option-open'
                                : ''
                            }`
                          )}
                          key={`option${option.value}`}
                        >
                          <div className={styles('c_checkbox')}>
                            <Checkbox
                              style={{
                                margin: '0 0 0em 0.25em'
                              }}
                              labelStyles={{
                                lineHeight: '1.5em',
                                marginTop: '-0.5em'
                              }}
                              id={`cb_${option.name}`}
                              labelText={option.name}
                              onChange={(event) =>
                                this.updateSelectedOptions(event, option)
                              }
                              key={`cb_${option.name}${this.isChecked(option)}`}
                              checked={this.isChecked(option)}
                            />
                          </div>
                        </div>
                      );
                    }, this)}
                </div>
                {!isMobile && (
                  <div className={styles('c_select-with-checkbox-buttons')}>
                    <button
                      className={styles(
                        'c_select-with-checkbox-buttons__reset'
                      )}
                      onClick={(e) => this.resetSelectedOptions(e)}
                    >
                      Nullstill
                    </button>
                    <button
                      type='button'
                      className={styles(
                        'c_select-with-checkbox-buttons__result'
                      )}
                      onClick={() => {
                        this.setState(
                          {
                            textBoxHasFocus: false
                          },
                          this.toggleOptions
                        );
                      }}
                    >
                      {this.props.okLabel}
                    </button>
                  </div>
                )}
                {isMobile && (
                  <div className={styles('c_select-with-checkbox-buttons')}>
                    <button
                      className={styles(
                        'c_select-with-checkbox-buttons__result single'
                      )}
                      onClick={() => this.toggleOptions()}
                    >
                      Lagre
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
