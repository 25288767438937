import React from 'react';

import { SelectOption } from '../dropdown/Dropdown';
// eslint-disable-next-line import/named
// @ts-ignore
import { SelectWithCheckBoxAndSearch } from './SelectWithCheckBoxAndSearch';

export interface IMultiSelectProps {
  options: SelectOption[];
  selectedIds: string[];
  label: string;
  okLabel: string;
  onChange: (val: string[]) => void;
  onSelectionChange?: (val: string[]) => void;
  doNotDisplayOptionsOnTop?: boolean;
  wrapperRef?: React.MutableRefObject<null>;
  ignoreClicksOutside?: boolean;
  triggerOnChangeAlways?: boolean; // Trigger onChange when closing even if values are the same
  isInitiallyOpen?: boolean; // MultiSelect is initally open and options expanded
  id: string;
}
function MultiSelect({
  options,
  selectedIds,
  onChange,
  label,
  okLabel,
  triggerOnChangeAlways,
  isInitiallyOpen,
  id,
  onSelectionChange,
  doNotDisplayOptionsOnTop,
  wrapperRef,
  ignoreClicksOutside
}: IMultiSelectProps) {
  return (
    <SelectWithCheckBoxAndSearch
      id={id}
      label={label}
      okLabel={okLabel}
      doNotDisplayOptionsOnTop={doNotDisplayOptionsOnTop}
      onSelectionChange={onSelectionChange}
      wrapperRef={wrapperRef}
      triggerOnChangeAlways={triggerOnChangeAlways}
      isInitiallyOpen={isInitiallyOpen}
      ignoreClicksOutside={ignoreClicksOutside}
      selectedOptions={
        selectedIds ? selectedIds.map((o) => ({ value: o })) : []
      }
      options={options.map((o) => ({ value: o.id, name: o.text }))}
      onChange={(o: any) => onChange(o.map((v: any) => v.value))}
    />
  );
}

export { MultiSelect };
