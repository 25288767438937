/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AdgangAccessControl {
    InternalUsersView = 'InternalUsersView',
    InternalUsersViewShowNoAppFilter = 'InternalUsersViewShowNoAppFilter',
    InternalUserView = 'InternalUserView',
    InternalUserEdit = 'InternalUserEdit',
    ExternalUsersView = 'ExternalUsersView',
    ExternalUsersViewShowNoAppFilter = 'ExternalUsersViewShowNoAppFilter',
    ExternalUserView = 'ExternalUserView',
    ExternalUserEdit = 'ExternalUserEdit',
    ApplicationsView = 'ApplicationsView',
    ApplicationsGlobalView = 'ApplicationsGlobalView',
    ApplicationView = 'ApplicationView',
    ApplicationEdit = 'ApplicationEdit',
    ApplicationEditViewResponsibles = 'ApplicationEditViewResponsibles',
    ApplicationEditChangeResponsibles = 'ApplicationEditChangeResponsibles',
    ApplicationExtendAppResponsibleAccessRights = 'ApplicationExtendAppResponsibleAccessRights',
    ApplicationUnlockExternalApprover = 'ApplicationUnlockExternalApprover',
    ApplicationCreate = 'ApplicationCreate',
    ApplicationDelete = 'ApplicationDelete',
    RolesView = 'RolesView',
    RolesViewShowNoAppFilter = 'RolesViewShowNoAppFilter',
    RolesGlobalView = 'RolesGlobalView',
    RoleView = 'RoleView',
    RoleEdit = 'RoleEdit',
    RoleDelete = 'RoleDelete',
    RoleEditChangeApplication = 'RoleEditChangeApplication',
    RoleEditChangeShortName = 'RoleEditChangeShortName',
    RoleEditChangeName = 'RoleEditChangeName',
    RoleEditChangeDescription = 'RoleEditChangeDescription',
    RoleEditChangeTags = 'RoleEditChangeTags',
    RoleEditChangeCanBeRequestedByUsers = 'RoleEditChangeCanBeRequestedByUsers',
    RoleEditChangeUserAuthenticationConstraint = 'RoleEditChangeUserAuthenticationConstraint',
    RoleEditChangeOrganizationGroup = 'RoleEditChangeOrganizationGroup',
    RoleEditChangeRequireOrganizationGroupInMinSide = 'RoleEditChangeRequireOrganizationGroupInMinSide',
    RoleEditChangeAccessRequestApprovalType = 'RoleEditChangeAccessRequestApprovalType',
    RoleEditChangeRequiresMfa = 'RoleEditChangeRequiresMfa',
    RoleEditChangeUserType = 'RoleEditChangeUserType',
    RoleEditChangePermissions = 'RoleEditChangePermissions',
    PermissionsView = 'PermissionsView',
    PermissionsViewShowNoAppFilter = 'PermissionsViewShowNoAppFilter',
    PermissionsGlobalView = 'PermissionsGlobalView',
    PermissionView = 'PermissionView',
    PermissionEdit = 'PermissionEdit',
    PermissionDelete = 'PermissionDelete',
    PermissionEditChangeApplication = 'PermissionEditChangeApplication',
    AssignAccessRight = 'AssignAccessRight',
    RemoveAccessRight = 'RemoveAccessRight',
    AccessRequestsView = 'AccessRequestsView',
    AccessRequestsViewShowNoAppFilter = 'AccessRequestsViewShowNoAppFilter',
    AccessRequestView = 'AccessRequestView',
    AccessRequestEdit = 'AccessRequestEdit',
    AccessRequestUnlockExternalApprover = 'AccessRequestUnlockExternalApprover',
    AccessRequestApproveExternalApproverRole = 'AccessRequestApproveExternalApproverRole',
    ApprovedAccessesView = 'ApprovedAccessesView',
    ApprovedAccessRemove = 'ApprovedAccessRemove',
    OrganizationGroupsView = 'OrganizationGroupsView',
    OrganizationGroupEdit = 'OrganizationGroupEdit',
    OrganizationGroupsDelete = 'OrganizationGroupsDelete',
    OrganizationGroupEditApplication = 'OrganizationGroupEditApplication',
    OrganizationGroupCreate = 'OrganizationGroupCreate',
    AdGroupsSectionsView = 'AdGroupsSectionsView',
    AdGroupView = 'AdGroupView',
    AdGroupEdit = 'AdGroupEdit',
    SectionView = 'SectionView',
    SectionEdit = 'SectionEdit',
    TranslationsView = 'TranslationsView',
    TranslationsEdit = 'TranslationsEdit',
    AllowCreationExternalUsers = 'AllowCreationExternalUsers',
    DevAllowAssignSysAdmin = 'Dev_AllowAssignSysAdmin'
}

export function AdgangAccessControlFromJSON(json: any): AdgangAccessControl {
    return AdgangAccessControlFromJSONTyped(json, false);
}

export function AdgangAccessControlFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdgangAccessControl {
    return json as AdgangAccessControl;
}

export function AdgangAccessControlToJSON(value?: AdgangAccessControl | null): any {
    return value as any;
}

