import { permissionsClient } from 'api/AdgangClients';
import { PermissionResponse, ResourceFilter, UserType } from 'api/adgang/models';
import { SelectOption, useFetch } from 'common.ui';
import { DependencyList } from 'react';
import { concat } from 'helpers/concat';

type PermissionsResult = [SelectOption[] | undefined, PermissionResponse[] | undefined];

export function usePermissions(
  userType?: UserType,
  applicationId?: string | string[] | null,
  userId?: string,
  requireUserOrganizationInMinSide?: boolean,
  resourceFilter?: ResourceFilter,
  dependencies: DependencyList = [] as DependencyList
): PermissionsResult {
  const [fetchedPermissions] = useFetch<PermissionsResult>(
    () => fetchAllPermissions(),
    [undefined, undefined] as PermissionsResult,
    false,
    [applicationId, ...dependencies]
  );

  const fetchAllPermissions = async (): Promise<PermissionsResult> => {
    const permissions =
      !Array.isArray(applicationId) || applicationId.length === 0
        ? await permissionsClient.apiPermissionsGet({
            pageNumber: 1,
            pageSize: 9999,
            applicationId:
              Array.isArray(applicationId) || applicationId === 'global'
                ? undefined
                : applicationId ?? undefined,
            isGlobal: applicationId === 'global',
            userId,
            userType,
            requireUserOrganizationInMinSide,
            resourceFilter
          })
        : await permissionsClient.apiPermissionsSearchesPost({
            permissionSearchRequest: {
              resourceFilter,
              applicationFilter: {
                applicationIds: applicationId.filter((a) => a !== 'global'),
                includeGlobalValues: applicationId.some((a) => a === 'global')
              },
              paginationQuery: {
                pageNumber: 1,
                pageSize: 9999
              },
              userType
            }
          });

    if (!permissions.data) {
      return [undefined, undefined] as PermissionsResult;
    }

    const options = permissions.data
      .sort((p1, p2) => (p1.name || '').localeCompare(p2.name || ''))
      .map((d) => {
        return {
          id: d.permissionId?.toString(),
          text: concat(' - ', d.name, d.description)
        } as SelectOption;
      });

    const data = permissions.data || [];

    return [options, data];
  };

  return fetchedPermissions;
}
