/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PermissionFilterRequest
 */
export interface PermissionFilterRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PermissionFilterRequest
     */
    includeGlobalValues?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PermissionFilterRequest
     */
    permissionIds?: Array<number> | null;
}

export function PermissionFilterRequestFromJSON(json: any): PermissionFilterRequest {
    return PermissionFilterRequestFromJSONTyped(json, false);
}

export function PermissionFilterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionFilterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'includeGlobalValues': !exists(json, 'includeGlobalValues') ? undefined : json['includeGlobalValues'],
        'permissionIds': !exists(json, 'permissionIds') ? undefined : json['permissionIds'],
    };
}

export function PermissionFilterRequestToJSON(value?: PermissionFilterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'includeGlobalValues': value.includeGlobalValues,
        'permissionIds': value.permissionIds,
    };
}


