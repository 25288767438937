/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationFilterRequest,
    ApplicationFilterRequestFromJSON,
    ApplicationFilterRequestFromJSONTyped,
    ApplicationFilterRequestToJSON,
    ApprovedAccessOrderByPaginationQuery,
    ApprovedAccessOrderByPaginationQueryFromJSON,
    ApprovedAccessOrderByPaginationQueryFromJSONTyped,
    ApprovedAccessOrderByPaginationQueryToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApprovedAccessSearchRequest
 */
export interface ApprovedAccessSearchRequest {
    /**
     * 
     * @type {ApprovedAccessOrderByPaginationQuery}
     * @memberof ApprovedAccessSearchRequest
     */
    paginationQuery?: ApprovedAccessOrderByPaginationQuery;
    /**
     * 
     * @type {ApplicationFilterRequest}
     * @memberof ApprovedAccessSearchRequest
     */
    applicationFilter?: ApplicationFilterRequest;
}

export function ApprovedAccessSearchRequestFromJSON(json: any): ApprovedAccessSearchRequest {
    return ApprovedAccessSearchRequestFromJSONTyped(json, false);
}

export function ApprovedAccessSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprovedAccessSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationQuery': !exists(json, 'paginationQuery') ? undefined : ApprovedAccessOrderByPaginationQueryFromJSON(json['paginationQuery']),
        'applicationFilter': !exists(json, 'applicationFilter') ? undefined : ApplicationFilterRequestFromJSON(json['applicationFilter']),
    };
}

export function ApprovedAccessSearchRequestToJSON(value?: ApprovedAccessSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paginationQuery': ApprovedAccessOrderByPaginationQueryToJSON(value.paginationQuery),
        'applicationFilter': ApplicationFilterRequestToJSON(value.applicationFilter),
    };
}


