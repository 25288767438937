import { ApplicationMigrationStatus } from 'api/adgang/models';
import { SelectOption } from 'common.ui';

export function useApplicationMigrationStatuses(): [SelectOption[]] {
  const statuses = [
    {
      id: ApplicationMigrationStatus.Available as string,
      text: 'Tilgjengelig'
    },
    {
      id: ApplicationMigrationStatus.NotAvailable as string,
      text: 'Ikke tilgjengelig'
    }
  ];
  return [statuses];
}
