import React from 'react';
import styles from './PageHeader.scss';

interface IProps {
  value: string;
  stepCurrent?: number;
  stepTotal?: number;
  renderStepInfo?: (stepCurrent: number, stepTotal: number) => string;
}

const PageHeader = ({
  value,
  stepCurrent,
  stepTotal,
  renderStepInfo
}: IProps) => {
  return (
    <React.Fragment>
      <h1 className={styles.h1}>
        {value}
        {stepCurrent !== undefined && stepTotal !== undefined && (
          <span className={styles.step}>
            {!renderStepInfo
              ? `Steg ${stepCurrent} av ${stepTotal}`
              : renderStepInfo(stepCurrent, stepTotal)}
          </span>
        )}
      </h1>
    </React.Fragment>
  );
};

export { PageHeader };
