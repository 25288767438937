import React, { FunctionComponent } from 'react';
// import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom';
import styles from './Menu.scss';
import IconArrowRight from '../../assets/icons/arrow-right-icon.svg';

interface SubMenuItemProps {
  active?: boolean;
  label?: string;
  link: string;
}

interface ISubMenuItem extends FunctionComponent<SubMenuItemProps> {}

const SubMenuItem: ISubMenuItem = ({ active, link, label }) => {
  return (
    <div>
      <span className={styles.menu_subitem}>
        <IconArrowRight className={styles['sub-menu-item-icon']} />
      </span>{' '}
      <span className={active ? styles.menu_item_active : ''}>
        <Link to={link}>{label}</Link>
      </span>
    </div>
  );
};

interface MenuItemProps {
  icon?: React.ReactNode;
  active?: boolean;
  subItemActive?: boolean;
  label?: string;
  link: string;
}

interface IMenuItem extends FunctionComponent<MenuItemProps> {}

const MenuItem: IMenuItem = ({
  icon,
  active,
  link,
  label,
  subItemActive,
  children
}) => {
  return (
    <div className={styles.menu_row}>
      <div className={styles.menu_icon}>{icon}</div>
      <div className={styles.menu_item}>
        <div>
          <span className={active ? styles.menu_item_active : ''}>
            <Link to={link}>{label}</Link>
          </span>
        </div>
        {subItemActive && children}
      </div>
    </div>
  );
};

interface MenuProps {
  children: React.ReactElement<IMenuItem> | React.ReactElement<IMenuItem>[];
}
const Menu = ({ children }: MenuProps) => {
  return <div className={styles.menu_container}>{children}</div>;
};

export { Menu, MenuItem, SubMenuItem };
