/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserOrganizationResponse
 */
export interface UserOrganizationResponse {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationResponse
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationResponse
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserOrganizationResponse
     */
    organizationId?: number;
}

export function UserOrganizationResponseFromJSON(json: any): UserOrganizationResponse {
    return UserOrganizationResponseFromJSONTyped(json, false);
}

export function UserOrganizationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOrganizationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
    };
}

export function UserOrganizationResponseToJSON(value?: UserOrganizationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'note': value.note,
        'organizationId': value.organizationId,
    };
}


