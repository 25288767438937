/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleSearchOrderBy,
    RoleSearchOrderByFromJSON,
    RoleSearchOrderByFromJSONTyped,
    RoleSearchOrderByToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderFromJSONTyped,
    SortOrderToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoleSearchSortingQuery
 */
export interface RoleSearchSortingQuery {
    /**
     * 
     * @type {SortOrder}
     * @memberof RoleSearchSortingQuery
     */
    order?: SortOrder;
    /**
     * 
     * @type {RoleSearchOrderBy}
     * @memberof RoleSearchSortingQuery
     */
    orderBy?: RoleSearchOrderBy;
}

export function RoleSearchSortingQueryFromJSON(json: any): RoleSearchSortingQuery {
    return RoleSearchSortingQueryFromJSONTyped(json, false);
}

export function RoleSearchSortingQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleSearchSortingQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': !exists(json, 'order') ? undefined : SortOrderFromJSON(json['order']),
        'orderBy': !exists(json, 'orderBy') ? undefined : RoleSearchOrderByFromJSON(json['orderBy']),
    };
}

export function RoleSearchSortingQueryToJSON(value?: RoleSearchSortingQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': SortOrderToJSON(value.order),
        'orderBy': RoleSearchOrderByToJSON(value.orderBy),
    };
}


