import { ExternalUserStatus } from 'api/adgang/models';
import { SelectOption } from 'common.ui';

export function useExternalUserStatus(): [SelectOption[]] {
  const types = [
    {
      id: ExternalUserStatus.Active as string,
      text: 'Aktiv'
    },
    {
      id: ExternalUserStatus.Inactive as string,
      text: 'Inaktiv'
    }
  ];
  return [types];
}
