import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from '../buttons/button/Button';
import styles from './Modal.scss';

export type IConfirmationModal = {
  header: string;
  show: boolean;
  disabled?: boolean;
  onCancel: () => void;
  onAccept: () => void;
  hideButtons?: boolean;
  yesText?: string;
  noText?: string;
};

const ConfirmationModal: React.FunctionComponent<IConfirmationModal> = ({
  header,
  children,
  show,
  disabled,
  onCancel,
  onAccept,
  hideButtons,
  yesText,
  noText
}) => (
  <Modal show={show} className={styles.modal} onHide={onCancel}>
    <Modal.Header closeButton>
      <Modal.Title>{header}</Modal.Title>
    </Modal.Header>

    <Modal.Body>{children}</Modal.Body>

    {!hideButtons && (
      <Modal.Footer>
        <Button
          type='button'
          text={yesText ?? 'Ja'}
          disabled={disabled ?? false}
          onClick={onAccept}
        />
        <Button
          type='button'
          text={noText ?? 'Nei'}
          styleType='light'
          onClick={onCancel}
        />
      </Modal.Footer>
    )}
  </Modal>
);
export { ConfirmationModal };
