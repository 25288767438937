import React from 'react';

import { SelectOption } from '../dropdown/Dropdown';
// eslint-disable-next-line import/named
// @ts-ignore
import { SelectWithCheckBoxAndSearch } from './SelectWithCheckBoxAndSearch';

export interface ISearchableSingleSelect {
  options: SelectOption[];
  selectedId: string;
  label: string;
  onChange: (val: string) => void;
  doNotDisplayOptionsOnTop?: boolean;
  wrapperRef?: React.MutableRefObject<null>;
  ignoreClicksOutside?: boolean;
  triggerOnChangeAlways?: boolean; // Trigger onChange when closing even if values are the same
  isInitiallyOpen?: boolean; // Select is initally open and options expanded
  id: string;
}
function SearchableSingleSelect({
  options,
  selectedId,
  onChange,
  label,
  triggerOnChangeAlways,
  isInitiallyOpen,
  id,
  doNotDisplayOptionsOnTop,
  wrapperRef,
  ignoreClicksOutside
}: ISearchableSingleSelect) {
  return (
    <SelectWithCheckBoxAndSearch
      id={id}
      label={label}
      doNotDisplayOptionsOnTop={doNotDisplayOptionsOnTop}
      wrapperRef={wrapperRef}
      triggerOnChangeAlways={triggerOnChangeAlways}
      isInitiallyOpen={isInitiallyOpen}
      ignoreClicksOutside={ignoreClicksOutside}
      selectedOption={selectedId ? { value: selectedId } : null}
      options={options.map((o) => ({ value: o.id, name: o.text }))}
      onChange={(o: any) => onChange(o?.value)}
    />
  );
}

export { SearchableSingleSelect };
