/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleFilterRequest
 */
export interface RoleFilterRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RoleFilterRequest
     */
    includeGlobalValues?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleFilterRequest
     */
    roleIds?: Array<number> | null;
}

export function RoleFilterRequestFromJSON(json: any): RoleFilterRequest {
    return RoleFilterRequestFromJSONTyped(json, false);
}

export function RoleFilterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleFilterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'includeGlobalValues': !exists(json, 'includeGlobalValues') ? undefined : json['includeGlobalValues'],
        'roleIds': !exists(json, 'roleIds') ? undefined : json['roleIds'],
    };
}

export function RoleFilterRequestToJSON(value?: RoleFilterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'includeGlobalValues': value.includeGlobalValues,
        'roleIds': value.roleIds,
    };
}


