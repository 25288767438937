/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccessRequestSearchOrderBy {
    RequestedByUserName = 'RequestedByUserName',
    RequestedByUserSectionName = 'RequestedByUserSectionName',
    RequestedAccessName = 'RequestedAccessName',
    ApplicationName = 'ApplicationName',
    AssignedOn = 'AssignedOn'
}

export function AccessRequestSearchOrderByFromJSON(json: any): AccessRequestSearchOrderBy {
    return AccessRequestSearchOrderByFromJSONTyped(json, false);
}

export function AccessRequestSearchOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestSearchOrderBy {
    return json as AccessRequestSearchOrderBy;
}

export function AccessRequestSearchOrderByToJSON(value?: AccessRequestSearchOrderBy | null): any {
    return value as any;
}

