import { UserType } from 'api/adgang/models';
import { SelectOption } from 'common.ui';

export function useUserTypes(customValue?: SelectOption): [SelectOption[]] {
  const types = [
    {
      id: UserType.Internal as string,
      text: 'Interne brukere'
    },
    {
      id: UserType.External as string,
      text: 'Eksterne brukere'
    }
  ];
  if (customValue) {
    types.push(customValue);
  }
  return [types];
}
