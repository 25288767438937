import React, { useState } from 'react';
import { useForm, Controller, Control } from 'react-hook-form';
import { Checkbox } from '../../components/formElements/checkbox/Checkbox';
import { MultiSelect } from '../../components/formElements/multiSelect/MultiSelect';
import { SearchableSingleSelect } from '../../components/formElements/searchableSingleSelect/SearchableSingleSelect';

import {
  Dropdown,
  SelectOption,
  SelectOptionValue
} from '../../components/formElements/dropdown/Dropdown';
import {
  TextInput,
  TextInputType
} from '../../components/formElements/textInput/TextInput';
import { Message } from '../../components/contentBlocks/message/Message';
import { TextArea } from '../../components/formElements/textArea/TextArea';
import { Button, IButton } from '../../components/buttons/button/Button';

import {
  RadioGroup,
  RadioOption
} from '../../components/formElements/radioGroup/RadioGroup';

type ISubmit<T extends object> = Pick<IButton, 'styleType' | 'text'> & {
  preSubmit?: (value: T) => void;
  form: Form<T>;
};

type IInput<T extends object> = {
  name: string;
  type?: TextInputType;
  height?: string;
  label: string;
  placeholder?: string;
  readonly?: boolean;
  form: Form<T>;
  onEnterPressed?: (value: string) => void;
  onChange?: (value: string) => void;
};

type IDrowdown<T extends object, V extends SelectOptionValue> = {
  name: string;
  label: string;
  placeholder?: string;
  readonly?: boolean;
  options: SelectOption<V>[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeSelection?: (key: string, value?: V) => void;
  form: Form<T>;
};

type IRadioGroup<T extends object> = {
  name: string;
  label: string;
  // placeholder?: string;
  readonly?: boolean;
  options: RadioOption[];
  form: Form<T>;
  onChange?: (value: string) => void;
  layout?: 'vertical' | 'horizontal';
};

type IForm<T extends object> = {
  form: Form<T>;
};

type ICheckbox<T extends object> = {
  name: string;
  label: string;
  question: string;
  checked?: boolean;
  form: Form<T>;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  labelContent?: React.ReactNode;
};

type IMultiSelect<T extends object> = {
  name: string;
  label: string;
  okLabel: string;
  options: SelectOption[];
  form: Form<T>;
  onChange?: (model: string[]) => void;
  triggerOnChangeAlways?: boolean; // Trigger onChange when closing even if values are the same
  isInitiallyOpen?: boolean; // MultiSelect is initally open and options expanded
};

type ISearchableSingleSelect<T extends object> = {
  name: string;
  label: string;
  options: SelectOption[];
  form: Form<T>;
  onChange?: (model: string) => void;
  triggerOnChangeAlways?: boolean; // Trigger onChange when closing even if values are the same
  isInitiallyOpen?: boolean; // MultiSelect is initally open and options expanded
};
type IDisplayErrors<T extends object> = {
  form: Form<T>;
  catchUnboundErrors?: boolean;
  catchFields?: string[];
};

export interface GenericValidationError {
  /**
   *
   * @type {Array<string>}
   * @memberof GenericValidationError
   */
  messages?: Array<string> | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GenericValidationError
   */
  errors?: { [key: string]: string } | null;
}

function Multi<T extends object>({
  name,
  label,
  okLabel,
  options,
  form,
  onChange: onChangeCallBack,
  triggerOnChangeAlways,
  isInitiallyOpen
}: IMultiSelect<T>) {
  return (
    <Controller
      // @ts-ignore
      defaultValue={form.propsform.model && form.propsform.model[name]}
      // @ts-ignore
      control={form.control}
      // @ts-ignore
      name={name}
      render={({ onChange, value }) => (
        <MultiSelect
          label={label}
          okLabel={okLabel}
          id={name}
          triggerOnChangeAlways={triggerOnChangeAlways}
          isInitiallyOpen={isInitiallyOpen}
          onChange={(model) => {
            onChange(model);
            if (onChangeCallBack) onChangeCallBack(model);
          }}
          options={options}
          selectedIds={value}
        />
      )}
    />
  );
}

function SingleSearch<T extends object>({
  name,
  label,
  options,
  form,
  onChange: onChangeCallBack,
  triggerOnChangeAlways,
  isInitiallyOpen
}: ISearchableSingleSelect<T>) {
  return (
    <Controller
      // @ts-ignore
      defaultValue={form.propsform.model && form.propsform.model[name]}
      // @ts-ignore
      control={form.control}
      // @ts-ignore
      name={name}
      render={({ onChange, value }) => (
        <SearchableSingleSelect
          label={label}
          id={name}
          triggerOnChangeAlways={triggerOnChangeAlways}
          isInitiallyOpen={isInitiallyOpen}
          onChange={(model) => {
            onChange(model);
            if (onChangeCallBack) onChangeCallBack(model);
          }}
          options={options}
          selectedId={value}
        />
      )}
    />
  );
}

function Cbox<T extends object>({
  name,
  label,
  question,
  form,
  disabled,
  onChange,
  labelContent
}: ICheckbox<T>) {
  // TODO: Checkbox has inconsistent behaviour ([], true, 'on')
  return (
    <Checkbox
      checked={
        form.propsform.model &&
        !!form.propsform.model?.[name as keyof typeof form.propsform.model]
      }
      id={name}
      labelText={label}
      questionText={question}
      disabled={disabled || form.isLoading}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
      }}
      ref={(e) => {
        // @ts-ignore
        form.register(e);
      }}
      invalidText={
        form.propsform.errors?.errors &&
        form.propsform.errors?.errors[name as string]
      }
      invalid={
        !!(
          form.propsform.errors?.errors &&
          form.propsform.errors?.errors[name as string]
        )
      }
      labelContent={labelContent}
    />
  );
}

function Input<T extends object>({
  name,
  type,
  label,
  readonly,
  placeholder,
  form,
  onEnterPressed,
  onChange
}: IInput<T>) {
  const { propsform, isLoading, register } = form;
  return (
    <React.Fragment>
      <TextInput
        id={name}
        type={type || 'text'}
        value={
          propsform.model &&
          (propsform.model[name as keyof typeof propsform.model] as string)
        }
        labelText={label}
        ref={(e) => {
          // @ts-ignore
          register(e);
        }}
        disabled={readonly || isLoading}
        invalidText={
          propsform.errors?.errors && propsform.errors?.errors[name as string]
        }
        invalid={
          !!(
            propsform.errors?.errors && propsform.errors?.errors[name as string]
          )
        }
        placeholder={placeholder}
        onChange={onChange}
        enterPressed={(value: string) => {
          if (onEnterPressed) {
            onEnterPressed(value);
          }
        }}
      />
    </React.Fragment>
  );
}

type FormProps<FormValues> = {
  onSubmit: (model: FormValues) => Promise<void>;
  errors?: GenericValidationError;
  model?: FormValues;
  // children: React.ReactNode
};

export type Form<T extends object> = {
  register: any;
  control: Control<T>;
  propsform: FormProps<T>;
  handleSubmit: any;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (
    callback?: ((ecb: T) => void) | undefined
  ) => (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  reset: () => void;
};

function DisplayErrors<T extends object>({
  form,
  catchUnboundErrors,
  catchFields
}: IDisplayErrors<T>) {
  const { propsform } = form;

  // catchUnboundErrors allows to map error fields not mapped in the form
  if (
    (catchUnboundErrors ?? true) &&
    propsform.errors?.errors &&
    propsform.model
  ) {
    const fields = Object.keys(propsform.model);
    Object.entries(propsform.errors.errors).forEach(([key, msg]) => {
      if (
        !catchFields?.find((f) => f === key) &&
        fields.find((f) => f === key)
      ) {
        return;
      }
      propsform.errors = propsform.errors ?? {
        messages: []
      };
      if (propsform.errors.messages?.some((m) => m === msg)) {
        return;
      }
      propsform.errors.messages = propsform.errors?.messages || [];
      propsform.errors.messages.push(msg);
    });
  }

  return (
    <React.Fragment>
      {propsform.errors?.messages && propsform.errors.messages.length > 0 && (
        <Message type='error' text={propsform.errors.messages} />
      )}
    </React.Fragment>
  );
}

function Text<T extends object>({
  name,
  label,
  placeholder,
  readonly,
  form,
  onChange,
  height
}: IInput<T>) {
  const { propsform, isLoading, register } = form;
  return (
    <React.Fragment>
      <TextArea
        id={name}
        height={height}
        disabled={readonly || isLoading}
        value={
          propsform.model &&
          (propsform.model[name as keyof typeof propsform.model] as string)
        }
        labelText={label}
        onChange={onChange}
        ref={(e) => {
          // @ts-ignore
          register(e);
        }}
        invalidText={
          propsform.errors?.errors && propsform.errors?.errors[name as string]
        }
        invalid={
          !!(
            propsform.errors?.errors && propsform.errors?.errors[name as string]
          ) || false
        }
        placeholder={placeholder}
      />
    </React.Fragment>
  );
}

function DropdownWrapper<T extends object, V extends SelectOptionValue>({
  name,
  label,
  options,
  placeholder,
  readonly,
  onChange,
  onChangeSelection,
  form
}: IDrowdown<T, V>) {
  const { propsform, isLoading, register } = form;
  return (
    <React.Fragment>
      <Dropdown
        id={name}
        disabled={readonly || isLoading}
        value={
          propsform.model &&
          (propsform.model[name as keyof typeof propsform.model] as string)
        }
        labelText={label}
        options={options}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          onChange && onChange(e)
        }
        onChangeSelection={(k, v) =>
          onChangeSelection && onChangeSelection(k, v as V)
        }
        ref={(e) => {
          // @ts-ignore
          register(e);
        }}
        invalidText={
          propsform.errors?.errors && propsform.errors?.errors[name as string]
        }
        invalid={
          !!(
            propsform.errors?.errors && propsform.errors?.errors[name as string]
          ) || false
        }
        placeholder={placeholder}
      />
    </React.Fragment>
  );
}

function RadioGroupWrapper<T extends object>({
  name,
  label,
  options,
  form,
  readonly,
  onChange,
  layout = 'horizontal'
}: IRadioGroup<T>) {
  const { propsform, register } = form;
  return (
    <React.Fragment>
      <RadioGroup
        id={name as string}
        onChange={onChange}
        disabled={readonly}
        selectedOptionId={
          // @ts-ignore
          propsform.model && (propsform.model[name] as string)
        }
        questionText={label}
        options={options}
        //   onChange={() => {}}
        ref={(e) => {
          // @ts-ignore
          register(e);
        }}
        invalidText={
          propsform.errors?.errors && propsform.errors?.errors[name as string]
        }
        invalid={
          !!(
            propsform.errors?.errors && propsform.errors?.errors[name as string]
          )
        }
        // placeholder={placeholder}
        layout={layout}
      />
    </React.Fragment>
  );
}

function Submit<T extends object>({
  text,
  styleType,
  preSubmit,
  form
}: ISubmit<T>) {
  const { onSubmit } = form;
  return (
    <Button styleType={styleType} text={text} onClick={onSubmit(preSubmit)} />
  );
}

function FormContainer<T extends object>(
  props: React.PropsWithChildren<IForm<T>>
) {
  const { form, children } = props;
  return (
    <form
      autoComplete='off'
      onSubmit={form.handleSubmit(async (e: any) => {
        form.setIsLoading(true);
        try {
          // @ts-ignore
          await props.form.propsform.onSubmit(e);
        } catch {}

        form.setIsLoading(false);
      })}
    >
      {/* Prevent implicit submission of the form */}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type='submit'
        disabled
        style={{ display: 'none' }}
        aria-hidden='true'
      />
      {children}
    </form>
  );
}

function useBoundForm<T extends object>(propsform: FormProps<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const { register, control, handleSubmit, reset } = useForm<T>();
  //   const Checkbox = Cbox;
  //   const MultiSel = Multi;

  const onSubmit = (callback?: (ecb: T) => void) => {
    return handleSubmit(async (e) => {
      // @ts-ignore
      if (callback) {
        // @ts-ignore
        const result = callback(e);
        // @ts-ignore
        if (result === false) {
          return;
        }
      }
      setIsLoading(true);
      try {
        // @ts-ignore
        await propsform.onSubmit(e);
      } catch {}
      setIsLoading(false);
    }); // firstName and lastName will have correct type
  };

  const form: Form<T> = {
    register,
    control,
    propsform,
    handleSubmit,
    isLoading,
    setIsLoading,
    onSubmit,
    reset: () => reset()
  };

  return {
    form,
    Checkbox: Cbox,
    MultiSelect: Multi,
    SearchableSingleSelect: SingleSearch,
    FormContainer,
    Input,
    DisplayErrors,
    TextArea: Text,
    RadioGroup: RadioGroupWrapper,
    Dropdown: DropdownWrapper,
    Submit,
    isLoading
  };
}

export { useBoundForm };
