const { host } = window.location;
let browserEnv = '';
if (host.startsWith('localhost')) {
  browserEnv = 'local';
} else if (host.split('.')[0].endsWith('-minside-d')) {
  browserEnv = 'dev';
} else if (host.split('.')[0].endsWith('-minside-t') || host.split('.')[1].endsWith('test')) {
  browserEnv = 'test';
} else {
  browserEnv = 'production';
}

interface IEnvironment {
  ApiBaseUrl: string;
  MsalClientId: string;
  MsalScope: string;
  Current: 'local' | 'dev' | 'test' | 'production';
  MsalInternalUserAuthority: string;
  MsalExternalUserAuthority: string;
}

let env: IEnvironment | undefined;

if (browserEnv === 'local' || browserEnv === 'dev')
  env = {
    ApiBaseUrl:
      browserEnv === 'local' ? 'https://localhost:5001' : 'https://adgangapi-minside-d.miljodirektoratet.no',
    MsalClientId: '4830dc39-5776-4bd3-930e-f3b36af56cfb',
    MsalScope: 'https://miljodirb2cdev.onmicrosoft.com/4830dc39-5776-4bd3-930e-f3b36af56cfb/default',
    MsalInternalUserAuthority:
      'https://miljodirb2cdev.b2clogin.com/miljodirb2cdev.onmicrosoft.com/B2C_1A_signup_signin_internal_user',
    MsalExternalUserAuthority:
      'https://miljodirb2cdev.b2clogin.com/miljodirb2cdev.onmicrosoft.com/B2C_1A_signup_signin_external_user',
    Current: browserEnv
  };
else if (browserEnv === 'test')
  env = {
    ApiBaseUrl: 'https://adgangapi-minside-t.miljodirektoratet.no',
    MsalClientId: 'ca398e0d-c3d6-44c5-bb12-b323971f9e4e',
    MsalScope: 'https://miljodirb2ctest.onmicrosoft.com/ca398e0d-c3d6-44c5-bb12-b323971f9e4e/default',
    MsalInternalUserAuthority:
      'https://miljodirb2ctest.b2clogin.com/miljodirb2ctest.onmicrosoft.com/B2C_1A_signup_signin_internal_user',
    MsalExternalUserAuthority:
      'https://miljodirb2ctest.b2clogin.com/miljodirb2ctest.onmicrosoft.com/B2C_1A_signup_signin_external_user',
    Current: browserEnv
  };
else if (browserEnv === 'production')
  env = {
    ApiBaseUrl: 'https://adgangapi-minside-p.miljodirektoratet.no',
    MsalClientId: 'd5a6e72e-a431-44de-b956-e97c7868c101',
    MsalScope:
      'https://miljodirb2cprod.onmicrosoft.com/d1e2d0aa-1adb-4d51-8461-fcce48acdc0c/adgang.api.default',
    MsalInternalUserAuthority:
      'https://miljodirb2cprod.b2clogin.com/miljodirb2cprod.onmicrosoft.com/B2C_1A_signup_signin_internal_user',
    MsalExternalUserAuthority:
      'https://miljodirb2cprod.b2clogin.com/miljodirb2cprod.onmicrosoft.com/B2C_1A_signup_signin_external_user',
    Current: browserEnv
  };

if (env === undefined) throw new Error('Environment variables not set.');

const Environment = env;

export default Environment;
