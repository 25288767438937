import React, { FunctionComponent } from 'react';
import Table from 'react-bootstrap/Table';
import { Pagination } from '../uiElements/pagination/Pagination';
import styles from './Table.scss';

interface Props {
  startIndex: number;
  totalHits: number;
  pageSize: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
  renderRowCount?: (rowsNr: number) => string;
  noRowsFoundText?: string;
  previousText?: string;
  nextText?: string;
  ofText?: string;
}

const PagedTable: FunctionComponent<Props> = ({
  children,
  onNextClick,
  onPreviousClick,
  totalHits,
  pageSize,
  startIndex,
  renderRowCount,
  noRowsFoundText,
  previousText,
  nextText,
  ofText
}) => (
  <div>
    <Table className={styles.table}>
      <caption>
        {!renderRowCount
          ? `Viser ${totalHits} rader`
          : renderRowCount(totalHits)}
      </caption>
      {children}
    </Table>
    {totalHits ? (
      <div style={{ maxWidth: '300px' }}>
        <Pagination
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          startIndex={startIndex}
          totalHits={totalHits}
          pageSize={pageSize}
          previousText={previousText}
          nextText={nextText}
          ofText={ofText}
        />
      </div>
    ) : (
      <p style={{ textAlign: 'center' }}>
        {' '}
        {noRowsFoundText || 'Ingen data ble funnet'}
      </p>
    )}
  </div>
);

export { PagedTable };
