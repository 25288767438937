import React from 'react';
// import SvgElement from '../svgElement/SvgElement'
import LoadingIcon from '../../../assets/icons/icon-loading-green.svg';

import utilStyles from '../../../styles/_utilities.scss';
import styles from './_loadingSpinner.scss';

const LoadingSpinner = ({ classes }: any) => (
  <div className={`${styles['c_loading-spinner']} ${classes || ''}`}>
    <LoadingIcon />
    <span className={utilStyles.u_visuallyhidden}>Laster. Vennligst vent</span>
  </div>
);

export { LoadingSpinner };
