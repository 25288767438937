/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddUserRoleRequest
 */
export interface AddUserRoleRequest {
    /**
     * 
     * @type {number}
     * @memberof AddUserRoleRequest
     */
    roleId?: number;
    /**
     * 
     * @type {number}
     * @memberof AddUserRoleRequest
     */
    organizationId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddUserRoleRequest
     */
    notifyUserByEmail?: boolean;
}

export function AddUserRoleRequestFromJSON(json: any): AddUserRoleRequest {
    return AddUserRoleRequestFromJSONTyped(json, false);
}

export function AddUserRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddUserRoleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'notifyUserByEmail': !exists(json, 'notifyUserByEmail') ? undefined : json['notifyUserByEmail'],
    };
}

export function AddUserRoleRequestToJSON(value?: AddUserRoleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'organizationId': value.organizationId,
        'notifyUserByEmail': value.notifyUserByEmail,
    };
}


