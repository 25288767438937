import { InternalUserDetailResponse } from 'api/adgang/models';

export type IdPortenInfo = {
  userId: string;
  firstName?: string;
  lastName?: string;
  epost?: string;
};

export function toIdPortenInfo(user: InternalUserDetailResponse): IdPortenInfo | undefined {
  if (user?.isIdPortenUser) {
    return {
      userId: user.userId || '',
      firstName: user.givenName || undefined,
      lastName: user.surname || undefined,
      epost: user.mail || undefined
    };
  }

  const idPortenUser = user?.userAliases?.find((x) => x.isIdPortenUser);
  if (idPortenUser?.id) {
    return {
      userId: idPortenUser.id || '',
      firstName: idPortenUser.firstname || undefined,
      lastName: idPortenUser.surname || undefined,
      epost: idPortenUser.mail || undefined
    };
  }
  return undefined;
}
