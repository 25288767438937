import React from 'react';
import Page from 'components/page/Page';
import { RoleUpdateRequest } from 'api/adgang/models';
import { LoadingSpinner, useFetch } from 'common.ui';

import RoleForm from 'components/roles/RoleForm';
import { rolesClient } from 'api/AdgangClients';
import { useParams } from 'react-router-dom';

interface IParams {
  id: string;
}

function UpdateRole() {
  const { id } = useParams<IParams>();

  const [role] = useFetch(async () => rolesClient.getRolebyId({ id: +id }), undefined, false, [id]);

  async function onSave(model: RoleUpdateRequest) {
    await rolesClient.apiRolesIdPut({
      id: +id,
      roleUpdateRequest: model
    });
  }

  return (
    <Page header='Detaljer om rolle'>
      {role ? (
        <RoleForm<RoleUpdateRequest>
          onSave={onSave}
          initialValue={role}
          onSaveLabel='Lagre'
          isUpdate
          resourceBasedAcl={role.accessControls ?? []}
          isUnlocksExternalApproverRightsRole={role.unlocksExternalApproverRights}
        />
      ) : (
        <LoadingSpinner />
      )}
    </Page>
  );
}

export default UpdateRole;
