/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationFilterRequest,
    ApplicationFilterRequestFromJSON,
    ApplicationFilterRequestFromJSONTyped,
    ApplicationFilterRequestToJSON,
    ResourceFilter,
    ResourceFilterFromJSON,
    ResourceFilterFromJSONTyped,
    ResourceFilterToJSON,
    RoleSearchOrderByPaginationQuery,
    RoleSearchOrderByPaginationQueryFromJSON,
    RoleSearchOrderByPaginationQueryFromJSONTyped,
    RoleSearchOrderByPaginationQueryToJSON,
    RoleSearchSortingQuery,
    RoleSearchSortingQueryFromJSON,
    RoleSearchSortingQueryFromJSONTyped,
    RoleSearchSortingQueryToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoleSearchRequest
 */
export interface RoleSearchRequest {
    /**
     * 
     * @type {ApplicationFilterRequest}
     * @memberof RoleSearchRequest
     */
    applicationFilter?: ApplicationFilterRequest;
    /**
     * 
     * @type {RoleSearchOrderByPaginationQuery}
     * @memberof RoleSearchRequest
     */
    paginationQuery?: RoleSearchOrderByPaginationQuery;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleSearchRequest
     */
    permissionIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleSearchRequest
     */
    roleIds?: Array<number> | null;
    /**
     * 
     * @type {RoleSearchSortingQuery}
     * @memberof RoleSearchRequest
     */
    sortingQuery?: RoleSearchSortingQuery;
    /**
     * 
     * @type {string}
     * @memberof RoleSearchRequest
     */
    textFilter?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof RoleSearchRequest
     */
    userType?: UserType;
    /**
     * 
     * @type {ResourceFilter}
     * @memberof RoleSearchRequest
     */
    resourceFilter?: ResourceFilter;
}

export function RoleSearchRequestFromJSON(json: any): RoleSearchRequest {
    return RoleSearchRequestFromJSONTyped(json, false);
}

export function RoleSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationFilter': !exists(json, 'applicationFilter') ? undefined : ApplicationFilterRequestFromJSON(json['applicationFilter']),
        'paginationQuery': !exists(json, 'paginationQuery') ? undefined : RoleSearchOrderByPaginationQueryFromJSON(json['paginationQuery']),
        'permissionIds': !exists(json, 'permissionIds') ? undefined : json['permissionIds'],
        'roleIds': !exists(json, 'roleIds') ? undefined : json['roleIds'],
        'sortingQuery': !exists(json, 'sortingQuery') ? undefined : RoleSearchSortingQueryFromJSON(json['sortingQuery']),
        'textFilter': !exists(json, 'textFilter') ? undefined : json['textFilter'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'resourceFilter': !exists(json, 'resourceFilter') ? undefined : ResourceFilterFromJSON(json['resourceFilter']),
    };
}

export function RoleSearchRequestToJSON(value?: RoleSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationFilter': ApplicationFilterRequestToJSON(value.applicationFilter),
        'paginationQuery': RoleSearchOrderByPaginationQueryToJSON(value.paginationQuery),
        'permissionIds': value.permissionIds,
        'roleIds': value.roleIds,
        'sortingQuery': RoleSearchSortingQueryToJSON(value.sortingQuery),
        'textFilter': value.textFilter,
        'userType': UserTypeToJSON(value.userType),
        'resourceFilter': ResourceFilterToJSON(value.resourceFilter),
    };
}


