/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserAccessType,
    UserAccessTypeFromJSON,
    UserAccessTypeFromJSONTyped,
    UserAccessTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApprovedAccessResponse
 */
export interface ApprovedAccessResponse {
    /**
     * 
     * @type {string}
     * @memberof ApprovedAccessResponse
     */
    userDisplayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAccessResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAccessResponse
     */
    userEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAccessResponse
     */
    userMfaPhone?: string | null;
    /**
     * 
     * @type {UserAccessType}
     * @memberof ApprovedAccessResponse
     */
    accessType?: UserAccessType;
    /**
     * 
     * @type {number}
     * @memberof ApprovedAccessResponse
     */
    accessId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ApprovedAccessResponse
     */
    assignedOn?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAccessResponse
     */
    accessName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAccessResponse
     */
    applicationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAccessResponse
     */
    organizationName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprovedAccessResponse
     */
    organizationId?: number | null;
}

export function ApprovedAccessResponseFromJSON(json: any): ApprovedAccessResponse {
    return ApprovedAccessResponseFromJSONTyped(json, false);
}

export function ApprovedAccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprovedAccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userDisplayName': !exists(json, 'userDisplayName') ? undefined : json['userDisplayName'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userEmail': !exists(json, 'userEmail') ? undefined : json['userEmail'],
        'userMfaPhone': !exists(json, 'userMfaPhone') ? undefined : json['userMfaPhone'],
        'accessType': !exists(json, 'accessType') ? undefined : UserAccessTypeFromJSON(json['accessType']),
        'accessId': !exists(json, 'accessId') ? undefined : json['accessId'],
        'assignedOn': !exists(json, 'assignedOn') ? undefined : (json['assignedOn'] === null ? null : new Date(json['assignedOn'])),
        'accessName': !exists(json, 'accessName') ? undefined : json['accessName'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'organizationName': !exists(json, 'organizationName') ? undefined : json['organizationName'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
    };
}

export function ApprovedAccessResponseToJSON(value?: ApprovedAccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userDisplayName': value.userDisplayName,
        'userId': value.userId,
        'userEmail': value.userEmail,
        'userMfaPhone': value.userMfaPhone,
        'accessType': UserAccessTypeToJSON(value.accessType),
        'accessId': value.accessId,
        'assignedOn': value.assignedOn === undefined ? undefined : (value.assignedOn === null ? null : value.assignedOn.toISOString()),
        'accessName': value.accessName,
        'applicationName': value.applicationName,
        'organizationName': value.organizationName,
        'organizationId': value.organizationId,
    };
}


