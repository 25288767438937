import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import SvgElement from '../../uiElements/svgElement/SvgElement'
import { getFormInputClassNames } from '../sharedFormFunctions';
import styles1 from './_checkbox.scss';
import styles2 from '../../../styles/_utilities.scss';
import Checkmark from '../../../assets/icons/checkmark-green.svg';

interface IProps {
  questionText?: string;
  labelText?: string;
  disabled?: boolean;
  id: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  style?: React.CSSProperties;
  invalid?: boolean;
  invalidText?: string | null;
  labelContent?: React.ReactNode;
}

const classNames = (names: string) => {
  const mapped = names.split(' ').map((c) => styles1[c] || styles2[c]);
  return mapped.join(' ');
};

// TODO: useBoundForm.Checkbox has an inconsistent behaviour ([], true, 'on')
const Checkbox = React.forwardRef((props: IProps, ref: any) => {
  let currentref: any = null;
  const [checked, setChecked] = useState(props.checked || false);
  const { onChange, value, style } = props;
  //   console.log(props.checked, { checked });
  return (
    <div
      style={style}
      className={classNames(getFormInputClassNames('c_checkbox', props))}
    >
      {props.questionText && props.questionText.length > 0 && (
        <span className={classNames('c_checkbox__question-text')}>
          {props.questionText}
        </span>
      )}
      {props.invalid && (
        <div className={classNames('c_checkbox__invalid-text')}>
          {props.invalidText}
        </div>
      )}
      <input
        id={props.id}
        className={classNames('c_checkbox__input TEST')}
        type='checkbox'
        name={props.id}
        disabled={props.disabled}
        value={value ?? 'on'}
        ref={
          ref
            ? (r) => {
                ref(r);
                // eslint-disable-next-line no-param-reassign
                if (r) r.checked = checked;
                currentref = r;
              }
            : undefined
        }
        onChange={(e) => {
          if (currentref) {
            setChecked(currentref.checked);
          } else {
            setChecked(e.target.checked);
          }
          if (onChange) {
            onChange(e);
          }
        }}
      />
      <label htmlFor={props.id}>
        <span
          className={classNames(
            `c_checkbox__check-area ${
              props.disabled && 'c_checkbox__check-area--disabled'
            }`
          )}
        >
          {checked && (
            <Checkmark />
            // <SvgElement
            //   src={checkmark}
            //   title='Avhukning'
            //   desc='Valg er avhuket'
            // />
          )}
        </span>
        <span
          className={classNames('c_checkbox__label-text')}
          // @ts-ignore
          style={props.labelStyles}
        >
          {props.labelText}
        </span>
        {props.labelContent && (
          <div className={classNames('c_checkbox__label-content')}>
            {props.labelContent}
          </div>
        )}
      </label>
    </div>
  );
});

// Checkbox.propTypes = {
//   id: PropTypes.string.isRequired,
//   labelText: PropTypes.string.isRequired,
//   questionText: PropTypes.string,
//   checked: PropTypes.bool,
//   disabled: PropTypes.bool,
//   onChange: PropTypes.func.isRequired,
//     className: PropTypes.string // eslint-disable-line
// };

// Checkbox.defaultProps = {
//   checked: false,
//   disabled: false
// };

export { Checkbox };
