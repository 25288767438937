/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationFilterRequest,
    ApplicationFilterRequestFromJSON,
    ApplicationFilterRequestFromJSONTyped,
    ApplicationFilterRequestToJSON,
    ExternalUserSearchOrderByPaginationQuery,
    ExternalUserSearchOrderByPaginationQueryFromJSON,
    ExternalUserSearchOrderByPaginationQueryFromJSONTyped,
    ExternalUserSearchOrderByPaginationQueryToJSON,
    ExternalUserStatus,
    ExternalUserStatusFromJSON,
    ExternalUserStatusFromJSONTyped,
    ExternalUserStatusToJSON,
    PermissionFilterRequest,
    PermissionFilterRequestFromJSON,
    PermissionFilterRequestFromJSONTyped,
    PermissionFilterRequestToJSON,
    RoleFilterRequest,
    RoleFilterRequestFromJSON,
    RoleFilterRequestFromJSONTyped,
    RoleFilterRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalUserSearchRequest
 */
export interface ExternalUserSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalUserSearchRequest
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserSearchRequest
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserSearchRequest
     */
    organizationName?: string | null;
    /**
     * 
     * @type {ExternalUserSearchOrderByPaginationQuery}
     * @memberof ExternalUserSearchRequest
     */
    paginationQuery?: ExternalUserSearchOrderByPaginationQuery;
    /**
     * 
     * @type {Array<number>}
     * @memberof ExternalUserSearchRequest
     */
    organizationGroupIds?: Array<number> | null;
    /**
     * 
     * @type {Array<ExternalUserStatus>}
     * @memberof ExternalUserSearchRequest
     */
    statusFilter?: Array<ExternalUserStatus> | null;
    /**
     * 
     * @type {RoleFilterRequest}
     * @memberof ExternalUserSearchRequest
     */
    roleFilter?: RoleFilterRequest;
    /**
     * 
     * @type {PermissionFilterRequest}
     * @memberof ExternalUserSearchRequest
     */
    permissionFilter?: PermissionFilterRequest;
    /**
     * 
     * @type {ApplicationFilterRequest}
     * @memberof ExternalUserSearchRequest
     */
    applicationFilter?: ApplicationFilterRequest;
}

export function ExternalUserSearchRequestFromJSON(json: any): ExternalUserSearchRequest {
    return ExternalUserSearchRequestFromJSONTyped(json, false);
}

export function ExternalUserSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalUserSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'organizationName': !exists(json, 'organizationName') ? undefined : json['organizationName'],
        'paginationQuery': !exists(json, 'paginationQuery') ? undefined : ExternalUserSearchOrderByPaginationQueryFromJSON(json['paginationQuery']),
        'organizationGroupIds': !exists(json, 'organizationGroupIds') ? undefined : json['organizationGroupIds'],
        'statusFilter': !exists(json, 'statusFilter') ? undefined : (json['statusFilter'] === null ? null : (json['statusFilter'] as Array<any>).map(ExternalUserStatusFromJSON)),
        'roleFilter': !exists(json, 'roleFilter') ? undefined : RoleFilterRequestFromJSON(json['roleFilter']),
        'permissionFilter': !exists(json, 'permissionFilter') ? undefined : PermissionFilterRequestFromJSON(json['permissionFilter']),
        'applicationFilter': !exists(json, 'applicationFilter') ? undefined : ApplicationFilterRequestFromJSON(json['applicationFilter']),
    };
}

export function ExternalUserSearchRequestToJSON(value?: ExternalUserSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstname': value.firstname,
        'surname': value.surname,
        'organizationName': value.organizationName,
        'paginationQuery': ExternalUserSearchOrderByPaginationQueryToJSON(value.paginationQuery),
        'organizationGroupIds': value.organizationGroupIds,
        'statusFilter': value.statusFilter === undefined ? undefined : (value.statusFilter === null ? null : (value.statusFilter as Array<any>).map(ExternalUserStatusToJSON)),
        'roleFilter': RoleFilterRequestToJSON(value.roleFilter),
        'permissionFilter': PermissionFilterRequestToJSON(value.permissionFilter),
        'applicationFilter': ApplicationFilterRequestToJSON(value.applicationFilter),
    };
}


