import {
  InternalUsersApi,
  ApplicationsApi,
  AccessRequestsApi,
  RolesApi,
  PermissionsApi,
  OrganizationGroupsApi,
  UsersApi,
  ExternalUsersApi,
  SectionsApi,
  MeApi,
  AdGroupsApi,
  DevToolsApi,
  TranslationsApi,
  UserEventExpiryApi,
  LegalEntitiesApi,
  ExternalUserEmployerApi
} from './adgang';
import { Configuration } from './adgang/runtime';
import AddTokenMiddleware from './AddTokenMiddleware';
import AccessControlListMiddleware from './AccessControlListMiddleware';
import ApiExceptionMiddleware from './ApiExceptionMiddleware';
import Environment from '../Environment';

const config = new Configuration({
  basePath: Environment.ApiBaseUrl,
  middleware: [new AddTokenMiddleware(), new AccessControlListMiddleware(), new ApiExceptionMiddleware()]
});

const accessRequestsClient = new AccessRequestsApi(config);
const adgroupsClient = new AdGroupsApi(config);
const applicationsClient = new ApplicationsApi(config);
const devToolsApi = new DevToolsApi(config);
const externalUsersClient = new ExternalUsersApi(config);
const internalUsersClient = new InternalUsersApi(config);
const meClient = new MeApi(config);
const organizationGroupsClient = new OrganizationGroupsApi(config);
const permissionsClient = new PermissionsApi(config);
const rolesClient = new RolesApi(config);
const sectionsClient = new SectionsApi(config);
const translationsClient = new TranslationsApi(config);
const usersClient = new UsersApi(config);
const userEventExpiryClient = new UserEventExpiryApi(config);
const legalEntitiesClient = new LegalEntitiesApi(config);
const externalUserEmployerClient = new ExternalUserEmployerApi(config);

export {
  accessRequestsClient,
  adgroupsClient,
  applicationsClient,
  devToolsApi,
  externalUsersClient,
  internalUsersClient,
  meClient,
  organizationGroupsClient,
  permissionsClient,
  rolesClient,
  sectionsClient,
  translationsClient,
  usersClient,
  userEventExpiryClient,
  legalEntitiesClient,
  externalUserEmployerClient
};
