import React, { FunctionComponent } from 'react';
import Table from 'react-bootstrap/Table';

import styles from './Table.scss';

interface Props {
  totalHits?: number;
}

const Table2: FunctionComponent<Props> = ({ children, totalHits }) => (
  <div>
    <Table className={styles.table}>
      {totalHits !== undefined && <caption>Viser {totalHits} rader</caption>}
      {children}
    </Table>
    {totalHits !== undefined && totalHits <= 0 && (
      <p style={{ textAlign: 'center' }}>Ingen data ble funnet</p>
    )}
  </div>
);

export { Table2 as Table };
