import React, { useEffect } from 'react';
import { PagedTable, LoadingSpinner, useFetch, ThSortable, useSortedPaging } from 'common.ui';
import { Link } from 'react-router-dom';
import {
  ApiSectionsGetRequest,
  PermissionResponse,
  RoleResponse,
  SectionDetailResponsePagedResult,
  SectionSearchOrderBy,
  SortOrder
} from 'api/adgang';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import { sectionsClient } from 'api/AdgangClients';
import RoutePaths from 'RoutePaths';
import { GlobalApplicationName } from 'helpers/application/consts';

interface ISectionsTable {
  textFilter: string;
}

function SectionsTable({ textFilter }: ISectionsTable) {
  useEffect(() => setCurrentPage(1), [textFilter]);

  const [sectionsPaged, isLoading, , fetchSections] = useFetch<SectionDetailResponsePagedResult>(
    () => fetchPagedSections(),
    {},
    false,
    [textFilter]
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    setCurrentPage,
    setSorting
  ] = useSortedPaging<SectionSearchOrderBy>(fetchSections, {
    field: SectionSearchOrderBy.SectionCode,
    direction: 'asc'
  });

  const fetchPagedSections = async () => {
    const request = {
      filter: textFilter,
      pageSize,
      pageNumber: currentPage,
      sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
      orderField: sorting.field
    } as ApiSectionsGetRequest;
    return sectionsClient.apiSectionsGet(request);
  };

  const handleSort = (e: SortEvent<SectionSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <PagedTable
          startIndex={startIndex}
          totalHits={sectionsPaged.pageInfo?.totalCount ?? 0}
          pageSize={pageSize}
          onNextClick={onNextPage}
          onPreviousClick={onPreviousPage}
        >
          <thead>
            <tr>
              <ThSortable
                title='Navn'
                field={SectionSearchOrderBy.SectionCode}
                currentSort={sorting}
                onSort={handleSort}
              />
              <ThSortable
                title='Tilknyttede roller'
                field={SectionSearchOrderBy.Roles}
                currentSort={sorting}
                onSort={handleSort}
              />
              <ThSortable
                title='Tilknyttede tilganger'
                field={SectionSearchOrderBy.Permissions}
                currentSort={sorting}
                onSort={handleSort}
              />
            </tr>
          </thead>

          <tbody>
            {sectionsPaged.data?.map((s) => (
              <tr key={s.sectionCode ?? ''}>
                <td>
                  <Link to={`${RoutePaths.sections}/${s.sectionCode}`}>
                    {s.sectionCode} - {s.sectionName}
                  </Link>
                </td>
                <td>
                  {s.roles
                    ?.sort(
                      (r1: RoleResponse, r2: RoleResponse) =>
                        (r1.applicationName || '').localeCompare(r2.applicationName || '') ||
                        (r1.name || '').localeCompare(r2.name || '')
                    )
                    .map((r: RoleResponse) => `${r.name} (${r.applicationName || GlobalApplicationName})`)
                    .join(', ')}
                </td>
                <td>
                  {s.permissions
                    ?.sort(
                      (p1: PermissionResponse, p2: PermissionResponse) =>
                        (p1.applicationName || '').localeCompare(p2.applicationName || '') ||
                        (p1.name || '').localeCompare(p2.name || '')
                    )
                    .map(
                      (p: PermissionResponse) => `${p.name} (${p.applicationName || GlobalApplicationName})`
                    )
                    .join(', ')}
                </td>
              </tr>
            ))}
          </tbody>
        </PagedTable>
      )}
    </>
  );
}

export default SectionsTable;
