import { useState, useEffect } from 'react';
import { applicationsClient } from 'api/AdgangClients';
import { SelectOption } from 'common.ui';
import {
  ApiApplicationsApplicationResponsiblePersonsActivePostRequest,
  ApplicationResponsiblePersonSearchOrderBy,
  SortOrder
} from 'api/adgang';

export function useApplicationActiveResponsibles(applicationIds?: string[]): [SelectOption[]] {
  const [responsibles, setResponsibles] = useState<SelectOption[]>([]);

  useEffect(() => {
    const load = async () => {
      const request = {
        applicationResponsiblePersonSearchRequest: {
          paginationQuery: {
            pageNumber: 1,
            pageSize: 9999,
            sortOrder: SortOrder.Ascending,
            orderField: ApplicationResponsiblePersonSearchOrderBy.Name
          },
          applicationIds
        }
      } as ApiApplicationsApplicationResponsiblePersonsActivePostRequest;
      const response = await applicationsClient.apiApplicationsApplicationResponsiblePersonsActivePost(
        request
      );
      const items = response.data || [];

      setResponsibles(
        items
          .sort((u1, u2) => (u1.name || '').localeCompare(u2.name || ''))
          .map((u) => {
            return {
              id: u.userId,
              text: u.name
            } as SelectOption;
          })
      );
    };

    load();
  }, [applicationIds]);

  return [responsibles];
}
