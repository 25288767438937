import React, { useState } from 'react';
// import PropTypes from 'prop-types'
import { getFormInputClassNames } from '../sharedFormFunctions';
import styles1 from './_textInput.scss';
import styles2 from '../../../styles/_utilities.scss';

const styles = (cN: string) => {
  const mapped = cN.split(' ').map((c) => styles1[c] || styles2[c]);
  return mapped.join(' ');
};

export type TextInputType = 'text' | 'password';
export type ITextInput = {
  id: string;
  type?: TextInputType;
  labelText?: string;
  hideLabelText?: boolean;
  value?: string | null | undefined;
  invalid?: boolean;
  invalidText?: string | null | undefined;
  disabled?: boolean;
  placeholder?: string;

  enterPressed?: (value?: string | null | undefined) => void;
  onChange?: (value?: string | null | undefined) => void;
};

const TextInput = React.forwardRef((props: ITextInput, ref: any) => {
  const [value, setValue] = useState(props.value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode !== 13 || !props.enterPressed) {
      return;
    }
    props.enterPressed(value);
  };

  return (
    <label
      htmlFor={props.id}
      className={styles(getFormInputClassNames('c_text-input', props))}
    >
      <span
        className={styles(
          `c_text-input__label-text ${
            props.hideLabelText && 'u_visuallyhidden'
          }`
        )}
      >
        {props.labelText}
      </span>
      {props.invalid && (
        <span className={styles('c_text-input__invalid-text')}>
          {props.invalidText}
        </span>
      )}
      <input
        type={props.type || 'text'}
        name={props.id}
        ref={ref}
        disabled={props.disabled}
        defaultValue={props.value || undefined}
        //   onChange={(e) => props.onChange(e)}
        //   onBlur={props.onBlur ? (e) => props.onBlur(e) : null}
        //   onFocus={props.onFocus ? (e) => props.onFocus(e) : null}
        placeholder={props.placeholder || undefined}
        onKeyUp={handleKeyUp}
        onChange={handleChange}
      />
    </label>
  );
});

// TextInput.propTypes = {
//   id: PropTypes.string.isRequired,
//   type: PropTypes.string,
//   labelText: PropTypes.string.isRequired,
//   hideLabelText: PropTypes.bool,
//   value: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
//   onBlur: PropTypes.func,
//   onFocus: PropTypes.func,
//     className: PropTypes.string, // eslint-disable-line
//   placeholder: PropTypes.string,
//   invalid: PropTypes.bool,
//   invalidText: PropTypes.string
// }

// TextInput.defaultProps = {
//   type: 'text',
//   hideLabelText: false,
//   invalid: false,
//   invalidText: 'Invalid'
// }

export { TextInput };
