import Environment from 'Environment';
import { Configuration, Middleware, RequestContext, FetchParams } from './adgang/runtime';
import { MeApi } from './adgang';
import { AdgangAccessControl } from './adgang/models';
import AddTokenMiddleware from './AddTokenMiddleware';

export type AclConfig = {
  jwtTokenSignature: string;
  acl: AdgangAccessControl[];
};

type AclConfigContainer = {
  config?: AclConfig;
};

const userAcl: AclConfigContainer = {};
let userAclTask: Promise<AdgangAccessControl[]> | undefined;
class AccessControlListMiddleware implements Middleware {
  // eslint-disable-next-line class-methods-use-this
  async pre?(context: RequestContext): Promise<FetchParams | void> {
    // @ts-ignore
    const token = context.init.headers?.Authorization;
    let tokenSignature = '';
    if (token) {
      const tokenSplit = token.split('.');
      tokenSignature = tokenSplit[tokenSplit.length - 1];
    }

    if (userAcl.config && userAcl.config.jwtTokenSignature !== tokenSignature) {
      userAcl.config = undefined;
    }
    if (userAcl.config) {
      return;
    }
    const config = new Configuration({
      basePath: Environment.ApiBaseUrl,
      middleware: [new AddTokenMiddleware()]
    });
    const api = new MeApi(config);
    if (!userAclTask) {
      userAclTask = api.apiMeAccessControlListGet();
    }
    const acl = await userAclTask;
    userAcl.config = {
      acl,
      jwtTokenSignature: tokenSignature
    };
    userAclTask = undefined;
    return context;
  }
}

export { userAcl };
export default AccessControlListMiddleware;
