import { SelectOption } from 'common.ui';
import { applicationsClient } from 'api/AdgangClients';
import { useEffect, useState } from 'react';

export function useApplicationResponsibles(): [SelectOption[] | undefined] {
  const [applicationResponsibles, setApplicationResponsibles] = useState<SelectOption[] | undefined>();

  const loadUsers = async () => {
    const response = await applicationsClient.apiApplicationsApplicationResponsiblePersonsCandidatesGet();
    const items = response || [];

    const result = items
      .sort((u1, u2) => (u1.name || '').localeCompare(u2.name || ''))
      .map((u) => {
        return {
          id: u.userId,
          text: u.name
        } as SelectOption;
      });

    setApplicationResponsibles(result);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return [applicationResponsibles];
}
