import React from 'react';

import { UserAccessAndClaimsResponse } from 'api/adgang/models';
import JSONPretty from 'react-json-pretty';
import './UserClaimsList.scss';

interface IProps {
  claims: UserAccessAndClaimsResponse[];
  userId: string;
}

export function UserClaimsList({ claims, userId }: IProps) {
  return (
    <>
      <pre>UserId: {userId}</pre>

      {claims.map((c) => (
        <>
          <b>{c.applicationName}</b>
          <br />
          <pre>ClientId: {c.clientId}</pre>
          <JSONPretty key={c.applicationName ?? ''} id='json-pretty' data={c.userClaims ?? {}} />
        </>
      ))}
    </>
  );
}
