import React, { useState } from 'react';
import Page from 'components/page/Page';
import { useAccessControlList } from 'hooks/access/useAccessControlList';
import { AdgangAccessControl } from 'api/adgang';
import { Button, LoadingSpinner } from 'common.ui';
import { devToolsApi } from 'api/AdgangClients';
import { useMsal } from 'auth/MsalContext';

function DevTools() {
  const [, hasAccess] = useAccessControlList();
  const { logout } = useMsal();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onMakeAdmin = async () => {
    setIsLoading(true);
    try {
      const resp = await devToolsApi.apiDevToolsAdgangAdministratorRightsPost();
      if (resp && resp.length > 0) {
        setIsLoading(false);
        logout();
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      setIsLoading(false);
    }
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <Page header='Test verktøy'>
      {hasAccess(AdgangAccessControl.DevAllowAssignSysAdmin) && (
        <div>
          <Button onClick={onMakeAdmin} text='Tildel system administrator rolle' />
        </div>
      )}
    </Page>
  );
}

export default DevTools;
