/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, DependencyList } from 'react';

export function useFetch<T>(
  fetchMethod: (...args: any[]) => Promise<T>,
  initialState: T,
  executeOnInit: boolean = true,
  dependencies: DependencyList = [] as DependencyList
): [T, boolean, any, () => Promise<void>] {
  const [data, setData] = useState<T>(initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(() => {});

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(undefined);
      const result = await fetchMethod();
      setData(result);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (executeOnInit) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [...dependencies]);

  return [data, isLoading, error, fetchData];
}
