/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Language,
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
    TranslationGroupType,
    TranslationGroupTypeFromJSON,
    TranslationGroupTypeFromJSONTyped,
    TranslationGroupTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TranslationUpdateRequest
 */
export interface TranslationUpdateRequest {
    /**
     * 
     * @type {Language}
     * @memberof TranslationUpdateRequest
     */
    languageId?: Language;
    /**
     * 
     * @type {TranslationGroupType}
     * @memberof TranslationUpdateRequest
     */
    groupType?: TranslationGroupType;
    /**
     * 
     * @type {number}
     * @memberof TranslationUpdateRequest
     */
    groupKey?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationUpdateRequest
     */
    text?: string | null;
}

export function TranslationUpdateRequestFromJSON(json: any): TranslationUpdateRequest {
    return TranslationUpdateRequestFromJSONTyped(json, false);
}

export function TranslationUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranslationUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'languageId': !exists(json, 'languageId') ? undefined : LanguageFromJSON(json['languageId']),
        'groupType': !exists(json, 'groupType') ? undefined : TranslationGroupTypeFromJSON(json['groupType']),
        'groupKey': !exists(json, 'groupKey') ? undefined : json['groupKey'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function TranslationUpdateRequestToJSON(value?: TranslationUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'languageId': LanguageToJSON(value.languageId),
        'groupType': TranslationGroupTypeToJSON(value.groupType),
        'groupKey': value.groupKey,
        'text': value.text,
    };
}


