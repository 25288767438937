const mapToLocationName = (locationCode: string | null | undefined) => {
  switch (locationCode) {
    case 'OSLO': {
      return 'Oslo';
    }
    case 'TRD': {
      return 'Trondheim';
    }
    default: {
      return undefined;
    }
  }
};

export function toFullname(locationCode: string | null | undefined) {
  const locationName = mapToLocationName(locationCode);

  if (!locationName) {
    return locationCode;
  }

  return `${locationName}`;
}
