import React from 'react';
import { SidebarItem, Sidebar } from './Sidebar';
import './Sidenav.scss';

type IProp = {
  routes: SidebarItem[];
};

export function Sidenav({ routes }: IProp) {
  return (
    <div className='sidenav'>
      <Sidebar routes={routes} />
    </div>
  );
}
