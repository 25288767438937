/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrregSearchItem
 */
export interface BrregSearchItem {
    /**
     * 
     * @type {string}
     * @memberof BrregSearchItem
     */
    organisationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrregSearchItem
     */
    organisationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrregSearchItem
     */
    organisationCompanyType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrregSearchItem
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrregSearchItem
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrregSearchItem
     */
    postalCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrregSearchItem
     */
    municipalityNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrregSearchItem
     */
    municipality?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrregSearchItem
     */
    countyNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BrregSearchItem
     */
    county?: string | null;
}

export function BrregSearchItemFromJSON(json: any): BrregSearchItem {
    return BrregSearchItemFromJSONTyped(json, false);
}

export function BrregSearchItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrregSearchItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisationNumber': !exists(json, 'organisationNumber') ? undefined : json['organisationNumber'],
        'organisationName': !exists(json, 'organisationName') ? undefined : json['organisationName'],
        'organisationCompanyType': !exists(json, 'organisationCompanyType') ? undefined : json['organisationCompanyType'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'postalCity': !exists(json, 'postalCity') ? undefined : json['postalCity'],
        'municipalityNumber': !exists(json, 'municipalityNumber') ? undefined : json['municipalityNumber'],
        'municipality': !exists(json, 'municipality') ? undefined : json['municipality'],
        'countyNumber': !exists(json, 'countyNumber') ? undefined : json['countyNumber'],
        'county': !exists(json, 'county') ? undefined : json['county'],
    };
}

export function BrregSearchItemToJSON(value?: BrregSearchItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisationNumber': value.organisationNumber,
        'organisationName': value.organisationName,
        'organisationCompanyType': value.organisationCompanyType,
        'address': value.address,
        'postalCode': value.postalCode,
        'postalCity': value.postalCity,
        'municipalityNumber': value.municipalityNumber,
        'municipality': value.municipality,
        'countyNumber': value.countyNumber,
        'county': value.county,
    };
}


