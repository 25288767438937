/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExternalUserDetailResponse,
    ExternalUserDetailResponseFromJSON,
    ExternalUserDetailResponseToJSON,
    ExternalUserResponsePagedResult,
    ExternalUserResponsePagedResultFromJSON,
    ExternalUserResponsePagedResultToJSON,
    ExternalUserSearchRequest,
    ExternalUserSearchRequestFromJSON,
    ExternalUserSearchRequestToJSON,
    ExternalUserStatus,
    ExternalUserStatusFromJSON,
    ExternalUserStatusToJSON,
    ExternalUserStatusUpdateRequest,
    ExternalUserStatusUpdateRequestFromJSON,
    ExternalUserStatusUpdateRequestToJSON,
    ExternalUserStatusUpdateResponse,
    ExternalUserStatusUpdateResponseFromJSON,
    ExternalUserStatusUpdateResponseToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
} from '../models';

export interface ApiExternalUsersSearchesPostRequest {
    externalUserSearchRequest?: ExternalUserSearchRequest;
}

export interface ApiExternalUsersUserIdGetRequest {
    userId: string;
}

export interface ApiExternalUsersUserIdStatusPutRequest {
    userId: string;
    externalUserStatusUpdateRequest?: ExternalUserStatusUpdateRequest;
}

/**
 * 
 */
export class ExternalUsersApi extends runtime.BaseAPI {

    /**
     */
    async apiExternalUsersSearchesPostRaw(requestParameters: ApiExternalUsersSearchesPostRequest): Promise<runtime.ApiResponse<ExternalUserResponsePagedResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/external-users/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalUserSearchRequestToJSON(requestParameters.externalUserSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalUserResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalUsersSearchesPost(requestParameters: ApiExternalUsersSearchesPostRequest): Promise<ExternalUserResponsePagedResult> {
        const response = await this.apiExternalUsersSearchesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiExternalUsersStatusesGetRaw(): Promise<runtime.ApiResponse<Array<ExternalUserStatus>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external-users/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExternalUserStatusFromJSON));
    }

    /**
     */
    async apiExternalUsersStatusesGet(): Promise<Array<ExternalUserStatus>> {
        const response = await this.apiExternalUsersStatusesGetRaw();
        return await response.value();
    }

    /**
     */
    async apiExternalUsersUserIdGetRaw(requestParameters: ApiExternalUsersUserIdGetRequest): Promise<runtime.ApiResponse<ExternalUserDetailResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiExternalUsersUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external-users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalUserDetailResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalUsersUserIdGet(requestParameters: ApiExternalUsersUserIdGetRequest): Promise<ExternalUserDetailResponse> {
        const response = await this.apiExternalUsersUserIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiExternalUsersUserIdStatusPutRaw(requestParameters: ApiExternalUsersUserIdStatusPutRequest): Promise<runtime.ApiResponse<ExternalUserStatusUpdateResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiExternalUsersUserIdStatusPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/external-users/{userId}/status`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalUserStatusUpdateRequestToJSON(requestParameters.externalUserStatusUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalUserStatusUpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalUsersUserIdStatusPut(requestParameters: ApiExternalUsersUserIdStatusPutRequest): Promise<ExternalUserStatusUpdateResponse> {
        const response = await this.apiExternalUsersUserIdStatusPutRaw(requestParameters);
        return await response.value();
    }

}
