import React, { useRef } from 'react';
import { ReactComponent as IconCross } from 'assets/icons/cross-icon.svg';
import { SelectOption, MultiSelect } from 'common.ui';

type OrganizationGroupMultiSelectProps<T> = {
  options: SelectOption[];
  selectedIds: string[];
  name: string;
  label: string;
  okLabel: string;
  onChange?: (model: string[]) => void;
  onSelectionChange?: (val: string[]) => void;
  ignoreClicksOutside?: boolean;
  onClose?: () => void;
  triggerOnChangeAlways?: boolean; // Trigger onChange when closing even if values are the same
  isInitiallyOpen?: boolean; // MultiSelect is initally open and options expanded
};

function OrganizationGroupMultiSelect<T>({
  options,
  selectedIds,
  name,
  label,
  okLabel,
  triggerOnChangeAlways,
  isInitiallyOpen,
  onSelectionChange,
  onChange: onChangeCallBack,
  onClose,
  ignoreClicksOutside
}: OrganizationGroupMultiSelectProps<T>) {
  const containerRef = useRef(null);

  return (
    <div className='organisations-multiselect-container' ref={containerRef}>
      <IconCross style={{ float: 'right', width: '8px' }} onClick={onClose} className='close-multiselect' />
      <b>Filtrer utvalg på:</b>

      <MultiSelect
        label={label}
        okLabel={okLabel}
        id={name}
        triggerOnChangeAlways={triggerOnChangeAlways}
        isInitiallyOpen={isInitiallyOpen}
        ignoreClicksOutside={ignoreClicksOutside}
        onSelectionChange={onSelectionChange}
        onChange={(model: any) => {
          if (onChangeCallBack) onChangeCallBack(model);
        }}
        options={options.map((o) => ({
          id: o.id ?? '',
          text: o.text ?? ''
        }))}
        selectedIds={selectedIds}
        doNotDisplayOptionsOnTop
        wrapperRef={containerRef}
      />
    </div>
  );
}

export { OrganizationGroupMultiSelect };
