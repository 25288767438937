import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.scss';

export interface FooterItem {
  link: string;
  label?: string;
  external?: boolean;
}

type IProp = {
  centered?: boolean;
  logoImage: string;
  links?: FooterItem[];
};

export function Footer({ centered, logoImage, links }: IProp) {
  return (
    <footer>
      <div
        className={`${styles['footer-container']} ${
          centered
            ? styles['footer-container-centered']
            : styles['footer-container-max']
        }`}
      >
        <div className={styles['footer-logo']}>
          <Link to='/'>
            <div className={styles['logo-group']}>
              <div className={styles['logo-container']}>
                <img src={logoImage} className={styles['logo-image']} alt='' />
              </div>
            </div>
          </Link>
        </div>

        <div className={styles['footer-info']}>
          <p>Organisasjonsnummer: 999 601 391</p>
          <p>Telefon: 73 58 05 00 </p>
          <div>
            <a href='mailto:post@miljodir.no'>E-post: post@miljodir.no</a>
          </div>

          <div className={`${styles['footer-links-box-xs']} d-block d-lg-none`}>
            {/* Visible only for XS */}
            <FooterLinks links={links} />
          </div>
        </div>

        <div className={`${styles['footer-links-box-sm']} d-none d-lg-block`}>
          {/* Visible only for > MD */}
          <FooterLinks links={links} />
        </div>
      </div>
    </footer>
  );
}

function FooterLinks({ links }: { links?: FooterItem[] }) {
  if (!links) {
    return <React.Fragment />;
  }
  return (
    <div className={styles['footer-links']}>
      {links &&
        links.map((link, index, array) => {
          return (
            <React.Fragment key={link.link}>
              {link.external ? (
                <a href={link.link}>
                  <span className={styles['footer-link-text']}>
                    {link.label}
                  </span>
                </a>
              ) : (
                <Link to={link.link}>
                  <span key={link.link} className={styles['footer-link-text']}>
                    {link.label}
                  </span>
                </Link>
              )}
              {index < array.length - 1 && (
                <span className={styles['footer-link-separator']}>|</span>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
}
