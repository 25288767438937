import React, { useEffect, useState } from 'react';
import { Table, Th, Button, SelectOption, LoadingSpinner } from 'common.ui';

import { ReactComponent as IconCross } from 'assets/icons/cross-icon.svg';
import { OrganizationGroupMultiSelect } from './OrganizationGroupMultiSelect';
import '../applications/ApplicationResponsibleSelector.scss';

export type OrganizationGroup = {
  id: string;
  text: string;
};

type IProps = {
  readonly: boolean;
  selectOptions: SelectOption[] | undefined;
  organizationGroups: OrganizationGroup[];
  onChanged(organizationGroups: OrganizationGroup[]): void;
};

function OrganizationGroupSelector({ readonly, selectOptions, organizationGroups, onChanged }: IProps) {
  const [showAddOrgGroup, setShowAddOrgGroup] = useState(false);

  const [activeOrgGroups, setActiveOrgGroups] = useState<OrganizationGroup[]>(organizationGroups);

  const [selectedIds, setSelectedIds] = useState<string[]>(
    organizationGroups.map((x: OrganizationGroup) => x.id)
  );

  useEffect(() => {
    setActiveOrgGroups((currentState) => {
      const currentSelection = selectOptions?.filter((x) => selectedIds.some((y) => x.id === y)) ?? [];
      const activeOrgGroupsFiltered = currentState?.filter((x) => selectedIds.some((y) => y === x.id));
      const newActiveResponsibles = currentSelection.filter(
        (x) => !activeOrgGroupsFiltered?.some((y) => y.id === x.id)
      );
      return [
        ...(activeOrgGroupsFiltered ?? []),
        ...(newActiveResponsibles?.map((x) => ({
          id: x.id,
          text: x.text
        })) ?? [])
      ];
    });
  }, [selectedIds, selectOptions]);

  useEffect(() => {
    setActiveOrgGroups(organizationGroups);
  }, [organizationGroups]);

  useEffect(() => {
    onChanged(activeOrgGroups);
  }, [onChanged, activeOrgGroups]);

  const handleRemoveOrganizationGroup = (orgGroupe: OrganizationGroup) => {
    if (selectedIds.length === 0 && activeOrgGroups.length > 0) {
      const activeGroupsIds = activeOrgGroups.map((orgGrp: OrganizationGroup) => orgGrp.id);
      setSelectedIds(activeGroupsIds?.filter((x) => x !== orgGroupe.id));
    } else {
      setSelectedIds(selectedIds?.filter((x) => x !== orgGroupe.id));
    }
  };

  if (!selectOptions) return <LoadingSpinner />;

  return (
    <>
      {!showAddOrgGroup && selectOptions && (
        <Button
          type='button'
          text=' + Legg til organisasjongruppe'
          onClick={() => setShowAddOrgGroup(true)}
          styleType='light'
          disabled={readonly}
        />
      )}

      {!showAddOrgGroup && selectOptions && activeOrgGroups && activeOrgGroups?.length > 0 && (
        <Table>
          <thead>
            <tr>
              <Th title='Navn' />
              <Th title='Fjern' className='text-center' />
            </tr>
          </thead>
          <tbody>
            {activeOrgGroups
              .sort((p1, p2) => (p1.text || '').localeCompare(p2.text || ''))
              .map((orgGroupe: OrganizationGroup) => {
                return (
                  <tr key={orgGroupe.id}>
                    <td>{orgGroupe.text}</td>
                    <td className='text-center'>
                      {!readonly && <IconCross onClick={() => handleRemoveOrganizationGroup(orgGroupe)} />}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}

      {showAddOrgGroup && !readonly && (
        <>
          <b>Legg til organisasjongruppe</b>
          <br />
          <OrganizationGroupMultiSelect
            options={selectOptions ? selectOptions.filter((x) => !selectedIds.some((y) => y === x.id)) : []}
            selectedIds={[]}
            name='organizationGroupIds'
            label='Velg organisasjongruppe'
            triggerOnChangeAlways
            isInitiallyOpen
            onChange={(og) => {
              let a = activeOrgGroups?.map((r) => r.id) ?? [];
              a = a.concat(og.filter((x) => !a.some((y) => y === x)));

              setSelectedIds(a);
              setShowAddOrgGroup(false);
            }}
            onClose={() => setShowAddOrgGroup(false)}
            okLabel='Velg'
          />
        </>
      )}
    </>
  );
}

export default OrganizationGroupSelector;
