/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AarOrganizationResponse,
    AarOrganizationResponseFromJSON,
    AarOrganizationResponseToJSON,
    AddOrganizationToOrganizationGroupRequest,
    AddOrganizationToOrganizationGroupRequestFromJSON,
    AddOrganizationToOrganizationGroupRequestToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    OrganizationGroupCreateRequest,
    OrganizationGroupCreateRequestFromJSON,
    OrganizationGroupCreateRequestToJSON,
    OrganizationGroupResponse,
    OrganizationGroupResponseFromJSON,
    OrganizationGroupResponseToJSON,
    OrganizationGroupResponsePagedResult,
    OrganizationGroupResponsePagedResultFromJSON,
    OrganizationGroupResponsePagedResultToJSON,
    OrganizationGroupSearchOrderBy,
    OrganizationGroupSearchOrderByFromJSON,
    OrganizationGroupSearchOrderByToJSON,
    OrganizationGroupType,
    OrganizationGroupTypeFromJSON,
    OrganizationGroupTypeToJSON,
    OrganizationGroupUpdateRequest,
    OrganizationGroupUpdateRequestFromJSON,
    OrganizationGroupUpdateRequestToJSON,
    OrganizationResponsePagedResult,
    OrganizationResponsePagedResultFromJSON,
    OrganizationResponsePagedResultToJSON,
    OrganizationSearchOrderBy,
    OrganizationSearchOrderByFromJSON,
    OrganizationSearchOrderByToJSON,
    ResourceFilter,
    ResourceFilterFromJSON,
    ResourceFilterToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface ApiOrganizationGroupsGetRequest {
    resourceFilter?: ResourceFilter;
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: OrganizationGroupSearchOrderBy;
}

export interface ApiOrganizationGroupsIdAarOrganizationsGetRequest {
    id: number;
}

export interface ApiOrganizationGroupsIdDeleteRequest {
    id: number;
}

export interface ApiOrganizationGroupsIdPutRequest {
    id: number;
    organizationGroupUpdateRequest?: OrganizationGroupUpdateRequest;
}

export interface ApiOrganizationGroupsOrganizationGroupIdOrganizationOrganizationIdDeleteRequest {
    organizationGroupId: number;
    organizationId: number;
}

export interface ApiOrganizationGroupsOrganizationGroupIdOrganizationPutRequest {
    organizationGroupId: number;
    addOrganizationToOrganizationGroupRequest?: AddOrganizationToOrganizationGroupRequest;
}

export interface ApiOrganizationGroupsOrganizationsGetRequest {
    organizationGroupIds?: Array<number>;
    organizationGroupType?: OrganizationGroupType;
    isActive?: boolean;
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: OrganizationSearchOrderBy;
}

export interface ApiOrganizationGroupsPostRequest {
    organizationGroupCreateRequest?: OrganizationGroupCreateRequest;
}

export interface GetOrganizationGroupByIdRequest {
    id: number;
}

/**
 * 
 */
export class OrganizationGroupsApi extends runtime.BaseAPI {

    /**
     */
    async apiOrganizationGroupsGetRaw(requestParameters: ApiOrganizationGroupsGetRequest): Promise<runtime.ApiResponse<OrganizationGroupResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.resourceFilter !== undefined) {
            queryParameters['resourceFilter'] = requestParameters.resourceFilter;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organization-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationGroupResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiOrganizationGroupsGet(requestParameters: ApiOrganizationGroupsGetRequest): Promise<OrganizationGroupResponsePagedResult> {
        const response = await this.apiOrganizationGroupsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiOrganizationGroupsIdAarOrganizationsGetRaw(requestParameters: ApiOrganizationGroupsIdAarOrganizationsGetRequest): Promise<runtime.ApiResponse<Array<AarOrganizationResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrganizationGroupsIdAarOrganizationsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organization-groups/{id}/aar-organizations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AarOrganizationResponseFromJSON));
    }

    /**
     */
    async apiOrganizationGroupsIdAarOrganizationsGet(requestParameters: ApiOrganizationGroupsIdAarOrganizationsGetRequest): Promise<Array<AarOrganizationResponse>> {
        const response = await this.apiOrganizationGroupsIdAarOrganizationsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiOrganizationGroupsIdDeleteRaw(requestParameters: ApiOrganizationGroupsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrganizationGroupsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organization-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrganizationGroupsIdDelete(requestParameters: ApiOrganizationGroupsIdDeleteRequest): Promise<void> {
        await this.apiOrganizationGroupsIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiOrganizationGroupsIdPutRaw(requestParameters: ApiOrganizationGroupsIdPutRequest): Promise<runtime.ApiResponse<OrganizationGroupResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrganizationGroupsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organization-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationGroupUpdateRequestToJSON(requestParameters.organizationGroupUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationGroupResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiOrganizationGroupsIdPut(requestParameters: ApiOrganizationGroupsIdPutRequest): Promise<OrganizationGroupResponse> {
        const response = await this.apiOrganizationGroupsIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiOrganizationGroupsOrganizationGroupIdOrganizationOrganizationIdDeleteRaw(requestParameters: ApiOrganizationGroupsOrganizationGroupIdOrganizationOrganizationIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationGroupId === null || requestParameters.organizationGroupId === undefined) {
            throw new runtime.RequiredError('organizationGroupId','Required parameter requestParameters.organizationGroupId was null or undefined when calling apiOrganizationGroupsOrganizationGroupIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling apiOrganizationGroupsOrganizationGroupIdOrganizationOrganizationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organization-groups/{organizationGroupId}/organization/{organizationId}`.replace(`{${"organizationGroupId"}}`, encodeURIComponent(String(requestParameters.organizationGroupId))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrganizationGroupsOrganizationGroupIdOrganizationOrganizationIdDelete(requestParameters: ApiOrganizationGroupsOrganizationGroupIdOrganizationOrganizationIdDeleteRequest): Promise<void> {
        await this.apiOrganizationGroupsOrganizationGroupIdOrganizationOrganizationIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiOrganizationGroupsOrganizationGroupIdOrganizationPutRaw(requestParameters: ApiOrganizationGroupsOrganizationGroupIdOrganizationPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationGroupId === null || requestParameters.organizationGroupId === undefined) {
            throw new runtime.RequiredError('organizationGroupId','Required parameter requestParameters.organizationGroupId was null or undefined when calling apiOrganizationGroupsOrganizationGroupIdOrganizationPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organization-groups/{organizationGroupId}/organization`.replace(`{${"organizationGroupId"}}`, encodeURIComponent(String(requestParameters.organizationGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrganizationToOrganizationGroupRequestToJSON(requestParameters.addOrganizationToOrganizationGroupRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrganizationGroupsOrganizationGroupIdOrganizationPut(requestParameters: ApiOrganizationGroupsOrganizationGroupIdOrganizationPutRequest): Promise<void> {
        await this.apiOrganizationGroupsOrganizationGroupIdOrganizationPutRaw(requestParameters);
    }

    /**
     */
    async apiOrganizationGroupsOrganizationsGetRaw(requestParameters: ApiOrganizationGroupsOrganizationsGetRequest): Promise<runtime.ApiResponse<OrganizationResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.organizationGroupIds) {
            queryParameters['organizationGroupIds'] = requestParameters.organizationGroupIds;
        }

        if (requestParameters.organizationGroupType !== undefined) {
            queryParameters['organizationGroupType'] = requestParameters.organizationGroupType;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organization-groups/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiOrganizationGroupsOrganizationsGet(requestParameters: ApiOrganizationGroupsOrganizationsGetRequest): Promise<OrganizationResponsePagedResult> {
        const response = await this.apiOrganizationGroupsOrganizationsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiOrganizationGroupsPostRaw(requestParameters: ApiOrganizationGroupsPostRequest): Promise<runtime.ApiResponse<OrganizationGroupResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organization-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationGroupCreateRequestToJSON(requestParameters.organizationGroupCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationGroupResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiOrganizationGroupsPost(requestParameters: ApiOrganizationGroupsPostRequest): Promise<OrganizationGroupResponse> {
        const response = await this.apiOrganizationGroupsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getOrganizationGroupByIdRaw(requestParameters: GetOrganizationGroupByIdRequest): Promise<runtime.ApiResponse<OrganizationGroupResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationGroupById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organization-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationGroupResponseFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationGroupById(requestParameters: GetOrganizationGroupByIdRequest): Promise<OrganizationGroupResponse> {
        const response = await this.getOrganizationGroupByIdRaw(requestParameters);
        return await response.value();
    }

}
