/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    InternalUserDetailResponse,
    InternalUserDetailResponseFromJSON,
    InternalUserDetailResponseToJSON,
    InternalUserResponsePagedResult,
    InternalUserResponsePagedResultFromJSON,
    InternalUserResponsePagedResultToJSON,
    InternalUserSearchRequest,
    InternalUserSearchRequestFromJSON,
    InternalUserSearchRequestToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    SectionResponse,
    SectionResponseFromJSON,
    SectionResponseToJSON,
} from '../models';

export interface ApiInternalUsersInternalUserIdGetRequest {
    internalUserId: string;
}

export interface ApiInternalUsersSearchesPostRequest {
    internalUserSearchRequest?: InternalUserSearchRequest;
}

/**
 * 
 */
export class InternalUsersApi extends runtime.BaseAPI {

    /**
     */
    async apiInternalUsersEmploymentCategoriesGetRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal-users/employment-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiInternalUsersEmploymentCategoriesGet(): Promise<Array<string>> {
        const response = await this.apiInternalUsersEmploymentCategoriesGetRaw();
        return await response.value();
    }

    /**
     */
    async apiInternalUsersInternalUserIdGetRaw(requestParameters: ApiInternalUsersInternalUserIdGetRequest): Promise<runtime.ApiResponse<InternalUserDetailResponse>> {
        if (requestParameters.internalUserId === null || requestParameters.internalUserId === undefined) {
            throw new runtime.RequiredError('internalUserId','Required parameter requestParameters.internalUserId was null or undefined when calling apiInternalUsersInternalUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal-users/{internalUserId}`.replace(`{${"internalUserId"}}`, encodeURIComponent(String(requestParameters.internalUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalUserDetailResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiInternalUsersInternalUserIdGet(requestParameters: ApiInternalUsersInternalUserIdGetRequest): Promise<InternalUserDetailResponse> {
        const response = await this.apiInternalUsersInternalUserIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiInternalUsersSearchesPostRaw(requestParameters: ApiInternalUsersSearchesPostRequest): Promise<runtime.ApiResponse<InternalUserResponsePagedResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal-users/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalUserSearchRequestToJSON(requestParameters.internalUserSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalUserResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiInternalUsersSearchesPost(requestParameters: ApiInternalUsersSearchesPostRequest): Promise<InternalUserResponsePagedResult> {
        const response = await this.apiInternalUsersSearchesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiInternalUsersSectionsGetRaw(): Promise<runtime.ApiResponse<Array<SectionResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal-users/sections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SectionResponseFromJSON));
    }

    /**
     */
    async apiInternalUsersSectionsGet(): Promise<Array<SectionResponse>> {
        const response = await this.apiInternalUsersSectionsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiInternalUsersWorkLocationsGetRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal-users/work-locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiInternalUsersWorkLocationsGet(): Promise<Array<string>> {
        const response = await this.apiInternalUsersWorkLocationsGetRaw();
        return await response.value();
    }

}
