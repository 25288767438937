import React from 'react';
import IconCross from '../../../assets/icons/cross-icon.svg';
import styles from './FilterLabel.scss';

interface IFilterLabelProps {
  label: string;
  onRemove: () => void;
}
const FilterLabel = ({ label, onRemove }: IFilterLabelProps) => (
  <span className={styles.filterContainer}>
    {label}
    <button type='button' className={styles.removeFilter} onClick={onRemove}>
      <IconCross />
    </button>
  </span>
);

export { FilterLabel };
