import React, { PropsWithChildren, useState } from 'react';
import { SortDirection, Sorting } from '../../util/useSortedPaging';
import IconSortUp from '../../assets/icons/sort-up-icon.svg';
import IconSortDown from '../../assets/icons/sort-down-icon.svg';
import IconSortNone from '../../assets/icons/sort-none-icon.svg';

import styles from './ThSortable.scss';

export type SortEvent<T> = {
  field: T;
  direction: SortDirection;
};

interface HeaderProps<T extends string | number> {
  title: string;
  colSpan?: number;
  noWrap?: boolean;
  field: T;
  currentSort: Sorting<T>;
  onSort: (e: SortEvent<T>) => void;
}

function ThSortable<T extends string | number>({
  title,
  colSpan,
  noWrap,
  field,
  currentSort,
  onSort
}: PropsWithChildren<HeaderProps<T>>) {
  const [direction, setDirection] = useState(currentSort.direction);

  const handleOnClick = () => {
    let newDirection: SortDirection = direction === 'asc' ? 'desc' : 'asc';
    newDirection = currentSort.field !== field ? 'asc' : newDirection;
    setDirection(newDirection);
    onSort({ direction: newDirection, field });
  };

  return (
    <th
      colSpan={colSpan}
      onClick={handleOnClick}
      className={`${styles.th} ${noWrap !== false ? styles.noWrap : ''}`}
    >
      {title}
      {field && (
        <span className={styles.sortContainer}>
          {currentSort.field !== field && (
            <IconSortNone style={{ opacity: '0.5' }} />
          )}
          {currentSort.field === field && direction === 'desc' && (
            <IconSortUp />
          )}
          {currentSort.field === field && direction === 'asc' && (
            <IconSortDown />
          )}
        </span>
      )}
    </th>
  );
}

export { ThSortable };
