import React from 'react';
import InternalUsers from 'screens/users/InternalUsers';
import ExternalUsers from 'screens/users/ExternalUsers';
import ExternalUser from 'screens/users/ExternalUser';
import ExternalUserClaims from 'screens/users/ExternalUserClaims';
import InternalUser from 'screens/users/InternalUser';
import InternalUserClaims from 'screens/users/InternalUserClaims';
import Tbd from 'screens/Tbd';
import AddApplication from 'screens/applications/AddApplication';
import AddRole from 'screens/roles/AddRole';
import UpdateRole from 'screens/roles/UpdateRole';
import AddPermission from 'screens/permissions/AddPermission';
import UpdatePermission from 'screens/permissions/UpdatePermission';
import Permissions from 'screens/permissions/Permissions';
import AddOrganizationGroup from 'screens/organizationgroups/AddOrganizationGroup';
import UpdateOrganizationGroup from 'screens/organizationgroups/UpdateOrganizationGroup';
import OrganizationGroups from 'screens/organizationgroups/OrganizationGroups';
import Section from 'screens/sections/Section';
import AdGroup from 'screens/adgroups/AdGroup';
import AdGroupAndSections from 'screens/adgroups/AdGroupAndSections';
import Roles from 'screens/roles/Roles';
import Applications from 'screens/applications/Applications';
import AccessRequests from 'screens/accessrequests/AccessRequests';
import AccessRequest from 'screens/accessrequests/AccessRequest';
import Application from 'screens/applications/Application';
import RoutePaths from 'RoutePaths';
import { RouteItem } from 'helpers/routing';
import DevTools from 'screens/devtools/DevTools';
import UiTexts from 'screens/uitexts/UiTexts';
import ApprovedAccessRequests from 'screens/accessrequests/ApprovedAccessRequests';
import { AdgangAccessControl } from './api/adgang';

import { ReactComponent as IconForside } from './assets/icons/home-icon.svg';
import { ReactComponent as IconProfil } from './assets/icons/profile-icon.svg';
import { ReactComponent as IconApplications } from './assets/icons/applications-icon.svg';
import { ReactComponent as IconRoles } from './assets/icons/roles-icon.svg';
import { ReactComponent as IconPermissions } from './assets/icons/access-key-icon.svg';
import { ReactComponent as IconAccessRequests } from './assets/icons/security-icon.svg';
import { ReactComponent as IconSections } from './assets/icons/sections-icon.svg';
import { ReactComponent as IconGroups } from './assets/icons/groups-icon.svg';
import { ReactComponent as IconSettings } from './assets/icons/settings-icon.svg';
import { ReactComponent as IconTexts } from './assets/icons/texts-icon.svg';

export const RouteConfig: RouteItem[] = [
  {
    link: RoutePaths.home,
    label: 'Forside',
    hidden: true,
    component: Tbd,
    icon: <IconForside />
  },
  {
    link: RoutePaths.internalusers,
    label: 'Interne ansatte',
    requiredPermission: AdgangAccessControl.InternalUsersView,
    icon: <IconProfil />,
    component: InternalUsers,
    children: [
      {
        link: RoutePaths.internalusersDetail,
        requiredPermission: AdgangAccessControl.InternalUserView,
        hidden: true,
        component: InternalUser
      },
      {
        link: RoutePaths.internalusersDetailClaims,
        requiredPermission: AdgangAccessControl.InternalUserView,
        hidden: true,
        component: InternalUserClaims
      }
    ]
  },
  {
    link: RoutePaths.externalusers,
    requiredPermission: AdgangAccessControl.ExternalUsersView,
    label: 'Eksterne brukere',
    icon: <IconProfil />,
    component: ExternalUsers,
    children: [
      {
        link: RoutePaths.externalusersDetail,
        requiredPermission: AdgangAccessControl.ExternalUserView,
        hidden: true,
        component: ExternalUser
      },
      {
        link: RoutePaths.externalusersDetailClaims,
        requiredPermission: AdgangAccessControl.ExternalUserView,
        hidden: true,
        component: ExternalUserClaims
      }
    ]
  },
  {
    link: RoutePaths.applications,
    label: 'Applikasjoner',
    requiredPermission: AdgangAccessControl.ApplicationsView,
    icon: <IconApplications />,
    component: Applications,
    children: [
      {
        link: RoutePaths.applicationsAdd,
        requiredPermission: AdgangAccessControl.ApplicationView,
        label: 'Legg til',
        component: AddApplication
      },
      { link: RoutePaths.applicationsDetail, hidden: true, component: Application }
    ]
  },
  {
    link: RoutePaths.roles,
    label: 'Roller',
    requiredPermission: AdgangAccessControl.RolesView,
    icon: <IconRoles />,
    component: Roles,
    children: [
      {
        link: RoutePaths.rolesAdd,
        label: 'Legg til',
        requiredPermission: AdgangAccessControl.RoleEdit,
        component: AddRole
      },
      {
        link: RoutePaths.rolesDetail,
        requiredPermission: AdgangAccessControl.RoleView,
        hidden: true,
        component: UpdateRole
      }
    ]
  },
  {
    link: RoutePaths.permissions,
    label: 'Tilganger',
    requiredPermission: AdgangAccessControl.PermissionsView,
    icon: <IconPermissions />,
    component: Permissions,
    children: [
      {
        link: RoutePaths.permissionsAdd,
        requiredPermission: AdgangAccessControl.PermissionEdit,
        label: 'Legg til',
        component: AddPermission
      },
      {
        link: RoutePaths.permissionsDetail,
        requiredPermission: AdgangAccessControl.PermissionView,
        hidden: true,
        component: UpdatePermission
      }
    ]
  },
  {
    link: RoutePaths.accessrequests,
    label: 'Rettighetsforespørsel',
    requiredPermission: AdgangAccessControl.AccessRequestsView,
    icon: <IconAccessRequests />,
    component: AccessRequests,
    children: [
      {
        link: RoutePaths.accessrequestsDetail,
        requiredPermission: AdgangAccessControl.AccessRequestView,
        hidden: true,
        component: AccessRequest
      }
    ]
  },
  {
    link: RoutePaths.approvedAccesses,
    label: 'Gitte rettigheter',
    requiredPermission: AdgangAccessControl.ApprovedAccessesView,
    icon: <IconAccessRequests />,
    component: ApprovedAccessRequests
  },
  {
    link: RoutePaths.organizationgroups,
    requiredPermission: AdgangAccessControl.OrganizationGroupsView,
    label: 'Organisasjonsgrupper',
    icon: <IconGroups />,
    component: OrganizationGroups,
    children: [
      {
        link: RoutePaths.organizationgroupsAdd,
        requiredPermission: AdgangAccessControl.OrganizationGroupCreate,
        label: 'Legg til',
        component: AddOrganizationGroup
      },
      {
        link: RoutePaths.organizationgroupsDetail,
        requiredPermission: AdgangAccessControl.OrganizationGroupEdit,
        hidden: true,
        component: UpdateOrganizationGroup
      }
    ]
  },
  {
    link: RoutePaths.sectionsadgroups,
    label: 'AD-grupper og seksjoner',
    requiredPermission: AdgangAccessControl.AdGroupsSectionsView,
    icon: <IconSections />,
    component: AdGroupAndSections,
    children: [
      {
        link: RoutePaths.sectionsDetail,
        requiredPermission: AdgangAccessControl.SectionView,
        hidden: true,
        component: Section
      },
      {
        link: RoutePaths.adgroupsDetail,
        requiredPermission: AdgangAccessControl.AdGroupView,
        hidden: true,
        component: AdGroup
      }
    ]
  },
  {
    link: RoutePaths.uiTexts,
    label: 'Tekster',
    requiredPermission: AdgangAccessControl.TranslationsView,
    icon: <IconTexts />,
    component: UiTexts
  },
  {
    link: RoutePaths.devTools,
    label: 'Test verktøy',
    requiredPermission: AdgangAccessControl.DevAllowAssignSysAdmin,
    icon: <IconSettings />,
    component: DevTools
  }
];
