/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserClaimsResponse,
    UserClaimsResponseFromJSON,
    UserClaimsResponseFromJSONTyped,
    UserClaimsResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserAccessAndClaimsResponse
 */
export interface UserAccessAndClaimsResponse {
    /**
     * 
     * @type {string}
     * @memberof UserAccessAndClaimsResponse
     */
    applicationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccessAndClaimsResponse
     */
    clientId?: string | null;
    /**
     * 
     * @type {UserClaimsResponse}
     * @memberof UserAccessAndClaimsResponse
     */
    userClaims?: UserClaimsResponse;
}

export function UserAccessAndClaimsResponseFromJSON(json: any): UserAccessAndClaimsResponse {
    return UserAccessAndClaimsResponseFromJSONTyped(json, false);
}

export function UserAccessAndClaimsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAccessAndClaimsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'userClaims': !exists(json, 'userClaims') ? undefined : UserClaimsResponseFromJSON(json['userClaims']),
    };
}

export function UserAccessAndClaimsResponseToJSON(value?: UserAccessAndClaimsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationName': value.applicationName,
        'clientId': value.clientId,
        'userClaims': UserClaimsResponseToJSON(value.userClaims),
    };
}


