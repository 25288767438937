import React from 'react';
import { RadioOption } from './RadioGroup';
import styles1 from './_radioGroup.scss';

const styles = (cN: string) => {
  const mapped = cN.split(' ').map((c) => styles1[c]);
  return mapped.join(' ');
};

interface IWrapperProps {
  children?: React.ReactNode;
}

interface IProps {
  option: RadioOption;
  onSelect: (e: any) => void;
  id: string;
  selected: boolean;
}
export function RadioOptionFascadeWrapper({ children }: IWrapperProps) {
  return <fieldset className={styles('c_radio-group')}>{children}</fieldset>;
}

export function RadioOptionFascade({ option, onSelect, id, selected }: IProps) {
  return (
    <label
      htmlFor={`${option.text}${id}`}
      key={`radioLabelKey__id${option.id}_${id}`}
      className={styles('c_radio-group-option')}
    >
      <span className={styles('c_radio-group-option__check-area')}>
        {selected && (
          <span className={styles('c_radio-group-option__selected-dot')} />
        )}
      </span>
      <input
        name={`${id}`}
        type='radio'
        value={option.id}
        defaultChecked={selected}
        checked={selected}
        onChange={onSelect}
      />
      <span className={styles('c_radio-group-option__text')}>
        {option.text}
      </span>
    </label>
  );
}
