import React, { useState, useCallback } from 'react';
import Page from 'components/page/Page';
import { Button, LoadingSpinner, SelectOption, useBoundForm } from 'common.ui';
import {
  ApplicationCreateRequest,
  ApplicationMigrationStatus,
  ApplicationResponse,
  ApplicationResponsiblePersonRequest,
  ApplicationStatus,
  GenericValidationError,
  UserType
} from 'api/adgang/models';
import { applicationsClient } from 'api/AdgangClients';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import ApplicationResponsibleSelector from 'components/applications/ApplicationResponsibleSelector';
import { useUserAuthenticationConstraints } from 'api/hooks/useUserAuthenticationConstraint';
import { useApplicationResponsibles } from 'api/hooks/useApplicationResponsibles';
import { useUserTypes } from 'api/hooks/useUserTypes';
import RoutePaths from 'RoutePaths';
import { useApplicationStatuses } from 'api/hooks/useApplicationStatuses';
import { useApplicationMigrationStatuses } from 'api/hooks/useApplicationMigrationStatuses';

function AddApplication() {
  const history = useHistory();
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [application, setApplication] = useState<ApplicationResponse>();
  const [values, setValues] = useState<ApplicationCreateRequest>({
    responsiblePersonIds: [],
    canAppResponsiblesAppointExternalApprovers: false
  } as ApplicationCreateRequest);
  const [userAuthenticationConstraints] = useUserAuthenticationConstraints(true, false);
  const [userTypes] = useUserTypes({ id: '', text: 'Intern og ekstern' } as SelectOption);
  const [statuses] = useApplicationStatuses();
  const [migrationStatuses] = useApplicationMigrationStatuses();
  const [selectOptions] = useApplicationResponsibles();

  const onResponsiblesChanged = useCallback((p: ApplicationResponsiblePersonRequest[]): void => {
    setValues((currentState) => ({ ...currentState, responsiblePersons: p }));
  }, []);

  const handleAccessRightSetChanged = (isExtended: boolean) => {
    setValues((currentState) => ({
      ...currentState,
      canAppResponsiblesAppointExternalApprovers: isExtended
    }));
  };

  const OnFormSubmit = useCallback(
    async (submitModel: ApplicationCreateRequest) => {
      const model = {
        ...submitModel,
        canAppResponsiblesAppointExternalApprovers: values.canAppResponsiblesAppointExternalApprovers,
        responsiblePersons: values.responsiblePersons || []
      } as ApplicationCreateRequest;
      setValues(model);
      try {
        const result = await applicationsClient.apiApplicationsPost({
          applicationCreateRequest: {
            ...model,
            isComponentSpecific: model.isComponentSpecific?.toString() === 'on',
            isSearchableInMinSide: model.isSearchableInMinSide?.toString() === 'on',
            isIdPortenFederationDisabled: model.isIdPortenFederationDisabled?.toString() === 'on',
            requiresElevatedSecurityPolicy: model.requiresElevatedSecurityPolicy?.toString() === 'on',
            requiresMfa: model.requiresMfa?.toString() === 'on',
            userType: model.userType ? (model.userType as UserType) : undefined,
            status: model.status ? (model.status as ApplicationStatus) : undefined,
            migrationStatus: model.migrationStatus
              ? (model.migrationStatus as ApplicationMigrationStatus)
              : undefined
          } as ApplicationCreateRequest
        });
        setApiErrors(undefined);
        setApplication(result);
      } catch (e) {
        if (e) {
          const result = (await (e as any).json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
      }
    },
    [values.responsiblePersons, values.canAppResponsiblesAppointExternalApprovers]
  );

  const { form, FormContainer, Checkbox, Dropdown, Input, DisplayErrors, TextArea } = useBoundForm<
    ApplicationCreateRequest
  >({
    onSubmit: async (e) => {
      await OnFormSubmit(e);
    },
    errors: apiErrors,
    model: values
  });

  if (form.isLoading) return <LoadingSpinner />;

  return application ? (
    <ApplicationCreated application={application} />
  ) : (
    <Page header='Legg til applikasjon'>
      <Container fluid>
        <FormContainer form={form}>
          <Row>
            <Col sm={12} lg={6}>
              <DisplayErrors form={form} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Input form={form} name='name' label='Navn' placeholder='Applikasjonsnavn' />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Input form={form} name='shortName' label='Kortnavn' placeholder='Applikasjonens kortnavn' />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <TextArea
                form={form}
                name='description'
                label='Beskrivelse'
                placeholder='Beskrivelse - Vises blant annet i MinSide'
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Input form={form} name='url' label='Url' placeholder='Url' />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Dropdown form={form} name='status' label='Applikasjon status' options={statuses} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Dropdown
                form={form}
                name='migrationStatus'
                label='Er applikasjon tilgjengelig i MinSide for kobling?'
                options={migrationStatuses}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Dropdown
                form={form}
                name='userType'
                label='Bruker type'
                options={userTypes.sort((o1, o2) => (o1.id || '').localeCompare(o2.id || ''))}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Dropdown
                form={form}
                name='userAuthenticationConstraint'
                label='Trenger kjent bruker'
                options={userAuthenticationConstraints}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Checkbox
                form={form}
                name='isIdPortenFederationDisabled'
                label='Deaktiver ID Porten pålogging for denne applikasjonen?'
                question=''
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Checkbox
                form={form}
                name='isComponentSpecific'
                label='Komponentspesifikk applikasjon?'
                question=''
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Checkbox
                form={form}
                name='isSearchableInMinSide'
                label='Finnes applikasjonen i MinSide?'
                question=''
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Checkbox
                form={form}
                name='requiresMfa'
                label='Krever applikasjon multi-faktor autentisering?'
                question=''
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Checkbox
                form={form}
                name='requiresElevatedSecurityPolicy'
                label='Krever applikasjonen påloggingsflyten med et høyere sikkerhetsnivå (gjelder eksterne brukere)?'
                question=''
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <ApplicationResponsibleSelector
                readonly={false}
                selectOptions={selectOptions}
                responsiblePersons={[]}
                expandedAccessRightsSet={values?.canAppResponsiblesAppointExternalApprovers ?? false}
                showAccessRightsExpander
                onAccessRightSetChanged={handleAccessRightSetChanged}
                onChanged={onResponsiblesChanged}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Button type='submit' text='Legg til applikasjon' />
              <Button
                type='button'
                text='Avbryt'
                styleType='light'
                onClick={() => history.push(RoutePaths.applications)}
              />
            </Col>
          </Row>
        </FormContainer>
      </Container>
    </Page>
  );
}

function ApplicationCreated({ application }: { application: ApplicationResponse }) {
  const history = useHistory();

  const showApplication = () => {
    if (application && application.id) history.push(`${RoutePaths.applications}/${application.id}`);
  };

  return (
    <Page header='Applikasjon opprettet'>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6}>
            <p>
              Applikasjonen <b>{application.name}</b> har nå blitt opprettet.
            </p>
            {/* <p>Følgende &quot;client secret&quot; er automatisk generert:</p>
            <p>
              <i>{application.clientSecrets && application.clientSecrets[0].secretText}</i>
            </p>
            <p>Vennligst skriv ned den ned, da den kun vises denne ene gangen.</p> */}
            <Button type='button' onClick={showApplication} text='Gå til applikasjonsinnstillinger' />
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default AddApplication;
