import React, { useEffect } from 'react';
import { useMsal } from './MsalContext';

const MsalAuthWrapper: React.FC = (props: any) => {
  const { isAuthenticated, authInProgress, login } = useMsal();

  useEffect(() => {
    if (!isAuthenticated && !authInProgress) login();
  }, [authInProgress, isAuthenticated, login]);

  return isAuthenticated && !authInProgress ? props.children : null;
};

export default MsalAuthWrapper;
