import React from 'react';
import CSS from 'csstype';
import styles from './_buttonLink.scss';

const renderClassNames = (props: IButtonLink) => {
  let cN = 'c_buttonLink';
  if (props.disabled) {
    cN = `${cN} c_buttonLink--disabled`;
  }
  //   if (props.className) {
  //     cN = `${cN} ${props.className}`;
  //   }
  if (props.inline) {
    cN = `${cN} c_buttonLink--inline`;
  }
  const mapped = cN.split(' ').map((c) => styles[c]);
  return mapped.join(' ');
};

export type IButtonLink = {
  text: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  showArrow?: boolean;
  disabled?: boolean;
  inline?: boolean;
  style?: CSS.Properties;
};

const ButtonLink = (props: IButtonLink) => {
  const { onClick, type, text, disabled, style } = props;

  return (
    <button
      style={style}
      className={renderClassNames(props)}
      onClick={(e) => {
        if (disabled) {
          // disable POST event
          e.preventDefault();
        }
        if (!disabled && onClick) {
          onClick();
        }
      }}
      type={type || 'button'} // eslint-disable-line
    >
      <span>{text}</span>
    </button>
  );
};

export { ButtonLink };
