import Environment from 'Environment';
import { Middleware, RequestContext, FetchParams } from './adgang/runtime';
import { AuthService } from '../auth/AuthService';

class AddTokenMiddleware implements Middleware {
  // eslint-disable-next-line class-methods-use-this
  async pre?(context: RequestContext): Promise<FetchParams | void> {
    // eslint-disable-next-line no-console
    console.log('pre midle');
    const token = await AuthService.getAccessToken();
    if (context.init.headers !== undefined) {
      // eslint-disable-next-line no-param-reassign
      (context.init.headers as Record<string, string>).Authorization = `Bearer ${token}`;
      // eslint-disable-next-line no-console
      if (Environment.Current !== 'production') console.log('token: ', { token });
    } else {
      // eslint-disable-next-line no-console
      console.log('pre midle undef');
    }

    return context;
  }
}

export default AddTokenMiddleware;
