import { AuthService } from 'auth/AuthService';
import Page from 'components/page/Page';
import React, { useEffect } from 'react';

const ClearCookies = () => {
  useEffect(() => {
    AuthService.clearStorageValues();
  }, []);

  return (
    <Page>
      <p>Nettleser informasjon er fjernet.</p>
    </Page>
  );
};

export default ClearCookies;
