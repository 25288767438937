/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserEventType {
    UserRegistered = 'UserRegistered',
    UserMigrated = 'UserMigrated',
    UserIdPortenValidated = 'UserIdPortenValidated',
    UserAccessRequested = 'UserAccessRequested',
    UserAccessRemoved = 'UserAccessRemoved',
    UserCancellationRequested = 'UserCancellationRequested',
    UserAccessLegalTermsAccepted = 'UserAccessLegalTermsAccepted',
    UserAcceptedGdpr = 'UserAcceptedGdpr',
    UserAccessApproved = 'UserAccessApproved',
    UserAccessAssigned = 'UserAccessAssigned',
    UserChangedEmployer = 'UserChangedEmployer'
}

export function UserEventTypeFromJSON(json: any): UserEventType {
    return UserEventTypeFromJSONTyped(json, false);
}

export function UserEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventType {
    return json as UserEventType;
}

export function UserEventTypeToJSON(value?: UserEventType | null): any {
    return value as any;
}

