/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalUserStatus,
    ExternalUserStatusFromJSON,
    ExternalUserStatusFromJSONTyped,
    ExternalUserStatusToJSON,
    UserAliasResponse,
    UserAliasResponseFromJSON,
    UserAliasResponseFromJSONTyped,
    UserAliasResponseToJSON,
    UserIdentityProvider,
    UserIdentityProviderFromJSON,
    UserIdentityProviderFromJSONTyped,
    UserIdentityProviderToJSON,
    UserKrrInfoResponse,
    UserKrrInfoResponseFromJSON,
    UserKrrInfoResponseFromJSONTyped,
    UserKrrInfoResponseToJSON,
    UserOrganizationGroupResponse,
    UserOrganizationGroupResponseFromJSON,
    UserOrganizationGroupResponseFromJSONTyped,
    UserOrganizationGroupResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalUserDetailResponse
 */
export interface ExternalUserDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    mfaPhoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalUserDetailResponse
     */
    isIdPortenUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalUserDetailResponse
     */
    isIdPortenValidated?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ExternalUserDetailResponse
     */
    lastLoggedInOn?: Date | null;
    /**
     * 
     * @type {Array<UserAliasResponse>}
     * @memberof ExternalUserDetailResponse
     */
    userAliases?: Array<UserAliasResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    organizationAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    organizationForm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    organizationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    organizationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserDetailResponse
     */
    organizationPostNumberAndCity?: string | null;
    /**
     * 
     * @type {ExternalUserStatus}
     * @memberof ExternalUserDetailResponse
     */
    status?: ExternalUserStatus;
    /**
     * 
     * @type {UserIdentityProvider}
     * @memberof ExternalUserDetailResponse
     */
    identityProvider?: UserIdentityProvider;
    /**
     * 
     * @type {UserKrrInfoResponse}
     * @memberof ExternalUserDetailResponse
     */
    userKrrInfo?: UserKrrInfoResponse;
    /**
     * 
     * @type {UserOrganizationGroupResponse}
     * @memberof ExternalUserDetailResponse
     */
    statsforvalterenOrganizationGroup?: UserOrganizationGroupResponse;
}

export function ExternalUserDetailResponseFromJSON(json: any): ExternalUserDetailResponse {
    return ExternalUserDetailResponseFromJSONTyped(json, false);
}

export function ExternalUserDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalUserDetailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mfaPhoneNumber': !exists(json, 'mfaPhoneNumber') ? undefined : json['mfaPhoneNumber'],
        'isIdPortenUser': !exists(json, 'isIdPortenUser') ? undefined : json['isIdPortenUser'],
        'isIdPortenValidated': !exists(json, 'isIdPortenValidated') ? undefined : json['isIdPortenValidated'],
        'lastLoggedInOn': !exists(json, 'lastLoggedInOn') ? undefined : (json['lastLoggedInOn'] === null ? null : new Date(json['lastLoggedInOn'])),
        'userAliases': !exists(json, 'userAliases') ? undefined : (json['userAliases'] === null ? null : (json['userAliases'] as Array<any>).map(UserAliasResponseFromJSON)),
        'organizationAddress': !exists(json, 'organizationAddress') ? undefined : json['organizationAddress'],
        'organizationForm': !exists(json, 'organizationForm') ? undefined : json['organizationForm'],
        'organizationName': !exists(json, 'organizationName') ? undefined : json['organizationName'],
        'organizationNumber': !exists(json, 'organizationNumber') ? undefined : json['organizationNumber'],
        'organizationPostNumberAndCity': !exists(json, 'organizationPostNumberAndCity') ? undefined : json['organizationPostNumberAndCity'],
        'status': !exists(json, 'status') ? undefined : ExternalUserStatusFromJSON(json['status']),
        'identityProvider': !exists(json, 'identityProvider') ? undefined : UserIdentityProviderFromJSON(json['identityProvider']),
        'userKrrInfo': !exists(json, 'userKrrInfo') ? undefined : UserKrrInfoResponseFromJSON(json['userKrrInfo']),
        'statsforvalterenOrganizationGroup': !exists(json, 'statsforvalterenOrganizationGroup') ? undefined : UserOrganizationGroupResponseFromJSON(json['statsforvalterenOrganizationGroup']),
    };
}

export function ExternalUserDetailResponseToJSON(value?: ExternalUserDetailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'firstName': value.firstName,
        'surname': value.surname,
        'displayName': value.displayName,
        'email': value.email,
        'mfaPhoneNumber': value.mfaPhoneNumber,
        'isIdPortenUser': value.isIdPortenUser,
        'isIdPortenValidated': value.isIdPortenValidated,
        'lastLoggedInOn': value.lastLoggedInOn === undefined ? undefined : (value.lastLoggedInOn === null ? null : value.lastLoggedInOn.toISOString()),
        'userAliases': value.userAliases === undefined ? undefined : (value.userAliases === null ? null : (value.userAliases as Array<any>).map(UserAliasResponseToJSON)),
        'organizationAddress': value.organizationAddress,
        'organizationForm': value.organizationForm,
        'organizationName': value.organizationName,
        'organizationNumber': value.organizationNumber,
        'organizationPostNumberAndCity': value.organizationPostNumberAndCity,
        'status': ExternalUserStatusToJSON(value.status),
        'identityProvider': UserIdentityProviderToJSON(value.identityProvider),
        'userKrrInfo': UserKrrInfoResponseToJSON(value.userKrrInfo),
        'statsforvalterenOrganizationGroup': UserOrganizationGroupResponseToJSON(value.statsforvalterenOrganizationGroup),
    };
}


