import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './Modal.scss';

export type IModal = {
  header: string;
  show: boolean;
  onCancel: () => void;
  size?: 'sm' | 'lg' | 'xl';
};

const SimpleModal: React.FunctionComponent<IModal> = ({
  header,
  children,
  show,
  size,
  onCancel
}) => (
  <Modal show={show} className={styles.modal} size={size} onHide={onCancel}>
    <Modal.Header closeButton>
      <Modal.Title>{header}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      {children}
      <div style={{ height: '3em' }} />
    </Modal.Body>
  </Modal>
);
export { SimpleModal };
