/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationMigrationStatus,
    ApplicationMigrationStatusFromJSON,
    ApplicationMigrationStatusFromJSONTyped,
    ApplicationMigrationStatusToJSON,
    ApplicationResponsiblePersonRequest,
    ApplicationResponsiblePersonRequestFromJSON,
    ApplicationResponsiblePersonRequestFromJSONTyped,
    ApplicationResponsiblePersonRequestToJSON,
    ApplicationStatus,
    ApplicationStatusFromJSON,
    ApplicationStatusFromJSONTyped,
    ApplicationStatusToJSON,
    UserAuthenticationConstraint,
    UserAuthenticationConstraintFromJSON,
    UserAuthenticationConstraintFromJSONTyped,
    UserAuthenticationConstraintToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationUpdateRequest
 */
export interface ApplicationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    url?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUpdateRequest
     */
    isComponentSpecific?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    logoutUri?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUpdateRequest
     */
    canIssueIdTokens?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUpdateRequest
     */
    canIssueAccessTokens?: boolean;
    /**
     * 
     * @type {UserAuthenticationConstraint}
     * @memberof ApplicationUpdateRequest
     */
    userAuthenticationConstraint?: UserAuthenticationConstraint;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUpdateRequest
     */
    isSearchableInMinSide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUpdateRequest
     */
    requiresMfa?: boolean;
    /**
     * 
     * @type {UserType}
     * @memberof ApplicationUpdateRequest
     */
    userType?: UserType;
    /**
     * 
     * @type {ApplicationStatus}
     * @memberof ApplicationUpdateRequest
     */
    status?: ApplicationStatus;
    /**
     * 
     * @type {ApplicationMigrationStatus}
     * @memberof ApplicationUpdateRequest
     */
    migrationStatus?: ApplicationMigrationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUpdateRequest
     */
    isIdPortenFederationDisabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUpdateRequest
     */
    requiresElevatedSecurityPolicy?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUpdateRequest
     */
    canAppResponsiblesAppointExternalApprovers?: boolean;
    /**
     * 
     * @type {Array<ApplicationResponsiblePersonRequest>}
     * @memberof ApplicationUpdateRequest
     */
    responsiblePersons?: Array<ApplicationResponsiblePersonRequest> | null;
}

export function ApplicationUpdateRequestFromJSON(json: any): ApplicationUpdateRequest {
    return ApplicationUpdateRequestFromJSONTyped(json, false);
}

export function ApplicationUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'isComponentSpecific': !exists(json, 'isComponentSpecific') ? undefined : json['isComponentSpecific'],
        'logoutUri': !exists(json, 'logoutUri') ? undefined : json['logoutUri'],
        'canIssueIdTokens': !exists(json, 'canIssueIdTokens') ? undefined : json['canIssueIdTokens'],
        'canIssueAccessTokens': !exists(json, 'canIssueAccessTokens') ? undefined : json['canIssueAccessTokens'],
        'userAuthenticationConstraint': !exists(json, 'userAuthenticationConstraint') ? undefined : UserAuthenticationConstraintFromJSON(json['userAuthenticationConstraint']),
        'isSearchableInMinSide': !exists(json, 'isSearchableInMinSide') ? undefined : json['isSearchableInMinSide'],
        'requiresMfa': !exists(json, 'requiresMfa') ? undefined : json['requiresMfa'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'status': !exists(json, 'status') ? undefined : ApplicationStatusFromJSON(json['status']),
        'migrationStatus': !exists(json, 'migrationStatus') ? undefined : ApplicationMigrationStatusFromJSON(json['migrationStatus']),
        'isIdPortenFederationDisabled': !exists(json, 'isIdPortenFederationDisabled') ? undefined : json['isIdPortenFederationDisabled'],
        'requiresElevatedSecurityPolicy': !exists(json, 'requiresElevatedSecurityPolicy') ? undefined : json['requiresElevatedSecurityPolicy'],
        'canAppResponsiblesAppointExternalApprovers': !exists(json, 'canAppResponsiblesAppointExternalApprovers') ? undefined : json['canAppResponsiblesAppointExternalApprovers'],
        'responsiblePersons': !exists(json, 'responsiblePersons') ? undefined : (json['responsiblePersons'] === null ? null : (json['responsiblePersons'] as Array<any>).map(ApplicationResponsiblePersonRequestFromJSON)),
    };
}

export function ApplicationUpdateRequestToJSON(value?: ApplicationUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'shortName': value.shortName,
        'description': value.description,
        'url': value.url,
        'isComponentSpecific': value.isComponentSpecific,
        'logoutUri': value.logoutUri,
        'canIssueIdTokens': value.canIssueIdTokens,
        'canIssueAccessTokens': value.canIssueAccessTokens,
        'userAuthenticationConstraint': UserAuthenticationConstraintToJSON(value.userAuthenticationConstraint),
        'isSearchableInMinSide': value.isSearchableInMinSide,
        'requiresMfa': value.requiresMfa,
        'userType': UserTypeToJSON(value.userType),
        'status': ApplicationStatusToJSON(value.status),
        'migrationStatus': ApplicationMigrationStatusToJSON(value.migrationStatus),
        'isIdPortenFederationDisabled': value.isIdPortenFederationDisabled,
        'requiresElevatedSecurityPolicy': value.requiresElevatedSecurityPolicy,
        'canAppResponsiblesAppointExternalApprovers': value.canAppResponsiblesAppointExternalApprovers,
        'responsiblePersons': value.responsiblePersons === undefined ? undefined : (value.responsiblePersons === null ? null : (value.responsiblePersons as Array<any>).map(ApplicationResponsiblePersonRequestToJSON)),
    };
}


