/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationFilterRequest
 */
export interface ApplicationFilterRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationFilterRequest
     */
    applicationIds?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFilterRequest
     */
    includeGlobalValues?: boolean;
}

export function ApplicationFilterRequestFromJSON(json: any): ApplicationFilterRequest {
    return ApplicationFilterRequestFromJSONTyped(json, false);
}

export function ApplicationFilterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationFilterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationIds': !exists(json, 'applicationIds') ? undefined : json['applicationIds'],
        'includeGlobalValues': !exists(json, 'includeGlobalValues') ? undefined : json['includeGlobalValues'],
    };
}

export function ApplicationFilterRequestToJSON(value?: ApplicationFilterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationIds': value.applicationIds,
        'includeGlobalValues': value.includeGlobalValues,
    };
}


