/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserAccessContextOrigin {
    Self = 'Self',
    OtherContext = 'OtherContext'
}

export function UserAccessContextOriginFromJSON(json: any): UserAccessContextOrigin {
    return UserAccessContextOriginFromJSONTyped(json, false);
}

export function UserAccessContextOriginFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAccessContextOrigin {
    return json as UserAccessContextOrigin;
}

export function UserAccessContextOriginToJSON(value?: UserAccessContextOrigin | null): any {
    return value as any;
}

