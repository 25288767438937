/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExternalUserStatus {
    Active = 'Active',
    Inactive = 'Inactive'
}

export function ExternalUserStatusFromJSON(json: any): ExternalUserStatus {
    return ExternalUserStatusFromJSONTyped(json, false);
}

export function ExternalUserStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalUserStatus {
    return json as ExternalUserStatus;
}

export function ExternalUserStatusToJSON(value?: ExternalUserStatus | null): any {
    return value as any;
}

