import React, { useEffect, useState, useCallback } from 'react';
import { LoadingSpinner, Button, useBoundForm, SelectOption, SimpleModal } from 'common.ui';
import { accessRequestsClient } from 'api/AdgangClients';
import { AccessRequestStatus, AdgangAccessControl, GenericValidationError, UserType } from 'api/adgang';
import Page from 'components/page/Page';
import { useParams, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RoutePaths from 'RoutePaths';
import { useAccessRequest } from 'api/hooks/useAccessRequest';
import AccessSelectorModal from 'components/accessrequests/AccessSelectorModal';
import { usePermission, useRole } from 'api/hooks';
import Frame from 'react-frame-component';
import './AccessRequest.scss';

type AccessRequestForm = {
  response: AccessRequestStatus;
  note: string;
  seq: number;
  modifiedRole?: SelectOption;
  modifiedPermission?: SelectOption;
  userConfirmation?: boolean;
};

interface IValue {
  label: string;
  value?: string | null;
}

interface IParams {
  id: string;
}

const isChecked = (value: boolean | string | string[] | undefined): boolean => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return value === 'on';
  }
  if (Array.isArray(value) && value.length > 0) {
    return isChecked(value[0]);
  }
  return false;
};

function AccessRequest() {
  const history = useHistory();
  const { id } = useParams<IParams>();
  const [accessRequest] = useAccessRequest(+id);

  const [assignedRoleOption] = useRole(accessRequest?.assignedRoleId);
  const [assignPermissionOption] = usePermission(accessRequest?.assignedPermissionId);

  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [showChangeAccessModal, setShowChangeAccessModal] = useState(false);

  const hasAccess = (accessControl: AdgangAccessControl): boolean => {
    return !!accessRequest?.accessControls && accessRequest.accessControls.some((x) => x === accessControl);
  };

  const [content, setContent] = useState({
    seq: 0,
    show: false,
    html: ``
  });

  const [values, setValues] = useState<AccessRequestForm>({
    response: AccessRequestStatus.Granted,
    note: '',
    userConfirmation: undefined,
    seq: 0
  });

  const isUnlocksExternalApproverRightsRequest =
    accessRequest?.isUnlocksExternalApproverRightsRequest ?? false;

  const isReadOnly =
    isUnlocksExternalApproverRightsRequest &&
    !hasAccess(AdgangAccessControl.AccessRequestApproveExternalApproverRole);

  useEffect(() => {
    setValues((v) => {
      return {
        ...v,
        modifiedRole: assignedRoleOption,
        modifiedPermission: assignPermissionOption,
        seq: v.seq + 1
      };
    });
  }, [assignedRoleOption, assignPermissionOption]);

  async function saveSelectedAccess(role: SelectOption | undefined, permission: SelectOption | undefined) {
    const request = {
      id: +id,
      accessRequestUpdateAccessRequest: {
        assignedRoleId: role ? +role.id : undefined,
        assignedPermissionId: permission ? +permission.id : undefined
      }
    };

    await accessRequestsClient.apiAccessRequestsIdAccessPut(request);

    const selected = role || permission;
    setValues({
      ...values,
      modifiedRole: role,
      modifiedPermission: permission,
      response: selected ? AccessRequestStatus.Granted : values.response,
      seq: values.seq + 1
    });

    setShowChangeAccessModal(false);
  }

  const ShowPreview = async (model: AccessRequestForm) => {
    const form = {
      ...values,
      response: model.response,
      note: model.note,
      modifiedRole: values.modifiedRole,
      modifiedPermission: values.modifiedPermission,
      // TODO: this is a fix to bypass useBoundForm.Checkbox inconsistent behaviour (['on'], true, 'on')
      userConfirmation: isChecked(model.userConfirmation),
      seq: values.seq + 1
    };

    const request = {
      id: +id,
      accessRequestUpdateStatusRequest: {
        note: form.note,
        status: form.response,
        userConfirmation: form.userConfirmation
      }
    };

    try {
      const preview = await accessRequestsClient.apiAccessRequestsIdStatusPreviewPost(request);
      setApiErrors(undefined);
      setContent({
        html: preview.email ?? '',
        seq: content.seq + 1,
        show: true
      });
    } catch (e) {
      if (e) {
        const result = (await (e as any).json()) as GenericValidationError;
        if (result) {
          setApiErrors(result);
        }
      }
    }
  };
  const OnFormSubmit = useCallback(
    async (model: AccessRequestForm) => {
      const form = {
        ...values,
        response: model.response,
        note: model.note,
        modifiedRole: values.modifiedRole,
        modifiedPermission: values.modifiedPermission,
        // TODO: this is a fix to bypass useBoundForm.Checkbox inconsistent behaviour (['on'], true, 'on')
        userConfirmation: isChecked(model.userConfirmation),
        seq: values.seq + 1
      };
      setValues(form);

      const request = {
        id: +id,
        accessRequestUpdateStatusRequest: {
          note: form.note,
          status: form.response,
          userConfirmation: form.userConfirmation
        }
      };

      try {
        await accessRequestsClient.apiAccessRequestsIdStatusPut(request);
        setApiErrors(undefined);
        history.push(RoutePaths.accessrequests);
      } catch (e) {
        if (e) {
          const result = (await (e as any).json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
      }
    },
    [history, id, values]
  );

  const { form, FormContainer, RadioGroup, DisplayErrors, TextArea, Checkbox, Submit } = useBoundForm<
    AccessRequestForm
  >({
    onSubmit: async (e: AccessRequestForm) => {
      await OnFormSubmit(e);
    },
    errors: apiErrors,
    model: values
  });

  const InfoField = ({ label, value }: IValue) => (
    <Row className='info-field-row'>
      <Col>
        <b>{label}</b>
        <div>{value}</div>
      </Col>
    </Row>
  );

  if (!accessRequest || form.isLoading) return <LoadingSpinner />;

  if (accessRequest.status !== AccessRequestStatus.Processing)
    return (
      <Page header='Rettighetsforespørselen har blitt behandlet'>
        <p>
          Rettighetsforespørselen {accessRequest.receiptCode} har blitt{' '}
          {accessRequest.status === AccessRequestStatus.Granted ? 'godkjent' : 'avvist'}.
        </p>
      </Page>
    );

  function renderIdPortenInfo() {
    return (
      <div className='idporten-container'>
        <InfoField label='Altinn profilnavn' value={accessRequest?.altinn?.displayName} />
      </div>
    );
  }

  function renderKrrUserInfo() {
    return (
      <div className='idporten-container'>
        <InfoField label='KRR e-post' value={accessRequest?.userKrr?.email} />
        <InfoField label='KRR mobiltelefonnummer' value={accessRequest?.userKrr?.mobileNr} />
      </div>
    );
  }

  return (
    <>
      <Page header='Detaljer om rettighetsforespørsel'>
        <>
          {accessRequest?.requestedByUserType && (
            <AccessSelectorModal
              show={showChangeAccessModal}
              key={accessRequest.accessReqeuestId}
              header='Gi en annen rettighet'
              userType={accessRequest?.requestedByUserType}
              requireUserOrganizationInMinSide={false}
              canChangeApplication={false}
              initialApplicationId={accessRequest.applicationId}
              initialRoleId={values.modifiedRole ? +values.modifiedRole.id : undefined}
              initialPermissionId={values.modifiedPermission ? +values.modifiedPermission.id : undefined}
              onCancel={() => setShowChangeAccessModal(false)}
              onAccessSelected={async (role, permission) => {
                let result: GenericValidationError | undefined;
                try {
                  await saveSelectedAccess(role, permission);
                  result = undefined;
                } catch (e) {
                  if (e) {
                    result = (await (e as any).json()) as GenericValidationError;
                  }
                }
                return result;
              }}
            />
          )}

          <SimpleModal
            size='lg'
            header='Forhåndsvisning Bruker E-post'
            show={content.show}
            onCancel={() =>
              setContent({
                ...content,
                show: false
              })
            }
          >
            <Frame
              key={content.seq}
              style={{ border: 'None', height: '70vh', width: '100%' }}
              initialContent={content.html}
            >
              {' '}
            </Frame>
          </SimpleModal>
          <Container fluid>
            <FormContainer form={form} key={values.seq}>
              <Row>
                <Col sm={12} lg={8} xl={4} className='info-field-group'>
                  <p>
                    <b className='info-field-group--title'> Brukerinformasjon </b>
                  </p>

                  <InfoField label='Navn' value={accessRequest?.requestedByUserName} />
                  <InfoField label='E-post' value={accessRequest?.email} />
                  {accessRequest?.requestedByUserMfaPhone && (
                    <InfoField label='Mobiltelefonnummer' value={accessRequest?.requestedByUserMfaPhone} />
                  )}
                  <InfoField
                    label='Id Porten verifisert?'
                    value={accessRequest.isRequestedByUserIdPortenVerified ? 'Ja' : 'Nei'}
                  />
                  {accessRequest.isRequestedByUserIdPortenVerified ? renderIdPortenInfo() : null}
                  {accessRequest.isRequestedByUserIdPortenVerified ? renderKrrUserInfo() : null}
                  <InfoField label='Arbeidsgiver' value={`${accessRequest?.employer ?? 'Ukjent'}`} />

                  {accessRequest?.employer && accessRequest?.requestedByUserType === UserType.External && (
                    <InfoField
                      label='Arbeidsforhold verifisert'
                      value={
                        accessRequest?.isEmployerVerified
                          ? 'Ja - brukerens arbeidsforhold er verifisert av systemet'
                          : 'Nei - brukerens arbeidsforhold må sjekkes manuelt'
                      }
                    />
                  )}
                </Col>
                <Col sm={12} lg={8} xl={4} className='info-field-group'>
                  <p>
                    <b className='info-field-group--title'> Rettighetsforespørsel </b>
                  </p>
                  <InfoField
                    label='Applikasjon'
                    value={accessRequest?.applicationDescription || accessRequest?.applicationName}
                  />

                  <InfoField
                    label='Rettighet'
                    value={accessRequest?.requestedRoleName || accessRequest?.requestedPermissionName}
                  />
                  {(values.modifiedRole || values.modifiedPermission) && (
                    <InfoField
                      label='Tildelt rettighet'
                      value={(values.modifiedRole || values.modifiedPermission)?.text}
                    />
                  )}

                  {accessRequest?.organizationId && (
                    <InfoField label='På vegne av organisasjon' value={accessRequest?.organization?.name} />
                  )}
                  <InfoField label='Begrunnelse gitt av bruker' value={accessRequest?.description} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={8} xl={8}>
                  <DisplayErrors form={form} />
                </Col>
                <Col sm={12}>
                  <br />
                  <RadioGroup
                    form={form}
                    name='response'
                    label='Hva er din beslutning?'
                    readonly={isReadOnly}
                    onChange={(value) => {
                      const status = value as AccessRequestStatus;
                      setValues({
                        ...values,
                        response: status,
                        seq: values.seq + 1
                      });
                    }}
                    options={[
                      {
                        id: AccessRequestStatus.Granted,
                        text: 'Godkjenne'
                      },
                      { id: AccessRequestStatus.Denied, text: 'Avvise' }
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {!isUnlocksExternalApproverRightsRequest && (
                    <>
                      <Button text='Gi annen rettighet' onClick={() => setShowChangeAccessModal(true)} />

                      {(values.modifiedRole || values.modifiedPermission) && (
                        <Button
                          text='Fjern tildelt rettighet'
                          onClick={() => saveSelectedAccess(undefined, undefined)}
                        />
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={8}>
                  <TextArea
                    height='16em'
                    form={form}
                    readonly={isReadOnly}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        note: value
                      });
                    }}
                    name='note'
                    label='Eventuel tilbakemelding til bruker'
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={8}>
                  <Checkbox
                    form={form}
                    disabled={isReadOnly}
                    name='userConfirmation'
                    question=''
                    label='Jeg bekrefter at jeg ved godkjenning har sjekket at det er en rimelig sammenheng mellom bruker, arbeidssted, ev. hvilken organisasjon bruker ønsker å representere og den rettighet bruker har bedt om. Er du i tvil må du kontakte bruker på epost/telefon før du gir bruker rettigheten.'
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={8}>
                  <Button type='submit' disabled={isReadOnly} text='Fullfør' />
                  <Submit
                    form={form}
                    text='Forhåndsvisning'
                    preSubmit={(f) => {
                      ShowPreview(f);
                      return false;
                    }}
                  />
                  <Button
                    type='button'
                    onClick={() => history.push(RoutePaths.accessrequests)}
                    text='Avbryt'
                    styleType='light'
                  />
                </Col>
              </Row>
            </FormContainer>
          </Container>
        </>
      </Page>
    </>
  );
}

export default AccessRequest;
