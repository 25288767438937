/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdGroupDetailResponse,
    AdGroupDetailResponseFromJSON,
    AdGroupDetailResponseToJSON,
    AdGroupDetailResponsePagedResult,
    AdGroupDetailResponsePagedResultFromJSON,
    AdGroupDetailResponsePagedResultToJSON,
    AdGroupDetailSearchOrderBy,
    AdGroupDetailSearchOrderByFromJSON,
    AdGroupDetailSearchOrderByToJSON,
    AdGroupPermissionsUpdateRequest,
    AdGroupPermissionsUpdateRequestFromJSON,
    AdGroupPermissionsUpdateRequestToJSON,
    AdGroupRolesUpdateRequest,
    AdGroupRolesUpdateRequestFromJSON,
    AdGroupRolesUpdateRequestToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    PermissionResponse,
    PermissionResponseFromJSON,
    PermissionResponseToJSON,
    RoleResponse,
    RoleResponseFromJSON,
    RoleResponseToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface ApiAdGroupsAdGroupIdGetRequest {
    adGroupId: string;
}

export interface ApiAdGroupsAdGroupIdPermissionsGetRequest {
    adGroupId: string;
}

export interface ApiAdGroupsAdGroupIdPermissionsPutRequest {
    adGroupId: string;
    adGroupPermissionsUpdateRequest?: AdGroupPermissionsUpdateRequest;
}

export interface ApiAdGroupsAdGroupIdRolesGetRequest {
    adGroupId: string;
}

export interface ApiAdGroupsAdGroupIdRolesPutRequest {
    adGroupId: string;
    adGroupRolesUpdateRequest?: AdGroupRolesUpdateRequest;
}

export interface ApiAdGroupsGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: AdGroupDetailSearchOrderBy;
    filter?: string;
}

/**
 * 
 */
export class AdGroupsApi extends runtime.BaseAPI {

    /**
     */
    async apiAdGroupsAdGroupIdGetRaw(requestParameters: ApiAdGroupsAdGroupIdGetRequest): Promise<runtime.ApiResponse<AdGroupDetailResponse>> {
        if (requestParameters.adGroupId === null || requestParameters.adGroupId === undefined) {
            throw new runtime.RequiredError('adGroupId','Required parameter requestParameters.adGroupId was null or undefined when calling apiAdGroupsAdGroupIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ad-groups/{adGroupId}`.replace(`{${"adGroupId"}}`, encodeURIComponent(String(requestParameters.adGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdGroupDetailResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAdGroupsAdGroupIdGet(requestParameters: ApiAdGroupsAdGroupIdGetRequest): Promise<AdGroupDetailResponse> {
        const response = await this.apiAdGroupsAdGroupIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdGroupsAdGroupIdPermissionsGetRaw(requestParameters: ApiAdGroupsAdGroupIdPermissionsGetRequest): Promise<runtime.ApiResponse<Array<PermissionResponse>>> {
        if (requestParameters.adGroupId === null || requestParameters.adGroupId === undefined) {
            throw new runtime.RequiredError('adGroupId','Required parameter requestParameters.adGroupId was null or undefined when calling apiAdGroupsAdGroupIdPermissionsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ad-groups/{adGroupId}/permissions`.replace(`{${"adGroupId"}}`, encodeURIComponent(String(requestParameters.adGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PermissionResponseFromJSON));
    }

    /**
     */
    async apiAdGroupsAdGroupIdPermissionsGet(requestParameters: ApiAdGroupsAdGroupIdPermissionsGetRequest): Promise<Array<PermissionResponse>> {
        const response = await this.apiAdGroupsAdGroupIdPermissionsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdGroupsAdGroupIdPermissionsPutRaw(requestParameters: ApiAdGroupsAdGroupIdPermissionsPutRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.adGroupId === null || requestParameters.adGroupId === undefined) {
            throw new runtime.RequiredError('adGroupId','Required parameter requestParameters.adGroupId was null or undefined when calling apiAdGroupsAdGroupIdPermissionsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ad-groups/{adGroupId}/permissions`.replace(`{${"adGroupId"}}`, encodeURIComponent(String(requestParameters.adGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdGroupPermissionsUpdateRequestToJSON(requestParameters.adGroupPermissionsUpdateRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiAdGroupsAdGroupIdPermissionsPut(requestParameters: ApiAdGroupsAdGroupIdPermissionsPutRequest): Promise<Array<number>> {
        const response = await this.apiAdGroupsAdGroupIdPermissionsPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdGroupsAdGroupIdRolesGetRaw(requestParameters: ApiAdGroupsAdGroupIdRolesGetRequest): Promise<runtime.ApiResponse<Array<RoleResponse>>> {
        if (requestParameters.adGroupId === null || requestParameters.adGroupId === undefined) {
            throw new runtime.RequiredError('adGroupId','Required parameter requestParameters.adGroupId was null or undefined when calling apiAdGroupsAdGroupIdRolesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ad-groups/{adGroupId}/roles`.replace(`{${"adGroupId"}}`, encodeURIComponent(String(requestParameters.adGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleResponseFromJSON));
    }

    /**
     */
    async apiAdGroupsAdGroupIdRolesGet(requestParameters: ApiAdGroupsAdGroupIdRolesGetRequest): Promise<Array<RoleResponse>> {
        const response = await this.apiAdGroupsAdGroupIdRolesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdGroupsAdGroupIdRolesPutRaw(requestParameters: ApiAdGroupsAdGroupIdRolesPutRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.adGroupId === null || requestParameters.adGroupId === undefined) {
            throw new runtime.RequiredError('adGroupId','Required parameter requestParameters.adGroupId was null or undefined when calling apiAdGroupsAdGroupIdRolesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ad-groups/{adGroupId}/roles`.replace(`{${"adGroupId"}}`, encodeURIComponent(String(requestParameters.adGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdGroupRolesUpdateRequestToJSON(requestParameters.adGroupRolesUpdateRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiAdGroupsAdGroupIdRolesPut(requestParameters: ApiAdGroupsAdGroupIdRolesPutRequest): Promise<Array<number>> {
        const response = await this.apiAdGroupsAdGroupIdRolesPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiAdGroupsGetRaw(requestParameters: ApiAdGroupsGetRequest): Promise<runtime.ApiResponse<AdGroupDetailResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ad-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdGroupDetailResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAdGroupsGet(requestParameters: ApiAdGroupsGetRequest): Promise<AdGroupDetailResponsePagedResult> {
        const response = await this.apiAdGroupsGetRaw(requestParameters);
        return await response.value();
    }

}
