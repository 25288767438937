/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessMetaDataResponse,
    AccessMetaDataResponseFromJSON,
    AccessMetaDataResponseFromJSONTyped,
    AccessMetaDataResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserClaimsResponse
 */
export interface UserClaimsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserClaimsResponse
     */
    roles?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserClaimsResponse
     */
    permissions?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserClaimsResponse
     */
    globalRoles?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserClaimsResponse
     */
    globalPermissions?: Array<string> | null;
    /**
     * 
     * @type {Array<AccessMetaDataResponse>}
     * @memberof UserClaimsResponse
     */
    accessMetaData?: Array<AccessMetaDataResponse> | null;
    /**
     * 
     * @type {Date}
     * @memberof UserClaimsResponse
     */
    lastChangeTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserClaimsResponse
     */
    anyDependencyRequiresMfa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserClaimsResponse
     */
    anyDependencyRequiresIdPortenMfa?: boolean;
}

export function UserClaimsResponseFromJSON(json: any): UserClaimsResponse {
    return UserClaimsResponseFromJSONTyped(json, false);
}

export function UserClaimsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserClaimsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'globalRoles': !exists(json, 'globalRoles') ? undefined : json['globalRoles'],
        'globalPermissions': !exists(json, 'globalPermissions') ? undefined : json['globalPermissions'],
        'accessMetaData': !exists(json, 'accessMetaData') ? undefined : (json['accessMetaData'] === null ? null : (json['accessMetaData'] as Array<any>).map(AccessMetaDataResponseFromJSON)),
        'lastChangeTime': !exists(json, 'lastChangeTime') ? undefined : (new Date(json['lastChangeTime'])),
        'anyDependencyRequiresMfa': !exists(json, 'anyDependencyRequiresMfa') ? undefined : json['anyDependencyRequiresMfa'],
        'anyDependencyRequiresIdPortenMfa': !exists(json, 'anyDependencyRequiresIdPortenMfa') ? undefined : json['anyDependencyRequiresIdPortenMfa'],
    };
}

export function UserClaimsResponseToJSON(value?: UserClaimsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roles': value.roles,
        'permissions': value.permissions,
        'globalRoles': value.globalRoles,
        'globalPermissions': value.globalPermissions,
        'accessMetaData': value.accessMetaData === undefined ? undefined : (value.accessMetaData === null ? null : (value.accessMetaData as Array<any>).map(AccessMetaDataResponseToJSON)),
        'lastChangeTime': value.lastChangeTime === undefined ? undefined : (value.lastChangeTime.toISOString()),
        'anyDependencyRequiresMfa': value.anyDependencyRequiresMfa,
        'anyDependencyRequiresIdPortenMfa': value.anyDependencyRequiresIdPortenMfa,
    };
}


