/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestSearchOrderBy,
    AccessRequestSearchOrderByFromJSON,
    AccessRequestSearchOrderByFromJSONTyped,
    AccessRequestSearchOrderByToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderFromJSONTyped,
    SortOrderToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccessRequestSearchOrderByPaginationQuery
 */
export interface AccessRequestSearchOrderByPaginationQuery {
    /**
     * 
     * @type {number}
     * @memberof AccessRequestSearchOrderByPaginationQuery
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestSearchOrderByPaginationQuery
     */
    pageSize?: number;
    /**
     * 
     * @type {SortOrder}
     * @memberof AccessRequestSearchOrderByPaginationQuery
     */
    sortOrder?: SortOrder;
    /**
     * 
     * @type {AccessRequestSearchOrderBy}
     * @memberof AccessRequestSearchOrderByPaginationQuery
     */
    orderField?: AccessRequestSearchOrderBy;
}

export function AccessRequestSearchOrderByPaginationQueryFromJSON(json: any): AccessRequestSearchOrderByPaginationQuery {
    return AccessRequestSearchOrderByPaginationQueryFromJSONTyped(json, false);
}

export function AccessRequestSearchOrderByPaginationQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestSearchOrderByPaginationQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : SortOrderFromJSON(json['sortOrder']),
        'orderField': !exists(json, 'orderField') ? undefined : AccessRequestSearchOrderByFromJSON(json['orderField']),
    };
}

export function AccessRequestSearchOrderByPaginationQueryToJSON(value?: AccessRequestSearchOrderByPaginationQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'sortOrder': SortOrderToJSON(value.sortOrder),
        'orderField': AccessRequestSearchOrderByToJSON(value.orderField),
    };
}


