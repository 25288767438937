import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  PagedTable,
  Th,
  LoadingSpinner,
  useFetch,
  ConfirmationModal,
  useBoundForm,
  SelectOption,
  FilterLabel,
  Button,
  FilterReset,
  ThSortable,
  useSortedPaging,
  SimpleModal
} from 'common.ui';
import { permissionsClient } from 'api/AdgangClients';
import { ReactComponent as IconDelete } from 'assets/icons/delete-icon.svg';
import {
  AdgangAccessControl,
  ApiPermissionsSearchesPostRequest,
  PermissionConsumptionResponse,
  PermissionResponse,
  PermissionSearchOrderBy,
  ResourceFilter,
  SortOrder,
  UserType
} from 'api/adgang';
import { useApplications, useRoles } from 'api/hooks';
import Page from 'components/page/Page';
import { Row, Col, Container } from 'react-bootstrap';
import { useSavedSearch } from 'hooks/search/useSavedSearch';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import RoutePaths from 'RoutePaths';
import { useAccessControlList } from 'hooks/access/useAccessControlList';

type Search = {
  textFilter: string;
  applicationIds: string[];
  roleIds: string[];
  userType?: UserType;
  seq: number;
};

interface IPermissionsScreen {
  applications: SelectOption[];
  initalSearch: Search;
  saveSearch: (value: Search) => void;
}

const emptySearch: Search = {
  applicationIds: [],
  roleIds: [],
  seq: 0,
  textFilter: '',
  userType: undefined
};
const hasSearchFilters = (model: Search): boolean => {
  return !!(model.applicationIds.length || model.roleIds.length || model.textFilter || model.userType);
};

function Permissions() {
  const [, hasAccess] = useAccessControlList();
  const [applications] = useApplications(
    undefined,
    ResourceFilter.Permission,
    !hasAccess(AdgangAccessControl.PermissionsViewShowNoAppFilter)
  );

  const [initialSearch, saveSearch] = useSavedSearch<Search>('permissions_search', emptySearch);

  return applications ? (
    <PermissionsScreen applications={applications} initalSearch={initialSearch} saveSearch={saveSearch} />
  ) : (
    <LoadingSpinner />
  );
}

function PermissionsScreen({ applications, initalSearch, saveSearch }: IPermissionsScreen) {
  const [unvalidatedSearch, setSearch] = useState<Search>(initalSearch);
  const [deletePermission, setDeletePermission] = useState<PermissionConsumptionResponse>();
  const [roles] = useRoles(
    unvalidatedSearch?.userType,
    unvalidatedSearch?.applicationIds,
    undefined,
    ResourceFilter.Permission
  );

  const history = useHistory();

  const userTypes = [
    { id: UserType.Internal, text: 'Interne ansatte' },
    { id: UserType.External, text: 'Eksterne brukere' }
  ];

  const search = cleanSearch(unvalidatedSearch);

  const [permissionsPaged, isLoading, , fetchPermissions] = useFetch(
    () => fetchPagedPermission(),
    {},
    false,
    [search.seq]
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    setCurrentPage,
    setSorting
  ] = useSortedPaging<PermissionSearchOrderBy>(fetchPermissions, {
    field: PermissionSearchOrderBy.Name,
    direction: 'asc'
  });

  const fetchPagedPermission = async () => {
    const request = {
      permissionSearchRequest: {
        paginationQuery: {
          pageSize,
          pageNumber: currentPage,
          sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
          orderField: sorting.field
        },
        roleFilter:
          search.roleIds.length === 0
            ? undefined
            : {
                roleIds: search.roleIds
                  .filter((r) => roles?.some((ro) => ro.id === r))
                  .map((r) => parseInt(r, 10))
              },
        applicationFilter:
          search.applicationIds.length > 0
            ? {
                applicationIds: search.applicationIds.filter((a) => a !== 'global'),
                includeGlobalValues: search.applicationIds.some((a) => a === 'global')
              }
            : undefined,
        permissionIds: [],
        textFilter: search?.textFilter,
        userType: (search?.userType || '') !== '' ? search?.userType : undefined,
        resourceFilter: ResourceFilter.Permission
      }
    } as ApiPermissionsSearchesPostRequest;
    return permissionsClient.apiPermissionsSearchesPost(request);
  };

  const { form, FormContainer, RadioGroup, Input, MultiSelect } = useBoundForm<Search>({
    onSubmit: async (e) => {
      runSearch(e);
    },
    model: search
  });

  const allowedToDeletePermission =
    deletePermission?.accessControls?.some((a) => a === AdgangAccessControl.PermissionDelete) ?? false;

  function onCancelDelete() {
    setDeletePermission(undefined);
  }

  async function onDeleteConfirmed() {
    if (deletePermission?.permissionId) {
      await permissionsClient.apiPermissionsIdDelete({
        id: deletePermission.permissionId
      });
      history.go(0); // refresh page
    }
  }

  function cleanSearch(s: Search) {
    const model: Search = {
      ...s,
      // Only search for roles, that are valid options based on your role dropdown
      // (which can change dynamically whenever a user multiselects an application)
      roleIds: s.roleIds?.filter((r) => roles?.some((ro) => ro.id === r))
    };
    return model;
  }
  function runSearch(s: Search) {
    const model = cleanSearch(s);

    setCurrentPage(1);
    saveSearch({ ...model, seq: 0 });
    setSearch({ ...model, seq: search.seq + 1 });
  }

  const handleSort = (e: SortEvent<PermissionSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  function renderNumber(result: JSX.Element[], num: number | undefined, text: string, pluralText: string) {
    const n = num ?? 0;
    if (n === 1) {
      result.push(<>1 {text}</>);
    } else if (n > 1) {
      result.push(
        <>
          {n} {pluralText}
        </>
      );
    }
  }

  function renderUsage(p: PermissionConsumptionResponse | undefined) {
    if (p === undefined || !p.isConsumedByOtherEntitities) return <></>;

    const result: JSX.Element[] = [];
    renderNumber(result, p.numUserPermissions, 'bruker', 'brukere');
    renderNumber(result, p.numActiveAccessRequests, 'rettighetsforespørsel', 'rettighetsforespørsler');
    renderNumber(result, p.numRolePermissions, 'rolle', 'roller');
    renderNumber(result, p.numAdGroupPermissions, 'AD-gruppe', 'AD-grupper');
    renderNumber(result, p.numSectionPermissions, 'seksjon', 'seksjoner');

    return (
      <>
        <br />
        <span style={{ background: '#faf8dd' }}>
          ! Tilgangen er i bruk av:
          {result.map((r, i) => (
            <>
              {i === 0 ? ' ' : ', '}
              {r}
            </>
          ))}
        </span>
      </>
    );
  }
  async function requestDelete(permission: PermissionResponse) {
    const p = await permissionsClient.apiPermissionsCheckusageGet({
      id: permission.permissionId
    });
    setDeletePermission(p);
  }

  return (
    <Page header='Tilganger'>
      <ConfirmationModal
        header='Slett tilgang'
        show={deletePermission !== undefined && allowedToDeletePermission}
        onCancel={onCancelDelete}
        onAccept={onDeleteConfirmed}
      >
        Er du sikker på at du vil slett denne tilgangen?
        {renderUsage(deletePermission)}
        <br />
      </ConfirmationModal>{' '}
      <SimpleModal
        header='Slett tilgang'
        onCancel={onCancelDelete}
        show={deletePermission !== undefined && !allowedToDeletePermission}
      >
        Du har ikke lov til å slette denne tilgang.
        {renderUsage(deletePermission)}
      </SimpleModal>
      <Container fluid>
        <FormContainer key={search.seq} form={form}>
          <Row>
            <Col sm={12} lg={4}>
              <Input
                form={form}
                name='textFilter'
                label='Søk'
                placeholder='Søker i alle tekstfelt'
                onEnterPressed={(e) => {
                  runSearch({
                    ...search,
                    textFilter: e,
                    seq: search.seq + 1
                  });
                }}
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='applicationIds'
                label='Applikasjon'
                okLabel='Vis resultater'
                options={applications}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    applicationIds: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='roleIds'
                label='Rolle'
                okLabel='Vis resultater'
                options={roles ?? []}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    roleIds: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <RadioGroup form={form} name='userType' label='Vis bare tilganger for:' options={userTypes} />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {search.textFilter && search.textFilter !== '' && (
                <FilterLabel
                  key='textFilter'
                  label={search.textFilter}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      textFilter: '',
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {search.applicationIds &&
                search.applicationIds.map((app) => (
                  <FilterLabel
                    key={`applicationId${app}`}
                    label={applications.filter((a) => a.id === app)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        applicationIds: search.applicationIds.filter((a) => a !== app),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {search.roleIds &&
                roles &&
                search.roleIds.map((role) => (
                  <FilterLabel
                    key={`role${role}`}
                    label={roles.filter((r) => r.id === role)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        roleIds: search.roleIds.filter((r) => r !== role),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {search.userType && (
                <FilterLabel
                  key='userType'
                  label={userTypes.filter((u) => u.id === search.userType)[0].text}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      userType: undefined,
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {hasSearchFilters(search) && (
                <FilterReset
                  key='reset'
                  label='Fjern alle'
                  onRemove={() => {
                    runSearch({
                      ...emptySearch
                    });
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Button type='submit' text='Søk' />
              <Button text='Legg til' onClick={() => history.push(RoutePaths.permissionsAdd)} />
            </Col>
          </Row>
        </FormContainer>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <PagedTable
              startIndex={startIndex}
              totalHits={permissionsPaged.pageInfo?.totalCount ?? 0}
              pageSize={pageSize}
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
            >
              <thead>
                <tr>
                  <ThSortable
                    title='Tilgangsnavn'
                    field={PermissionSearchOrderBy.Name}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Beskrivelse'
                    field={PermissionSearchOrderBy.Description}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Applikasjon'
                    field={PermissionSearchOrderBy.ApplicationName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />

                  <Th title='' />
                </tr>
              </thead>

              <tbody>
                {permissionsPaged.data?.map((r) => (
                  <tr key={r.permissionId ?? ''}>
                    <td>
                      <Link to={`${RoutePaths.permissions}/${r.permissionId}`}>{r.name}</Link>
                    </td>
                    <td>{r.description}</td>
                    <td>{r.applicationName}</td>
                    <td>
                      <IconDelete onClick={() => requestDelete(r)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </PagedTable>
          </>
        )}
      </Container>
    </Page>
  );
}

export default Permissions;
