import React, { FunctionComponent } from 'react';
// import SvgElement from '../svgElement/SvgElement'

import styles from './_pagination.scss';
// import arrow from '../../../assets/icons/icon-arrow-right-darkblue.svg'

interface Props {
  startIndex: number;
  totalHits: number;
  pageSize: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
  previousText?: string;
  nextText?: string;
  ofText?: string;
}
const Pagination: FunctionComponent<Props> = (props) => {
  const {
    startIndex,
    pageSize,
    totalHits,
    previousText,
    nextText,
    ofText
  } = props;
  return (
    <div className={styles.c_pagination}>
      <div className={styles['c_pagination__page-info']}>
        <span>{startIndex + 1} – </span>
        <span>
          {startIndex + pageSize > totalHits
            ? totalHits
            : startIndex + pageSize}
        </span>
        <span>
          {' '}
          {ofText || 'av'} {totalHits}
        </span>
      </div>
      <button
        type='button'
        className={`${styles.c_pagination__button} ${
          styles['c_pagination__button--previous']
        } ${
          startIndex - pageSize < 0
            ? styles['c_pagination__button--disabled']
            : ''
        }`}
        onClick={() =>
          props.startIndex - props.pageSize >= 0 && props.onPreviousClick()
        }
      >
        {/* <SvgElement
                src={arrow}
                title="Pil venstre"
                desc="Vis forrige props.pageSize"
            /> */}
        <span>{previousText || 'Forrige'}</span>
      </button>
      <button
        type='button'
        className={`${styles.c_pagination__button} ${
          styles['c_pagination__button--next']
        } ${
          startIndex + pageSize >= totalHits
            ? styles['c_pagination__button--disabled']
            : ''
        }`}
        onClick={() =>
          props.startIndex + props.pageSize < props.totalHits &&
          props.onNextClick()
        }
      >
        <span>{nextText || 'Neste'}</span>
        {/* <SvgElement src={arrow} title='Pil høyre' desc='Vis neste props.pageSize' /> */}
      </button>
    </div>
  );
};

export { Pagination };
