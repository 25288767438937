import React, { useState } from 'react';
import { Button, TextArea } from 'common.ui';
import Modal from 'react-bootstrap/Modal';
import { TranslationResponse } from 'api/adgang';

export type IProps = {
  show: boolean;
  item: TranslationResponse;
  onCancel: () => void;
  onAccept: (text: string) => void;
};

const EditTextPopupModal: React.FunctionComponent<IProps> = ({ show, item, onCancel, onAccept }) => {
  const [result, setResult] = useState<string>(item.text || '');

  const handleTextChanged = (value?: string | null | undefined) => {
    setResult(value || '');
  };

  return (
    <Modal size='lg' show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Endre tekst</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <TextArea id='text' labelText='Tekst' value={item.text} onBlur={handleTextChanged} />

        {item.placeholders && Object.keys(item.placeholders).length > 0 && (
          <>
            <h2>Plassholdere</h2>
            <ul style={{ paddingLeft: 0, marginLeft: '1em' }}>
              {item.placeholders &&
                Object.keys(item.placeholders).map((x) => (
                  <li key={x}>
                    &#123;{x}&#125;: {item.placeholders ? item.placeholders[x] : ''}
                  </li>
                ))}
            </ul>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          id='btnOk'
          type='button'
          text='Ok'
          onClick={() => {
            onAccept(result);
          }}
        />
        <Button type='button' text='Avbryt' styleType='light' onClick={onCancel} />
      </Modal.Footer>
    </Modal>
  );
};

export { EditTextPopupModal };
