import React, { useEffect, useState } from 'react';
import {
  LoadingSpinner,
  Button,
  useBoundForm,
  SimpleModal,
  SelectOption,
  SearchableSingleSelect
} from 'common.ui';
import { useApplications, useRoles, useRole } from 'api/hooks';
import { OrganizationResponse, ResourceFilter, UserType } from 'api/adgang';
import { useOrganizations } from 'api/hooks/useOrganizations';

type IProps = {
  header: string;
  show: boolean;
  userId?: string;
  userType: UserType;
  initialApplicationId?: string | null;
  initialRoleId?: number;
  initialOrganizationId?: number;
  additionalJsx?: JSX.Element;
  okText?: string;
  resourceFilter?: ResourceFilter;
  onCancel: () => void;
  onRoleSelected: (role: SelectOption, organizationId: string | null) => Promise<void>;
};

interface IPropsImpl {
  applications: SelectOption[];
}

type RoleForm = {
  applicationId: string;
  roleId: string;
  organizationId?: string;
};

function RoleSelectorModalImpl({
  userId,
  userType,
  applications,
  initialApplicationId,
  initialRoleId,
  initialOrganizationId,
  onCancel,
  onRoleSelected,
  additionalJsx,
  okText,
  resourceFilter
}: IProps & IPropsImpl) {
  const [values, setValues] = useState<RoleForm>({
    applicationId: initialApplicationId ?? 'global',
    roleId: initialRoleId ? initialRoleId.toString() : '',
    organizationId: initialOrganizationId ? initialOrganizationId.toString() : ''
  });

  const [organizationGroupIds, setOrganizationGroupIds] = useState<number[] | null | undefined>();

  const [roles, data] = useRoles(userType, values.applicationId, userId, resourceFilter);
  const [organizations] = useOrganizations(organizationGroupIds!, [organizationGroupIds]);

  const { form, FormContainer, Dropdown } = useBoundForm<RoleForm>({
    onSubmit: async (e) => {
      if (e.roleId)
        onRoleSelected(
          {
            id: e.roleId,
            text: roles?.filter((r) => r.id === e.roleId)[0].text ?? ''
          },
          values.organizationId === undefined ? null : values.organizationId
        );
    },
    model: values
  });

  useEffect(() => {
    if (roles !== undefined && roles.length > 0) {
      const orgGroupIds = data?.filter((d) => d.roleId?.toString() === roles[0].id)[0];
      setOrganizationGroupIds(orgGroupIds?.organizationGroupIds);
    }
  }, [roles]);

  function renderOrganizationDropDown() {
    if (
      organizationGroupIds === undefined ||
      organizationGroupIds === null ||
      organizationGroupIds?.length === 0
    )
      return null;

    const opt = organizations?.map((org: OrganizationResponse) => {
      return {
        id: org.organizationId?.toString(),
        text: org.name
      } as SelectOption;
    });

    if (opt?.length === 0) return null;

    return (
      <SearchableSingleSelect
        id='selected_organization'
        selectedId={values.organizationId !== undefined ? values.organizationId.toString() : ''}
        label='Organisasjon'
        options={opt || []}
        onChange={(val) => {
          setValues({ ...values, organizationId: val });
        }}
      />
      //   <Dropdown
      //     form={form}
      //     onChange={(e) => {
      //       setValues({ ...values, organizationId: e.target.value });
      //     }}
      //     options={opt || []}
      //     label='Organisasjon'
      //     name='organizationId'
      //   />
    );
  }

  return (
    <FormContainer form={form}>
      <Dropdown
        form={form}
        onChange={(e) => setValues({ ...values, applicationId: e.target.value })}
        options={applications}
        label='Applikasjon'
        name='applicationId'
      />
      <Dropdown
        form={form}
        onChange={(e) => {
          setValues({ ...values, roleId: e.target.value });
          const orgGroupIds = data?.filter((d) => d.roleId?.toString() === e.target.value)[0];
          setOrganizationGroupIds(orgGroupIds?.organizationGroupIds);
        }}
        options={roles || []}
        label='Rolle'
        name='roleId'
      />
      {renderOrganizationDropDown()}
      {additionalJsx}
      <Button type='submit' text={okText ?? 'Endre'} />
      <Button type='button' text='Avbryt' styleType='light' onClick={onCancel} />
    </FormContainer>
  );
}

function RoleSelectorModal(props: IProps) {
  const { header, show, onCancel, initialRoleId, initialApplicationId, userId, resourceFilter } = props;
  const [applications] = useApplications(userId, resourceFilter);
  const [, role] = useRole(initialRoleId);

  return (
    <SimpleModal header={header} show={show} onCancel={onCancel}>
      {applications && (!initialRoleId || (initialRoleId && role)) ? (
        <RoleSelectorModalImpl
          {...props}
          initialApplicationId={role ? role.applicationId : initialApplicationId}
          applications={applications}
        />
      ) : (
        <LoadingSpinner />
      )}
    </SimpleModal>
  );
}
export default RoleSelectorModal;
