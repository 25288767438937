/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExternalUserSearchOrderBy {
    DisplayName = 'DisplayName',
    Status = 'Status',
    OrganizationName = 'OrganizationName',
    OrganizationNumber = 'OrganizationNumber',
    OrganizationForm = 'OrganizationForm'
}

export function ExternalUserSearchOrderByFromJSON(json: any): ExternalUserSearchOrderBy {
    return ExternalUserSearchOrderByFromJSONTyped(json, false);
}

export function ExternalUserSearchOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalUserSearchOrderBy {
    return json as ExternalUserSearchOrderBy;
}

export function ExternalUserSearchOrderByToJSON(value?: ExternalUserSearchOrderBy | null): any {
    return value as any;
}

