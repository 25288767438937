import React from 'react';
import Page from 'components/page/Page';
import OrganizationGroupForm from 'components/organizationgroups/OrganizationGroupForm';
import { LoadingSpinner, useFetch } from 'common.ui';
import { OrganizationGroupUpdateRequest } from 'api/adgang/models';
import { organizationGroupsClient } from 'api/AdgangClients';
import { useHistory, useParams } from 'react-router-dom';
import RoutePaths from 'RoutePaths';

interface IParams {
  id: string;
}

function UpdateOrganizationGroup() {
  const history = useHistory();
  const { id } = useParams<IParams>();

  const [organizationGroup] = useFetch(
    async () => organizationGroupsClient.getOrganizationGroupById({ id: +id }),
    undefined,
    false,
    [id]
  );

  async function onSave(model: OrganizationGroupUpdateRequest) {
    await organizationGroupsClient.apiOrganizationGroupsIdPut({
      id: +id,
      organizationGroupUpdateRequest: model
    });
    history.push(RoutePaths.organizationgroups);
  }

  return (
    <Page header='Detaljer om organisasjonsgruppe'>
      {organizationGroup ? (
        <OrganizationGroupForm<OrganizationGroupUpdateRequest>
          id={+id}
          onSave={onSave}
          onSaveLabel='Lagre'
          initialValue={organizationGroup}
        />
      ) : (
        <LoadingSpinner />
      )}
    </Page>
  );
}

export default UpdateOrganizationGroup;
