/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationResponsiblePersonRequest
 */
export interface ApplicationResponsiblePersonRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponsiblePersonRequest
     */
    userId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponsiblePersonRequest
     */
    isApplicationAdministrator?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponsiblePersonRequest
     */
    canManageAccessRequestsForApplication?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponsiblePersonRequest
     */
    canAppointExternalApproversForApplication?: boolean;
}

export function ApplicationResponsiblePersonRequestFromJSON(json: any): ApplicationResponsiblePersonRequest {
    return ApplicationResponsiblePersonRequestFromJSONTyped(json, false);
}

export function ApplicationResponsiblePersonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponsiblePersonRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'isApplicationAdministrator': !exists(json, 'isApplicationAdministrator') ? undefined : json['isApplicationAdministrator'],
        'canManageAccessRequestsForApplication': !exists(json, 'canManageAccessRequestsForApplication') ? undefined : json['canManageAccessRequestsForApplication'],
        'canAppointExternalApproversForApplication': !exists(json, 'canAppointExternalApproversForApplication') ? undefined : json['canAppointExternalApproversForApplication'],
    };
}

export function ApplicationResponsiblePersonRequestToJSON(value?: ApplicationResponsiblePersonRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'isApplicationAdministrator': value.isApplicationAdministrator,
        'canManageAccessRequestsForApplication': value.canManageAccessRequestsForApplication,
        'canAppointExternalApproversForApplication': value.canAppointExternalApproversForApplication,
    };
}


