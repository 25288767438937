import { useState, useEffect } from 'react';
import { internalUsersClient } from 'api/AdgangClients';
import { SelectOption } from 'common.ui';
import { toFullname } from 'helpers/workLocation/toFullName';

export function useWorkLocations(): [SelectOption[] | undefined] {
  const [workLocations, setWorkLocations] = useState<SelectOption[]>();

  useEffect(() => {
    const loadWorkLocations = async () => {
      const w = await internalUsersClient.apiInternalUsersWorkLocationsGet();

      setWorkLocations(
        w.map((d) => {
          return {
            id: d,
            text: toFullname(d)
          } as SelectOption;
        })
      );
    };

    loadWorkLocations();
  }, []);

  return [workLocations];
}
