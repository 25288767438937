/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdgangAccessControl,
    AdgangAccessControlFromJSON,
    AdgangAccessControlFromJSONTyped,
    AdgangAccessControlToJSON,
    ApplicationExternalApproverResponse,
    ApplicationExternalApproverResponseFromJSON,
    ApplicationExternalApproverResponseFromJSONTyped,
    ApplicationExternalApproverResponseToJSON,
    ApplicationMigrationStatus,
    ApplicationMigrationStatusFromJSON,
    ApplicationMigrationStatusFromJSONTyped,
    ApplicationMigrationStatusToJSON,
    ApplicationResponsiblePersonResponse,
    ApplicationResponsiblePersonResponseFromJSON,
    ApplicationResponsiblePersonResponseFromJSONTyped,
    ApplicationResponsiblePersonResponseToJSON,
    ApplicationStatus,
    ApplicationStatusFromJSON,
    ApplicationStatusFromJSONTyped,
    ApplicationStatusToJSON,
    UserAuthenticationConstraint,
    UserAuthenticationConstraintFromJSON,
    UserAuthenticationConstraintFromJSONTyped,
    UserAuthenticationConstraintToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationResponse
 */
export interface ApplicationResponse {
    /**
     * 
     * @type {Array<AdgangAccessControl>}
     * @memberof ApplicationResponse
     */
    accessControls?: Array<AdgangAccessControl> | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    clientId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    isComponentSpecific?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationResponse
     */
    redirectUris?: Array<string> | null;
    /**
     * 
     * @type {UserAuthenticationConstraint}
     * @memberof ApplicationResponse
     */
    userAuthenticationConstraint?: UserAuthenticationConstraint;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    isSearchableInMinSide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    requiresMfa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    allowsExternalApprovers?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    canAppResponsiblesAppointExternalApprovers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    isIdPortenFederationDisabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    requiresElevatedSecurityPolicy?: boolean;
    /**
     * 
     * @type {UserType}
     * @memberof ApplicationResponse
     */
    userType?: UserType;
    /**
     * 
     * @type {ApplicationStatus}
     * @memberof ApplicationResponse
     */
    status?: ApplicationStatus;
    /**
     * 
     * @type {ApplicationMigrationStatus}
     * @memberof ApplicationResponse
     */
    migrationStatus?: ApplicationMigrationStatus;
    /**
     * 
     * @type {Array<ApplicationResponsiblePersonResponse>}
     * @memberof ApplicationResponse
     */
    responsiblePersons?: Array<ApplicationResponsiblePersonResponse> | null;
    /**
     * 
     * @type {Array<ApplicationExternalApproverResponse>}
     * @memberof ApplicationResponse
     */
    externalApprovers?: Array<ApplicationExternalApproverResponse> | null;
}

export function ApplicationResponseFromJSON(json: any): ApplicationResponse {
    return ApplicationResponseFromJSONTyped(json, false);
}

export function ApplicationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessControls': !exists(json, 'accessControls') ? undefined : (json['accessControls'] === null ? null : (json['accessControls'] as Array<any>).map(AdgangAccessControlFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'isComponentSpecific': !exists(json, 'isComponentSpecific') ? undefined : json['isComponentSpecific'],
        'redirectUris': !exists(json, 'redirectUris') ? undefined : json['redirectUris'],
        'userAuthenticationConstraint': !exists(json, 'userAuthenticationConstraint') ? undefined : UserAuthenticationConstraintFromJSON(json['userAuthenticationConstraint']),
        'isSearchableInMinSide': !exists(json, 'isSearchableInMinSide') ? undefined : json['isSearchableInMinSide'],
        'requiresMfa': !exists(json, 'requiresMfa') ? undefined : json['requiresMfa'],
        'allowsExternalApprovers': !exists(json, 'allowsExternalApprovers') ? undefined : json['allowsExternalApprovers'],
        'canAppResponsiblesAppointExternalApprovers': !exists(json, 'canAppResponsiblesAppointExternalApprovers') ? undefined : json['canAppResponsiblesAppointExternalApprovers'],
        'isIdPortenFederationDisabled': !exists(json, 'isIdPortenFederationDisabled') ? undefined : json['isIdPortenFederationDisabled'],
        'requiresElevatedSecurityPolicy': !exists(json, 'requiresElevatedSecurityPolicy') ? undefined : json['requiresElevatedSecurityPolicy'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'status': !exists(json, 'status') ? undefined : ApplicationStatusFromJSON(json['status']),
        'migrationStatus': !exists(json, 'migrationStatus') ? undefined : ApplicationMigrationStatusFromJSON(json['migrationStatus']),
        'responsiblePersons': !exists(json, 'responsiblePersons') ? undefined : (json['responsiblePersons'] === null ? null : (json['responsiblePersons'] as Array<any>).map(ApplicationResponsiblePersonResponseFromJSON)),
        'externalApprovers': !exists(json, 'externalApprovers') ? undefined : (json['externalApprovers'] === null ? null : (json['externalApprovers'] as Array<any>).map(ApplicationExternalApproverResponseFromJSON)),
    };
}

export function ApplicationResponseToJSON(value?: ApplicationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessControls': value.accessControls === undefined ? undefined : (value.accessControls === null ? null : (value.accessControls as Array<any>).map(AdgangAccessControlToJSON)),
        'id': value.id,
        'shortName': value.shortName,
        'name': value.name,
        'description': value.description,
        'url': value.url,
        'clientId': value.clientId,
        'isComponentSpecific': value.isComponentSpecific,
        'redirectUris': value.redirectUris,
        'userAuthenticationConstraint': UserAuthenticationConstraintToJSON(value.userAuthenticationConstraint),
        'isSearchableInMinSide': value.isSearchableInMinSide,
        'requiresMfa': value.requiresMfa,
        'allowsExternalApprovers': value.allowsExternalApprovers,
        'canAppResponsiblesAppointExternalApprovers': value.canAppResponsiblesAppointExternalApprovers,
        'isIdPortenFederationDisabled': value.isIdPortenFederationDisabled,
        'requiresElevatedSecurityPolicy': value.requiresElevatedSecurityPolicy,
        'userType': UserTypeToJSON(value.userType),
        'status': ApplicationStatusToJSON(value.status),
        'migrationStatus': ApplicationMigrationStatusToJSON(value.migrationStatus),
        'responsiblePersons': value.responsiblePersons === undefined ? undefined : (value.responsiblePersons === null ? null : (value.responsiblePersons as Array<any>).map(ApplicationResponsiblePersonResponseToJSON)),
        'externalApprovers': value.externalApprovers === undefined ? undefined : (value.externalApprovers === null ? null : (value.externalApprovers as Array<any>).map(ApplicationExternalApproverResponseToJSON)),
    };
}


