class AuthStateStorageService {
  private msalError: string | undefined;

  private redirectionStateKey = 'redirectionState';

  private msalErrorKey = 'msalError';

  getRedirectionState(): string | undefined {
    return window.localStorage.getItem(this.redirectionStateKey) ?? undefined;
  }

  setRedirectionState(state: string | undefined) {
    if (state === undefined) {
      window.localStorage.removeItem(this.redirectionStateKey);
    } else {
      window.localStorage.setItem(this.redirectionStateKey, state);
    }
  }

  public getMsalError() {
    return this.msalError;
  }

  public setMsalError(error: string | undefined) {
    if (!error) {
      window.localStorage.removeItem(this.msalErrorKey);
    } else {
      window.localStorage.setItem(this.msalErrorKey, error);
    }

    this.msalError = error;
  }

  // eslint-disable-next-line class-methods-use-this
  public clearMsalValues() {
    Object.keys(window.localStorage).forEach((key) => {
      if (
        key.startsWith('msal.') ||
        key.startsWith('server-telemetry') ||
        key.toLowerCase().indexOf('b2c_') !== -1
      ) {
        window.localStorage.removeItem(key);
      }
    });

    // msal writes to session storage too
    window.sessionStorage.clear();
  }

  constructor() {
    this.msalError = window.localStorage.getItem('msalError') ?? undefined;
  }
}

const ls = new AuthStateStorageService();
export { ls as AuthStateStorageService };
