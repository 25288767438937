/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEventExpirationUpdateRequest
 */
export interface UserEventExpirationUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof UserEventExpirationUpdateRequest
     */
    translationResourceKey?: number;
    /**
     * 
     * @type {number}
     * @memberof UserEventExpirationUpdateRequest
     */
    expiryInDays?: number | null;
}

export function UserEventExpirationUpdateRequestFromJSON(json: any): UserEventExpirationUpdateRequest {
    return UserEventExpirationUpdateRequestFromJSONTyped(json, false);
}

export function UserEventExpirationUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventExpirationUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'translationResourceKey': !exists(json, 'translationResourceKey') ? undefined : json['translationResourceKey'],
        'expiryInDays': !exists(json, 'expiryInDays') ? undefined : json['expiryInDays'],
    };
}

export function UserEventExpirationUpdateRequestToJSON(value?: UserEventExpirationUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'translationResourceKey': value.translationResourceKey,
        'expiryInDays': value.expiryInDays,
    };
}


