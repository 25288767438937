/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationConsumptionResponse
 */
export interface ApplicationConsumptionResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationConsumptionResponse
     */
    applicationId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationConsumptionResponse
     */
    isConsumedByOtherEntitities?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationConsumptionResponse
     */
    numRoles?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationConsumptionResponse
     */
    numPermissions?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationConsumptionResponse
     */
    userMigrations?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationConsumptionResponse
     */
    applicationResponsibles?: number;
}

export function ApplicationConsumptionResponseFromJSON(json: any): ApplicationConsumptionResponse {
    return ApplicationConsumptionResponseFromJSONTyped(json, false);
}

export function ApplicationConsumptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationConsumptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'isConsumedByOtherEntitities': !exists(json, 'isConsumedByOtherEntitities') ? undefined : json['isConsumedByOtherEntitities'],
        'numRoles': !exists(json, 'numRoles') ? undefined : json['numRoles'],
        'numPermissions': !exists(json, 'numPermissions') ? undefined : json['numPermissions'],
        'userMigrations': !exists(json, 'userMigrations') ? undefined : json['userMigrations'],
        'applicationResponsibles': !exists(json, 'applicationResponsibles') ? undefined : json['applicationResponsibles'],
    };
}

export function ApplicationConsumptionResponseToJSON(value?: ApplicationConsumptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'isConsumedByOtherEntitities': value.isConsumedByOtherEntitities,
        'numRoles': value.numRoles,
        'numPermissions': value.numPermissions,
        'userMigrations': value.userMigrations,
        'applicationResponsibles': value.applicationResponsibles,
    };
}


