/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestStatus,
    AccessRequestStatusFromJSON,
    AccessRequestStatusFromJSONTyped,
    AccessRequestStatusToJSON,
    AdgangAccessControl,
    AdgangAccessControlFromJSON,
    AdgangAccessControlFromJSONTyped,
    AdgangAccessControlToJSON,
    AltinnResponse,
    AltinnResponseFromJSON,
    AltinnResponseFromJSONTyped,
    AltinnResponseToJSON,
    OrganizationResponse,
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
    UserKrrResponse,
    UserKrrResponseFromJSON,
    UserKrrResponseFromJSONTyped,
    UserKrrResponseToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccessRequestResponse
 */
export interface AccessRequestResponse {
    /**
     * 
     * @type {Array<AdgangAccessControl>}
     * @memberof AccessRequestResponse
     */
    accessControls?: Array<AdgangAccessControl> | null;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestResponse
     */
    accessReqeuestId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    applicationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    applicationDescription?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AccessRequestResponse
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    employer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    finalNote?: string | null;
    /**
     * 
     * @type {AccessRequestStatus}
     * @memberof AccessRequestResponse
     */
    status?: AccessRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    telefonNr?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestResponse
     */
    isChangedByProcessingUser?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AccessRequestResponse
     */
    modifiedOn?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    processedByUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    receiptCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    requestedByUserId?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof AccessRequestResponse
     */
    requestedByUserType?: UserType;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    requestedByUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    requestedByUserSectionName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestResponse
     */
    isRequestedByUserIdPortenVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    requestedByUserMfaPhone?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestResponse
     */
    isUnlocksExternalApproverRightsRequest?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestResponse
     */
    requestedRoleId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    requestedRoleName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestResponse
     */
    assignedRoleId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    assignedRoleName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestResponse
     */
    requestedPermissionId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    requestedPermissionName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestResponse
     */
    assignedPermissionId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestResponse
     */
    assignedPermissionName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestResponse
     */
    organizationId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestResponse
     */
    isEmployerVerified?: boolean;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof AccessRequestResponse
     */
    organization?: OrganizationResponse;
    /**
     * 
     * @type {AltinnResponse}
     * @memberof AccessRequestResponse
     */
    altinn?: AltinnResponse;
    /**
     * 
     * @type {UserKrrResponse}
     * @memberof AccessRequestResponse
     */
    userKrr?: UserKrrResponse;
}

export function AccessRequestResponseFromJSON(json: any): AccessRequestResponse {
    return AccessRequestResponseFromJSONTyped(json, false);
}

export function AccessRequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessControls': !exists(json, 'accessControls') ? undefined : (json['accessControls'] === null ? null : (json['accessControls'] as Array<any>).map(AdgangAccessControlFromJSON)),
        'accessReqeuestId': !exists(json, 'accessReqeuestId') ? undefined : json['accessReqeuestId'],
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'applicationDescription': !exists(json, 'applicationDescription') ? undefined : json['applicationDescription'],
        'createdOn': !exists(json, 'createdOn') ? undefined : (new Date(json['createdOn'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'finalNote': !exists(json, 'finalNote') ? undefined : json['finalNote'],
        'status': !exists(json, 'status') ? undefined : AccessRequestStatusFromJSON(json['status']),
        'telefonNr': !exists(json, 'telefonNr') ? undefined : json['telefonNr'],
        'isChangedByProcessingUser': !exists(json, 'isChangedByProcessingUser') ? undefined : json['isChangedByProcessingUser'],
        'modifiedOn': !exists(json, 'modifiedOn') ? undefined : (json['modifiedOn'] === null ? null : new Date(json['modifiedOn'])),
        'processedByUserId': !exists(json, 'processedByUserId') ? undefined : json['processedByUserId'],
        'receiptCode': !exists(json, 'receiptCode') ? undefined : json['receiptCode'],
        'requestedByUserId': !exists(json, 'requestedByUserId') ? undefined : json['requestedByUserId'],
        'requestedByUserType': !exists(json, 'requestedByUserType') ? undefined : UserTypeFromJSON(json['requestedByUserType']),
        'requestedByUserName': !exists(json, 'requestedByUserName') ? undefined : json['requestedByUserName'],
        'requestedByUserSectionName': !exists(json, 'requestedByUserSectionName') ? undefined : json['requestedByUserSectionName'],
        'isRequestedByUserIdPortenVerified': !exists(json, 'isRequestedByUserIdPortenVerified') ? undefined : json['isRequestedByUserIdPortenVerified'],
        'requestedByUserMfaPhone': !exists(json, 'requestedByUserMfaPhone') ? undefined : json['requestedByUserMfaPhone'],
        'isUnlocksExternalApproverRightsRequest': !exists(json, 'isUnlocksExternalApproverRightsRequest') ? undefined : json['isUnlocksExternalApproverRightsRequest'],
        'requestedRoleId': !exists(json, 'requestedRoleId') ? undefined : json['requestedRoleId'],
        'requestedRoleName': !exists(json, 'requestedRoleName') ? undefined : json['requestedRoleName'],
        'assignedRoleId': !exists(json, 'assignedRoleId') ? undefined : json['assignedRoleId'],
        'assignedRoleName': !exists(json, 'assignedRoleName') ? undefined : json['assignedRoleName'],
        'requestedPermissionId': !exists(json, 'requestedPermissionId') ? undefined : json['requestedPermissionId'],
        'requestedPermissionName': !exists(json, 'requestedPermissionName') ? undefined : json['requestedPermissionName'],
        'assignedPermissionId': !exists(json, 'assignedPermissionId') ? undefined : json['assignedPermissionId'],
        'assignedPermissionName': !exists(json, 'assignedPermissionName') ? undefined : json['assignedPermissionName'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'isEmployerVerified': !exists(json, 'isEmployerVerified') ? undefined : json['isEmployerVerified'],
        'organization': !exists(json, 'organization') ? undefined : OrganizationResponseFromJSON(json['organization']),
        'altinn': !exists(json, 'altinn') ? undefined : AltinnResponseFromJSON(json['altinn']),
        'userKrr': !exists(json, 'userKrr') ? undefined : UserKrrResponseFromJSON(json['userKrr']),
    };
}

export function AccessRequestResponseToJSON(value?: AccessRequestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessControls': value.accessControls === undefined ? undefined : (value.accessControls === null ? null : (value.accessControls as Array<any>).map(AdgangAccessControlToJSON)),
        'accessReqeuestId': value.accessReqeuestId,
        'applicationId': value.applicationId,
        'applicationName': value.applicationName,
        'applicationDescription': value.applicationDescription,
        'createdOn': value.createdOn === undefined ? undefined : (value.createdOn.toISOString()),
        'description': value.description,
        'email': value.email,
        'employer': value.employer,
        'finalNote': value.finalNote,
        'status': AccessRequestStatusToJSON(value.status),
        'telefonNr': value.telefonNr,
        'isChangedByProcessingUser': value.isChangedByProcessingUser,
        'modifiedOn': value.modifiedOn === undefined ? undefined : (value.modifiedOn === null ? null : value.modifiedOn.toISOString()),
        'processedByUserId': value.processedByUserId,
        'receiptCode': value.receiptCode,
        'requestedByUserId': value.requestedByUserId,
        'requestedByUserType': UserTypeToJSON(value.requestedByUserType),
        'requestedByUserName': value.requestedByUserName,
        'requestedByUserSectionName': value.requestedByUserSectionName,
        'isRequestedByUserIdPortenVerified': value.isRequestedByUserIdPortenVerified,
        'requestedByUserMfaPhone': value.requestedByUserMfaPhone,
        'isUnlocksExternalApproverRightsRequest': value.isUnlocksExternalApproverRightsRequest,
        'requestedRoleId': value.requestedRoleId,
        'requestedRoleName': value.requestedRoleName,
        'assignedRoleId': value.assignedRoleId,
        'assignedRoleName': value.assignedRoleName,
        'requestedPermissionId': value.requestedPermissionId,
        'requestedPermissionName': value.requestedPermissionName,
        'assignedPermissionId': value.assignedPermissionId,
        'assignedPermissionName': value.assignedPermissionName,
        'organizationId': value.organizationId,
        'isEmployerVerified': value.isEmployerVerified,
        'organization': OrganizationResponseToJSON(value.organization),
        'altinn': AltinnResponseToJSON(value.altinn),
        'userKrr': UserKrrResponseToJSON(value.userKrr),
    };
}


