/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationFilterRequest,
    ApplicationFilterRequestFromJSON,
    ApplicationFilterRequestFromJSONTyped,
    ApplicationFilterRequestToJSON,
    PermissionSearchOrderByPaginationQuery,
    PermissionSearchOrderByPaginationQueryFromJSON,
    PermissionSearchOrderByPaginationQueryFromJSONTyped,
    PermissionSearchOrderByPaginationQueryToJSON,
    PermissionSearchSortingQuery,
    PermissionSearchSortingQueryFromJSON,
    PermissionSearchSortingQueryFromJSONTyped,
    PermissionSearchSortingQueryToJSON,
    ResourceFilter,
    ResourceFilterFromJSON,
    ResourceFilterFromJSONTyped,
    ResourceFilterToJSON,
    RoleFilterRequest,
    RoleFilterRequestFromJSON,
    RoleFilterRequestFromJSONTyped,
    RoleFilterRequestToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface PermissionSearchRequest
 */
export interface PermissionSearchRequest {
    /**
     * 
     * @type {ApplicationFilterRequest}
     * @memberof PermissionSearchRequest
     */
    applicationFilter?: ApplicationFilterRequest;
    /**
     * 
     * @type {PermissionSearchOrderByPaginationQuery}
     * @memberof PermissionSearchRequest
     */
    paginationQuery?: PermissionSearchOrderByPaginationQuery;
    /**
     * 
     * @type {Array<number>}
     * @memberof PermissionSearchRequest
     */
    permissionIds?: Array<number> | null;
    /**
     * 
     * @type {RoleFilterRequest}
     * @memberof PermissionSearchRequest
     */
    roleFilter?: RoleFilterRequest;
    /**
     * 
     * @type {PermissionSearchSortingQuery}
     * @memberof PermissionSearchRequest
     */
    sortingQuery?: PermissionSearchSortingQuery;
    /**
     * 
     * @type {string}
     * @memberof PermissionSearchRequest
     */
    textFilter?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof PermissionSearchRequest
     */
    userType?: UserType;
    /**
     * 
     * @type {ResourceFilter}
     * @memberof PermissionSearchRequest
     */
    resourceFilter?: ResourceFilter;
}

export function PermissionSearchRequestFromJSON(json: any): PermissionSearchRequest {
    return PermissionSearchRequestFromJSONTyped(json, false);
}

export function PermissionSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationFilter': !exists(json, 'applicationFilter') ? undefined : ApplicationFilterRequestFromJSON(json['applicationFilter']),
        'paginationQuery': !exists(json, 'paginationQuery') ? undefined : PermissionSearchOrderByPaginationQueryFromJSON(json['paginationQuery']),
        'permissionIds': !exists(json, 'permissionIds') ? undefined : json['permissionIds'],
        'roleFilter': !exists(json, 'roleFilter') ? undefined : RoleFilterRequestFromJSON(json['roleFilter']),
        'sortingQuery': !exists(json, 'sortingQuery') ? undefined : PermissionSearchSortingQueryFromJSON(json['sortingQuery']),
        'textFilter': !exists(json, 'textFilter') ? undefined : json['textFilter'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'resourceFilter': !exists(json, 'resourceFilter') ? undefined : ResourceFilterFromJSON(json['resourceFilter']),
    };
}

export function PermissionSearchRequestToJSON(value?: PermissionSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationFilter': ApplicationFilterRequestToJSON(value.applicationFilter),
        'paginationQuery': PermissionSearchOrderByPaginationQueryToJSON(value.paginationQuery),
        'permissionIds': value.permissionIds,
        'roleFilter': RoleFilterRequestToJSON(value.roleFilter),
        'sortingQuery': PermissionSearchSortingQueryToJSON(value.sortingQuery),
        'textFilter': value.textFilter,
        'userType': UserTypeToJSON(value.userType),
        'resourceFilter': ResourceFilterToJSON(value.resourceFilter),
    };
}


