/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApplicationConsumptionResponse,
    ApplicationConsumptionResponseFromJSON,
    ApplicationConsumptionResponseToJSON,
    ApplicationCreateRequest,
    ApplicationCreateRequestFromJSON,
    ApplicationCreateRequestToJSON,
    ApplicationResponse,
    ApplicationResponseFromJSON,
    ApplicationResponseToJSON,
    ApplicationResponsePagedResult,
    ApplicationResponsePagedResultFromJSON,
    ApplicationResponsePagedResultToJSON,
    ApplicationResponsiblePersonResponse,
    ApplicationResponsiblePersonResponseFromJSON,
    ApplicationResponsiblePersonResponseToJSON,
    ApplicationResponsiblePersonResponsePagedResult,
    ApplicationResponsiblePersonResponsePagedResultFromJSON,
    ApplicationResponsiblePersonResponsePagedResultToJSON,
    ApplicationResponsiblePersonSearchRequest,
    ApplicationResponsiblePersonSearchRequestFromJSON,
    ApplicationResponsiblePersonSearchRequestToJSON,
    ApplicationSearchOrderBy,
    ApplicationSearchOrderByFromJSON,
    ApplicationSearchOrderByToJSON,
    ApplicationUpdateRequest,
    ApplicationUpdateRequestFromJSON,
    ApplicationUpdateRequestToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    ResourceFilter,
    ResourceFilterFromJSON,
    ResourceFilterToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface ApiApplicationsApplicationResponsiblePersonsActivePostRequest {
    applicationResponsiblePersonSearchRequest?: ApplicationResponsiblePersonSearchRequest;
}

export interface ApiApplicationsCheckusageGetRequest {
    id?: string;
}

export interface ApiApplicationsGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: ApplicationSearchOrderBy;
    userId?: string;
    resourceFilter?: ResourceFilter;
}

export interface ApiApplicationsIdDeleteRequest {
    id: string;
}

export interface ApiApplicationsIdPutRequest {
    id: string;
    applicationUpdateRequest?: ApplicationUpdateRequest;
}

export interface ApiApplicationsIdUnlockExternalApproverSupportPutRequest {
    id: string;
}

export interface ApiApplicationsPostRequest {
    applicationCreateRequest?: ApplicationCreateRequest;
}

export interface GetApplicationByIdRequest {
    id: string;
}

/**
 * 
 */
export class ApplicationsApi extends runtime.BaseAPI {

    /**
     */
    async apiApplicationsApplicationResponsiblePersonsActivePostRaw(requestParameters: ApiApplicationsApplicationResponsiblePersonsActivePostRequest): Promise<runtime.ApiResponse<ApplicationResponsiblePersonResponsePagedResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/applications/application-responsible-persons/active`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationResponsiblePersonSearchRequestToJSON(requestParameters.applicationResponsiblePersonSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponsiblePersonResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiApplicationsApplicationResponsiblePersonsActivePost(requestParameters: ApiApplicationsApplicationResponsiblePersonsActivePostRequest): Promise<ApplicationResponsiblePersonResponsePagedResult> {
        const response = await this.apiApplicationsApplicationResponsiblePersonsActivePostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiApplicationsApplicationResponsiblePersonsCandidatesGetRaw(): Promise<runtime.ApiResponse<Array<ApplicationResponsiblePersonResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applications/application-responsible-persons/candidates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApplicationResponsiblePersonResponseFromJSON));
    }

    /**
     */
    async apiApplicationsApplicationResponsiblePersonsCandidatesGet(): Promise<Array<ApplicationResponsiblePersonResponse>> {
        const response = await this.apiApplicationsApplicationResponsiblePersonsCandidatesGetRaw();
        return await response.value();
    }

    /**
     */
    async apiApplicationsCheckusageGetRaw(requestParameters: ApiApplicationsCheckusageGetRequest): Promise<runtime.ApiResponse<ApplicationConsumptionResponse>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applications/checkusage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationConsumptionResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiApplicationsCheckusageGet(requestParameters: ApiApplicationsCheckusageGetRequest): Promise<ApplicationConsumptionResponse> {
        const response = await this.apiApplicationsCheckusageGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiApplicationsGetRaw(requestParameters: ApiApplicationsGetRequest): Promise<runtime.ApiResponse<ApplicationResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.resourceFilter !== undefined) {
            queryParameters['resourceFilter'] = requestParameters.resourceFilter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiApplicationsGet(requestParameters: ApiApplicationsGetRequest): Promise<ApplicationResponsePagedResult> {
        const response = await this.apiApplicationsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiApplicationsIdDeleteRaw(requestParameters: ApiApplicationsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiApplicationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiApplicationsIdDelete(requestParameters: ApiApplicationsIdDeleteRequest): Promise<void> {
        await this.apiApplicationsIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiApplicationsIdPutRaw(requestParameters: ApiApplicationsIdPutRequest): Promise<runtime.ApiResponse<ApplicationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiApplicationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/applications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationUpdateRequestToJSON(requestParameters.applicationUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiApplicationsIdPut(requestParameters: ApiApplicationsIdPutRequest): Promise<ApplicationResponse> {
        const response = await this.apiApplicationsIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiApplicationsIdUnlockExternalApproverSupportPutRaw(requestParameters: ApiApplicationsIdUnlockExternalApproverSupportPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiApplicationsIdUnlockExternalApproverSupportPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applications/{id}/unlock-external-approver-support`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiApplicationsIdUnlockExternalApproverSupportPut(requestParameters: ApiApplicationsIdUnlockExternalApproverSupportPutRequest): Promise<void> {
        await this.apiApplicationsIdUnlockExternalApproverSupportPutRaw(requestParameters);
    }

    /**
     */
    async apiApplicationsPostRaw(requestParameters: ApiApplicationsPostRequest): Promise<runtime.ApiResponse<ApplicationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/applications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationCreateRequestToJSON(requestParameters.applicationCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiApplicationsPost(requestParameters: ApiApplicationsPostRequest): Promise<ApplicationResponse> {
        const response = await this.apiApplicationsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getApplicationByIdRaw(requestParameters: GetApplicationByIdRequest): Promise<runtime.ApiResponse<ApplicationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getApplicationById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponseFromJSON(jsonValue));
    }

    /**
     */
    async getApplicationById(requestParameters: GetApplicationByIdRequest): Promise<ApplicationResponse> {
        const response = await this.getApplicationByIdRaw(requestParameters);
        return await response.value();
    }

}
