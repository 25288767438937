/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalUserStatus,
    ExternalUserStatusFromJSON,
    ExternalUserStatusFromJSONTyped,
    ExternalUserStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalUserStatusUpdateRequest
 */
export interface ExternalUserStatusUpdateRequest {
    /**
     * 
     * @type {ExternalUserStatus}
     * @memberof ExternalUserStatusUpdateRequest
     */
    status?: ExternalUserStatus;
}

export function ExternalUserStatusUpdateRequestFromJSON(json: any): ExternalUserStatusUpdateRequest {
    return ExternalUserStatusUpdateRequestFromJSONTyped(json, false);
}

export function ExternalUserStatusUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalUserStatusUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : ExternalUserStatusFromJSON(json['status']),
    };
}

export function ExternalUserStatusUpdateRequestToJSON(value?: ExternalUserStatusUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ExternalUserStatusToJSON(value.status),
    };
}


