import React from 'react';
// import PropTypes from 'prop-types'
import { getFormInputClassNames } from '../sharedFormFunctions';

import styles1 from './_textArea.scss';
import styles2 from '../../../styles/_utilities.scss';

const styles = (cN: string) => {
  const mapped = cN.split(' ').map((c) => styles1[c] || styles2[c]);
  return mapped.join(' ');
};

export type ITextArea = {
  id: string;
  labelText?: string;
  height?: string;
  hideLabelText?: boolean;
  value?: string | null | undefined;
  invalid?: boolean;
  invalidText?: string | null | undefined;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (arg: string) => void;

  onBlur?: (value?: string | null | undefined) => void;
};

const TextArea = React.forwardRef((props: ITextArea, ref: any) => {
  return (
    <label
      htmlFor={props.id}
      className={styles(getFormInputClassNames('c_text-area', props))}
    >
      <span
        className={styles(
          `c_text-area__label-text ${props.hideLabelText && 'u_visuallyhidden'}`
        )}
      >
        {props.labelText}
      </span>
      {props.invalid && (
        <span className={styles('c_text-area__invalid-text')}>
          {props.invalidText}
        </span>
      )}
      <textarea
        name={props.id}
        ref={ref}
        style={props.height ? { minHeight: props.height } : {}}
        defaultValue={props.value || undefined}
        disabled={props.disabled}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
        onBlur={(event) => {
          if (props.onBlur) {
            props.onBlur(event.target.value);
          }
        }}
        // onFocus={props.onFocus ? (e) => props.onFocus(e) : null}
        placeholder={props.placeholder || undefined}
      />
    </label>
  );
});

// TextArea.propTypes = {
//   id: PropTypes.string.isRequired,
//   labelText: PropTypes.string.isRequired,
//   hideLabelText: PropTypes.bool,
//   value: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
//   onBlur: PropTypes.func,
//   onFocus: PropTypes.func,
//     className: PropTypes.string,  // eslint-disable-line
//   placeholder: PropTypes.string,
//   invalid: PropTypes.bool,
//   invalidText: PropTypes.string
// }

// TextArea.defaultProps = {
//   hideLabelText: false,
//   invalid: false,
//   invalidText: 'Invalid'
// }

export { TextArea };
