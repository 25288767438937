/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdgangAccessControl,
    AdgangAccessControlFromJSON,
    AdgangAccessControlFromJSONTyped,
    AdgangAccessControlToJSON,
} from './';

/**
 * 
 * @export
 * @interface PermissionConsumptionResponse
 */
export interface PermissionConsumptionResponse {
    /**
     * 
     * @type {Array<AdgangAccessControl>}
     * @memberof PermissionConsumptionResponse
     */
    accessControls?: Array<AdgangAccessControl> | null;
    /**
     * 
     * @type {number}
     * @memberof PermissionConsumptionResponse
     */
    permissionId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionConsumptionResponse
     */
    isConsumedByOtherEntitities?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PermissionConsumptionResponse
     */
    numRolePermissions?: number;
    /**
     * 
     * @type {number}
     * @memberof PermissionConsumptionResponse
     */
    numUserPermissions?: number;
    /**
     * 
     * @type {number}
     * @memberof PermissionConsumptionResponse
     */
    numAdGroupPermissions?: number;
    /**
     * 
     * @type {number}
     * @memberof PermissionConsumptionResponse
     */
    numSectionPermissions?: number;
    /**
     * 
     * @type {number}
     * @memberof PermissionConsumptionResponse
     */
    numActiveAccessRequests?: number;
}

export function PermissionConsumptionResponseFromJSON(json: any): PermissionConsumptionResponse {
    return PermissionConsumptionResponseFromJSONTyped(json, false);
}

export function PermissionConsumptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionConsumptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessControls': !exists(json, 'accessControls') ? undefined : (json['accessControls'] === null ? null : (json['accessControls'] as Array<any>).map(AdgangAccessControlFromJSON)),
        'permissionId': !exists(json, 'permissionId') ? undefined : json['permissionId'],
        'isConsumedByOtherEntitities': !exists(json, 'isConsumedByOtherEntitities') ? undefined : json['isConsumedByOtherEntitities'],
        'numRolePermissions': !exists(json, 'numRolePermissions') ? undefined : json['numRolePermissions'],
        'numUserPermissions': !exists(json, 'numUserPermissions') ? undefined : json['numUserPermissions'],
        'numAdGroupPermissions': !exists(json, 'numAdGroupPermissions') ? undefined : json['numAdGroupPermissions'],
        'numSectionPermissions': !exists(json, 'numSectionPermissions') ? undefined : json['numSectionPermissions'],
        'numActiveAccessRequests': !exists(json, 'numActiveAccessRequests') ? undefined : json['numActiveAccessRequests'],
    };
}

export function PermissionConsumptionResponseToJSON(value?: PermissionConsumptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessControls': value.accessControls === undefined ? undefined : (value.accessControls === null ? null : (value.accessControls as Array<any>).map(AdgangAccessControlToJSON)),
        'permissionId': value.permissionId,
        'isConsumedByOtherEntitities': value.isConsumedByOtherEntitities,
        'numRolePermissions': value.numRolePermissions,
        'numUserPermissions': value.numUserPermissions,
        'numAdGroupPermissions': value.numAdGroupPermissions,
        'numSectionPermissions': value.numSectionPermissions,
        'numActiveAccessRequests': value.numActiveAccessRequests,
    };
}


