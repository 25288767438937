/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdgangAccessControl,
    AdgangAccessControlFromJSON,
    AdgangAccessControlToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
} from '../models';

/**
 * 
 */
export class DevToolsApi extends runtime.BaseAPI {

    /**
     */
    async apiDevToolsAdgangAdministratorRightsPostRaw(): Promise<runtime.ApiResponse<Array<AdgangAccessControl>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dev-tools/adgang-administrator-rights`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdgangAccessControlFromJSON));
    }

    /**
     */
    async apiDevToolsAdgangAdministratorRightsPost(): Promise<Array<AdgangAccessControl>> {
        const response = await this.apiDevToolsAdgangAdministratorRightsPostRaw();
        return await response.value();
    }

}
