/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResourceFilter {
    None = 'None',
    AccessRequest = 'AccessRequest',
    Role = 'Role',
    Permission = 'Permission',
    InternalUser = 'InternalUser',
    ExternalUser = 'ExternalUser',
    Application = 'Application',
    InternalUserAccessAssignment = 'InternalUserAccessAssignment',
    ExternalUserAccessAssignment = 'ExternalUserAccessAssignment',
    OrganizationGroup = 'OrganizationGroup'
}

export function ResourceFilterFromJSON(json: any): ResourceFilter {
    return ResourceFilterFromJSONTyped(json, false);
}

export function ResourceFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceFilter {
    return json as ResourceFilter;
}

export function ResourceFilterToJSON(value?: ResourceFilter | null): any {
    return value as any;
}

