import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Sidenav } from 'components/pageTemplate/Sidenav';
import { SidebarItem } from 'common.ui';
import './MainContent.scss';

type IProp = {
  routes: SidebarItem[];
  children: React.ReactElement;
};

export function MainContent({ routes, children }: IProp) {
  return (
    <>
      <Container fluid className='main-container'>
        <Row className='m-0'>
          <Col className='sidenav-col'>
            <Sidenav routes={routes} />
          </Col>
          <Col>{children}</Col>
        </Row>
      </Container>
    </>
  );
}
