/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationResponsiblePersonSearchOrderBy {
    Name = 'Name'
}

export function ApplicationResponsiblePersonSearchOrderByFromJSON(json: any): ApplicationResponsiblePersonSearchOrderBy {
    return ApplicationResponsiblePersonSearchOrderByFromJSONTyped(json, false);
}

export function ApplicationResponsiblePersonSearchOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponsiblePersonSearchOrderBy {
    return json as ApplicationResponsiblePersonSearchOrderBy;
}

export function ApplicationResponsiblePersonSearchOrderByToJSON(value?: ApplicationResponsiblePersonSearchOrderBy | null): any {
    return value as any;
}

