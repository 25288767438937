/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserAccessOrigin {
    Unknown = 'Unknown',
    User = 'User',
    Section = 'Section',
    AdGroup = 'AdGroup',
    Role = 'Role'
}

export function UserAccessOriginFromJSON(json: any): UserAccessOrigin {
    return UserAccessOriginFromJSONTyped(json, false);
}

export function UserAccessOriginFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAccessOrigin {
    return json as UserAccessOrigin;
}

export function UserAccessOriginToJSON(value?: UserAccessOrigin | null): any {
    return value as any;
}

