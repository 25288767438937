/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserAliasResponse
 */
export interface UserAliasResponse {
    /**
     * 
     * @type {string}
     * @memberof UserAliasResponse
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAliasResponse
     */
    derivedUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAliasResponse
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAliasResponse
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAliasResponse
     */
    mail?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof UserAliasResponse
     */
    userType?: UserType;
    /**
     * 
     * @type {boolean}
     * @memberof UserAliasResponse
     */
    isIdPortenUser?: boolean;
}

export function UserAliasResponseFromJSON(json: any): UserAliasResponse {
    return UserAliasResponseFromJSONTyped(json, false);
}

export function UserAliasResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAliasResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'derivedUserId': !exists(json, 'derivedUserId') ? undefined : json['derivedUserId'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'mail': !exists(json, 'mail') ? undefined : json['mail'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'isIdPortenUser': !exists(json, 'isIdPortenUser') ? undefined : json['isIdPortenUser'],
    };
}

export function UserAliasResponseToJSON(value?: UserAliasResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'derivedUserId': value.derivedUserId,
        'firstname': value.firstname,
        'surname': value.surname,
        'mail': value.mail,
        'userType': UserTypeToJSON(value.userType),
        'isIdPortenUser': value.isIdPortenUser,
    };
}


