import React, { useEffect, useState } from 'react';
import { LoadingSpinner, Button, useBoundForm, SimpleModal, SelectOption } from 'common.ui';
import { useApplications, useRoles, usePermissions } from 'api/hooks';
import {
  ApplicationResponse,
  GenericValidationError,
  PermissionResponse,
  ResourceFilter,
  RoleResponse,
  UserType
} from 'api/adgang';

enum AccessType {
  role = 1,
  permission = 2
}

type RequestedAccess = {
  type: AccessType;
  id: number;
};

type IProps = {
  header: string;
  show: boolean;
  userId?: string;
  userType: UserType;
  requireUserOrganizationInMinSide?: boolean;
  canChangeApplication: boolean;
  initialApplicationId?: string | null;
  initialRoleId?: number;
  initialPermissionId?: number;
  additionalJsx?: JSX.Element;
  okText?: string;
  onCancel: () => void;
  onAccessSelected: (
    role: SelectOption | undefined,
    permission?: SelectOption | undefined
  ) => Promise<GenericValidationError | undefined>;
};

interface IPropsImpl {
  initialAccessId: string;
  applicationOptions: SelectOption[];
  applications: ApplicationResponse[];
}

type AccessForm = {
  applicationId: string;
  accessId: string;
};

function AccessSelectorModal(props: IProps) {
  const {
    header,
    show,
    onCancel,
    initialApplicationId,
    initialRoleId,
    initialPermissionId,
    userId,
    requireUserOrganizationInMinSide
  } = props;
  const [applicationOptions, applications] = useApplications(userId, ResourceFilter.AccessRequest);

  let initialAccessId = '';
  if (initialRoleId) {
    initialAccessId = `${AccessType.role}_${initialRoleId}`;
  }
  if (initialPermissionId) {
    initialAccessId = `${AccessType.permission}_${initialPermissionId}`;
  }
  return (
    <SimpleModal header={header} show={show} onCancel={onCancel}>
      {applications && applicationOptions ? (
        <AccessSelectorModalImpl
          {...props}
          requireUserOrganizationInMinSide={requireUserOrganizationInMinSide}
          applicationOptions={applicationOptions}
          applications={applications}
          initialApplicationId={initialApplicationId}
          initialAccessId={initialAccessId}
        />
      ) : (
        <LoadingSpinner />
      )}
    </SimpleModal>
  );
}

function AccessSelectorModalImpl({
  userId,
  userType,
  requireUserOrganizationInMinSide,
  canChangeApplication,
  applicationOptions,
  applications,
  initialApplicationId,
  initialAccessId,
  onCancel,
  onAccessSelected,
  additionalJsx,
  okText
}: IProps & IPropsImpl) {
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [selectedApplication, setSelectedApplication] = useState<ApplicationResponse | undefined>(
    applications.find((x) => x.id === initialApplicationId) ?? applications.find((x) => x.id === '')
  );

  const [roleOptions, roles] = useRoles(
    userType,
    selectedApplication?.id,
    userId,
    ResourceFilter.AccessRequest,
    requireUserOrganizationInMinSide
  );
  const [permissionOptions, permissions] = usePermissions(
    userType,
    selectedApplication?.id,
    userId,
    requireUserOrganizationInMinSide,
    ResourceFilter.AccessRequest
  );
  const [accesses, setAccesses] = useState<SelectOption<RequestedAccess>[]>([]);

  const [selectedRole, setSelectedRole] = useState<RoleResponse | undefined>(undefined);
  const [selectedPermission, setSelectedPermission] = useState<PermissionResponse | undefined>(undefined);

  const [values] = useState<AccessForm>({
    applicationId: initialApplicationId || '',
    accessId: initialAccessId
  });

  const handleApplicationIdChanged = (value: string) => {
    const application = applications?.find((x) => x.id === value);
    setSelectedApplication(application);
  };

  const handleRequestedAccessChanged = (key: string, requestedAccess: RequestedAccess | undefined) => {
    const role =
      requestedAccess?.type === AccessType.role
        ? roles?.find((a) => a.roleId === requestedAccess?.id)
        : undefined;
    setSelectedRole(role);

    const permission =
      requestedAccess?.type === AccessType.permission
        ? permissions?.find((a) => a.permissionId === requestedAccess?.id)
        : undefined;
    setSelectedPermission(permission);
  };

  useEffect(() => {
    const ro =
      roles
        ?.filter((r) => !r.unlocksExternalApproverRights)
        .map(
          (r) =>
            ({
              id: `${AccessType.role}_${r.roleId}`,
              text: r.name,
              value: { type: AccessType.role, id: r.roleId }
            } as SelectOption<RequestedAccess>)
        ) || [];

    const po =
      permissions?.map(
        (r) =>
          ({
            id: `${AccessType.permission}_${r.permissionId}`,
            text: r.name,
            value: { type: AccessType.permission, id: r.permissionId }
          } as SelectOption<RequestedAccess>)
      ) || [];
    setAccesses(ro.concat(po));
  }, [selectedApplication, roles, permissions]);

  const { form, FormContainer, DisplayErrors, Dropdown } = useBoundForm<AccessForm>({
    onSubmit: async (e) => {
      const role = roleOptions?.find((x) => x.id === selectedRole?.roleId?.toString());
      const permission = permissionOptions?.find(
        (x) => x.id === selectedPermission?.permissionId?.toString()
      );
      setApiErrors(undefined);
      const errors = await onAccessSelected(role, permission);
      setApiErrors(errors);
    },
    model: values,
    errors: apiErrors
  });

  if (!applicationOptions || !accesses) {
    return <LoadingSpinner />;
  }

  return (
    <FormContainer form={form}>
      <DisplayErrors form={form} />
      {!applicationOptions ? (
        <LoadingSpinner />
      ) : (
        <Dropdown
          form={form}
          onChange={(e) => handleApplicationIdChanged(e.target.value)}
          options={applicationOptions}
          label='Applikasjon'
          name='applicationId'
          readonly={!canChangeApplication}
        />
      )}

      {!accesses ? (
        <LoadingSpinner />
      ) : (
        <Dropdown
          form={form}
          placeholder=''
          options={accesses}
          label='Rettighet'
          name='accessId'
          onChangeSelection={(k, v) => {
            handleRequestedAccessChanged(k, v);
          }}
        />
      )}

      {additionalJsx}
      <Button type='submit' text={okText ?? 'Endre'} disabled={!applications || !accesses} />
      <Button type='button' text='Avbryt' styleType='light' onClick={onCancel} />
    </FormContainer>
  );
}

export default AccessSelectorModal;
