/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestStatus,
    AccessRequestStatusFromJSON,
    AccessRequestStatusFromJSONTyped,
    AccessRequestStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccessRequestUpdateStatusRequest
 */
export interface AccessRequestUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessRequestUpdateStatusRequest
     */
    note?: string | null;
    /**
     * 
     * @type {AccessRequestStatus}
     * @memberof AccessRequestUpdateStatusRequest
     */
    status?: AccessRequestStatus;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestUpdateStatusRequest
     */
    userConfirmation?: boolean;
}

export function AccessRequestUpdateStatusRequestFromJSON(json: any): AccessRequestUpdateStatusRequest {
    return AccessRequestUpdateStatusRequestFromJSONTyped(json, false);
}

export function AccessRequestUpdateStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestUpdateStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'note': !exists(json, 'note') ? undefined : json['note'],
        'status': !exists(json, 'status') ? undefined : AccessRequestStatusFromJSON(json['status']),
        'userConfirmation': !exists(json, 'userConfirmation') ? undefined : json['userConfirmation'],
    };
}

export function AccessRequestUpdateStatusRequestToJSON(value?: AccessRequestUpdateStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'note': value.note,
        'status': AccessRequestStatusToJSON(value.status),
        'userConfirmation': value.userConfirmation,
    };
}


