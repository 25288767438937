import React, { useCallback, useEffect, useState } from 'react';
import { applicationsClient } from 'api/AdgangClients';
import {
  Table,
  Th,
  Button,
  LoadingSpinner,
  useBoundForm,
  useFetch,
  usePaging,
  SelectOption,
  ConfirmationModal,
  SimpleModal,
  Form,
  Message
} from 'common.ui';
import {
  ApplicationResponse,
  AdgangAccessControl,
  GenericValidationError,
  ApplicationUpdateRequest,
  UserType,
  ApplicationResponsiblePersonRequest,
  ApplicationStatus,
  ApplicationMigrationStatus,
  ApplicationConsumptionResponse
} from 'api/adgang';
import RoutePaths from 'RoutePaths';
import { Link, useParams, useHistory } from 'react-router-dom';

import Page from 'components/page/Page';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ApplicationResponsibleSelector from 'components/applications/ApplicationResponsibleSelector';
import { useUserAuthenticationConstraints } from 'api/hooks/useUserAuthenticationConstraint';
import { useApplicationResponsibles } from 'api/hooks/useApplicationResponsibles';
import { useUserTypes } from 'api/hooks/useUserTypes';

import { useApplicationStatuses } from 'api/hooks/useApplicationStatuses';
import { useApplicationMigrationStatuses } from 'api/hooks/useApplicationMigrationStatuses';

type Application = {
  userType: string;
} & Omit<ApplicationResponse, 'userType' | 'redirectUri'>;

interface IParams {
  id: string;
}

function Application() {
  const { id } = useParams<IParams>();
  const history = useHistory();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [confirmExternalApproversUnlocked, setConfirmExternalApproversUnlocked] = useState({
    externalApproversUnlocked: false,
    modalConfirmed: false
  });
  const [values, setValues] = useState<Application>({} as Application);
  const [resourceAcl, setResourceAcl] = useState<Array<AdgangAccessControl>>([]);
  const [userAuthenticationConstraints] = useUserAuthenticationConstraints(true, false);
  const [userTypes] = useUserTypes({ id: '', text: 'Interne og eksterne brukere' } as SelectOption);
  const [statuses] = useApplicationStatuses();
  const [migrationStatuses] = useApplicationMigrationStatuses();
  const [deleteApplication, setDeleteApplication] = useState<ApplicationConsumptionResponse>();
  const [applicationResponsibles] = useApplicationResponsibles();

  const fetchApplicationAndSetAcl = async (): Promise<ApplicationResponse> => {
    const app = await applicationsClient.getApplicationById({ id });
    setResourceAcl(app?.accessControls ?? []);
    return app;
  };

  const [application, isLoading, , fetchApplications] = useFetch<ApplicationResponse | undefined>(
    fetchApplicationAndSetAcl,
    undefined,
    false,
    [id]
  );

  const requestDelete = async () => {
    const c = await applicationsClient.apiApplicationsCheckusageGet({
      id
    });
    setDeleteApplication(c);
  };

  const hasAccess = (accessControl: AdgangAccessControl): boolean => {
    return !!resourceAcl && resourceAcl.some((x) => x === accessControl);
  };

  const isReadOnly = !hasAccess(AdgangAccessControl.ApplicationEdit);

  useEffect(() => {
    if (application) {
      setValues({
        ...application,
        userType: application.userType ? (application.userType as UserType) : ''
      });
    }
  }, [application]);

  usePaging(fetchApplications);

  const OnFormSubmit = async (submitModel: Application) => {
    const model = {
      ...submitModel,
      canAppResponsiblesAppointExternalApprovers: values.canAppResponsiblesAppointExternalApprovers,
      responsiblePersons: values.responsiblePersons || []
    };
    setValues(model);
    try {
      await applicationsClient.apiApplicationsIdPut({
        id,
        applicationUpdateRequest: {
          ...model,
          isComponentSpecific: model.isComponentSpecific?.toString() === 'on',
          isSearchableInMinSide: model.isSearchableInMinSide?.toString() === 'on',
          isIdPortenFederationDisabled: model.isIdPortenFederationDisabled?.toString() === 'on',
          requiresElevatedSecurityPolicy: model.requiresElevatedSecurityPolicy?.toString() === 'on',
          requiresMfa: model.requiresMfa?.toString() === 'on',
          userType: model.userType ? (model.userType as UserType) : undefined,
          status: model.status ? (model.status as ApplicationStatus) : undefined,
          migrationStatus: model.migrationStatus
            ? (model.migrationStatus as ApplicationMigrationStatus)
            : undefined
        } as ApplicationUpdateRequest
      });
      setApiErrors(undefined);
      history.push(RoutePaths.applications);
    } catch (e) {
      if (e) {
        const result = (await (e as any).json()) as GenericValidationError;
        if (result) {
          setApiErrors(result);
        }
      }
    }
  };

  async function unlockApplicationForExternalApprovers() {
    if (application?.id) {
      setIsDisabled(true);
      await applicationsClient.apiApplicationsIdUnlockExternalApproverSupportPut({
        id: application.id
      });
      setConfirmExternalApproversUnlocked({
        modalConfirmed: true,
        externalApproversUnlocked: true
      });
      setIsDisabled(false);
    }
  }

  function renderNumber(result: JSX.Element[], num: number | undefined, text: string, pluralText: string) {
    const n = num ?? 0;
    if (n === 1) {
      result.push(<>1 {text}</>);
    } else if (n > 1) {
      result.push(
        <>
          {n} {pluralText}
        </>
      );
    }
  }

  const canApplicationBeUnlockedForExternalApprovers = () => {
    if (application) {
      // Application should allow external users
      if (application.userType == null || application.userType === UserType.External) {
        // User should be authorized and application should not already be unlocked
        if (
          hasAccess(AdgangAccessControl.ApplicationUnlockExternalApprover) &&
          !application.allowsExternalApprovers &&
          !confirmExternalApproversUnlocked.modalConfirmed
        ) {
          return true;
        }
      }
    }
    return false;
  };

  function renderUsage(r: ApplicationConsumptionResponse | undefined) {
    if (r === undefined || !r.isConsumedByOtherEntitities) return <></>;

    const result: JSX.Element[] = [];
    renderNumber(result, r.numRoles, 'rolle', 'roller');
    renderNumber(result, r.numPermissions, 'tilgang', 'tilganger');
    renderNumber(result, r.userMigrations, 'koblet konto', 'koblede kontoer');
    renderNumber(result, r.applicationResponsibles, 'applikasjonsansvarlig', 'applikasjonsansvarlige');

    return (
      <>
        <br />
        <span style={{ background: '#faf8dd' }}>
          ! Applikasjonen brukes av:
          {result.map((ro, i) => (
            <>
              {i === 0 ? ' ' : ', '}
              {ro}
            </>
          ))}
        </span>
      </>
    );
  }

  const { form, FormContainer, Checkbox, Dropdown, Input, DisplayErrors, TextArea } = useBoundForm<
    Application
  >({
    onSubmit: async (e) => {
      await OnFormSubmit(e);
    },
    errors: apiErrors,
    model: values
  });

  useEffect(() => {
    setIsDisabled(isReadOnly || isLoading || form.isLoading || !applicationResponsibles);
  }, [isReadOnly, isLoading, form.isLoading, applicationResponsibles]);

  const onResponsiblesChanged = useCallback((p: ApplicationResponsiblePersonRequest[]): void => {
    setValues((currentState) => ({ ...currentState, responsiblePersons: p }));
  }, []);

  const handleAccessRightSetChanged = (isExtended: boolean) => {
    setValues((currentState) => ({
      ...currentState,
      canAppResponsiblesAppointExternalApprovers: isExtended
    }));
  };

  return (
    <Page header='Applikasjon'>
      {isLoading || form.isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {deleteApplication && application && !deleteApplication.isConsumedByOtherEntitities && (
            <DeleteApplicationModal
              application={application}
              form={form}
              onCancelled={() => setDeleteApplication(undefined)}
              onDeleted={() => {
                setDeleteApplication(undefined);
                history.push(RoutePaths.applications);
              }}
            />
          )}
          {deleteApplication && application && deleteApplication.isConsumedByOtherEntitities && (
            <SimpleModal show header='Slett applikasjon' onCancel={() => setDeleteApplication(undefined)}>
              Applikasjonen kan ikke slettes.
              {renderUsage(deleteApplication)}
            </SimpleModal>
          )}
          {application && confirmExternalApproversUnlocked.externalApproversUnlocked && (
            <SimpleModal
              show
              header='Applikasjon er nå forberedt for bruk av eksterne godkjennere'
              onCancel={() =>
                setConfirmExternalApproversUnlocked({
                  modalConfirmed: true,
                  externalApproversUnlocked: false
                })
              }
            >
              Applikasjonen tillater nå at det opprettes eksterne godkjennere for de eksterne organisasjoner
              (brukersted) som har behov for dette. Kravet for å benytte denne muligheten er at rettigheten
              (rollen /tilgangen) som tildeles ekstern godkjenner (etter søknad) er satt opp med et krav om at
              godkjenner velger den organisasjon (fra en liste) som de skal representere. Dette gjelder videre
              også de eksterne rettigheter som sluttbruker søker om til den eksterne godkjenneren. Ekstern
              godkjenning vil kun gjelde rettighetsforespørsler fra brukere som har samme arbeidsgiver
              (organisasjonsnummer) som godkjenneren har eller som representerer samme organisasjon (fra
              liste).
            </SimpleModal>
          )}

          <Container fluid>
            <FormContainer form={form}>
              <Row>
                <Col sm={12} lg={6}>
                  <DisplayErrors form={form} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Input form={form} name='name' label='Navn' readonly={isReadOnly} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Input form={form} name='shortName' label='Kortnavn' readonly={isReadOnly} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <TextArea
                    form={form}
                    name='description'
                    label='Beskrivelse'
                    placeholder='Beskrivelse - Vises blant annet i MinSide'
                    readonly={isReadOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Input form={form} name='url' label='Url' readonly={isReadOnly} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Dropdown
                    form={form}
                    name='status'
                    label='Applikasjon status'
                    options={statuses}
                    readonly={isReadOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Dropdown
                    form={form}
                    name='migrationStatus'
                    label='Er det mulig å overføre rettigheter fra gammel til ny løsning nå?'
                    options={migrationStatuses}
                    readonly={isReadOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Dropdown
                    form={form}
                    name='userType'
                    label='Ment for type bruker'
                    options={userTypes}
                    readonly={isReadOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Dropdown
                    form={form}
                    name='userAuthenticationConstraint'
                    label='Type brukerautentisering'
                    options={userAuthenticationConstraints}
                    readonly={isReadOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Checkbox
                    form={form}
                    name='isIdPortenFederationDisabled'
                    label='Deaktiver ID Porten pålogging for denne applikasjonen?'
                    question=''
                    disabled={isReadOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Checkbox
                    form={form}
                    name='isComponentSpecific'
                    label='Komponentspesifikk applikasjon?'
                    question=''
                    disabled={isReadOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Checkbox
                    form={form}
                    name='isSearchableInMinSide'
                    label='Er applikasjonen søkbar i MinSide?'
                    question=''
                    disabled={isReadOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Checkbox
                    form={form}
                    name='requiresMfa'
                    label='Krever applikasjonen multi-faktor autentisering for eksterne brukere?'
                    question=''
                    disabled={isReadOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Checkbox
                    form={form}
                    name='requiresElevatedSecurityPolicy'
                    label='Krever applikasjonen påloggingsflyten med et høyere sikkerhetsnivå (gjelder eksterne brukere)?'
                    question=''
                    disabled={isReadOnly}
                  />
                </Col>
              </Row>
              {hasAccess(AdgangAccessControl.ApplicationEditViewResponsibles) && (
                <Row>
                  <Col sm={12} lg={6}>
                    <h2>Applikasjonsansvarlig</h2>
                    <ApplicationResponsibleSelector
                      readonly={
                        isDisabled || !hasAccess(AdgangAccessControl.ApplicationEditChangeResponsibles)
                      }
                      selectOptions={applicationResponsibles}
                      responsiblePersons={application?.responsiblePersons ?? []}
                      expandedAccessRightsSet={values?.canAppResponsiblesAppointExternalApprovers ?? false}
                      showAccessRightsExpander={hasAccess(
                        AdgangAccessControl.ApplicationExtendAppResponsibleAccessRights
                      )}
                      onAccessRightSetChanged={handleAccessRightSetChanged}
                      onChanged={onResponsiblesChanged}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={12} lg={6}>
                  {canApplicationBeUnlockedForExternalApprovers() && (
                    <Button
                      type='button'
                      text='Lås opp eksterne godkjennere'
                      onClick={unlockApplicationForExternalApprovers}
                      disabled={isDisabled}
                    />
                  )}
                </Col>
              </Row>
              {application?.externalApprovers && application.externalApprovers.length > 0 && (
                <Row>
                  <Col sm={12} lg={6}>
                    <h2>Eksterne godkjennere</h2>

                    <Table>
                      <thead>
                        <tr>
                          <Th title='Navn' />
                        </tr>
                      </thead>
                      <tbody>
                        {application?.externalApprovers?.map((e) => (
                          <tr key={e.userId ?? ''}>
                            <td>
                              <Link to={`${RoutePaths.externalusers}/${e.userId}`}>
                                {e.firstName} {e.surname}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={12} lg={6}>
                  <h2>Teknisk informasjon</h2>

                  <Table>
                    <thead>
                      <tr>
                        <Th colSpan={2} title='Tekniske detaljer' />
                      </tr>
                    </thead>
                    <tbody>
                      <tr key='clientId'>
                        <td>Client ID</td>
                        <td>{application?.clientId}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  {application?.redirectUris && (
                    <Table>
                      <thead>
                        <tr>
                          <Th title='Redirect Uri' />
                        </tr>
                      </thead>
                      <tbody>
                        {application.redirectUris.map((r) => (
                          <tr key={r}>
                            <td>{r}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={12}>
                  <Button type='submit' text='Lagre' disabled={isDisabled} />
                  <Button
                    type='button'
                    text='Avbryt'
                    styleType='light'
                    disabled={isDisabled}
                    onClick={() => history.push(RoutePaths.applications)}
                  />
                  {hasAccess(AdgangAccessControl.ApplicationDelete) && (
                    <Button
                      type='button'
                      text='Slett applikasjonen'
                      disabled={isDisabled || !hasAccess(AdgangAccessControl.ApplicationDelete)}
                      onClick={requestDelete}
                      styleType='light'
                    />
                  )}
                </Col>
              </Row>
            </FormContainer>
          </Container>
        </>
      )}
    </Page>
  );
}

function DeleteApplicationModal({
  application,
  form,
  onCancelled,
  onDeleted
}: {
  application: ApplicationResponse;
  form: Form<Application>;
  onCancelled: () => void;
  onDeleted: () => void;
}) {
  const [error, setError] = useState<GenericValidationError | undefined>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  async function onDeleteConfirmed() {
    if (application?.id) {
      setDeleteLoading(true);
      let isError = false;
      try {
        setError(undefined);
        await applicationsClient.apiApplicationsIdDelete({
          id: application.id
        });
      } catch (e) {
        isError = true;
        if (e instanceof Response && e.status === 400) {
          const apiError = (await e.json()) as GenericValidationError;
          if (apiError) {
            setError(apiError);
          }
        }
      } finally {
        setDeleteLoading(false);

        if (!isError) onDeleted();
      }
    }
  }

  return (
    <ConfirmationModal
      header='Slett applikasjon'
      show={application !== undefined}
      onCancel={onCancelled}
      onAccept={onDeleteConfirmed}
      disabled={error !== undefined || form.isLoading}
      hideButtons={deleteLoading}
    >
      {!error && 'Er du sikker på at du vil slett denne applikasjonen?'}
      {deleteLoading && <LoadingSpinner />}
      {error?.messages && error.messages.length > 0 && (
        <Message key='app_delete_err' text={error.messages[0]} type='error' />
      )}
    </ConfirmationModal>
  );
}

export default Application;
