import React, { useState } from 'react';
import {
  LoadingSpinner,
  useFetch,
  PagedTable,
  useBoundForm,
  SelectOption,
  FilterLabel,
  Button,
  FilterReset,
  ThSortable,
  useSortedPaging
} from 'common.ui';
import { accessRequestsClient } from 'api/AdgangClients';
import {
  AccessRequestResponsePagedResult,
  UserType,
  AccessRequestStatus,
  AccessRequestSearchOrderBy,
  SortOrder,
  ResourceFilter,
  AdgangAccessControl
} from 'api/adgang';
import Page from 'components/page/Page';
import { Link } from 'react-router-dom';
import { useApplications, usePermissions, useRoles } from 'api/hooks';
import { useSavedSearch } from 'hooks/search/useSavedSearch';

import { Row, Col, Container } from 'react-bootstrap';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import { useApplicationActiveResponsibles } from 'api/hooks/useApplicationActiveResponsibles';
import RoutePaths from 'RoutePaths';
import { useAccessControlList } from 'hooks/access/useAccessControlList';

type Search = {
  applicationIds: string[];
  applicationResponsiblePersonIds: string[];
  roleIds: string[];
  permissionIds: string[];
  userType?: UserType;
  seq: number;
};

interface IAccessRequestsScreen {
  applications: SelectOption[];
  initalSearch: Search;
  saveSearch: (value: Search) => void;
}

const emptySearch: Search = {
  applicationIds: [],
  applicationResponsiblePersonIds: [],
  roleIds: [],
  permissionIds: [],
  seq: 0
  // textFilter: '',
  // userType: undefined
};
const hasSearchFilters = (model: Search): boolean => {
  return !!(model.applicationIds.length || model.roleIds.length || model.permissionIds.length);
};

function AccessRequests() {
  const [, hasAccess] = useAccessControlList();
  const [applications] = useApplications(
    undefined,
    ResourceFilter.AccessRequest,
    !hasAccess(AdgangAccessControl.AccessRequestsViewShowNoAppFilter)
  );

  const [initialSearch, saveSearch] = useSavedSearch<Search>('access_requests_search', emptySearch);

  return applications ? (
    <AccessRequestsScreen applications={applications} initalSearch={initialSearch} saveSearch={saveSearch} />
  ) : (
    <LoadingSpinner />
  );
}

function AccessRequestsScreen({ initalSearch, saveSearch, applications }: IAccessRequestsScreen) {
  const [unvalidatedSearch, setSearch] = useState<Search>(initalSearch);
  const [roles] = useRoles(
    unvalidatedSearch?.userType ? unvalidatedSearch?.userType : undefined,
    unvalidatedSearch?.applicationIds,
    undefined,
    ResourceFilter.AccessRequest
  );
  const [permissions] = usePermissions(
    unvalidatedSearch?.userType ? unvalidatedSearch?.userType : undefined,
    unvalidatedSearch?.applicationIds,
    undefined,
    undefined,
    ResourceFilter.AccessRequest
  );
  const [applicationResponsiblePersons] = useApplicationActiveResponsibles(unvalidatedSearch?.applicationIds);

  const search = cleanSearch(unvalidatedSearch);

  const userTypes = [
    { id: UserType.Internal, text: 'Interne ansatte' },
    { id: UserType.External, text: 'Eksterne brukere' }
  ];

  const [accessRequestsPaged, isLoading, , fetchAccessRequests] = useFetch<AccessRequestResponsePagedResult>(
    () => fetchPagedAccessRequests(),
    {} as AccessRequestResponsePagedResult,
    false,
    [search.seq]
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    setCurrentPage,
    setSorting
  ] = useSortedPaging<AccessRequestSearchOrderBy>(fetchAccessRequests, {
    field: AccessRequestSearchOrderBy.RequestedByUserName,
    direction: 'asc'
  });

  const fetchPagedAccessRequests = async (): Promise<AccessRequestResponsePagedResult> => {
    const request = {
      accessRequestSearchRequest: {
        paginationQuery: {
          pageSize,
          pageNumber: currentPage,
          sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
          orderField: sorting.field
        },
        roleFilter:
          search.roleIds.length === 0
            ? undefined
            : {
                roleIds: search.roleIds.filter((r) => roles?.some((ro) => ro.id === r)).map((r) => +r)
              },
        permissionFilter:
          search.permissionIds.length === 0
            ? undefined
            : {
                permissionIds: search.permissionIds
                  .filter((p) => permissions?.some((x) => x.id === p))
                  .map((p) => +p)
              },
        applicationFilter:
          search.applicationIds.length > 0
            ? {
                applicationIds: search.applicationIds.filter((a) => a !== 'global'),
                includeGlobalValues: search.applicationIds.some((a) => a === 'global')
              }
            : undefined,
        userType: search?.userType ? search?.userType : undefined,
        status: AccessRequestStatus.Processing,
        responsiblePersonIds: search.applicationResponsiblePersonIds
      }
    };
    return accessRequestsClient.apiAccessRequestsSearchesPost(request);
  };

  function cleanSearch(s: Search) {
    const model: Search = {
      ...s,
      // Only search for roles, that are valid options based on your role dropdown
      // (which can change dynamically whenever a user multiselects an application)
      roleIds: s.roleIds.filter((r) => roles?.some((ro) => ro.id === r)),
      permissionIds: s.permissionIds.filter((p) => permissions?.some((x) => x.id === p))
    };
    return model;
  }

  function runSearch(s: Search) {
    const model = cleanSearch(s);

    setCurrentPage(1);
    saveSearch({ ...model, seq: 0 });
    setSearch({ ...model, seq: search.seq + 1 });
  }

  const { form, FormContainer, RadioGroup, MultiSelect } = useBoundForm<Search>({
    onSubmit: async (e) => {
      runSearch(e);
    },
    model: search
  });

  const handleSort = (e: SortEvent<AccessRequestSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  return (
    <Page header='Rettighetsforespørsler'>
      <Container fluid>
        <FormContainer key={search.seq} form={form}>
          <Row>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='applicationIds'
                label='Applikasjon'
                okLabel='Vis resultater'
                options={applications}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    applicationIds: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='roleIds'
                label='Rolle'
                okLabel='Vis resultater'
                options={roles ?? []}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    roleIds: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='permissionIds'
                label='Tilgang'
                okLabel='Vis resultater'
                options={permissions ?? []}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    permissionIds: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='applicationResponsiblePersonIds'
                label='Applikasjonsansvarlig'
                okLabel='Vis resultater'
                options={applicationResponsiblePersons ?? []}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    applicationResponsiblePersonIds: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
            <Col sm={12} lg={8}>
              <RadioGroup
                form={form}
                name='userType'
                label='Vis bare forespørsler fra:'
                options={userTypes}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div style={{ paddingTop: '5px' }}>
                {search.applicationIds &&
                  search.applicationIds.map((app) => (
                    <FilterLabel
                      key={`applicationId${app}`}
                      label={applications.filter((a) => a.id === app)[0]?.text}
                      onRemove={() => {
                        runSearch({
                          ...search,
                          applicationIds: search.applicationIds.filter((a) => a !== app),
                          seq: search.seq + 1
                        });
                      }}
                    />
                  ))}
                {search.roleIds &&
                  roles &&
                  search.roleIds.map((role) => (
                    <FilterLabel
                      key={`role${role}`}
                      label={roles.filter((r) => r.id === role)[0]?.text}
                      onRemove={() => {
                        runSearch({
                          ...search,
                          roleIds: search.roleIds.filter((r) => r !== role),
                          seq: search.seq + 1
                        });
                      }}
                    />
                  ))}
                {search.permissionIds &&
                  permissions &&
                  search.permissionIds.map((permission) => (
                    <FilterLabel
                      key={`permission${permission}`}
                      label={permissions.filter((p) => p.id === permission)[0]?.text}
                      onRemove={() => {
                        runSearch({
                          ...search,
                          permissionIds: search.permissionIds.filter((p) => p !== permission),
                          seq: search.seq + 1
                        });
                      }}
                    />
                  ))}
                {search.userType && (
                  <FilterLabel
                    key='userType'
                    label={userTypes.filter((u) => u.id === search.userType)[0].text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        userType: undefined,
                        seq: search.seq + 1
                      });
                    }}
                  />
                )}
                {hasSearchFilters(search) && (
                  <FilterReset
                    key='reset'
                    label='Fjern alle'
                    onRemove={() => {
                      runSearch({
                        ...emptySearch
                      });
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div style={{ paddingTop: '5px' }}>
                <Button type='submit' text='Søk' />
              </div>
            </Col>
          </Row>
        </FormContainer>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <PagedTable
              startIndex={startIndex}
              totalHits={accessRequestsPaged.pageInfo?.totalCount ?? 0}
              pageSize={pageSize}
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
            >
              <thead>
                <tr>
                  <ThSortable
                    title='Ansatt/bruker'
                    field={AccessRequestSearchOrderBy.RequestedByUserName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Organisasjon/Seksjonsnavn'
                    field={AccessRequestSearchOrderBy.RequestedByUserSectionName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Rettighet'
                    field={AccessRequestSearchOrderBy.RequestedAccessName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Applikasjon'
                    field={AccessRequestSearchOrderBy.ApplicationName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                </tr>
              </thead>

              <tbody>
                {accessRequestsPaged?.data?.map((u) => (
                  <tr key={u.accessReqeuestId ?? ''}>
                    <td>
                      <Link to={`${RoutePaths.accessrequests}/${u.accessReqeuestId}`}>
                        {u.requestedByUserName}
                      </Link>
                    </td>
                    <td>{u.organization?.name || u.employer || u.requestedByUserSectionName}</td>
                    <td>{u.requestedRoleName || u.requestedPermissionName}</td>
                    <td>{u.applicationDescription || u.applicationName}</td>
                  </tr>
                ))}
              </tbody>
            </PagedTable>
          </>
        )}
      </Container>
    </Page>
  );
}

export default AccessRequests;
