/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    TranslationResponsePagedResult,
    TranslationResponsePagedResultFromJSON,
    TranslationResponsePagedResultToJSON,
    TranslationUpdateRequest,
    TranslationUpdateRequestFromJSON,
    TranslationUpdateRequestToJSON,
    TranslationsSearchRequest,
    TranslationsSearchRequestFromJSON,
    TranslationsSearchRequestToJSON,
} from '../models';

export interface ApiTranslationsEditPutRequest {
    translationUpdateRequest?: TranslationUpdateRequest;
}

export interface ApiTranslationsSearchPostRequest {
    translationsSearchRequest?: TranslationsSearchRequest;
}

/**
 * 
 */
export class TranslationsApi extends runtime.BaseAPI {

    /**
     */
    async apiTranslationsEditPutRaw(requestParameters: ApiTranslationsEditPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/translations/edit`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TranslationUpdateRequestToJSON(requestParameters.translationUpdateRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTranslationsEditPut(requestParameters: ApiTranslationsEditPutRequest): Promise<void> {
        await this.apiTranslationsEditPutRaw(requestParameters);
    }

    /**
     */
    async apiTranslationsSearchPostRaw(requestParameters: ApiTranslationsSearchPostRequest): Promise<runtime.ApiResponse<TranslationResponsePagedResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/translations/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TranslationsSearchRequestToJSON(requestParameters.translationsSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TranslationResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiTranslationsSearchPost(requestParameters: ApiTranslationsSearchPostRequest): Promise<TranslationResponsePagedResult> {
        const response = await this.apiTranslationsSearchPostRaw(requestParameters);
        return await response.value();
    }

}
