/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserEventType,
    UserEventTypeFromJSON,
    UserEventTypeFromJSONTyped,
    UserEventTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserEventExpirationResponse
 */
export interface UserEventExpirationResponse {
    /**
     * 
     * @type {UserEventType}
     * @memberof UserEventExpirationResponse
     */
    userEvent?: UserEventType;
    /**
     * 
     * @type {number}
     * @memberof UserEventExpirationResponse
     */
    translationResourceKey?: number;
    /**
     * 
     * @type {number}
     * @memberof UserEventExpirationResponse
     */
    expiryInDays?: number;
}

export function UserEventExpirationResponseFromJSON(json: any): UserEventExpirationResponse {
    return UserEventExpirationResponseFromJSONTyped(json, false);
}

export function UserEventExpirationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventExpirationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userEvent': !exists(json, 'userEvent') ? undefined : UserEventTypeFromJSON(json['userEvent']),
        'translationResourceKey': !exists(json, 'translationResourceKey') ? undefined : json['translationResourceKey'],
        'expiryInDays': !exists(json, 'expiryInDays') ? undefined : json['expiryInDays'],
    };
}

export function UserEventExpirationResponseToJSON(value?: UserEventExpirationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userEvent': UserEventTypeToJSON(value.userEvent),
        'translationResourceKey': value.translationResourceKey,
        'expiryInDays': value.expiryInDays,
    };
}


