import { useState } from 'react';

export function useSavedSearch<T>(searchName: string, defaultValue?: T): [T, (value: T) => void] {
  const [search] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(searchName);
      if (!item) {
        return defaultValue;
      }

      const value = JSON.parse(item);
      if (!defaultValue) {
        return value;
      }

      // This makes sure that deserializing the stored object, the object's properties will match the expected object <T>.
      // If you add a new property in the object <T>, we want to have the default value there, and not a runtime-only undefined,
      // as the new property in the Search object <T> could not be set as optional.
      Object.keys(defaultValue).forEach((propertyName) => {
        const hasProperty = Object.prototype.hasOwnProperty.call(value, propertyName);
        if (hasProperty) {
          return;
        }
        value[propertyName] = (defaultValue as any)[propertyName];
      });
      return value;
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  });

  const saveSearch = (value: T) => {
    localStorage.setItem(searchName, JSON.stringify(value));
  };

  return [search, saveSearch];
}
