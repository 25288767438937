/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AarOrganizationResponse
 */
export interface AarOrganizationResponse {
    /**
     * 
     * @type {string}
     * @memberof AarOrganizationResponse
     */
    legalEntity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AarOrganizationResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AarOrganizationResponse
     */
    organizationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AarOrganizationResponse
     */
    shortName?: string | null;
}

export function AarOrganizationResponseFromJSON(json: any): AarOrganizationResponse {
    return AarOrganizationResponseFromJSONTyped(json, false);
}

export function AarOrganizationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AarOrganizationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'legalEntity': !exists(json, 'legalEntity') ? undefined : json['legalEntity'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'organizationNumber': !exists(json, 'organizationNumber') ? undefined : json['organizationNumber'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
    };
}

export function AarOrganizationResponseToJSON(value?: AarOrganizationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'legalEntity': value.legalEntity,
        'name': value.name,
        'organizationNumber': value.organizationNumber,
        'shortName': value.shortName,
    };
}


