import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import IconArrowRight from '../../assets/icons/arrow-right-icon.svg';
import styles from './Sidebar.scss';

export interface SidebarItem {
  link: string;
  label?: string;
  icon?: React.ReactNode;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  children?: SidebarItem[];
}

interface IProp extends RouteComponentProps {
  routes: SidebarItem[];
  isOpened: boolean;
  onLinkClick?: () => void;
}

const Sidebar = ({ routes, location, isOpened, onLinkClick }: IProp) => {
  return (
    <div className={`${styles.sidebar} ${isOpened ? '' : styles.closed}`}>
      {routes.map((m) => (
        <MenuItem
          key={m.link}
          link={m.link}
          onLinkClick={onLinkClick}
          label={m.label}
          isActive={m.link === location.pathname}
          isSubItemActive={
            m.link === location.pathname ||
            m.children?.some((c) => c.link === location.pathname)
          }
          isOpened={isOpened}
          icon={m.icon}
        >
          {/* {isOpened && ( */}
          <React.Fragment>
            {m.children?.map((c) => (
              <SubMenuItem
                key={c.link}
                link={c.link}
                onLinkClick={onLinkClick}
                label={c.label}
                icon={c.icon}
                isActive={c.link === location.pathname}
              />
            ))}
          </React.Fragment>
          {/* )} */}
        </MenuItem>
      ))}
    </div>
  );
};

interface IMenuItemProps {
  icon?: React.ReactNode;
  isActive?: boolean;
  isSubItemActive?: boolean;
  isOpened?: boolean;
  label?: string;
  link: string;
  onLinkClick?: () => void;
  children?: React.ReactNode;
}

const MenuItem = ({
  icon,
  isActive,
  isSubItemActive,
  isOpened,
  label,
  link,
  onLinkClick,
  children
}: IMenuItemProps) => {
  return (
    <React.Fragment>
      <div
        className={`${styles['menu-list-item']} ${
          isActive ? styles['list-item-active'] : ''
        } ${isSubItemActive ? styles['list-subitem-active'] : ''}`}
      >
        <Link to={link} onClick={onLinkClick} title={isOpened ? '' : label}>
          {icon}
          <span className={styles['menu-item-text']}>{label}</span>
        </Link>
      </div>
      {children}
      <div className={styles['bottom-divider']} />
    </React.Fragment>
  );
};

const SubMenuItem = ({
  isActive,
  label,
  isOpened,
  link,
  onLinkClick
}: IMenuItemProps) => {
  return (
    <div
      className={`${styles['menu-list-sub-item']} ${
        isActive ? styles['list-sub-item-active'] : ''
      }`}
    >
      <Link to={link} onClick={onLinkClick} title={isOpened ? '' : label}>
        <IconArrowRight className={styles['sub-menu-item-icon']} />
        <span className={styles['menu-item-text']}>{label}</span>
      </Link>
    </div>
  );
};

const sideBarWithRouter = withRouter(Sidebar);

export { sideBarWithRouter as Sidebar };
