import React, { useEffect, useState } from 'react';
import { Table, Th, Button, Checkbox, SelectOption, LoadingSpinner } from 'common.ui';

import { ApplicationResponsiblePersonRequest, ApplicationResponsiblePersonResponse } from 'api/adgang';
import { ReactComponent as IconCross } from 'assets/icons/cross-icon.svg';
import { ResponsiblePersonsMultiSelect } from './ResponsiblePersonsMultiSelect';
import './ApplicationResponsibleSelector.scss';

type IProps = {
  readonly: boolean;
  selectOptions: SelectOption[] | undefined;
  responsiblePersons: ApplicationResponsiblePersonResponse[];
  expandedAccessRightsSet: boolean;
  showAccessRightsExpander: boolean;
  onAccessRightSetChanged(isExpanded: boolean): void;
  onChanged(responsiblePersons: ApplicationResponsiblePersonRequest[]): void;
};

function ApplicationResponsibleSelector({
  readonly,
  selectOptions,
  responsiblePersons,
  expandedAccessRightsSet,
  showAccessRightsExpander,
  onAccessRightSetChanged,
  onChanged
}: IProps) {
  const [showAddResponsibles, setShowAddResponsibles] = useState(false);
  const [activeResponsibles, setActiveResponsibles] = useState<ApplicationResponsiblePersonResponse[]>(
    responsiblePersons
  );
  const [selectedIds, setSelectedIds] = useState<string[]>(responsiblePersons.map((x) => x.userId as string));

  useEffect(() => {
    setActiveResponsibles((currentState) => {
      const currentSelection = selectOptions?.filter((x) => selectedIds.some((y) => x.id === y)) ?? [];
      const activeResponsiblesFiltered = currentState?.filter((x) => selectedIds.some((y) => y === x.userId));
      const newActiveResponsibles = currentSelection.filter(
        (x) => !activeResponsiblesFiltered?.some((y) => y.userId === x.id)
      );
      return [
        ...(activeResponsiblesFiltered ?? []),
        ...(newActiveResponsibles?.map((x) => ({
          userId: x.id,
          name: x.text
        })) ?? [])
      ];
    });
  }, [selectedIds, selectOptions]);

  const onSetAccess = (responsiblePerson: ApplicationResponsiblePersonRequest) => {
    setActiveResponsibles(
      activeResponsibles?.map((x) => (x.userId === responsiblePerson.userId ? responsiblePerson : x))
    );
  };

  const handleAccessRightSetChanged = (isExtended: boolean) => {
    if (!isExtended && activeResponsibles.some((x) => x.canAppointExternalApproversForApplication)) {
      setActiveResponsibles(
        activeResponsibles?.map((x) =>
          x.canAppointExternalApproversForApplication
            ? { ...x, canAppointExternalApproversForApplication: false }
            : x
        )
      );
    }

    onAccessRightSetChanged(isExtended);
  };

  useEffect(() => {
    onChanged(
      activeResponsibles?.map((x) => ({
        userId: x.userId,
        isApplicationAdministrator: x.isApplicationAdministrator,
        canManageAccessRequestsForApplication: x.canManageAccessRequestsForApplication,
        canAppointExternalApproversForApplication: x.canAppointExternalApproversForApplication
      })) ?? []
    );
  }, [onChanged, activeResponsibles]);

  if (!selectOptions) return <LoadingSpinner />;

  return (
    <>
      {showAccessRightsExpander && (
        <div>
          <Checkbox
            id='expandedAccessRightsSet_checkBox'
            key={`cb_expandedAccessRightsSet${expandedAccessRightsSet}`}
            checked={expandedAccessRightsSet}
            labelText='Kan applikasjonsansvarlige godkjenne rettighetsforespørsler om ekstern godkjenningsrollen?'
            disabled={readonly}
            onChange={() => handleAccessRightSetChanged(!expandedAccessRightsSet)}
          />
        </div>
      )}

      {!showAddResponsibles && selectOptions && (
        <Button
          type='button'
          text=' + Legg til applikasjonsansvarlig'
          onClick={() => setShowAddResponsibles(true)}
          styleType='light'
          disabled={readonly}
        />
      )}

      {!showAddResponsibles && selectOptions && activeResponsibles && activeResponsibles?.length > 0 && (
        <Table>
          <thead className='responsible-table-header'>
            <tr>
              <Th title='Navn' />
              <td>Administrator</td>
              <td>Godkjenning sluttbruker</td>
              {expandedAccessRightsSet && <td>Godkjenning lokal admin</td>}
              <td>Fjern</td>
            </tr>
          </thead>
          <tbody>
            {activeResponsibles
              .sort((p1, p2) => (p1.name || '').localeCompare(p2.name || ''))
              .map((responsible) => {
                return (
                  <tr key={responsible.userId as string} className='responsible-table'>
                    <td>{responsible.name}</td>
                    <td>
                      <Checkbox
                        id={`${responsible.userId}_rolesCheckBox`}
                        key={`cb_${responsible.userId}${responsible.isApplicationAdministrator}`}
                        checked={responsible.isApplicationAdministrator}
                        disabled={readonly}
                        onChange={() =>
                          onSetAccess({
                            ...responsible,
                            isApplicationAdministrator: !responsible.isApplicationAdministrator
                          })
                        }
                      />
                    </td>
                    <td>
                      <Checkbox
                        id={`${responsible.userId}_appsCheckBox`}
                        key={`cb_${responsible.userId}${responsible.canManageAccessRequestsForApplication}`}
                        checked={responsible.canManageAccessRequestsForApplication}
                        disabled={readonly}
                        onChange={() =>
                          onSetAccess({
                            ...responsible,
                            canManageAccessRequestsForApplication: !responsible.canManageAccessRequestsForApplication
                          })
                        }
                      />
                    </td>

                    {expandedAccessRightsSet && (
                      <td>
                        <Checkbox
                          id={`${responsible.userId}_externalApproversCheckBox`}
                          key={`cb_${responsible.userId}${responsible.canAppointExternalApproversForApplication}`}
                          checked={responsible.canAppointExternalApproversForApplication}
                          disabled={readonly}
                          onChange={() =>
                            onSetAccess({
                              ...responsible,
                              canAppointExternalApproversForApplication: !responsible.canAppointExternalApproversForApplication
                            })
                          }
                        />
                      </td>
                    )}
                    <td className='text-center'>
                      {!readonly && (
                        <IconCross
                          onClick={() => setSelectedIds(selectedIds?.filter((x) => x !== responsible.userId))}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}

      {showAddResponsibles && !readonly && (
        <>
          <b>Legg til applikasjonsansvarliger</b>
          <br />
          <ResponsiblePersonsMultiSelect
            options={selectOptions ? selectOptions.filter((x) => !selectedIds.some((y) => y === x.id)) : []}
            selectedIds={[]}
            name='responsiblePersonIds'
            label='Velg ansvarliger'
            triggerOnChangeAlways
            isInitiallyOpen
            onChange={(p) => {
              let a = activeResponsibles?.map((r) => r.userId as string) ?? [];
              a = a.concat(p.filter((x) => !a.some((y) => y === x)));

              setSelectedIds(a);
              setShowAddResponsibles(false);
            }}
            onClose={() => setShowAddResponsibles(false)}
            okLabel='Velg'
          />
        </>
      )}
    </>
  );
}

export default ApplicationResponsibleSelector;
