import React, { useState } from 'react';
import { Button, useBoundForm } from 'common.ui';
import Page from 'components/page/Page';
import { Row, Col, Container } from 'react-bootstrap';

import AdGroupsTable from 'components/adgroups/AdGroupsTable';
import SectionsTable from 'components/sections/SectionsTable';

type Search = {
  textFilter: string;
  filterType: string;
};

function AdGroupsAndSections() {
  const [search, setSearch] = useState<Search>({ textFilter: '', filterType: 'adgroups' });

  const filterTypes = [
    { id: 'adgroups', text: 'AD-grupper' },
    { id: 'sections', text: 'Seksjoner' }
  ];

  const { form, FormContainer, RadioGroup, Input } = useBoundForm<Search>({
    onSubmit: async (e) => {
      runSearch(e);
    },
    model: search
  });

  function runSearch(model: Search) {
    setSearch(model);
  }

  return (
    <Page header='AD-grupper og seksjoner'>
      <Container fluid>
        <FormContainer form={form}>
          <Row>
            <Col sm={12} lg={4}>
              <Input
                form={form}
                name='textFilter'
                label='Fritekstsøk'
                placeholder=''
                onEnterPressed={(e) => {
                  runSearch({
                    ...search,
                    textFilter: e
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <RadioGroup
                onChange={(e) => {
                  runSearch({
                    ...search,
                    filterType: e
                  });
                }}
                form={form}
                name='filterType'
                label='Vis bare:'
                options={filterTypes}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Button type='submit' text='Søk' />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {search.filterType === 'adgroups' && <AdGroupsTable textFilter={search.textFilter} />}
              {search.filterType === 'sections' && <SectionsTable textFilter={search.textFilter} />}
            </Col>
          </Row>
        </FormContainer>
      </Container>
    </Page>
  );
}

export default AdGroupsAndSections;
