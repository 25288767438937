import { rolesClient } from 'api/AdgangClients';
import { SelectOption, useFetch } from 'common.ui';
import { DependencyList } from 'react';
import { RoleResponse } from 'api/adgang';

export type RoleResult = [SelectOption | undefined, RoleResponse | undefined];

export function useRole(
  roleId?: number | null,
  dependencies: DependencyList = [] as DependencyList
): RoleResult {
  const [fetchedRole] = useFetch<RoleResult>(() => fetchRole(), [undefined, undefined] as RoleResult, false, [
    roleId,
    ...dependencies
  ]);

  const fetchRole = async (): Promise<RoleResult> => {
    if (!roleId) {
      return [undefined, undefined];
    }
    const result = await rolesClient.getRolebyId({
      id: roleId
    });
    const option = {
      id: result.roleId?.toString(),
      text: result.name
    } as SelectOption;
    return [option, result];
  };

  return fetchedRole;
}
