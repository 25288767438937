import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import './Sidebar.scss';

export interface SidebarItem {
  link: string;
  label?: string;
  icon?: React.ReactNode;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  children?: SidebarItem[];
}

interface IProp extends RouteComponentProps {
  routes: SidebarItem[];
}

const Sidebar = ({ routes, location }: IProp) => {
  return (
    <div className='sidebar'>
      {routes.map((m) => (
        <MenuItem
          key={m.link}
          link={m.link}
          label={m.label}
          isActive={m.link === location.pathname}
          isSubItemActive={
            m.link === location.pathname ||
            location.pathname.startsWith(`${m.link}/`) ||
            m.children?.some((c) => c.link === location.pathname)
          }
          icon={m.icon}
        />
      ))}
    </div>
  );
};

interface IMenuItemProps {
  icon?: React.ReactNode;
  isActive?: boolean;
  isSubItemActive?: boolean;
  label?: string;
  link: string;
  children?: React.ReactNode;
}

const MenuItem = ({ icon, isActive, isSubItemActive, label, link, children }: IMenuItemProps) => {
  return (
    <>
      <div className={`menu-list-item ${isActive || isSubItemActive ? 'list-item-active' : ''}`}>
        <Link to={link} title={label}>
          {icon}
          <span className='menu-item-text'>{label}</span>
        </Link>
      </div>
      {children}
      <div className='bottom-divider' />
    </>
  );
};

const sideBarWithRouter = withRouter(Sidebar);

export { sideBarWithRouter as Sidebar };
