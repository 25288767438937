import { permissionsClient } from 'api/AdgangClients';
import { SelectOption, useFetch } from 'common.ui';
import { DependencyList } from 'react';
import { PermissionResponse } from 'api/adgang';

export type PermissionResult = [SelectOption | undefined, PermissionResponse | undefined];

export function usePermission(
  permissionId?: number | null,
  dependencies: DependencyList = [] as DependencyList
): PermissionResult {
  const [fetchedPermission] = useFetch<PermissionResult>(
    () => fetchPermission(),
    [undefined, undefined],
    false,
    [permissionId, ...dependencies]
  );

  const fetchPermission = async (): Promise<PermissionResult> => {
    if (!permissionId) {
      return [undefined, undefined];
    }
    const result = await permissionsClient.getPermissionbyId({
      id: permissionId
    });
    const option = {
      id: result.permissionId?.toString(),
      text: result.name
    } as SelectOption;
    return [option, result];
  };

  return fetchedPermission;
}
