import React from 'react';
import styles from './WizardBreadCrumb.scss';

export type WizardBreadCrumbAction = {
  label: string;
  active?: boolean;
  onClick?: () => void;
};

type Props = {
  actions: WizardBreadCrumbAction[];
};
export function WizardBreadCrumb({ actions }: Props) {
  let maxActive = 0;
  actions.forEach((a, i) => {
    if (a.active) {
      maxActive = i;
    }
  });
  return (
    <div className={styles.container}>
      {/* <span className={styles.label}>Be om rettighet</span> */}
      <ul className={styles.breadcrumb}>
        {actions.map((a, i) => (
          <li
            className={
              (i < maxActive ? styles.finished : '') +
              (i !== maxActive - 1
                ? ' d-none d-sm-block '
                : ` ${styles.reverse}`) +
              (i === maxActive ? ` ${styles.active}` : '') +
              (i > maxActive ? ` ${styles.future}` : '')
            }
            key={a.label ?? ''}
          >
            <span
              onClick={() => {
                if (i < maxActive && a.onClick) {
                  a.onClick();
                }
              }}
            >
              {i + 1}. {a.label}
            </span>
          </li>
        ))}
        <li />
      </ul>
    </div>
  );
}
