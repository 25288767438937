import { UserAccessOrigin } from 'api/adgang/models';

export function formatOrigin(
  origin: UserAccessOrigin | null | undefined,
  originDetails: string | undefined | null
) {
  switch (origin) {
    case UserAccessOrigin.AdGroup:
      return `AD-gruppe: ${originDetails}`;
    case UserAccessOrigin.Section:
      return `Seksjon: ${originDetails}`;
    case UserAccessOrigin.User:
      return `Bruker`;
    case UserAccessOrigin.Role:
      return `Rolle: ${originDetails}`;
    default:
      return 'Ukjennt';
  }
}

export function asAccessOriginText(
  origin: UserAccessOrigin | null | undefined,
  originDetails: string | undefined | null,
  parentOrigin: UserAccessOrigin | null | undefined,
  parentOriginDetails: string | undefined | null
) {
  if (origin === UserAccessOrigin.Section || origin === UserAccessOrigin.AdGroup) {
    return formatOrigin(origin, originDetails);
  }
  return parentOrigin ? formatOrigin(parentOrigin, parentOriginDetails) : '';
}
