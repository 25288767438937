import React from 'react';
import Page from 'components/page/Page';
import OrganizationGroupForm from 'components/organizationgroups/OrganizationGroupForm';
import { OrganizationGroupCreateRequest, OrganizationGroupType } from 'api/adgang/models';
import { organizationGroupsClient } from 'api/AdgangClients';
import { useHistory } from 'react-router-dom';
import RoutePaths from 'RoutePaths';

function AddOrganizationGroup() {
  const history = useHistory();

  async function onSave(model: OrganizationGroupCreateRequest) {
    await organizationGroupsClient.apiOrganizationGroupsPost({
      organizationGroupCreateRequest: model
    });
    history.push(RoutePaths.organizationgroups);
  }

  return (
    <Page header='Legg til ny organisasjonsgruppe'>
      <OrganizationGroupForm<OrganizationGroupCreateRequest>
        onSave={onSave}
        onSaveLabel='Legg til organisasjonsgruppe'
        initialValue={{
          organizationGroupType: OrganizationGroupType.DomesticCompany
        }}
      />
    </Page>
  );
}

export default AddOrganizationGroup;
