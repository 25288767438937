import React from 'react';
import {
  formatDatetime,
  LoadingSpinner,
  PagedTable,
  ThSortable,
  Th,
  useFetch,
  useSortedPaging
} from 'common.ui';
import { Row, Col } from 'react-bootstrap';
import { usersClient } from 'api/AdgangClients';
import {
  ApiUsersUserIdEventsPagedPostRequest,
  UserEventResponsePagedResult,
  UserEventRequestSearchOrderBy,
  SortOrder
} from 'api/adgang';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';

export interface IProps {
  userId: string;
  refresh?: number;
}

export function UserEventsTable({ userId }: IProps) {
  // const [users, isLoading] = useFetch<UserEventResponse[]>(
  //   () => fetchUserEvents(),
  //   [] as UserEventResponse[],
  //   false,
  //   [userId, refresh]
  // );

  const [userEventsPaged, isLoading, , fetchPagedEvents] = useFetch<UserEventResponsePagedResult>(
    () => fetchUserEventPaged(),
    {},
    false,
    []
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    ,
    setSorting
  ] = useSortedPaging<UserEventRequestSearchOrderBy>(fetchPagedEvents, {
    field: UserEventRequestSearchOrderBy.When,
    direction: 'desc'
  });

  const fetchUserEventPaged = async () => {
    const request = {
      userId,
      pageNumber: currentPage,
      pageSize,
      sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
      orderField: sorting.field
    } as ApiUsersUserIdEventsPagedPostRequest;
    return usersClient.apiUsersUserIdEventsPagedPost(request);
  };

  const handleSort = (e: SortEvent<UserEventRequestSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  // const fetchUserEvents = async () => {
  //   const request: ApiUsersUserIdEventsPagedPostRequest = {
  //     userId
  //   };
  //   return usersClient.apiUsersUserIdEventsPost(request);
  // };

  return (
    <>
      <Row>
        <Col sm='4'>
          <h2>Hendelsesarkiv</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <PagedTable
              startIndex={startIndex}
              totalHits={userEventsPaged.pageInfo?.totalCount ?? 0}
              pageSize={pageSize}
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
            >
              <thead>
                <tr>
                  <ThSortable
                    title='Dato/tid'
                    field={UserEventRequestSearchOrderBy.When}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <Th title='Hva' />
                  <ThSortable
                    title='Hvem'
                    field={UserEventRequestSearchOrderBy.Who}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                </tr>
              </thead>
              <tbody>
                {userEventsPaged.data?.map((ue) => (
                  <tr key={ue.userEventId}>
                    <td className='text-nowrap'>{formatDatetime(ue.createdOn)}</td>
                    <td>{ue.eventDescription}</td>
                    <td className='text-nowrap'>{ue.createdByDescription}</td>
                  </tr>
                ))}
              </tbody>
            </PagedTable>
            // <UnpagedTable totalHits={users.length ?? 0}>
            //   <thead>
            //     <tr>
            //       <Th title='Dato/tid' />
            //       <Th title='Hva' />
            //       <Th title='Hvem' />
            //     </tr>
            //   </thead>

            // <tbody>
            //   {users
            //     ?.sort((u1, u2) => (u2.createdOn?.valueOf() || 0) - (u1.createdOn?.valueOf() || 0))
            //     .map((u) => (
            //       <tr key={u.userEventId}>
            //         <td className='text-nowrap'>{formatDatetime(u.createdOn)}</td>
            //         <td>{u.eventDescription}</td>
            //         <td className='text-nowrap'>{u.createdByDescription}</td>
            //       </tr>
            //     ))}
            // </tbody>
            // </UnpagedTable>
          )}
        </Col>
      </Row>
    </>
  );
}
