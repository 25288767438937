import { applicationsClient } from 'api/AdgangClients';
import { SelectOption, useFetch } from 'common.ui';
import { ApplicationResponse, ApplicationSearchOrderBy, ResourceFilter, SortOrder } from 'api/adgang';
import { GlobalApplicationName } from 'helpers/application/consts';

export type ApplicationsResult = [SelectOption[] | undefined, ApplicationResponse[] | undefined];

export function useApplications(
  userId?: string,
  resourceFilter?: ResourceFilter,
  excludeNoAppSelectOption?: boolean
): ApplicationsResult {
  const [fetchedApplications] = useFetch<ApplicationsResult>(
    () => fetchApplications(),
    [undefined, undefined] as ApplicationsResult,
    false,
    [userId]
  );

  const fetchApplications = async (): Promise<ApplicationsResult> => {
    const applications = await applicationsClient.apiApplicationsGet({
      pageNumber: 1,
      pageSize: 9999,
      sortOrder: SortOrder.Ascending,
      orderField: ApplicationSearchOrderBy.Name,
      userId,
      resourceFilter: resourceFilter ?? ResourceFilter.None
    });

    if (!applications.data) {
      return [undefined, undefined] as ApplicationsResult;
    }

    const data = applications.data || [];

    const options = [...data, { name: GlobalApplicationName, id: 'global' }]
      .sort((o1, o2) => (o1.description || o1.name || '').localeCompare(o2.description || o2.name || ''))
      .filter((x) => !excludeNoAppSelectOption || x.id !== 'global')
      .map((app) => {
        return {
          id: app.id,
          text: app.name || app.description
        } as SelectOption;
      });

    return [options, data];
  };

  return fetchedApplications;
}
