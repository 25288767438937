/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    PermissionResponse,
    PermissionResponseFromJSON,
    PermissionResponseToJSON,
    RoleResponse,
    RoleResponseFromJSON,
    RoleResponseToJSON,
    SectionDetailResponse,
    SectionDetailResponseFromJSON,
    SectionDetailResponseToJSON,
    SectionDetailResponsePagedResult,
    SectionDetailResponsePagedResultFromJSON,
    SectionDetailResponsePagedResultToJSON,
    SectionPermissionsUpdateRequest,
    SectionPermissionsUpdateRequestFromJSON,
    SectionPermissionsUpdateRequestToJSON,
    SectionRolesUpdateRequest,
    SectionRolesUpdateRequestFromJSON,
    SectionRolesUpdateRequestToJSON,
    SectionSearchOrderBy,
    SectionSearchOrderByFromJSON,
    SectionSearchOrderByToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface ApiSectionsGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: SectionSearchOrderBy;
    filter?: string;
}

export interface ApiSectionsSectionCodeGetRequest {
    sectionCode: string;
}

export interface ApiSectionsSectionCodePermissionsGetRequest {
    sectionCode: string;
}

export interface ApiSectionsSectionCodePermissionsPutRequest {
    sectionCode: string;
    sectionPermissionsUpdateRequest?: SectionPermissionsUpdateRequest;
}

export interface ApiSectionsSectionCodeRolesGetRequest {
    sectionCode: string;
}

export interface ApiSectionsSectionCodeRolesPutRequest {
    sectionCode: string;
    sectionRolesUpdateRequest?: SectionRolesUpdateRequest;
}

/**
 * 
 */
export class SectionsApi extends runtime.BaseAPI {

    /**
     */
    async apiSectionsGetRaw(requestParameters: ApiSectionsGetRequest): Promise<runtime.ApiResponse<SectionDetailResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionDetailResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiSectionsGet(requestParameters: ApiSectionsGetRequest): Promise<SectionDetailResponsePagedResult> {
        const response = await this.apiSectionsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSectionsSectionCodeGetRaw(requestParameters: ApiSectionsSectionCodeGetRequest): Promise<runtime.ApiResponse<SectionDetailResponse>> {
        if (requestParameters.sectionCode === null || requestParameters.sectionCode === undefined) {
            throw new runtime.RequiredError('sectionCode','Required parameter requestParameters.sectionCode was null or undefined when calling apiSectionsSectionCodeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sections/{sectionCode}`.replace(`{${"sectionCode"}}`, encodeURIComponent(String(requestParameters.sectionCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionDetailResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSectionsSectionCodeGet(requestParameters: ApiSectionsSectionCodeGetRequest): Promise<SectionDetailResponse> {
        const response = await this.apiSectionsSectionCodeGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSectionsSectionCodePermissionsGetRaw(requestParameters: ApiSectionsSectionCodePermissionsGetRequest): Promise<runtime.ApiResponse<Array<PermissionResponse>>> {
        if (requestParameters.sectionCode === null || requestParameters.sectionCode === undefined) {
            throw new runtime.RequiredError('sectionCode','Required parameter requestParameters.sectionCode was null or undefined when calling apiSectionsSectionCodePermissionsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sections/{sectionCode}/permissions`.replace(`{${"sectionCode"}}`, encodeURIComponent(String(requestParameters.sectionCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PermissionResponseFromJSON));
    }

    /**
     */
    async apiSectionsSectionCodePermissionsGet(requestParameters: ApiSectionsSectionCodePermissionsGetRequest): Promise<Array<PermissionResponse>> {
        const response = await this.apiSectionsSectionCodePermissionsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSectionsSectionCodePermissionsPutRaw(requestParameters: ApiSectionsSectionCodePermissionsPutRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.sectionCode === null || requestParameters.sectionCode === undefined) {
            throw new runtime.RequiredError('sectionCode','Required parameter requestParameters.sectionCode was null or undefined when calling apiSectionsSectionCodePermissionsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sections/{sectionCode}/permissions`.replace(`{${"sectionCode"}}`, encodeURIComponent(String(requestParameters.sectionCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SectionPermissionsUpdateRequestToJSON(requestParameters.sectionPermissionsUpdateRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiSectionsSectionCodePermissionsPut(requestParameters: ApiSectionsSectionCodePermissionsPutRequest): Promise<Array<number>> {
        const response = await this.apiSectionsSectionCodePermissionsPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSectionsSectionCodeRolesGetRaw(requestParameters: ApiSectionsSectionCodeRolesGetRequest): Promise<runtime.ApiResponse<Array<RoleResponse>>> {
        if (requestParameters.sectionCode === null || requestParameters.sectionCode === undefined) {
            throw new runtime.RequiredError('sectionCode','Required parameter requestParameters.sectionCode was null or undefined when calling apiSectionsSectionCodeRolesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sections/{sectionCode}/roles`.replace(`{${"sectionCode"}}`, encodeURIComponent(String(requestParameters.sectionCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleResponseFromJSON));
    }

    /**
     */
    async apiSectionsSectionCodeRolesGet(requestParameters: ApiSectionsSectionCodeRolesGetRequest): Promise<Array<RoleResponse>> {
        const response = await this.apiSectionsSectionCodeRolesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiSectionsSectionCodeRolesPutRaw(requestParameters: ApiSectionsSectionCodeRolesPutRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.sectionCode === null || requestParameters.sectionCode === undefined) {
            throw new runtime.RequiredError('sectionCode','Required parameter requestParameters.sectionCode was null or undefined when calling apiSectionsSectionCodeRolesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sections/{sectionCode}/roles`.replace(`{${"sectionCode"}}`, encodeURIComponent(String(requestParameters.sectionCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SectionRolesUpdateRequestToJSON(requestParameters.sectionRolesUpdateRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiSectionsSectionCodeRolesPut(requestParameters: ApiSectionsSectionCodeRolesPutRequest): Promise<Array<number>> {
        const response = await this.apiSectionsSectionCodeRolesPutRaw(requestParameters);
        return await response.value();
    }

}
