/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    UserEventExpirationResponse,
    UserEventExpirationResponseFromJSON,
    UserEventExpirationResponseToJSON,
    UserEventExpirationUpdateRequest,
    UserEventExpirationUpdateRequestFromJSON,
    UserEventExpirationUpdateRequestToJSON,
} from '../models';

export interface ApiUserEventExpiryEditPutRequest {
    userEventExpirationUpdateRequest?: UserEventExpirationUpdateRequest;
}

/**
 * 
 */
export class UserEventExpiryApi extends runtime.BaseAPI {

    /**
     */
    async apiUserEventExpiryEditPutRaw(requestParameters: ApiUserEventExpiryEditPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-event-expiry/edit`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserEventExpirationUpdateRequestToJSON(requestParameters.userEventExpirationUpdateRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserEventExpiryEditPut(requestParameters: ApiUserEventExpiryEditPutRequest): Promise<void> {
        await this.apiUserEventExpiryEditPutRaw(requestParameters);
    }

    /**
     */
    async apiUserEventExpirySearchPostRaw(): Promise<runtime.ApiResponse<Array<UserEventExpirationResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-event-expiry/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserEventExpirationResponseFromJSON));
    }

    /**
     */
    async apiUserEventExpirySearchPost(): Promise<Array<UserEventExpirationResponse>> {
        const response = await this.apiUserEventExpirySearchPostRaw();
        return await response.value();
    }

}
