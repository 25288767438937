import React, { FunctionComponent } from 'react';

interface HeaderProps {
  title: string;
  className?: string;
  colSpan?: number;
  onClick?: () => void;
}
const Th: FunctionComponent<HeaderProps> = ({
  onClick,
  title,
  className,
  colSpan
}) => (
  <React.Fragment>
    <th colSpan={colSpan} onClick={onClick} className={className}>
      {title}
    </th>
  </React.Fragment>
);

export { Th };
