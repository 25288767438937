/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import ArrowIcon from '../../../assets/icons/icon-arrow-right-darkblue.svg';
import styles1 from './SelectWithCheckBoxAndSearch.scss';

const styles = (cN) => {
  const mapped = cN.split(' ').map((c) => styles1[c]);
  return mapped.join(' ');
};

const Arrow = () => (
  <span className={styles('c_select-with-checkbox__arrow-wrapper')}>
    <ArrowIcon />
  </span>
);

export class SelectWithCheckBoxAndSearch extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    this.setState({
      showOptions: this.props.isInitiallyOpen ?? false,
      selectedOption: this.props.selectedOption,
      currentOptions: this.props.options,
      textBoxHasFocus: this.props.isInitiallyOpen ?? false,
      changed: false
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentOptions: this.props.options
      });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keypress', this.handleEnterPressed);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keypress', this.handleEnterPressed);
  }

  handleTextBoxFocus() {
    this.setState({
      textBoxHasFocus: true
    });
  }

  handleTextBoxBlur() {
    this.setState({
      textBoxHasFocus: false
    });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const wrapperRef = this.props.wrapperRef?.current ?? this.wrapperRef;
    if (this.props.ignoreClicksOutside) {
      return;
    }
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      if (this.state.showOptions) {
        this.setState({
          showOptions: !this.state.showOptions,
          currentOptions: this.props.options
        });

        this.triggerOnChange();
      }
    }
  }

  handleEnterPressed(event) {
    if (event.charCode !== 13) {
      return;
    }

    // only one dropdown can be open in a page: it's closed when the user clicks outside
    if (this.state.showOptions) {
      this.triggerOnChange();
    }
  }

  triggerOnChange() {
    const textInput = document.getElementById(`txtListFilter_${this.props.id}`);
    textInput.value = '';
    let calledOnChange = false;
    if (this.state.changed) {
      this.setState({
        changed: false
      });
      this.props.onChange(this.state.selectedOption);
      calledOnChange = true;
    }
    if (this.props.triggerOnChangeAlways && !calledOnChange) {
      this.props.onChange(this.state.selectedOption);
    }
  }

  toggleOptions() {
    if (
      this.state.textBoxHasFocus === true &&
      this.state.showOptions === true
    ) {
      return;
    }

    this.setState({
      showOptions: !this.state.showOptions
    });

    if (this.state.showOptions) {
      this.setState({
        currentOptions: this.props.options
      });
      this.triggerOnChange();
    } else {
      this.setState({
        currentOptions: this.props.options,
        selectedOption: this.props.selectedOption,
        changed: false
      });
    }
  }

  updateSelectedOptions(option) {
    this.setState(
      {
        selectedOption: option,
        changed: true
      },
      () => {
        this.setState(
          {
            textBoxHasFocus: false
          },
          this.toggleOptions
        );
      }
    );
  }

  tokenizeString(str) {
    return str.split(/[\s,]+/);
  }

  compareOptions(searchTokenized, options) {
    const optionsTokenized = this.tokenizeString(options.toLowerCase());
    return searchTokenized.every((s) =>
      optionsTokenized.some((o) => o.search(s) !== -1)
    );
  }

  filterOptions(event) {
    const searchTokenized = this.tokenizeString(
      event.target.value.toLowerCase()
    );

    const filteredOptions = this.props.options.filter(
      (option) => this.compareOptions(searchTokenized, option.name)
      // option.name.toLowerCase().search(event.target.value.toLowerCase()) !==
      // -1
    );
    this.setState({
      currentOptions: filteredOptions
    });
  }

  isChecked(option) {
    if (!this.state.selectedOption) return false;
    const res = this.state.selectedOption.value === option.value;

    return res;
  }

  get inputPlaceholder() {
    if (this.state.showOptions) {
      return 'Søk';
    }

    if (this.state.selectedOption) {
      const selection = this.state.currentOptions.filter(
        (co) => co.value === this.state.selectedOption.value
      )[0];
      return selection.name;
    }
    return '';
  }

  render() {
    return (
      <div className={styles('c_filter-container')}>
        <span className={styles('filter-label')}>{this.props.label}</span>

        <div
          className={`${styles('c_select-with-checkbox')} ${
            this.props.class ?? ''
          }`}
          id={`select-container_${this.props.id}`}
          ref={this.setWrapperRef}
        >
          <div
            className={styles(
              `c_select-with-checkbox-select ${
                this.state.showOptions === true
                  ? 'c_select-with-checkbox-select--active'
                  : ''
              }`
            )}
            key='allYears'
            onClick={() => this.toggleOptions()}
          >
            <input
              id={`txtListFilter_${this.props.id}`}
              type='textbox'
              placeholder={this.inputPlaceholder}
              className={styles('c_select-with-checkbox-select__textbox')}
              onChange={(event) => this.filterOptions(event)}
              onFocus={() => this.handleTextBoxFocus()}
              onBlur={() => this.handleTextBoxBlur()}
            />

            {!this.state.showOptions && <Arrow />}
            {this.state.showOptions && <Arrow />}
          </div>
          {this.state.showOptions && (
            <div className={styles('relative')}>
              <div
                className={styles('c_select-with-checkbox-options-wrapper')}
                style={
                  this.props.doNotDisplayOptionsOnTop
                    ? { position: 'inherit' }
                    : {}
                }
              >
                <div className={styles('c_select-with-checkbox-options')}>
                  {this.state.currentOptions &&
                    this.state.currentOptions.map((option) => {
                      return (
                        <div
                          className={styles(
                            `c_select-with-checkbox-option ${
                              this.isChecked(option)
                                ? 'c_select-with-checkbox-option-open'
                                : ''
                            }`
                          )}
                          key={`option${option.value}`}
                        >
                          <div
                            className={
                              this.isChecked(option)
                                ? styles('c_selected-item c_option')
                                : styles('c_option')
                            }
                            onClick={() => this.updateSelectedOptions(option)}
                          >
                            {option.name}
                          </div>
                        </div>
                      );
                    }, this)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
