function formatDate(inputFormat?: Date | null) {
  return formatDatetime(inputFormat, false);
}

function formatDatetime(inputFormat?: Date | null, showTime: boolean = true) {
  if (!inputFormat) {
    return '';
  }
  function pad(s: any) {
    return s < 10 ? `0${s}` : s;
  }
  const d = new Date(inputFormat);
  let result = `${pad(d.getDate())}.${pad(
    d.getMonth() + 1
  )}.${d.getFullYear()}`;
  if (showTime) result += ` ${pad(d.getHours())}:${pad(d.getMinutes())}`;
  return result;
}

export { formatDate, formatDatetime };
