import React, { useState, useEffect } from 'react';
import * as msal from '@azure/msal-browser';
import { AuthService } from './AuthService';
import { B2cPolicies } from './B2cPoliciesAndScopes';

export interface IMsalContext {
  isAuthenticated: boolean;
  user: msal.AccountInfo | undefined;
  login: () => Promise<void>;
  logout: () => void;
  authInProgress: boolean;
}

export const MsalContext = React.createContext<IMsalContext | undefined>(undefined);

export const useMsal = () => {
  const context = React.useContext(MsalContext);
  if (context === undefined) {
    throw new Error('useMsal must be used within a MsalProvider');
  }
  return context;
};

export const MsalProvider = ({ children }: { children: any }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<msal.AccountInfo | undefined>();
  const [authInProgress, setAuthInProgress] = useState<boolean>(true);
  const [b2cAuthority, setB2cAuthority] = useState<string | undefined>(
    B2cPolicies.authorities.internalUser.authority
  );

  useEffect(() => {
    const authenticate = async () => {
      setAuthInProgress(true);
      try {
        await AuthService.handleRedirect();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('HandleRedirect: ', e);
      } finally {
        const newUser = AuthService.getUser();

        setUser(newUser);
        // eslint-disable-next-line no-console
        console.log('Current user claims:', newUser?.idTokenClaims);
        setIsAuthenticated(AuthService.isLoggedIn());

        if (AuthService.isLoggedIn())
          setB2cAuthority(AuthService.mapHomeAccountIdToAuthority(newUser?.homeAccountId));

        setAuthInProgress(false);
      }
    };

    authenticate();
  }, []);

  const login = async () => {
    if (b2cAuthority === undefined) throw Error(`B2C policy is not set.`);

    setAuthInProgress(true);
    await AuthService.signInRedirect(b2cAuthority);
  };

  const logout = async () => {
    setAuthInProgress(true);

    await AuthService.signOut();
  };

  return (
    <MsalContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        authInProgress
      }}
    >
      {children}
    </MsalContext.Provider>
  );
};
