/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationGroupType,
    OrganizationGroupTypeFromJSON,
    OrganizationGroupTypeFromJSONTyped,
    OrganizationGroupTypeToJSON,
    UserOrganizationResponse,
    UserOrganizationResponseFromJSON,
    UserOrganizationResponseFromJSONTyped,
    UserOrganizationResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserOrganizationGroupResponse
 */
export interface UserOrganizationGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationGroupResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationGroupResponse
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserOrganizationGroupResponse
     */
    organizationGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationGroupResponse
     */
    shortName?: string | null;
    /**
     * 
     * @type {OrganizationGroupType}
     * @memberof UserOrganizationGroupResponse
     */
    organizationGroupType?: OrganizationGroupType;
    /**
     * 
     * @type {Array<UserOrganizationResponse>}
     * @memberof UserOrganizationGroupResponse
     */
    organizations?: Array<UserOrganizationResponse> | null;
}

export function UserOrganizationGroupResponseFromJSON(json: any): UserOrganizationGroupResponse {
    return UserOrganizationGroupResponseFromJSONTyped(json, false);
}

export function UserOrganizationGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOrganizationGroupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'organizationGroupId': !exists(json, 'organizationGroupId') ? undefined : json['organizationGroupId'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'organizationGroupType': !exists(json, 'organizationGroupType') ? undefined : OrganizationGroupTypeFromJSON(json['organizationGroupType']),
        'organizations': !exists(json, 'organizations') ? undefined : (json['organizations'] === null ? null : (json['organizations'] as Array<any>).map(UserOrganizationResponseFromJSON)),
    };
}

export function UserOrganizationGroupResponseToJSON(value?: UserOrganizationGroupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'organizationGroupId': value.organizationGroupId,
        'shortName': value.shortName,
        'organizationGroupType': OrganizationGroupTypeToJSON(value.organizationGroupType),
        'organizations': value.organizations === undefined ? undefined : (value.organizations === null ? null : (value.organizations as Array<any>).map(UserOrganizationResponseToJSON)),
    };
}


