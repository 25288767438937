import { useState, useEffect } from 'react';
import { internalUsersClient } from 'api/AdgangClients';
import { SelectOption } from 'common.ui';

export function useSections(): [SelectOption[] | undefined] {
  const [sections, setSections] = useState<SelectOption[]>();

  useEffect(() => {
    const loadSections = async () => {
      const s = await internalUsersClient.apiInternalUsersSectionsGet();

      setSections(
        s
          .map((d) => {
            return {
              id: d.sectionCode,
              text: `${d.sectionCode} - ${d.sectionName}`
            } as SelectOption;
          })
          .sort((r1, r2) => (r1.text || '').localeCompare(r2.text || ''))
      );
    };

    loadSections();
  }, []);

  return [sections];
}
