/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestSearchOrderByPaginationQuery,
    AccessRequestSearchOrderByPaginationQueryFromJSON,
    AccessRequestSearchOrderByPaginationQueryFromJSONTyped,
    AccessRequestSearchOrderByPaginationQueryToJSON,
    AccessRequestStatus,
    AccessRequestStatusFromJSON,
    AccessRequestStatusFromJSONTyped,
    AccessRequestStatusToJSON,
    ApplicationFilterRequest,
    ApplicationFilterRequestFromJSON,
    ApplicationFilterRequestFromJSONTyped,
    ApplicationFilterRequestToJSON,
    PermissionFilterRequest,
    PermissionFilterRequestFromJSON,
    PermissionFilterRequestFromJSONTyped,
    PermissionFilterRequestToJSON,
    RoleFilterRequest,
    RoleFilterRequestFromJSON,
    RoleFilterRequestFromJSONTyped,
    RoleFilterRequestToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccessRequestSearchRequest
 */
export interface AccessRequestSearchRequest {
    /**
     * 
     * @type {ApplicationFilterRequest}
     * @memberof AccessRequestSearchRequest
     */
    applicationFilter?: ApplicationFilterRequest;
    /**
     * 
     * @type {AccessRequestSearchOrderByPaginationQuery}
     * @memberof AccessRequestSearchRequest
     */
    paginationQuery?: AccessRequestSearchOrderByPaginationQuery;
    /**
     * 
     * @type {RoleFilterRequest}
     * @memberof AccessRequestSearchRequest
     */
    roleFilter?: RoleFilterRequest;
    /**
     * 
     * @type {PermissionFilterRequest}
     * @memberof AccessRequestSearchRequest
     */
    permissionFilter?: PermissionFilterRequest;
    /**
     * 
     * @type {AccessRequestStatus}
     * @memberof AccessRequestSearchRequest
     */
    status?: AccessRequestStatus;
    /**
     * 
     * @type {UserType}
     * @memberof AccessRequestSearchRequest
     */
    userType?: UserType;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessRequestSearchRequest
     */
    responsiblePersonIds?: Array<string> | null;
}

export function AccessRequestSearchRequestFromJSON(json: any): AccessRequestSearchRequest {
    return AccessRequestSearchRequestFromJSONTyped(json, false);
}

export function AccessRequestSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationFilter': !exists(json, 'applicationFilter') ? undefined : ApplicationFilterRequestFromJSON(json['applicationFilter']),
        'paginationQuery': !exists(json, 'paginationQuery') ? undefined : AccessRequestSearchOrderByPaginationQueryFromJSON(json['paginationQuery']),
        'roleFilter': !exists(json, 'roleFilter') ? undefined : RoleFilterRequestFromJSON(json['roleFilter']),
        'permissionFilter': !exists(json, 'permissionFilter') ? undefined : PermissionFilterRequestFromJSON(json['permissionFilter']),
        'status': !exists(json, 'status') ? undefined : AccessRequestStatusFromJSON(json['status']),
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'responsiblePersonIds': !exists(json, 'responsiblePersonIds') ? undefined : json['responsiblePersonIds'],
    };
}

export function AccessRequestSearchRequestToJSON(value?: AccessRequestSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationFilter': ApplicationFilterRequestToJSON(value.applicationFilter),
        'paginationQuery': AccessRequestSearchOrderByPaginationQueryToJSON(value.paginationQuery),
        'roleFilter': RoleFilterRequestToJSON(value.roleFilter),
        'permissionFilter': PermissionFilterRequestToJSON(value.permissionFilter),
        'status': AccessRequestStatusToJSON(value.status),
        'userType': UserTypeToJSON(value.userType),
        'responsiblePersonIds': value.responsiblePersonIds,
    };
}


