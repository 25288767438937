import React from 'react';

import style from './_message.scss';
import IconTick from '../../../assets/icons/icon_tick_green.svg';
import IconCross from '../../../assets/icons/icon_cross_red.svg';
import IconExclamationBlack from '../../../assets/icons/icon_exclamation_black.svg';
import IconExclamationYellow from '../../../assets/icons/icon_exclamation_yellow.svg';

interface IProps {
  type: 'confirmation' | 'error' | 'warning';
  text: string | string[];
}

const styles = (cN: string) => {
  const mapped = cN.split(' ').map((c) => style[c]);
  return mapped.join(' ');
};

const getIcon = (type: any) => {
  if (type === 'confirmation') {
    return <IconTick className={styles('c_message__left-icon')} />;
  }
  if (type === 'error') {
    return <IconCross className={styles('c_message__left-icon')} />;
  }
  if (type === 'warning') {
    return <IconExclamationYellow className={styles('c_message__left-icon')} />;
  }
  return <IconExclamationBlack className={styles('c_message__left-icon')} />;
};

const Message = ({ type, text }: IProps) => (
  <div className={styles(`c_message ${type ? `c_message--${type}` : ''}`)}>
    {getIcon(type)}

    {Array.isArray(text) ? (
      text.map((t) => (
        <span key={t}>
          {t}
          <br />
        </span>
      ))
    ) : (
      <span>{text}</span>
    )}
  </div>
);

export { Message };
