import React from 'react';
// import SvgElement from 'react-svg-loader!../../uiElements/svgElement/SvgElement';

import styles from './_button.scss';
// import arrowRight from '../../../assets/icons/icon-arrow-right-black.svg';
// import Image1 from '-!react-svg-loader!./assets/icons/icon-arrow-right-black.svg';
import Image1 from '../../../assets/icons/icon-arrow-right-black.svg';

const renderClassNames = (props: IButton) => {
  let cN = `c_button c_button--${props.styleType ? props.styleType : 'dark'}`;
  if (props.disabled) {
    cN = `${cN} c_button--disabled`;
  }
  //   if (props.className) {
  //     cN = `${cN} ${props.className}`;
  //   }
  const mapped = cN.split(' ').map((c) => styles[c]);
  return mapped.join(' ');
};

export type IButton = {
  id?: string;
  text: string;
  styleType?: 'light' | 'dark';
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  //   className: PropTypes.string,
  showArrow?: boolean;
  disabled?: boolean;
  // preIcon: PropTypes.object,
  href?: string;
};
const Button = (props: IButton) => {
  const { id, href, onClick, type, text, disabled } = props;
  //   return (
  //     <React.Fragment>
  //       <Image1 className={50} height={50} />
  //       <div>FOOS2</div>
  //     </React.Fragment>
  //   )
  if ((href?.length || 0) > 0) {
    return <Image1 width={50} height={50} />;
    return (
      <a className={renderClassNames(props)} href={props.href}>
        <span>{props.text}</span>
        {/* {props.showArrow &&
                <SvgElement
                    src={arrowRight}
                    title="Gå videre"
                    desc="Gå videre"
                    className="c_button__arrow"
                /> */}
      </a>
    );
  }
  return (
    <button
      id={id}
      className={renderClassNames(props)}
      onClick={(e) => {
        if (disabled) {
          // disable POST event
          e.preventDefault();
        }
        if (!disabled && onClick) {
          onClick();
        }
      }}
      type={type || 'button'} // eslint-disable-line
    >
      {/* {props.preIcon &&
            <SvgElement
                src={props.preIcon}
                title="Ikon i knapp"
                desc="Ikon"
                className="c_button__left-icon"
            />
            } */}
      <span>{text}</span>
      {/* {props.showArrow &&
            <SvgElement
                src={arrowRight}
                title="Gå videre"
                desc="Gå videre"
                className="c_button__arrow"
            />
            } */}
    </button>
  );
};

export { Button };
