import React, { useState, useCallback } from 'react';
import { Button, useBoundForm, Table, Th, LoadingSpinner, useFetch, TextInput } from 'common.ui';
import Page from 'components/page/Page';

import { GenericValidationError, PermissionResponse, RoleResponse, UserType } from 'api/adgang/models';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import { ReactComponent as IconCross } from 'assets/icons/cross-icon.svg';
import { useApplications, usePermissions, useRoles } from 'api/hooks';
import { PermissionsMultiSelect } from 'components/permissions/PermissionsMultiSelect';
import { RolesMultiSelect } from 'components/roles/RolesMultiSelect';
import { adgroupsClient } from 'api/AdgangClients';

type AdGroupMappings = {
  permissionIds: string[];
  roleIds: string[];
};

type AdGroupMappingsExpanded = {
  permissions: PermissionResponse[];
  roles: RoleResponse[];
};

interface IParams {
  id: string;
}

async function GetCurrentSettings(id: string): Promise<AdGroupMappingsExpanded> {
  const permsTask = adgroupsClient.apiAdGroupsAdGroupIdPermissionsGet({
    adGroupId: id
  });

  const rolesTask = adgroupsClient.apiAdGroupsAdGroupIdRolesGet({
    adGroupId: id
  });

  const perms = await permsTask;
  const roles = await rolesTask;

  return {
    permissions: perms,
    roles
  };
}

function AdGroup() {
  const { id } = useParams<IParams>();
  const [applicationOptions] = useApplications();
  const [, permissions] = usePermissions(UserType.Internal);
  const [, roles] = useRoles(UserType.Internal);
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [showAddPermissions, setShowAddPermissions] = useState(false);
  const [showAddRoles, setShowAddRoles] = useState(false);
  const [seq, setSeq] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [adgroup] = useFetch(
    async () =>
      adgroupsClient.apiAdGroupsAdGroupIdGet({
        adGroupId: id
      }),
    undefined,
    false,
    [id]
  );

  const [settings] = useFetch(async () => (adgroup ? GetCurrentSettings(id) : undefined), undefined, false, [
    adgroup,
    seq
  ]);

  const updateRoles = useCallback(
    async (roleIds: number[]) => {
      setIsLoading(true);
      try {
        await adgroupsClient.apiAdGroupsAdGroupIdRolesPut({
          adGroupId: id,
          adGroupRolesUpdateRequest: {
            roleIds
          }
        });
        setSeq(seq + 1);
        setShowAddRoles(false);
        setApiErrors(undefined);
      } catch (e) {
        if (e instanceof Response) {
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
      }
      setIsLoading(false);
    },
    [id, seq]
  );

  const updatePermissions = useCallback(
    async (permissionIds: number[]) => {
      setIsLoading(true);
      try {
        await adgroupsClient.apiAdGroupsAdGroupIdPermissionsPut({
          adGroupId: id,
          adGroupPermissionsUpdateRequest: {
            permissionIds
          }
        });
        setSeq(seq + 1);
        setShowAddPermissions(false);
        setApiErrors(undefined);
      } catch (e) {
        if (e instanceof Response) {
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
      }
      setIsLoading(false);
    },
    [id, seq]
  );

  const { form, FormContainer, DisplayErrors } = useBoundForm<AdGroupMappings>({
    onSubmit: async () => {},
    errors: apiErrors,
    model: {
      permissionIds: settings?.permissions.map((p) => p.permissionId?.toString() ?? '') ?? [],
      roleIds: settings?.roles.map((r) => r.roleId?.toString() ?? '') ?? []
    }
  });

  return (
    <Page header='Detaljer om AD-gruppe (roller og tilganger)'>
      {adgroup && permissions && roles && settings ? (
        <Container fluid>
          {isLoading && (
            <div style={{ position: 'absolute', left: 'calc(50% - 100px)' }}>
              <LoadingSpinner />
            </div>
          )}
          <FormContainer form={form}>
            <Row>
              <Col sm={12} lg={12}>
                <DisplayErrors form={form} />
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={6}>
                Gruppenavn
                <TextInput id='groupname' value={adgroup.name} disabled />
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={6}>
                {!showAddRoles && (
                  <Button
                    type='button'
                    text=' + Legg til rolle'
                    disabled={isLoading}
                    onClick={() => setShowAddRoles(true)}
                    styleType='light'
                  />
                )}
                {!showAddRoles && (
                  <Table>
                    <thead>
                      <tr>
                        <Th title='Applikasjon' />
                        <Th title='Tilknyttede roller' />
                        <Th title='Fjern' />
                      </tr>
                    </thead>
                    <tbody>
                      {settings.roles.map((p) => (
                        <tr key={p.roleId}>
                          <td className='text-break-all'>{p.applicationName}</td>
                          <td className='text-break-all'>{p.name}</td>
                          <td>
                            <IconCross
                              onClick={async () => {
                                if (isLoading) {
                                  return;
                                }
                                updateRoles(
                                  settings.roles
                                    .filter((r) => r.roleId !== p.roleId)
                                    .map((x) => x.roleId as number)
                                );
                              }}
                              style={{ cursor: 'pointer', width: '12px' }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {showAddRoles && applicationOptions && (
                  <>
                    <b>Legg til rolle</b>
                    <br />
                    Velg rolle
                    <RolesMultiSelect
                      applicationOptions={applicationOptions}
                      roles={roles.filter((r) => !settings.roles.some((rs) => rs.roleId === r.roleId))}
                      form={form}
                      name='roleIds'
                      label='Velg rolle'
                      triggerOnChangeAlways
                      ignoreClicksOutside
                      isInitiallyOpen
                      onChange={async (r) => {
                        const newRoles = r.map((x) => parseInt(x, 10));
                        settings.roles.forEach((r) => {
                          if (r.roleId) {
                            newRoles.push(r.roleId);
                          }
                        });
                        updateRoles(newRoles);
                      }}
                      onClose={() => setShowAddRoles(false)}
                      okLabel='Lagre'
                    />
                  </>
                )}
              </Col>
              <Col sm={6} lg={6}>
                {!showAddPermissions && (
                  <Button
                    type='button'
                    text=' + Legg til tilgang'
                    disabled={isLoading}
                    onClick={() => setShowAddPermissions(true)}
                    styleType='light'
                  />
                )}
                {!showAddPermissions && (
                  <Table>
                    <thead>
                      <tr>
                        <Th title='Applikasjon' />
                        <Th title='Tilknyttede tilganger' />
                        <Th title='Fjern' />
                      </tr>
                    </thead>
                    <tbody>
                      {settings.permissions.map((p) => (
                        <tr key={p.permissionId}>
                          <td className='text-break-all'>{p.applicationName}</td>
                          <td className='text-break-all'>{p.name}</td>
                          <td>
                            <IconCross
                              onClick={async () => {
                                if (isLoading) {
                                  return;
                                }
                                updatePermissions(
                                  settings.permissions
                                    .filter((pe) => pe.permissionId !== p.permissionId)
                                    .map((x) => x.permissionId as number)
                                );
                              }}
                              style={{ cursor: 'pointer', width: '12px' }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {showAddPermissions && applicationOptions && (
                  <>
                    <b>Legg til tilgang</b>
                    <br />
                    Velg tilgang
                    <PermissionsMultiSelect
                      applicationOptions={applicationOptions}
                      permissions={permissions.filter(
                        (p) => !settings.permissions.some((pm) => pm.permissionId === p.permissionId)
                      )}
                      form={form}
                      name='permissionIds'
                      label='Velg tilganger'
                      triggerOnChangeAlways
                      ignoreClicksOutside
                      isInitiallyOpen
                      onChange={async (p) => {
                        const newPermissions = p.map((x) => parseInt(x, 10));
                        settings.permissions.forEach((pm) => {
                          if (pm.permissionId) {
                            newPermissions.push(pm.permissionId);
                          }
                        });

                        updatePermissions(newPermissions);
                      }}
                      onClose={() => setShowAddPermissions(false)}
                      okLabel='Lagre'
                    />
                  </>
                )}
              </Col>
            </Row>
          </FormContainer>
        </Container>
      ) : (
        <LoadingSpinner />
      )}
    </Page>
  );
}

export default AdGroup;
