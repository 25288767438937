import React from 'react';
import Page from 'components/page/Page';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Tbd() {
  return (
    <Page header='Du har åpnet applikasjonen "Adgang" – men du har ingen rettigheter'>
      <Row>
        <Col sm={12} lg={8}>
          <p>
            Applikasjonen Adgang er en administrasjonsmodul til &quot;Min Side&quot; og benyttes av utvalgte
            ressurspersoner til å få innsyn i brukerdata, rettigheter, godkjenning av nye rettigheter mm. For
            å bruke Adgang må du ha fått særskilte rettigheter av utvalgte systemansvarlige i ODI (såkalte
            applikasjonsansvarlige) eller de systemforvaltere i fagseksjonene som har fått mulighet til å
            utpeke brukere i Adgang. Hvis du mener at du har eller bør ha tilgang til Adgang (på vegne av en
            applikasjon) ber vi deg ta kontakt med aktuell systemansvarlig for applikasjonen i
            digitaliseringsseksjonen.
          </p>
        </Col>
      </Row>
    </Page>
  );
}

export default Tbd;
