export function toFullname(firstname: string | null | undefined, lastname?: string | null | undefined) {
  const fn = (firstname || '').trim();
  const ln = (lastname || '').trim();

  if (!fn) {
    return ln;
  }
  if (!ln) {
    return fn;
  }
  return `${fn} ${ln}`;
}
