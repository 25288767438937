import React, { useState } from 'react';
import {
  PagedTable,
  LoadingSpinner,
  useFetch,
  ThSortable,
  useSortedPaging,
  ConfirmationModal
} from 'common.ui';
import {
  OrganizationResponsePagedResult,
  OrganizationSearchOrderBy,
  SortOrder,
  OrganizationResponse
} from 'api/adgang';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import { organizationGroupsClient } from 'api/AdgangClients';
import { ReactComponent as IconDelete } from 'assets/icons/delete-icon.svg';

type Props = {
  organizationGroupId: number;
  onDeleteOrganization: (organizationId: number) => void;
};

function OrganizationsTable({ organizationGroupId, onDeleteOrganization }: Props): React.ReactElement<Props> {
  const [deleteOrganization, setDeleteOrganization] = useState<OrganizationResponse>();

  const [organizationsPaged, isLoading, , fetchOrganizations] = useFetch<OrganizationResponsePagedResult>(
    () => fetchPagedOrganizations(),
    {} as OrganizationResponsePagedResult,
    false
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    ,
    setSorting
  ] = useSortedPaging<OrganizationSearchOrderBy>(fetchOrganizations, {
    field: OrganizationSearchOrderBy.Code,
    direction: 'asc'
  });

  const fetchPagedOrganizations = async (): Promise<OrganizationResponsePagedResult> => {
    const request = {
      pageSize,
      pageNumber: currentPage,
      sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
      orderField: sorting.field,
      organizationGroupIds: [organizationGroupId]
    };
    return organizationGroupsClient.apiOrganizationGroupsOrganizationsGet(request);
  };

  const handleSort = (e: SortEvent<OrganizationSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  function onCancelDelete() {
    setDeleteOrganization(undefined);
  }

  async function onDeleteConfirmed() {
    if (deleteOrganization?.organizationId) {
      onDeleteOrganization(deleteOrganization.organizationId);
    }
  }

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <ConfirmationModal
        header='Slett organisasjon'
        show={deleteOrganization !== undefined}
        onCancel={onCancelDelete}
        onAccept={onDeleteConfirmed}
      >
        Er du sikker på at du vil slette denne organisasjon?
      </ConfirmationModal>

      <PagedTable
        startIndex={startIndex}
        totalHits={organizationsPaged.pageInfo?.totalCount ?? 0}
        pageSize={pageSize}
        onNextClick={onNextPage}
        onPreviousClick={onPreviousPage}
      >
        <thead>
          <tr>
            <ThSortable
              title='Org.nr'
              field={OrganizationSearchOrderBy.Code}
              currentSort={sorting}
              onSort={handleSort}
            />
            <ThSortable
              title='Organisasjonsnavn'
              field={OrganizationSearchOrderBy.Name}
              currentSort={sorting}
              onSort={handleSort}
            />
            <th />
          </tr>
        </thead>

        <tbody>
          {organizationsPaged.data?.map((o) => (
            <tr key={o.code || ''}>
              <td>{o.code}</td>
              <td>{o.name}</td>
              <td>
                {' '}
                <IconDelete onClick={() => setDeleteOrganization(o)} style={{ cursor: 'pointer' }} />
              </td>
            </tr>
          ))}
        </tbody>
      </PagedTable>
    </>
  );
}

export default OrganizationsTable;
