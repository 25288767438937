import { ResourceFilter, RoleResponse, UserType } from 'api/adgang';
import { rolesClient } from 'api/AdgangClients';
import { SelectOption, useFetch } from 'common.ui';

export type RolesResult = [SelectOption[] | undefined, RoleResponse[] | undefined];

export function useRoles(
  userType?: UserType,
  applicationId?: string | string[] | null,
  userId?: string,
  resourceFilter?: ResourceFilter,
  requireUserOrganizationInMinSide?: boolean
): RolesResult {
  const [fetchedRoles] = useFetch<RolesResult>(
    () => fetchAllRoles(),
    [undefined, undefined] as RolesResult,
    false,
    [applicationId]
  );

  const fetchAllRoles = async (): Promise<RolesResult> => {
    const roles =
      !Array.isArray(applicationId) || applicationId.length === 0
        ? await rolesClient.apiRolesGet({
            pageNumber: 1,
            pageSize: 9999,
            userType,
            applicationId:
              Array.isArray(applicationId) || applicationId === 'global'
                ? undefined
                : applicationId ?? undefined,
            isGlobal: applicationId === 'global',
            userId,
            resourceFilter,
            requireUserOrganizationInMinSide
          })
        : await rolesClient.apiRolesSearchesPost({
            roleSearchRequest: {
              paginationQuery: {
                pageNumber: 1,
                pageSize: 9999
              },
              userType: userType || undefined,
              applicationFilter: {
                applicationIds: applicationId.filter((a) => a !== 'global'),
                includeGlobalValues: applicationId.some((a) => a === 'global')
              },
              resourceFilter
            }
          });

    if (!roles.data) {
      return [undefined, undefined] as RolesResult;
    }

    const options = roles.data
      .sort((r1, r2) => (r1.name || '').localeCompare(r2.name || ''))
      .map((d) => {
        return {
          id: d.roleId?.toString(),
          text: d.name
        } as SelectOption;
      });

    const data = roles.data || [];

    return [options, data];
  };

  return fetchedRoles;
}
