/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

export type SortDirection = 'desc' | 'asc';
export type Sorting<T extends string | number> = {
  direction: SortDirection;
  field: T;
};

export function useSortedPaging<T extends string | number>(
  fetchMethod: () => Promise<void>,
  initialSorting: Sorting<T>,
  initialPage: number = 1,
  pageSize: number = 20,
  ignoreFirstCall: boolean = true
): [
  number,
  number,
  number,
  Sorting<T>,
  () => void,
  () => void,
    React.Dispatch<React.SetStateAction<number>>,
    React.Dispatch<React.SetStateAction<Sorting<T>>>
] {
  const calculateStartIndex = (page: number) =>
    page > 0 ? (page - 1) * pageSize : 0;

  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [sorting, setSorting] = useState<Sorting<T>>(initialSorting);
  const [startIndex, setStartIndex] = useState<number>(
    calculateStartIndex(initialPage)
  );
  const [firstCall, setFirstCall] = useState(ignoreFirstCall);

  useEffect(() => {
    if (!firstCall) {
      fetchMethod().then(() => {
        setStartIndex(calculateStartIndex(currentPage));
      });
    } else {
      setFirstCall(false);
    }
  }, [currentPage, pageSize, sorting]);

  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPreviousPage = () =>
    setCurrentPage(currentPage > 0 ? currentPage - 1 : 0);

  return [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    setCurrentPage,
    setSorting
  ];
}
