/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationResponsiblePersonResponse
 */
export interface ApplicationResponsiblePersonResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponsiblePersonResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof ApplicationResponsiblePersonResponse
     */
    userType?: UserType;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponsiblePersonResponse
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponsiblePersonResponse
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponsiblePersonResponse
     */
    readonly name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponsiblePersonResponse
     */
    isApplicationAdministrator?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponsiblePersonResponse
     */
    canManageAccessRequestsForApplication?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponsiblePersonResponse
     */
    canAppointExternalApproversForApplication?: boolean;
}

export function ApplicationResponsiblePersonResponseFromJSON(json: any): ApplicationResponsiblePersonResponse {
    return ApplicationResponsiblePersonResponseFromJSONTyped(json, false);
}

export function ApplicationResponsiblePersonResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponsiblePersonResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isApplicationAdministrator': !exists(json, 'isApplicationAdministrator') ? undefined : json['isApplicationAdministrator'],
        'canManageAccessRequestsForApplication': !exists(json, 'canManageAccessRequestsForApplication') ? undefined : json['canManageAccessRequestsForApplication'],
        'canAppointExternalApproversForApplication': !exists(json, 'canAppointExternalApproversForApplication') ? undefined : json['canAppointExternalApproversForApplication'],
    };
}

export function ApplicationResponsiblePersonResponseToJSON(value?: ApplicationResponsiblePersonResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'userType': UserTypeToJSON(value.userType),
        'firstName': value.firstName,
        'surname': value.surname,
        'isApplicationAdministrator': value.isApplicationAdministrator,
        'canManageAccessRequestsForApplication': value.canManageAccessRequestsForApplication,
        'canAppointExternalApproversForApplication': value.canAppointExternalApproversForApplication,
    };
}


