/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddUserPermissionRequest,
    AddUserPermissionRequestFromJSON,
    AddUserPermissionRequestToJSON,
    AddUserRoleRequest,
    AddUserRoleRequestFromJSON,
    AddUserRoleRequestToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    UserAccessAndClaimsResponse,
    UserAccessAndClaimsResponseFromJSON,
    UserAccessAndClaimsResponseToJSON,
    UserAccessResponse,
    UserAccessResponseFromJSON,
    UserAccessResponseToJSON,
    UserEventRequestSearchOrderBy,
    UserEventRequestSearchOrderByFromJSON,
    UserEventRequestSearchOrderByToJSON,
    UserEventResponse,
    UserEventResponseFromJSON,
    UserEventResponseToJSON,
    UserEventResponsePagedResult,
    UserEventResponsePagedResultFromJSON,
    UserEventResponsePagedResultToJSON,
} from '../models';

export interface ApiUsersUserIdAccessClaimsGetRequest {
    userId: string;
}

export interface ApiUsersUserIdAccessGetRequest {
    userId: string;
}

export interface ApiUsersUserIdEventsPagedPostRequest {
    userId: string;
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: UserEventRequestSearchOrderBy;
}

export interface ApiUsersUserIdEventsPostRequest {
    userId: string;
}

export interface ApiUsersUserIdPermissionsPermissionIdDeleteRequest {
    userId: string;
    permissionId: number;
}

export interface ApiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDeleteRequest {
    userId: string;
    permissionId: number;
    organizationId: number;
}

export interface ApiUsersUserIdPermissionsPostRequest {
    userId: string;
    addUserPermissionRequest?: AddUserPermissionRequest;
}

export interface ApiUsersUserIdRolesPostRequest {
    userId: string;
    addUserRoleRequest?: AddUserRoleRequest;
}

export interface ApiUsersUserIdRolesRoleIdDeleteRequest {
    userId: string;
    roleId: number;
}

export interface ApiUsersUserIdRolesRoleIdOrganizationOrganizationIdDeleteRequest {
    userId: string;
    roleId: number;
    organizationId: number;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async apiUsersUserIdAccessClaimsGetRaw(requestParameters: ApiUsersUserIdAccessClaimsGetRequest): Promise<runtime.ApiResponse<Array<UserAccessAndClaimsResponse>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersUserIdAccessClaimsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{userId}/access/claims`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserAccessAndClaimsResponseFromJSON));
    }

    /**
     */
    async apiUsersUserIdAccessClaimsGet(requestParameters: ApiUsersUserIdAccessClaimsGetRequest): Promise<Array<UserAccessAndClaimsResponse>> {
        const response = await this.apiUsersUserIdAccessClaimsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUsersUserIdAccessGetRaw(requestParameters: ApiUsersUserIdAccessGetRequest): Promise<runtime.ApiResponse<Array<UserAccessResponse>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersUserIdAccessGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{userId}/access`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserAccessResponseFromJSON));
    }

    /**
     */
    async apiUsersUserIdAccessGet(requestParameters: ApiUsersUserIdAccessGetRequest): Promise<Array<UserAccessResponse>> {
        const response = await this.apiUsersUserIdAccessGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUsersUserIdEventsPagedPostRaw(requestParameters: ApiUsersUserIdEventsPagedPostRequest): Promise<runtime.ApiResponse<UserEventResponsePagedResult>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersUserIdEventsPagedPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{userId}/events-paged`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEventResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersUserIdEventsPagedPost(requestParameters: ApiUsersUserIdEventsPagedPostRequest): Promise<UserEventResponsePagedResult> {
        const response = await this.apiUsersUserIdEventsPagedPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUsersUserIdEventsPostRaw(requestParameters: ApiUsersUserIdEventsPostRequest): Promise<runtime.ApiResponse<Array<UserEventResponse>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersUserIdEventsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{userId}/events`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserEventResponseFromJSON));
    }

    /**
     */
    async apiUsersUserIdEventsPost(requestParameters: ApiUsersUserIdEventsPostRequest): Promise<Array<UserEventResponse>> {
        const response = await this.apiUsersUserIdEventsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUsersUserIdPermissionsPermissionIdDeleteRaw(requestParameters: ApiUsersUserIdPermissionsPermissionIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersUserIdPermissionsPermissionIdDelete.');
        }

        if (requestParameters.permissionId === null || requestParameters.permissionId === undefined) {
            throw new runtime.RequiredError('permissionId','Required parameter requestParameters.permissionId was null or undefined when calling apiUsersUserIdPermissionsPermissionIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{userId}/permissions/{permissionId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters.permissionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersUserIdPermissionsPermissionIdDelete(requestParameters: ApiUsersUserIdPermissionsPermissionIdDeleteRequest): Promise<void> {
        await this.apiUsersUserIdPermissionsPermissionIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDeleteRaw(requestParameters: ApiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.permissionId === null || requestParameters.permissionId === undefined) {
            throw new runtime.RequiredError('permissionId','Required parameter requestParameters.permissionId was null or undefined when calling apiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling apiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{userId}/permissions/{permissionId}/organization/{organizationId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters.permissionId))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDelete(requestParameters: ApiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDeleteRequest): Promise<void> {
        await this.apiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiUsersUserIdPermissionsPostRaw(requestParameters: ApiUsersUserIdPermissionsPostRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersUserIdPermissionsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/{userId}/permissions`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddUserPermissionRequestToJSON(requestParameters.addUserPermissionRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiUsersUserIdPermissionsPost(requestParameters: ApiUsersUserIdPermissionsPostRequest): Promise<Array<number>> {
        const response = await this.apiUsersUserIdPermissionsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUsersUserIdRolesPostRaw(requestParameters: ApiUsersUserIdRolesPostRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersUserIdRolesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/{userId}/roles`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddUserRoleRequestToJSON(requestParameters.addUserRoleRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiUsersUserIdRolesPost(requestParameters: ApiUsersUserIdRolesPostRequest): Promise<Array<number>> {
        const response = await this.apiUsersUserIdRolesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUsersUserIdRolesRoleIdDeleteRaw(requestParameters: ApiUsersUserIdRolesRoleIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersUserIdRolesRoleIdDelete.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling apiUsersUserIdRolesRoleIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{userId}/roles/{roleId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersUserIdRolesRoleIdDelete(requestParameters: ApiUsersUserIdRolesRoleIdDeleteRequest): Promise<void> {
        await this.apiUsersUserIdRolesRoleIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiUsersUserIdRolesRoleIdOrganizationOrganizationIdDeleteRaw(requestParameters: ApiUsersUserIdRolesRoleIdOrganizationOrganizationIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUsersUserIdRolesRoleIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling apiUsersUserIdRolesRoleIdOrganizationOrganizationIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling apiUsersUserIdRolesRoleIdOrganizationOrganizationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{userId}/roles/{roleId}/organization/{organizationId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersUserIdRolesRoleIdOrganizationOrganizationIdDelete(requestParameters: ApiUsersUserIdRolesRoleIdOrganizationOrganizationIdDeleteRequest): Promise<void> {
        await this.apiUsersUserIdRolesRoleIdOrganizationOrganizationIdDeleteRaw(requestParameters);
    }

}
