/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SapUserDataResponse
 */
export interface SapUserDataResponse {
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    employmentCategory?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SapUserDataResponse
     */
    employmentEndDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SapUserDataResponse
     */
    employmentStartDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    employmentStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    fullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    initials?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    jobCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    jobCode2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    jobTitleCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    mdirCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    organizationalUnit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    personCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    personGroup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    sapFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    sapSurname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    sapUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    sectionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapUserDataResponse
     */
    sectionName?: string | null;
}

export function SapUserDataResponseFromJSON(json: any): SapUserDataResponse {
    return SapUserDataResponseFromJSONTyped(json, false);
}

export function SapUserDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SapUserDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employmentCategory': !exists(json, 'employmentCategory') ? undefined : json['employmentCategory'],
        'employmentEndDate': !exists(json, 'employmentEndDate') ? undefined : (json['employmentEndDate'] === null ? null : new Date(json['employmentEndDate'])),
        'employmentStartDate': !exists(json, 'employmentStartDate') ? undefined : (json['employmentStartDate'] === null ? null : new Date(json['employmentStartDate'])),
        'employmentStatus': !exists(json, 'employmentStatus') ? undefined : json['employmentStatus'],
        'fullname': !exists(json, 'fullname') ? undefined : json['fullname'],
        'initials': !exists(json, 'initials') ? undefined : json['initials'],
        'jobCode': !exists(json, 'jobCode') ? undefined : json['jobCode'],
        'jobCode2': !exists(json, 'jobCode2') ? undefined : json['jobCode2'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'jobTitleCode': !exists(json, 'jobTitleCode') ? undefined : json['jobTitleCode'],
        'mdirCity': !exists(json, 'mdirCity') ? undefined : json['mdirCity'],
        'organizationalUnit': !exists(json, 'organizationalUnit') ? undefined : json['organizationalUnit'],
        'personCity': !exists(json, 'personCity') ? undefined : json['personCity'],
        'personGroup': !exists(json, 'personGroup') ? undefined : json['personGroup'],
        'sapFirstname': !exists(json, 'sapFirstname') ? undefined : json['sapFirstname'],
        'sapSurname': !exists(json, 'sapSurname') ? undefined : json['sapSurname'],
        'sapUserId': !exists(json, 'sapUserId') ? undefined : json['sapUserId'],
        'sectionCode': !exists(json, 'sectionCode') ? undefined : json['sectionCode'],
        'sectionName': !exists(json, 'sectionName') ? undefined : json['sectionName'],
    };
}

export function SapUserDataResponseToJSON(value?: SapUserDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employmentCategory': value.employmentCategory,
        'employmentEndDate': value.employmentEndDate === undefined ? undefined : (value.employmentEndDate === null ? null : value.employmentEndDate.toISOString()),
        'employmentStartDate': value.employmentStartDate === undefined ? undefined : (value.employmentStartDate === null ? null : value.employmentStartDate.toISOString()),
        'employmentStatus': value.employmentStatus,
        'fullname': value.fullname,
        'initials': value.initials,
        'jobCode': value.jobCode,
        'jobCode2': value.jobCode2,
        'jobTitle': value.jobTitle,
        'jobTitleCode': value.jobTitleCode,
        'mdirCity': value.mdirCity,
        'organizationalUnit': value.organizationalUnit,
        'personCity': value.personCity,
        'personGroup': value.personGroup,
        'sapFirstname': value.sapFirstname,
        'sapSurname': value.sapSurname,
        'sapUserId': value.sapUserId,
        'sectionCode': value.sectionCode,
        'sectionName': value.sectionName,
    };
}


