import React, { useState } from 'react';
import {
  PagedTable,
  Button,
  ThSortable,
  LoadingSpinner,
  SelectOption,
  FilterLabel,
  FilterReset,
  useBoundForm,
  useSortedPaging,
  useFetch
} from 'common.ui';
import { internalUsersClient } from 'api/AdgangClients';
import Page from 'components/page/Page';
import { Row, Col, Container } from 'react-bootstrap';
import { useWorkLocations, useRoles, useSections, useEmploymentCategories, useApplications } from 'api/hooks';
import { useSavedSearch } from 'hooks/search/useSavedSearch';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import {
  AdgangAccessControl,
  ApiInternalUsersSearchesPostRequest,
  InternalUserResponsePagedResult,
  InternalUserSearchOrderBy,
  ResourceFilter,
  SortOrder,
  UserType
} from 'api/adgang';
import { Link } from 'react-router-dom';
import RoutePaths from 'RoutePaths';
import { toFullname } from 'helpers/workLocation/toFullName';
import { useAccessControlList } from 'hooks/access/useAccessControlList';

type Search = {
  initials: string;
  firstName: string;
  surname: string;
  employeeNr: string;
  workLocations: string[];
  employmentCategories: string[];
  sections: string[];
  roles: string[];
  applications: string[];
  seq: number;
};

interface IUsersScreen {
  workLocations: SelectOption[];
  applications: SelectOption[];
  sections: SelectOption[];
  employmentCategories: SelectOption[];
  initalSearch: Search;
  saveSearch: (value: Search) => void;
}

const emptySearch: Search = {
  workLocations: [],
  sections: [],
  roles: [],
  employmentCategories: [],
  applications: [],
  seq: 0,
  firstName: '',
  surname: '',
  initials: '',
  employeeNr: ''
};

const hasSearchFilters = (model: Search): boolean => {
  return !!(
    model.initials ||
    model.firstName ||
    model.surname ||
    model.employeeNr ||
    model.workLocations.length ||
    model.employmentCategories.length ||
    model.sections.length ||
    model.roles.length ||
    model.applications.length
  );
};

function InternalUsers() {
  const [, hasAccess] = useAccessControlList();

  const [workLocations] = useWorkLocations();
  const [sections] = useSections();
  const [applications] = useApplications(
    undefined,
    ResourceFilter.InternalUser,
    !hasAccess(AdgangAccessControl.InternalUsersViewShowNoAppFilter)
  );
  const [employmentCategories] = useEmploymentCategories();
  const [initialSearch, saveSearch] = useSavedSearch<Search>('internal_users_search', emptySearch);

  return workLocations && sections && employmentCategories && applications ? (
    <UsersScreen
      workLocations={workLocations}
      applications={applications}
      initalSearch={initialSearch}
      employmentCategories={employmentCategories}
      sections={sections}
      saveSearch={saveSearch}
    />
  ) : (
    <LoadingSpinner />
  );
}

function UsersScreen({
  workLocations,
  sections,
  applications,
  employmentCategories,
  initalSearch,
  saveSearch
}: IUsersScreen) {
  const [unvalidatedSearch, setSearch] = useState<Search>(initalSearch);
  const [roles] = useRoles(
    UserType.Internal,
    unvalidatedSearch?.applications,
    undefined,
    ResourceFilter.InternalUser
  );

  const search = cleanSearch(unvalidatedSearch);

  const [usersPaged, isLoading, , fetchUsers] = useFetch<InternalUserResponsePagedResult>(
    () => fetchPagedUsers(),
    {} as InternalUserResponsePagedResult,
    false,
    [search.seq]
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    setCurrentPage,
    setSorting
  ] = useSortedPaging<InternalUserSearchOrderBy>(fetchUsers, {
    field: InternalUserSearchOrderBy.Name,
    direction: 'asc'
  });

  const fetchPagedUsers = async () => {
    const request = {
      internalUserSearchRequest: {
        paginationQuery: {
          pageNumber: currentPage,
          pageSize,
          sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
          orderField: sorting.field
        },
        firstName: search.firstName,
        surname: search.surname,
        initials: search.initials,
        sections: search.sections,
        workLocations: search.workLocations,
        employeeNr: search.employeeNr,
        applicationFilter:
          search.applications.length > 0
            ? {
                applicationIds: search.applications.filter((a) => a !== 'global'),
                includeGlobalValues: search.applications.some((a) => a === 'global')
              }
            : undefined,
        roleFilter:
          search.roles.length === 0
            ? undefined
            : {
                roleIds: search.roles.map((r) => parseInt(r, 10)),
                includeGlobalValues: false
              },
        employmentCategories: search.employmentCategories
      }
    } as ApiInternalUsersSearchesPostRequest;
    return internalUsersClient.apiInternalUsersSearchesPost(request);
  };

  const { form, FormContainer, Input, MultiSelect } = useBoundForm<Search>({
    onSubmit: async (e) => {
      runSearch(e);
    },
    model: search
  });

  function cleanSearch(s: Search) {
    const model: Search = {
      ...s,
      // Only search for roles, that are valid options based on your role dropdown
      // (which can change dynamically whenever a user multiselects an application)
      roles: s.roles.filter((r) => roles?.some((ro) => ro.id === r))
    };
    return model;
  }

  function runSearch(s: Search) {
    const model = cleanSearch(s);

    setCurrentPage(1);
    saveSearch({ ...model, seq: 0 });
    setSearch({ ...model, seq: search.seq + 1 });
  }

  const handleSort = (e: SortEvent<InternalUserSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  return (
    <Page header='Interne ansatte'>
      <Container fluid>
        <FormContainer key={search.seq} form={form}>
          <Row>
            <Col sm={12} lg={4}>
              <Input
                form={form}
                name='firstName'
                label='Fornavn'
                placeholder='Fornavn'
                onEnterPressed={(e) => {
                  runSearch({
                    ...search,
                    firstName: e,
                    seq: search.seq + 1
                  });
                }}
              />
            </Col>
            <Col sm={12} lg={4}>
              <Input
                form={form}
                name='surname'
                label='Etternavn'
                placeholder='Etternavn'
                onEnterPressed={(e) => {
                  runSearch({
                    ...search,
                    surname: e,
                    seq: search.seq + 1
                  });
                }}
              />
            </Col>
            <Col sm={12} lg={4}>
              <Input
                form={form}
                name='initials'
                label='Initial'
                placeholder='Initial'
                onEnterPressed={(e) => {
                  runSearch({
                    ...search,
                    initials: e,
                    seq: search.seq + 1
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={4}>
              <Input
                form={form}
                name='employeeNr'
                label='Ansattnummer (SAP)'
                placeholder='Ansattnummer (SAP)'
                onEnterPressed={(e) => {
                  runSearch({
                    ...search,
                    employeeNr: e,
                    seq: search.seq + 1
                  });
                }}
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='workLocations'
                label='Arbeidssted'
                okLabel='Vis resultater'
                options={workLocations}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    workLocations: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='sections'
                label='Seksjon'
                okLabel='Vis resultater'
                options={sections}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    sections: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='employmentCategories'
                label='Stillingskategori'
                okLabel='Vis resultater'
                options={employmentCategories}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    employmentCategories: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='applications'
                label='Applikasjon'
                okLabel='Vis resultater'
                options={applications ?? []}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    applications: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='roles'
                label='Rolle'
                okLabel='Vis resultater'
                options={roles ?? []}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    roles: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <br />
              {search.firstName && search.firstName !== '' && (
                <FilterLabel
                  key='firstName'
                  label={search.firstName}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      firstName: '',
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {search.surname && search.surname !== '' && (
                <FilterLabel
                  key='surname'
                  label={search.surname}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      surname: '',
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {search.initials && search.initials !== '' && (
                <FilterLabel
                  key='initials'
                  label={search.initials}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      initials: '',
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {search.employeeNr && search.employeeNr !== '' && (
                <FilterLabel
                  key='employeeNr'
                  label={search.employeeNr}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      employeeNr: '',
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {search.workLocations &&
                search.workLocations.map((wl) => (
                  <FilterLabel
                    key={`workLocation${wl}`}
                    label={workLocations.filter((a) => a.id === wl)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        workLocations: search.workLocations.filter((w) => w !== wl),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {search.sections &&
                search.sections.map((s) => (
                  <FilterLabel
                    key={`section${s}`}
                    label={sections.filter((a) => a.id === s)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        sections: search.sections.filter((w) => w !== s),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {search.employmentCategories &&
                search.employmentCategories.map((s) => (
                  <FilterLabel
                    key={`employmentCategories${s}`}
                    label={employmentCategories.filter((a) => a.id === s)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        employmentCategories: search.employmentCategories.filter((w) => w !== s),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {search.applications &&
                search.applications.map((a) => (
                  <FilterLabel
                    key={`application${a}`}
                    label={applications.filter((app) => app.id === a)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        applications: search.applications.filter((app) => app !== a),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {roles &&
                search.roles &&
                search.roles.map((r) => (
                  <FilterLabel
                    key={`role${r}`}
                    label={roles.filter((ro) => ro.id === r)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        roles: search.roles.filter((ro) => ro !== r),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {hasSearchFilters(search) && (
                <FilterReset
                  key='reset'
                  label='Fjern alle'
                  onRemove={() => {
                    runSearch({
                      ...emptySearch
                    });
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Button type='submit' text='Søk' />
            </Col>
          </Row>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <PagedTable
              startIndex={startIndex}
              totalHits={usersPaged.pageInfo?.totalCount ?? 0}
              pageSize={pageSize}
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
            >
              <thead>
                <tr>
                  <ThSortable
                    title='Navn'
                    field={InternalUserSearchOrderBy.Name}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Initial'
                    field={InternalUserSearchOrderBy.Initials}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Ansattnummer'
                    field={InternalUserSearchOrderBy.EmployeeId}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Stillingskategori'
                    field={InternalUserSearchOrderBy.EmploymentCategory}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Seksjon'
                    field={InternalUserSearchOrderBy.SectionCode}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Arbeidssted'
                    field={InternalUserSearchOrderBy.WorkLocation}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                </tr>
              </thead>

              <tbody>
                {usersPaged.data?.map((u) => (
                  <tr key={u.internalUserId ?? ''}>
                    <td>
                      <Link to={`${RoutePaths.internalusers}/${u.internalUserId}`}>{`${u.name}`}</Link>
                    </td>
                    <td>{u.initials}</td>
                    <td>{u.employeeId}</td>
                    <td>{u.employmentCategory}</td>
                    <td>{u.sectionCode}</td>
                    <td>{toFullname(u.workLocation)}</td>
                  </tr>
                ))}
              </tbody>
            </PagedTable>
          )}
        </FormContainer>
      </Container>
    </Page>
  );
}

export default InternalUsers;
