/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    PermissionConsumptionResponse,
    PermissionConsumptionResponseFromJSON,
    PermissionConsumptionResponseToJSON,
    PermissionCreateRequest,
    PermissionCreateRequestFromJSON,
    PermissionCreateRequestToJSON,
    PermissionResponse,
    PermissionResponseFromJSON,
    PermissionResponseToJSON,
    PermissionResponsePagedResult,
    PermissionResponsePagedResultFromJSON,
    PermissionResponsePagedResultToJSON,
    PermissionSearchOrderBy,
    PermissionSearchOrderByFromJSON,
    PermissionSearchOrderByToJSON,
    PermissionSearchRequest,
    PermissionSearchRequestFromJSON,
    PermissionSearchRequestToJSON,
    PermissionUpdateRequest,
    PermissionUpdateRequestFromJSON,
    PermissionUpdateRequestToJSON,
    ResourceFilter,
    ResourceFilterFromJSON,
    ResourceFilterToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeToJSON,
} from '../models';

export interface ApiPermissionsCheckusageGetRequest {
    id?: number;
}

export interface ApiPermissionsGetRequest {
    pageNumber?: number;
    pageSize?: number;
    sortOrder?: SortOrder;
    orderField?: PermissionSearchOrderBy;
    applicationId?: string;
    isGlobal?: boolean;
    textFilter?: string;
    roleId?: number;
    permissionId?: number;
    userType?: UserType;
    userId?: string;
    resourceFilter?: ResourceFilter;
    requireUserOrganizationInMinSide?: boolean;
}

export interface ApiPermissionsIdDeleteRequest {
    id: number;
}

export interface ApiPermissionsIdPutRequest {
    id: number;
    permissionUpdateRequest?: PermissionUpdateRequest;
}

export interface ApiPermissionsPostRequest {
    permissionCreateRequest?: PermissionCreateRequest;
}

export interface ApiPermissionsSearchesPostRequest {
    permissionSearchRequest?: PermissionSearchRequest;
}

export interface GetPermissionbyIdRequest {
    id: number;
}

/**
 * 
 */
export class PermissionsApi extends runtime.BaseAPI {

    /**
     */
    async apiPermissionsCheckusageGetRaw(requestParameters: ApiPermissionsCheckusageGetRequest): Promise<runtime.ApiResponse<PermissionConsumptionResponse>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/permissions/checkusage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionConsumptionResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPermissionsCheckusageGet(requestParameters: ApiPermissionsCheckusageGetRequest): Promise<PermissionConsumptionResponse> {
        const response = await this.apiPermissionsCheckusageGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiPermissionsGetRaw(requestParameters: ApiPermissionsGetRequest): Promise<runtime.ApiResponse<PermissionResponsePagedResult>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['orderField'] = requestParameters.orderField;
        }

        if (requestParameters.applicationId !== undefined) {
            queryParameters['applicationId'] = requestParameters.applicationId;
        }

        if (requestParameters.isGlobal !== undefined) {
            queryParameters['isGlobal'] = requestParameters.isGlobal;
        }

        if (requestParameters.textFilter !== undefined) {
            queryParameters['textFilter'] = requestParameters.textFilter;
        }

        if (requestParameters.roleId !== undefined) {
            queryParameters['roleId'] = requestParameters.roleId;
        }

        if (requestParameters.permissionId !== undefined) {
            queryParameters['permissionId'] = requestParameters.permissionId;
        }

        if (requestParameters.userType !== undefined) {
            queryParameters['userType'] = requestParameters.userType;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.resourceFilter !== undefined) {
            queryParameters['resourceFilter'] = requestParameters.resourceFilter;
        }

        if (requestParameters.requireUserOrganizationInMinSide !== undefined) {
            queryParameters['requireUserOrganizationInMinSide'] = requestParameters.requireUserOrganizationInMinSide;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiPermissionsGet(requestParameters: ApiPermissionsGetRequest): Promise<PermissionResponsePagedResult> {
        const response = await this.apiPermissionsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiPermissionsIdDeleteRaw(requestParameters: ApiPermissionsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPermissionsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/permissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPermissionsIdDelete(requestParameters: ApiPermissionsIdDeleteRequest): Promise<void> {
        await this.apiPermissionsIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiPermissionsIdPutRaw(requestParameters: ApiPermissionsIdPutRequest): Promise<runtime.ApiResponse<PermissionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPermissionsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/permissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionUpdateRequestToJSON(requestParameters.permissionUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPermissionsIdPut(requestParameters: ApiPermissionsIdPutRequest): Promise<PermissionResponse> {
        const response = await this.apiPermissionsIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiPermissionsPostRaw(requestParameters: ApiPermissionsPostRequest): Promise<runtime.ApiResponse<PermissionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/permissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionCreateRequestToJSON(requestParameters.permissionCreateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiPermissionsPost(requestParameters: ApiPermissionsPostRequest): Promise<PermissionResponse> {
        const response = await this.apiPermissionsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiPermissionsSearchesPostRaw(requestParameters: ApiPermissionsSearchesPostRequest): Promise<runtime.ApiResponse<PermissionResponsePagedResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/permissions/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionSearchRequestToJSON(requestParameters.permissionSearchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionResponsePagedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiPermissionsSearchesPost(requestParameters: ApiPermissionsSearchesPostRequest): Promise<PermissionResponsePagedResult> {
        const response = await this.apiPermissionsSearchesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPermissionbyIdRaw(requestParameters: GetPermissionbyIdRequest): Promise<runtime.ApiResponse<PermissionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPermissionbyId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/permissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionResponseFromJSON(jsonValue));
    }

    /**
     */
    async getPermissionbyId(requestParameters: GetPermissionbyIdRequest): Promise<PermissionResponse> {
        const response = await this.getPermissionbyIdRaw(requestParameters);
        return await response.value();
    }

}
