/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InternalUserSearchOrderBy {
    Name = 'Name',
    Initials = 'Initials',
    EmployeeId = 'EmployeeId',
    EmploymentCategory = 'EmploymentCategory',
    SectionCode = 'SectionCode',
    WorkLocation = 'WorkLocation'
}

export function InternalUserSearchOrderByFromJSON(json: any): InternalUserSearchOrderBy {
    return InternalUserSearchOrderByFromJSONTyped(json, false);
}

export function InternalUserSearchOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalUserSearchOrderBy {
    return json as InternalUserSearchOrderBy;
}

export function InternalUserSearchOrderByToJSON(value?: InternalUserSearchOrderBy | null): any {
    return value as any;
}

